import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH, QuestionTypes } from "@remar/shared/dist/constants";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";

import {
	FixErrorPosition,
	useTestOnlyQuestionStyles
} from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";
import AnswerOption from "../components/AnswerOption/AnswerOption";
import AnswerOptionDropZone from "../components/AnswerOptionDropZone";

const SequencingQuestion = ({
	createQuestionAnswerOptionItem,
	getFullState,
	setStateValue,
	removeQuestionAttachment,
	uploadQuestionMaterial,
	updateOptionOrder,
	validateForm,
	statePath = "",
	formName = "testOnlyQuestionsForm"
}) => {
	const dispatch = useDispatch();
	let order = 0;
	const classes = useTestOnlyQuestionStyles();
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const lesson = get(useSelector(getFullState), "lesson");
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const {
		_expanded,
		description,
		id,
		text,
		endOfQuestionSummary,
		mainImageKey,
		attachments = [],
		answerOptions: sourceAnswerOptions
	} = questionData;
	const expanded = _expanded.value;

	const answerOptions = [...sourceAnswerOptions].sort((a, b) => {
		if (a.order?.value === undefined || b.order?.value === undefined) return 0;
		return a.order.value - b.order.value;
	});

	useEffect(() => {
		if (answerOptions.length === 0) {
			dispatch(
				createQuestionAnswerOptionItem({
					formName,
					inputsPath: `${fullStatePath}.answerOptions`,
					parentPath: fullStatePath,
					templatePath: "questions.answerOptions"
				})
			);
		}
	}, [answerOptions.length]);

	const nonDeletedAnswerOptsLength = answerOptions.filter(x => !x.deleted.value).length;

	return (
		<CommonQuestionFields
			statePath={statePath}
			lessonId={lesson?.id}
			expanded={expanded}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			attachments={attachments}
			questionId={id.value}
			description={description}
			setStateValue={setStateValue}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
			mediaAfterChildren
			hideDescription
		>
			{expanded && (
				<FixErrorPosition>
					<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
					<CustomInput style={{ flex: 1 }} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
				</FixErrorPosition>
			)}
			<Typography className={classes.addOptionHeader}>ANSWER OPTIONS</Typography>
			<DndProvider backend={HTML5Backend}>
				<AnswerOptionDropZone
					onDrop={({ order }) =>
						dispatch(
							updateOptionOrder({
								newOrder: 0,
								order,
								parentPath: `${formName}.${fullStatePath}`,
								formPath: formName
							})
						)
					}
					index={0}
				>
					<Box style={{ minHeight: 10 }} />
				</AnswerOptionDropZone>
				{answerOptions.map((answerOptionInputs, answerOptionIndex) => {
					const { deleted, isCorrect, text } = answerOptionInputs;
					if (deleted.value) {
						return null;
					}
					// eslint-disable-next-line
					order++;
					return (
						<AnswerOptionDropZone
							key={`answerOption.${answerOptionIndex}`}
							onDrop={({ order }) =>
								dispatch(
									updateOptionOrder({
										newOrder: answerOptionIndex + 1,
										order,
										parentPath: `${formName}.${fullStatePath}`,
										formPath: formName
									})
								)
							}
							index={answerOptionIndex + 1}
						>
							<AnswerOption
								formName={formName}
								statePath={statePath}
								defaultCustomInputOptions={defaultCustomInputOptions}
								order={order}
								typeId={QuestionTypes.Sequencing}
								isCorrect={isCorrect}
								text={text}
								answerOptionIndex={answerOptionIndex}
							/>
						</AnswerOptionDropZone>
					);
				})}
			</DndProvider>
			<Box mt={2}>
				<AddButton
					isDisabled={nonDeletedAnswerOptsLength >= MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH}
					onClick={() => {
						if (nonDeletedAnswerOptsLength < MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH) {
							dispatch(
								createQuestionAnswerOptionItem({
									formName,
									inputsPath: `${fullStatePath}.answerOptions`,
									parentPath: fullStatePath,
									templatePath: "questions.answerOptions"
								})
							);
						}
					}}
					label={"Add option"}
				/>
			</Box>
		</CommonQuestionFields>
	);
};

export default SequencingQuestion;
