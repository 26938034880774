import {
	Box,
	Button,
	FormControlLabel,
	IconButton,
	RadioGroup,
	Typography,
	createStyles,
	makeStyles
} from "@material-ui/core";
import CancelRoundedDefault from "@material-ui/icons/CancelRounded";
import { basic } from "@remar/shared/dist/colors";
import { CustomInput as Input } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		activeTab: { background: `${theme.palette.colors.basic["750"]} !important` },
		schemaContainer: {
			display: "flex",
			flexFlow: "row nowrap"
		},
		highlightTable: {
			tableLayout: "fixed",
			border: 0,
			marginTop: "14px",
			width: "100%",
			"& thead td": {
				background: "#262930"
			},
			"& td:first-child": {
				width: "50%",
				borderRight: "1px solid rgba(151, 151, 151, 0.1)"
			},
			"& tbody td": {
				verticalAlign: "top",
				width: "50%",
				background: "#2a2e37",
				borderTop: "1px solid rgba(151, 151, 151, 0.1)"
			}
		},
		error: { color: "red" },
		centerAlign: { display: "flex", alignItems: "center" },
		multipleResponsegGroupTable: {
			tableLayout: "fixed",
			borderCollapse: "collapse",
			bacgrkound: "#2a2e37",
			"& td": {
				border: "solid 1px rgba(151, 151, 151, .1)",
				padding: "10px",
				verticalAlign: "top"
			},
			"& td:first-child": {
				borderLeft: "none"
			},
			"& tr:first-child td": {
				borderTop: "none"
			},
			"& tr:last-child td": {
				borderBottom: "none"
			},
			"& td:last-child": {
				borderRight: "none"
			},
			"& tr:first-child": {
				background: "#262930",
				"& td:last-child": {
					width: "70%",
					textAlign: "center"
				}
			}
		},
		addOptionLabel: {
			color: "rgba(255, 255, 255, 0.6)",
			fontSize: "15px",
			fontWeight: 600,
			lineHeight: 1.6
		},
		addOptionButtonContainer: {
			cursor: "pointer",
			width: "fit-content",
			"& button": {
				height: "20px",
				marginRight: "8px",
				minWidth: "20px",
				padding: "0px"
			}
		},
		flexTop: {
			alignItems: "start !important",
			justifyContent: "start !important"
		},
		shortSchemaColumn: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "304px",
			flexShrink: 1,
			flexGrow: 1
		},
		longSchemaColumn: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "513px",
			flexShrink: 1,
			flexGrow: 1
		},
		schemaSeparator: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "77px",
			flexShrink: 1,
			flexGrow: 1
		},
		schemaTopDiag: {
			height: "25%",
			width: "100%",
			position: "relative",
			background: `linear-gradient(to top right,
					transparent 0%,
					transparent calc(50% - 1.3px),
					${theme.palette.colors.basic["800"]} calc(50% - 1px),
					${theme.palette.colors.basic["800"]} calc(50% + 1px),
					transparent calc(50% + 1.3px),
					transparent 100%
				)`,
			"& > i": {
				display: "block",
				borderRadius: "50%",
				position: "absolute",
				background: theme.palette.colors.basic["800"],
				border: `2px solid ${theme.palette.colors.basic["900"]}`,
				width: "8px",
				height: "8px"
			},
			"& > i:first-child": {
				bottom: 0,
				right: 0,
				transform: "translate(50%, 50%)"
			},
			"& > i:last-child": {
				top: 0,
				left: 0,
				transform: "translate(-50%, -50%)"
			}
		},
		schemaBottomDiag: {
			height: "25%",
			width: "100%",
			position: "relative",
			background: `linear-gradient(to bottom right,
					transparent 0%,
					transparent calc(50% - 1.3px),
					${theme.palette.colors.basic["800"]} calc(50% - 1px),
					${theme.palette.colors.basic["800"]} calc(50% + 1px),
					transparent calc(50% + 1.3px),
					transparent 100%
				)`,
			"& > i": {
				display: "block",
				borderRadius: "50%",
				position: "absolute",
				background: theme.palette.colors.basic["800"],
				border: `2px solid ${theme.palette.colors.basic["900"]}`,
				width: "8px",
				height: "8px"
			},
			"& > i:first-child": {
				top: 0,
				right: 0,
				transform: "translate(50%, -50%)"
			},
			"& > i:last-child": {
				bottom: 0,
				left: 0,
				transform: "translate(-50%, 50%)"
			}
		},
		groupsContainer: {
			display: "flex",
			flexFlow: "row nowrap"
		},
		groupColumn: {
			flexBasis: "33%"
		},
		shcemaBox: {
			background: theme.palette.colors.basic["800"],
			margin: "5px 0",
			padding: "0 14px",
			width: "100%",
			height: "48px",
			borderRadius: "4px",
			opacity: "0.87",
			fontFamily: "var(--openSans)",
			fontSize: "13px",
			fontWeight: 600,
			color: theme.palette.colors.basic["100"],
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			flexGrow: 0,
			flexShrink: 1
		},
		tab: {
			background: theme.palette.colors.basic["800"],
			whiteSpace: "nowrap",
			height: "31px",
			marginTop: "5px",
			display: "flex",
			alignItems: "center",
			width: "100%",
			padding: "0 8px",
			cursor: "pointer",
			justifyContent: "space-between",
			fontSize: "15px",
			fontWeight: 600
		},
		select: {
			color: theme.palette.colors.basic["100"],
			textTransform: "uppercase",
			opacity: 0.38,
			width: "220px",
			fontFamily: "var(--openSans)",
			fontSize: "12px",
			fontWeight: "bold",
			lineHeight: 1.33,
			"& > div": {
				backgroundColor: "transparent"
			}
		},
		container: {
			background: theme.palette.colors.basic["900"],
			marginRight: 32,
			marginTop: 16,
			display: "flex",
			flexDirection: "column",
			padding: 24
		},
		rationalContainer: {
			background: theme.palette.colors.basic["900"],
			marginTop: 16,
			display: "flex",
			flexDirection: "column"
		},
		rationalText: {
			marginBottom: "10px"
		},
		addOptionHeader: {
			color: "#ffffff",
			fontSize: "12px",
			fontWeight: "bold",
			lineHeight: 1.33,
			opacity: 0.38,
			marginTop: 16
		},
		alignSelf: {
			alignSelf: "center"
		},
		duplicateTabsButton: {
			marginTop: "16px",
			color: theme.palette.colors.primary[1900],
			fontSize: "12px",
			fontWeight: 700,
			backgroundColor: theme.palette.colors.primary[2000]
		}
	})
);

export const InputContainer = styled.div`
	width: calc(29% - 20px);
	display: flex;
	align-items: center;
`;

export const TabText = styled.div`
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CancelRounded = styled(CancelRoundedDefault)`
	color: red;
`;

export const StyledButton = styled(Button)``;

export const MatrixGroupLabel = styled.div`
	min-width: 60%;
	max-width: 60%;
	display: flex;
	background: #262930;
	border: 1px solid rgba(151, 151, 151, 0.3);

	[class*="CustomInputComponent-MuiFilledInput"] {
		border: none !important;
	}

	.CustomInputError {
		position: static !important;
	}
`;
export const MatrixTableGroup = styled.div`
	flex-grow: 1;
	display: flex;
	background: #262930;
	border: 1px solid rgba(151, 151, 151, 0.3);

	[class*="CustomInputComponent-MuiFilledInput"] {
		border: none !important;
	}

	.CustomInputError {
		position: static !important;
	}
`;

export const CancelContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: end;
	flex: 1;
	margin-right: 16px;
`;

export const Page = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 4px;
	display: flex;
	color: #fff;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	margin: 0 2px;
	cursor: pointer;
	${({ active }) => (active ? "background: #264fae;" : "")}
`;

export const QuestionInputLabel = styled.div`
	padding: 30px;
	opacity: 0.87;
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #fff;
	margin-top: ${props => props.index === 5 && "5px"};
`;

export const QuestionInputWrapper = styled.div`
	padding: 20px;
	width: 30%;
`;

export const HeaderTitle = styled(Typography)`
	font-size: 15px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const HeaderWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${basic["800"]};
	padding: 20px;
	border-radius: 4px 4px 0 0;
`;

export const CustomInput = styled(Input)`
	width: 100%;
	margin: 5px 0 0;
	${({ noMargin }) => (noMargin ? "margin: 0" : "")}
`;
export const InvisibleInput = styled(Input)`
	width: 100%;
	margin: 5px 0 0;

	* {
		border: none !important;
		background: none !important;
	}
`;

export const CustomTabTitle = styled(Input)`
	width: 100%;
	margin: 5px 0 0;

	& input {
		padding-top: 6px !important;
	}
`;

export const InputsWrapper = styled(Box)`
	display: flex;
	background-color: ${basic["900"]};
	border-radius: 0 0 4px 4px;
`;

export const RadioGroupWrapper = styled(RadioGroup)`
	flex-direction: row;
	width: 100%;
	background-color: ${basic["800"]};
`;

export const CheckBoxWrapper = styled(Box)`
	flex-direction: row;
	width: 100%;
	display: flex;
	background-color: ${basic["800"]};
`;

export const FormControlLabelWrapper = styled(FormControlLabel)`
	flex-grow: 1;
	justify-content: center;
	margin: 0;
	border: solid 1px rgb(151, 151, 151, 0.3);
`;

export const RemoveGapWrapper = styled(Box)`
	display: flex;
	max-width: 40px;
	flex-basis: 3%;
	align-items: center;
	padding-top: 16px;
	padding-left: 8px;
`;

export const InputStyles = {
	borderRadius: 0,
	border: "solid 1px rgb(151, 151, 151, 0.3)"
};

export const FlexStyledInput = styled(CustomInput)`
	flex: 1;
`;

export const IconButtonStyled = styled(IconButton)`
	color: ${({ theme }) => theme.palette.colors.basic[100]};
`;
export const GroupContainer = styled.div`
	display: flex;
	${({ error }) => (error ? "margin-bottom: 16px" : "")}
`;

export const StyledBoxContainer = styled(Box)<{ isDisabled: boolean }>`
	${({ isDisabled }) => (isDisabled ? "cursor: not-allowed" : "")}
`;
