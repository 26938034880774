import { Box, Breadcrumbs as MuiBreadcrumbs } from "@material-ui/core";
import styled from "styled-components";

export const Breadcrumbs = styled(MuiBreadcrumbs)`
	li:nth-child(even) {
		display: none;
		visibility: hidden;
	}
`;

export const BreadcrumbItem = styled(Box)`
	color: rgba(255, 255, 255, 0.6);
	padding: 18px;
	width: 187px;
	cursor: pointer;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;
	height: 64px;
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 36px;
	padding-left: 36px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: ${props =>
		props.isActive ? props.theme.palette.colors.info[700] : props.theme.palette.colors.basic[900]};

	&:after {
		z-index: 1;
		position: absolute;
		top: 0;
		right: -25px;
		display: block;
		border-left: ${props =>
			`25px solid ${props.isActive ? props.theme.palette.colors.info[700] : props.theme.palette.colors.basic[900]}`};
		border-top: 32px solid transparent;
		border-bottom: 32px solid transparent;
		width: 0;
		height: 0;
		content: " ";
	}

	${props => {
		if (props.notFirstIndex) {
			return `
       border-top-left-radius: 0px;
       border-bottom-left-radius: 0px;
       &:before{
          position: absolute;
          top: 0;
          left: -25px;
          display: block;
      		border-left:  25px solid transparent;
          border-top: 32px solid ${
						props.isActive ? props.theme.palette.colors.info[700] : props.theme.palette.colors.basic[900]
					};
          border-bottom: 32px solid ${
						props.isActive ? props.theme.palette.colors.info[700] : props.theme.palette.colors.basic[900]
					};
          width: 0;
          height: 0;
          content: " ";
          }
    `;
		}
	}}
`;
