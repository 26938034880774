import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

export const ModuleContainer = styled(Grid)`
	padding: 24px 0 0 0;
`;

export const ModuleWrapper = styled(Grid)`
	.filter-container {
		display: flex;
		gap: 16px;
		align-items: center;

		.filter-selector {
			display: flex;
			flex-direction: column;
		}

		svg {
			color: hsl(224, 16%, 41%);
		}
	}

	.report-card {
		display: flex;
		padding: 1.2rem;
		background-color: ${({ theme }) => theme.palette.background.paper};
		border-radius: 0.2rem;

		${({ theme }) => theme.breakpoints.down("sm")} {
			flex-direction: column;
			align-items: center;
		}
	}
`;

export const TotalText = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(15)};
	color: ${({ theme }) => theme.palette.text.primary};
	font-weight: 600;
`;

export const TitleText = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.text.secondary};
`;
