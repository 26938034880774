import React, { useState } from "react";

import { Box, Button, Checkbox, CircularProgress, FormControlLabel } from "@material-ui/core";
import { TodaySharp } from "@material-ui/icons";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import { useDispatch, useSelector } from "react-redux";

import {
	assignCourseSubscription,
	getAllStudents,
	getFullState,
	getStudentAccount
} from "store/features/Students/students.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { Input, useStyles } from "../../styles";

const AmountInput = ({ refund, setRefund, amount, setAmount, setAmountValid, amountValid }) => {
	const classes = useStyles();

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const amount = event.target.value as number;
		setAmountValid(amount > 0 && amount <= 1000);
		setAmount(amount);
	};

	return (
		<>
			<Box className={classes.input}>
				<Box display="flex" justifyContent="space-between">
					<Box className={classes.refundText} ml={2} mt={1}>
						Add Additional Charge?
					</Box>
					<FormControlLabel
						label=""
						className={classes.amountCheckbox}
						control={
							<Checkbox value="refund" checked={refund} onChange={() => setRefund(refund => !refund)} color="primary" />
						}
					/>
				</Box>
				{refund && (
					<Input
						errorText={(!amount && "Required") || (!amountValid && "Amount should be between 1 to 1000")}
						onChange={handleChange}
						value={amount}
						type="number"
						placeholder="Enter Amount in $"
					/>
				)}
			</Box>
		</>
	);
};

const AssignCourseModal = ({
	modalOpen,
	setModalOpen,
	text,
	subscriptionTypeId,
	userId,
	charges,
	setCharges,
	amount,
	setAmount,
	fetchAccount
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { manageSubscriptionLoading } = useSelector(getFullState);
	const [amountValid, setAmountValid] = useState(false);

	const onClose = () => {
		if (!manageSubscriptionLoading) {
			setModalOpen(false);
		}
	};

	const sideEffect = (e, notificationType) => {
		if (!manageSubscriptionLoading) {
			notificationType !== "error" && setModalOpen(false);
			dispatch(_emit(notificationType === "error" ? e.message : e, notificationType));
			notificationType === "success" &&
				(fetchAccount ? dispatch(getStudentAccount({ id: userId })) : dispatch(getAllStudents({})));
		}
	};

	const handleAssignCourse = () => {
		dispatch(assignCourseSubscription({ subscriptionTypeId, userId, amount, sideEffect, refund: charges }));
	};

	return (
		<>
			<SimpleModal
				theme={theme}
				open={modalOpen}
				onClose={onClose}
				text={text}
				extraContent={
					<AmountInput
						refund={charges}
						setRefund={setCharges}
						amount={amount}
						setAmount={setAmount}
						amountValid={amountValid}
						setAmountValid={setAmountValid}
					/>
				}
				icon={<TodaySharp style={{ color: "#586179", width: "50px", height: "50px" }} />}
				footer={
					<>
						<Button className={classes.cancelBtn} size="medium" disabled={manageSubscriptionLoading} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							className={classes.save}
							onClick={handleAssignCourse}
							disabled={charges && !amountValid}
						>
							{manageSubscriptionLoading ? (
								<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
							) : (
								<>Assign Course</>
							)}
						</Button>
					</>
				}
			/>
		</>
	);
};

export default AssignCourseModal;
