import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FindResponse } from "@remar/shared/dist/api/baseApiService";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { Question, QuestionTypeOptions, QuestionsCount, Subjects } from "@remar/shared/dist/models";
import { ManageSubject, ManageSubjects } from "@remar/shared/dist/models/manageSubjects.model";
import { LocationEnum } from "@remar/shared/dist/services/subjectCategories/modal";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { set } from "lodash";
import { RootState } from "store";

import { questionTypesService, questionsService } from "store/services";

import { communityPoolService } from "../../services/communityPool/communityPool.service";
import {
	ManageCommunitySubjectsCreateDto,
	ManageCommunitySubjectsDeleteDto,
	ManageCommunitySubjectsUpdateDto
} from "../../services/communityPool/dto";
import { emit } from "../notifications/notifications.slice";

export interface manageCommunityPoolSubjectState {
	isLoading: boolean;
	isDeleteLoading: boolean;
	submitLoading: boolean;
	subjectDeleteLoading: boolean;
	manageCommunityPoolSubject: ManageSubjects;
	questionsCount: QuestionsCount;
	page: number;
	perPage: number;
	questions: Question[];
	totalItems: number;
	subjects: Subjects[];
	questionTypeOptions: QuestionTypeOptions[];
}

const initialState: manageCommunityPoolSubjectState = {
	isLoading: false,
	isDeleteLoading: false,
	submitLoading: false,
	subjectDeleteLoading: false,
	questionsCount: { totalQuestionsCount: 0, courses: [], isLoading: false },
	page: 1,
	perPage: 10,
	totalItems: 0,
	questions: [],
	subjects: [],
	questionTypeOptions: [],
	manageCommunityPoolSubject: {
		page: 1,
		perPage: 0,
		items: [],
		totalItems: 0,
		totalPages: 0,
		more: false
	}
};

export const fetchQuestionTypes = createAsyncThunk("manageLocations/fetchQuestionTypes", async (_, { dispatch }) => {
	try {
		const { items } = await questionTypesService.find({
			findAll: true,
			orderBy: { name: "ASC" },
			filters: { forQuestionBank: true }
		});

		dispatch(setQuestionTypeOptions(items.map(({ name, id }) => ({ text: name, value: id }))));
	} catch (e) {
		dispatch(emit({ message: e.message, color: "error" }));
	}
});

export const fetchSubjectCategories = createAsyncThunk(
	"manageLocations/fetchSubjectCategories",
	async (_, { dispatch }) => {
		try {
			const { items } = await communityPoolService.find({ findAll: true });
			dispatch(setSubjectCategories(items));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		}
	}
);

export const fetchCommunityQuestions = createAsyncThunk(
	"manageLocations/fetchQuestions",
	async (
		{
			locationId,
			page: optPage,
			perPage: optPerPage,
			questionTypes,
			searchText,
			subjects
		}: {
			page?: number;
			perPage?: number;
			subjects?: Subjects[] | undefined;
			questionTypes?: string[];
			searchText?: string;
			locationId?: number;
		},
		{ dispatch, getState }
	) => {
		try {
			const { isLoading, page, perPage } = getState() as manageCommunityPoolSubjectState;
			if (!isLoading) {
				dispatch(setLoading(true));
			}

			const filters = { "locations.id": locationId || LocationEnum.Common };

			if (questionTypes) {
				filters["typeId"] = questionTypes;
			}

			if (subjects?.length) {
				filters["subjectCategories.id"] = subjects.map(({ id }) => id);
			}

			if (searchText) {
				filters["text"] = {
					$ilike: `%${searchText}%`
				};
			}

			const response = await questionsService.find({
				page: optPage || page || 1,
				perPage: optPerPage || perPage || 10,
				orderBy: { id: "DESC" },
				include: ["type", "owner", "subjectCategories", "votes", "caseStudyQuestions", "caseStudyQuestions.type"],
				filters
			});
			dispatch(setQuestions(response));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(setLoading(false));
		}
	}
);

export const fetchSubjects = createAsyncThunk(
	"manageLocations/fetchSubjects",
	async (
		options: { perPage: number; page: number; searchKeyword?: string; filters?: Record<string, unknown> },
		{ dispatch }
	) => {
		dispatch(setLoading(true));
		const { perPage, page, searchKeyword, filters } = options;
		try {
			const Subjects = await communityPoolService.find({
				page,
				perPage,
				searchKeyword,
				filters,
				orderBy: { name: "ASC" }
			});
			const { items, totalItems } = Subjects;
			dispatch(setSubjects({ items, totalItems }));
		} catch (e) {
			throw new Error(e.message);
		} finally {
			dispatch(setLoading(false));
		}
	}
);

export const createCommunityPoolSubject = createAsyncThunk(
	"manageCommunityPool/createSubject",
	async ({ options, callback }: { options: ManageCommunitySubjectsCreateDto; callback: () => void }, { dispatch }) => {
		dispatch(setSubmitLoading(true));
		try {
			const subjectCreated: ManageSubject = await communityPoolService.create({ ...options });
			dispatch(addSubject(subjectCreated));
			callback();
			dispatch(emit({ message: "New subject is Added", color: "success" }));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(setSubmitLoading(false));
		}
	}
);

export const updateCommunityPoolSubject = createAsyncThunk(
	"manageCommunityPool/updateSubject",
	async ({ options, callback }: { options: ManageCommunitySubjectsUpdateDto; callback: () => void }, { dispatch }) => {
		dispatch(setSubmitLoading(true));
		try {
			const { subjectId, name } = options;
			const { raw } = await communityPoolService.update({
				data: { name },
				filters: { id: subjectId }
			});
			dispatch(updateSubject(raw[0]));
			callback();
			dispatch(emit({ message: "Subject is updated successfully", color: "success" }));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(setSubmitLoading(false));
		}
	}
);

export const deleteCommunityQuestion = createAsyncThunk(
	"manageLocations/deleteCommunityQuestion",
	async (option: { id: number; cb: () => void }, { dispatch, getState }) => {
		try {
			const { isDeleteLoading } = getState() as manageCommunityPoolSubjectState;
			if (!isDeleteLoading) dispatch(setDeleteLoading(true));
			const { id, cb } = option;

			await questionsService.delete({ filters: { id } });
			cb();
			dispatch(emit({ message: "A question is deleted", color: "success" }));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(setDeleteLoading(false));
		}
	}
);

export const deleteCommunityPoolSubject = createAsyncThunk(
	"manageCommunityPool/deleteSubject",
	async ({ options, callback }: { options: ManageCommunitySubjectsDeleteDto; callback: () => void }, { dispatch }) => {
		dispatch(setDeleteSubjectLoading(true));
		try {
			const { subjectId } = options;
			await communityPoolService.delete({ filters: { id: subjectId } });
			callback();
			dispatch(emit({ message: "Subject was deleted successfully", color: "success" }));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(setDeleteSubjectLoading(false));
		}
	}
);

export const manageCommunityPoolSlice = createSlice({
	name: "manageCommunityPool",
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setDeleteLoading: (state, action) => {
			state.isDeleteLoading = action.payload;
		},
		setQuestionTypeOptions: (state, action) => {
			state.questionTypeOptions = action.payload;
		},
		setSubjectCategories: (state, action) => {
			state.subjects = action.payload;
		},
		setQuestions: (state, action: PayloadAction<FindResponse<Question>>) => {
			const {
				payload: { page: newPage, perPage: newPerPage, items, totalItems }
			} = action;
			set(state, "page", newPage);
			set(state, "perPage", newPerPage);
			set(
				state,
				"questions",
				items.map(item =>
					item.typeId === QuestionTypes.CaseStudy
						? { ...item, hasChildren: true, childItems: item.caseStudyQuestions }
						: item
				)
			);
			set(state, "totalItems", totalItems);
		},
		setSubmitLoading: (state, action) => {
			state.submitLoading = action.payload;
		},
		setDeleteSubjectLoading: (state, action) => {
			state.subjectDeleteLoading = action.payload;
		},
		setSubjects: (state, action) => {
			state.manageCommunityPoolSubject.items = action.payload.items;
			state.manageCommunityPoolSubject.totalItems = action.payload.totalItems;
		},
		addSubject: (state, action) => {
			state.manageCommunityPoolSubject.items = [action.payload, ...state.manageCommunityPoolSubject.items];
		},
		updateSubject: (state, action) => {
			const { items } = state.manageCommunityPoolSubject;
			const { id: subjectId } = action.payload;
			const findItemIndex = items.findIndex(({ id }) => id === subjectId);
			if (findItemIndex > -1) {
				items[findItemIndex] = action.payload;
			}
		},
		deleteSubject: (state, action) => {
			const { items } = state.manageCommunityPoolSubject;
			const findItemIndex = items.findIndex(({ id }) => id === action.payload);
			if (findItemIndex > -1) {
				items.splice(findItemIndex, 1);
			}
		},
		setStateValue: utilsSetStateValue
	}
});

export function getFullState(state: RootState): manageCommunityPoolSubjectState {
	return state.manageCommunityPool;
}

export const {
	setSubjects,
	setLoading,
	setSubmitLoading,
	setDeleteSubjectLoading,
	addSubject,
	deleteSubject,
	updateSubject,
	setStateValue,
	setQuestions,
	setQuestionTypeOptions,
	setSubjectCategories,
	setDeleteLoading
} = manageCommunityPoolSlice.actions;

export default manageCommunityPoolSlice.reducer;
