import { Box } from "@material-ui/core";
import styled from "styled-components";

interface NavItemProps {
	highlight: boolean;
}

export const Container = styled(Box)`
	top: 0;
	bottom: 0;
	padding-top: 12px;
	z-index: 4;
	position: fixed;
	background-color: transparent;
	width: 100px;
	transition: width 0.2s;

	.title {
		display: none;
	}

	.highlight {
		transition: all 0.1s;
	}

	.MuiCollapse-wrapper {
		display: none;
	}

	.expand-chevron {
		display: none;
		transition: all 0.2s;
		padding: 2px;
	}

	&:hover {
		.MuiCollapse-wrapper {
			display: inherit;
		}

		.expand-chevron {
			display: inherit;
		}

		.highlight {
			background: #192435;
		}

		width: 280px;
		background: linear-gradient(
			90deg,
			rgba(19, 22, 30, 1) 0%,
			rgba(19, 22, 30, 0.89) 50%,
			rgba(19, 22, 30, 0.6) 80%,
			rgba(19, 22, 30, 0) 100%
		);

		.title {
			margin-top: 6px;
			display: block;
			margin-left: 16px;
		}

		img {
			margin-left: 10px;
			width: 83px;
			height: 42px;
		}

		.navItem {
			width: 280px;
		}
	}
`;

export const LogoContainer = styled.div`
	margin-left: 10px;

	img {
		transition: height, width, margin 0.2s;
		height: 24px;
		width: 47px;
	}
`;

export const NavItemContainer = styled.div`
	position: absolute;
	top: 8vh;
	height: 92vh;
	overflow-y: scroll;
`;

export const NavItem = styled.div<NavItemProps>`
	cursor: pointer;
	font-size: 13px;
	line-height: 1.23;
	color: rgba(255, 255, 255, 0.6);
	transition: all 0.2s;

	.item-box {
		padding: 12px 20px;
		display: flex;

		.nested {
			padding-left: 36px;
		}
	}

	${props => {
		if (props.highlight) {
			return `
				background: #192435;
			`;
		}
	}}
	&:hover {
		${props =>
			props.route &&
			`background: linear-gradient(
			to left,
			rgba(19, 22, 30, 0.12),
			rgba(24, 30, 43, 0.24) 73%,
			#293c5a);`}
	}
`;
