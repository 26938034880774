import React from "react";

import { Box, Button, Card, CircularProgress, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "../../styles";

interface Props {
	open: boolean;
	title: string;
	message: string;
	cancelBtnText: string;
	deleteBtnText: string;
	onClose: () => void;
	onDelete: () => void;
	deleteLoading?: boolean;
	children?;
}

//todo: edge case of dialog modal. Can be updated reusing dialog modal
const DeleteModal = ({
	open,
	title,
	message,
	cancelBtnText,
	deleteBtnText,
	onClose,
	onDelete,
	deleteLoading,
	children
}: Props) => {
	const classes = useStyles();
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={open} onClose={onClose}>
			<Box width={550}>
				<Card className={classes.card}>
					<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={"30px"}>
						<Typography className={classes.title}>{title}</Typography>
						<IconButton className={classes.closeIcon} onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<Box>
						<Typography className={classes.subTitle}>{message}</Typography>
					</Box>
					{children}
					<Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.modalFooter}>
						{/*todo: replace button by shared*/}
						<Button className={classes.cancelBtn} size="medium" onClick={onClose}>
							{cancelBtnText}
						</Button>
						{/*todo: replace button by shared*/}
						<Button className={classes.deleteBtn} size="medium" onClick={onDelete} disabled={deleteLoading}>
							{/*todo: after button replacement remove delete loading bc shared button support it natively*/}
							{deleteLoading && (
								<CircularProgress
									size="1rem"
									color="inherit"
									thickness={2}
									variant="indeterminate"
									style={{ marginRight: "5px" }}
								/>
							)}
							{deleteBtnText}
						</Button>
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default DeleteModal;
