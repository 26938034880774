import React from "react";

import { TextareaAutosize, lighten } from "@material-ui/core";
import styled from "styled-components";

const StyledTextArea = styled(TextareaAutosize)`
	min-width: 100%;
	max-width: 100%;
	min-height: 100px;
	max-height: 250px;
	background-color: ${({ theme }) => theme.palette.colors.primary[960]};
	color: ${({ theme }) => theme.palette.text.primary};
	padding: 10px;
	font-family: var(--poppins);
	font-size: 16px;
	text-decoration: none;
	outline: none;
	border-radius: 5px;
	&:hover {
		background-color: ${({ theme }) => lighten(theme.palette.colors.primary[960], 0.13)};
	}
	&:focus {
		background-color: ${({ theme }) => lighten(theme.palette.colors.primary[960], 0.09)};
	}
`;
const TextAreaInput = ({ ...fieldProps }) => {
	return <StyledTextArea {...fieldProps} />;
};

export default TextAreaInput;
