import React from "react";

import { Button } from "@material-ui/core";

import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import { useDispatch } from "react-redux";

import { createPersonalStorageFolder } from "store/features/FileVault/FileVault.slice";

import theme from "theme/default";

import {
	CheckboxContainer,
	CheckboxLabel,
	StyledCheckbox,
	CustomInput as StyledInput,
	SubFolderInputLabel,
	TransparentBgContainer
} from "./styles";

export const CreatePersonalStorageModal = ({
	createPersonalStorageModal,
	onCloseModal,
	defaultCustomInputOptions,
	fileVaultPersonalStorageFolderForm
}) => {
	const dispatch = useDispatch();

	return (
		<SimpleModal
			theme={theme}
			title={
				fileVaultPersonalStorageFolderForm?.inputs?.id?.value
					? "Edit Personal Storage Folder"
					: "Create Personal Storage Folder"
			}
			open={createPersonalStorageModal}
			onClose={onCloseModal}
			text={""}
			extraContent={
				<>
					<StyledInput
						width={150}
						mr={2}
						theme={theme}
						options={{
							...defaultCustomInputOptions,
							inputData: fileVaultPersonalStorageFolderForm.inputs.name
						}}
					/>
					<SubFolderInputLabel>What this folder should contain?</SubFolderInputLabel>
					<TransparentBgContainer>
						<CheckboxContainer>
							<StyledCheckbox
								width={150}
								theme={theme}
								options={{
									...defaultCustomInputOptions,
									inputData: { value: true, type: CustomInputType.Checkbox, readonly: true, error: null }
								}}
							/>
							<CheckboxLabel>
								<span>Date</span>
							</CheckboxLabel>
							<StyledCheckbox
								width={150}
								theme={theme}
								options={{
									...defaultCustomInputOptions,
									inputData: { value: true, type: CustomInputType.Checkbox, readonly: true, error: null }
								}}
							/>
							<CheckboxLabel>
								<span>Files</span>
							</CheckboxLabel>
						</CheckboxContainer>
					</TransparentBgContainer>
				</>
			}
			footer={
				<Button
					variant={"contained"}
					color={"primary"}
					style={{ fontWeight: "bold" }}
					onClick={() => {
						if (fileVaultPersonalStorageFolderForm.valid) {
							dispatch(createPersonalStorageFolder({ sideEffect: onCloseModal }));
						}
					}}
				>
					{fileVaultPersonalStorageFolderForm?.inputs?.id?.value ? "Update" : "Create Folder"}
				</Button>
			}
		/>
	);
};
