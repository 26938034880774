import React from "react";

import { SnackBar } from "@remar/shared/dist/components/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { NotificationItem, dismiss, selectNotifications } from "store/features/notifications/notifications.slice";

const Notifications = () => {
	const dispatch = useDispatch();

	const items: NotificationItem[] = useSelector(selectNotifications);

	const handleClose = (id: number) => {
		dispatch(dismiss(id));
	};

	return <SnackBar items={items} handleClose={id => handleClose(id)} />;
};

export default Notifications;
