import React, { Dispatch, FC, useState } from "react";

import { Box, IconButton } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { CustomInputModel } from "@remar/shared/dist/components/CustomInput/customInput.model";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { ValidateFormAction } from "@remar/shared/dist/utils/form/form.model";
import { SetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";

import { removeQuestionAnserOptionItem, updateCorrectOption } from "store/features/CreateLesson/createLesson.slice";

import theme from "theme/default";

const AnswerOption: FC<{
	answerOptionIndex: number;
	defaultCustomInputOptions: {
		_emit: unknown;
		dispatch: Dispatch<unknown>;
		setStateValue: SetStateValue;
		validateForm?: ValidateFormAction;
	};
	dropZoneIndex?: number;
	isCorrect?: CustomInputModel<boolean>;
	order?: number;
	questionPath?: string;
	text: CustomInputModel<string>;
	typeId: number;
	formName: string;
	statePath: string;
}> = ({
	answerOptionIndex,
	dropZoneIndex,
	defaultCustomInputOptions,
	isCorrect,
	order,
	questionPath,
	text,
	formName = "testOnlyQuestionsForm",
	statePath = "",
	typeId
}) => {
	const [canDrag, setCanDrag] = useState(false);
	const dispatch = useDispatch();
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const [, drag] = useDrag({
		item: { dropZoneIndex, index: answerOptionIndex },
		type: "box",
		canDrag: () => (typeId === QuestionTypes.Sequencing || typeId === QuestionTypes.Grouping) && canDrag,
		end: () => setCanDrag(false)
	});

	return (
		<div ref={drag}>
			<Box
				key={`createLesson-qestionItem-answerOption-${answerOptionIndex}`}
				display="flex"
				flexDirection="row"
				mt={2}
				width="100%"
			>
				<Box display="flex" flexDirection="row" width={"100%"}>
					{typeId === QuestionTypes.Sequencing ? (
						<div
							style={{
								background: "#4d5058",
								borderRadius: 4,
								height: 48,
								width: 48,
								fontSize: 15,
								fontWeight: 600,
								color: "#fff",
								textAlign: "center",
								lineHeight: "48px",
								marginRight: 7
							}}
						>
							{order}
						</div>
					) : (
						isCorrect && (
							<CustomInput
								display="flex"
								alignItems="center"
								theme={theme}
								inputProps={{
									onChange: () =>
										dispatch(
											updateCorrectOption({
												itemIndex: answerOptionIndex,
												parentPath: `${formName}.${fullStatePath}`
											})
										)
								}}
								options={{ ...defaultCustomInputOptions, inputData: isCorrect }}
							/>
						)
					)}
					<CustomInput style={{ flex: 1 }} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
				</Box>
				{(typeId === QuestionTypes.Sequencing || typeId === QuestionTypes.Grouping) && (
					<div
						style={{
							color: "#fff",
							fontSize: 40,
							width: 48,
							lineHeight: "48px",
							cursor: "grabbing",
							textAlign: "center",
							background: "#4d5058",
							borderRadius: 4,
							margin: "0 7px"
						}}
						onMouseDown={() => setCanDrag(true)}
					>
						=
					</div>
				)}
				<Box display="flex" alignItems="center" justifyContent="center" width="51px">
					<IconButton
						size="small"
						style={{ color: "#ffffff" }}
						onClick={() =>
							dispatch(
								removeQuestionAnserOptionItem({
									formName,
									inputsPath: questionPath
										? `inputs.${statePath}.${questionPath}.answerOptions.${answerOptionIndex}`
										: `inputs.${statePath}.answerOptions.${answerOptionIndex}`
								})
							)
						}
					>
						<CancelRounded color="disabled" />
					</IconButton>
				</Box>
			</Box>
		</div>
	);
};

export default AnswerOption;
