import React, { useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import HeaderContainer from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";
import { CUSTOM_PACKAGE, UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Package as LocationPackage } from "@remar/shared/dist/models";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
	ManagePackageState,
	fetchPackage,
	getFullState,
	initializeForm,
	publishPackage,
	resetState,
	saveForm,
	setAddonItem,
	setStateValue,
	validateForm
} from "store/features/ManagePackage/managePackage.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";
import TriangleBreadcrumbs from "modules/Components/TriangleBreadcrumbs";

import LocationDetails from "./LocationDetails";
import PackageDetails from "./PackageDetails";

import { mapLocationPackage } from "./managePackageUtils";

import Package from "../components";

import { Container } from "../styles";

const EDIT_MODE = "edit";

enum StepsEnum {
	packageDetails,
	LocationDetails
}
const ManagePackage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const { courseId, locationPackageTypeId, action } = useParams<{
		courseId: string;
		locationPackageTypeId: string;
		action: string;
	}>();
	const [currentStep, setCurrentStep] = useState(StepsEnum.packageDetails);

	const {
		managePackageForm,
		packageFeaturesForm,
		userSubscriptionTypesForm,
		userSubscriptionTypesFormPerSeat,
		data,
		isPublishLoading,
		isLoading
	} = useSelector(getFullState) as ManagePackageState;
	const { id: locationPackageId, description, isDefault, isActive, locations, invites } = data;
	const { inputs: managePackageInputs } = managePackageForm;
	const { inputs: packageFeaturesFormInputs } = packageFeaturesForm;

	const { inputs: ustFormInputs } = userSubscriptionTypesForm;
	const { inputs: ustFormInputsPerSeat } = userSubscriptionTypesFormPerSeat;
	const { initial, recurring } = ustFormInputs;
	const { initial: initialPerSeat, recurring: recurringPerSeat } = ustFormInputsPerSeat;
	const { stripeItem: initialStripeItem } = initial;
	const initialPriceValue = initialStripeItem.price.value;
	const initialDuration = initialStripeItem.duration.value;
	const initialIsLoading = initialStripeItem.isLoading.value;
	const { stripeItem: recurringStripeItem } = recurring;
	const recurringPriceValue = recurringStripeItem.price.value;
	const recurringIsLoading = recurringStripeItem.isLoading.value;
	const recurringDuration = recurringStripeItem.duration.value;
	const { stripeItem: initialStripeItemPerSeat, addons: perSeatAddons } = initialPerSeat;
	const initialPriceValuePerSeat = initialStripeItemPerSeat.price.value;
	const initialIsLoadingPerSeat = initialStripeItemPerSeat.isLoading.value;
	const initialDurationPerSeat = initialStripeItemPerSeat.duration.value;
	const { stripeItem: recurringStripeItemPerSeat } = recurringPerSeat;
	const recurringIsLoadingPerSeat = recurringStripeItemPerSeat.isLoading.value;
	const recurringPriceValuePerSeat = recurringStripeItemPerSeat.price.value;
	const recurringDurationPerSeat = recurringStripeItemPerSeat.duration.value;
	const locationState = location.state as LocationPackage;
	const isCustomPackage = !isDefault || CUSTOM_PACKAGE === locationPackageTypeId;

	const addons = perSeatAddons
		?.filter(f => (f.isDigital.value ? f.digitalAssetItem.deleted.value : f.shipStationItem.deleted.value) == false)
		.map(item => {
			const {
				shipStationItem: { name: skuName, isLoading: skuLoader },
				digitalAssetItem: { name: digitalAssetName, isLoading: digitalAssetLoader },
				isDigital
			} = item;
			return {
				name: isDigital.value ? digitalAssetName.value : skuName.value,
				loader: isDigital.value ? digitalAssetLoader.value : skuLoader.value
			};
		});
	const handleFetchPackage = locationPackageId => dispatch(fetchPackage({ packageId: locationPackageId }));
	const customSteps = useMemo(
		() => [
			{
				label: "Package Details",
				onClick: () => setCurrentStep(StepsEnum.packageDetails)
			},
			{
				label: "Location",
				onClick: () => setCurrentStep(StepsEnum.LocationDetails)
			}
		],
		[dispatch]
	);

	const breadcrumb = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Manage Locations", key: 1, link: routes.manageLocations.getPath() },
			{ title: "Package Overview", key: 2, link: `${routes.manageLocations.getPath()}/package-overview` },
			{ title: `Manage ${isCustomPackage ? "Custom" : ""} Package`, key: 3 }
		],
		[isCustomPackage]
	);

	useEffect(() => {
		if (locationState) handleFetchPackage(locationState.id);
	}, [locationState]);

	useEffect(() => {
		if (action === EDIT_MODE) {
			const mappedPackageState = mapLocationPackage(data);
			dispatch(initializeForm(mappedPackageState));
			const subscriptionType = data.allowedUST.find(
				f =>
					f.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.LocationPerSeat &&
					f.isRecurring == false &&
					f.isActive == true
			);
			subscriptionType && dispatch(setAddonItem(subscriptionType!));
		}
	}, [action, data]);
	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	const sideEffect = async locationPackageId => {
		if (isCustomPackage && currentStep === StepsEnum.packageDetails) {
			await handleFetchPackage(locationPackageId);
		}
		if (locationPackageTypeId === CUSTOM_PACKAGE) {
			history.push({
				pathname: `${routes.manageLocations.getPath()}/package-overview/manage-package/${courseId}/${+managePackageForm
					.rawData.customLocationPackageId}/edit`,
				state: { id: locationPackageId }
			});
		}
	};
	if (isLoading) {
		return <ContentLoader />;
	}
	return (
		<>
			<Container>
				<HeaderContainer
					heading={"Manage Package"}
					breadcrumb={breadcrumb}
					actions={
						<Box>
							{locationState && (
								<Button
									variant={"filled"}
									color={isActive ? "danger" : "primary"}
									disabled={isPublishLoading}
									loading={isPublishLoading}
									onClick={() =>
										dispatch(publishPackage({ id: locationState.id as number, isActive: isActive as boolean }))
									}
								>
									{isActive ? "Un Publish Package" : "Publish Package"}
								</Button>
							)}
						</Box>
					}
				/>
				<Box mt={1} mb={2}>
					{isCustomPackage && <TriangleBreadcrumbs breadcrumb={customSteps} activeIndex={currentStep} />}
				</Box>

				<Box display={"flex"} mr={2} gridGap={"20px"}>
					<Box flex={2}>
						{currentStep === StepsEnum.packageDetails ? (
							<PackageDetails
								initialStripeItem={initialStripeItem}
								initialStripeItemPerSeat={initialStripeItemPerSeat}
								recurringStripeItem={recurringStripeItem}
								recurringStripeItemPerSeat={recurringStripeItemPerSeat}
								description={description}
								managePackageInputs={managePackageInputs}
								defaultCustomInputOptions={defaultCustomInputOptions}
								packageFeaturesFormInputs={packageFeaturesFormInputs}
								perSeatAddons={perSeatAddons}
								isCustomPackage={isCustomPackage}
							/>
						) : (
							<LocationDetails
								locationPackageId={locationPackageId}
								initialLocations={locations}
								initialInvites={(invites || []).map(i => i.user?.email)}
							/>
						)}
					</Box>
					<Box>
						<Package
							packageName={managePackageInputs.packageName.value}
							initialIsLoading={initialIsLoading}
							recurringIsLoading={recurringIsLoading}
							initialIsLoadingPerSeat={initialIsLoadingPerSeat}
							recurringIsLoadingPerSeat={recurringIsLoadingPerSeat}
							initialPrice={initialPriceValue ? `$${initialPriceValue?.toFixed(2)}` : "-"}
							recurringPrice={recurringPriceValue ? `$${recurringPriceValue?.toFixed(2)}` : "-"}
							initialPricePerSeat={initialPriceValuePerSeat ? `$${initialPriceValuePerSeat?.toFixed(2)}` : "-"}
							recurringPricePerSeat={recurringPriceValuePerSeat ? `$${recurringPriceValuePerSeat?.toFixed(2)}` : "-"}
							locationPackageTypeId={+locationPackageTypeId}
							description={description}
							showStatus={false}
							showEdit={false}
							showDeletePackage={false}
							initialDuration={initialDuration}
							recurringDuration={recurringDuration}
							initialDurationPerSeat={initialDurationPerSeat}
							recurringDurationPerSeat={recurringDurationPerSeat}
							showActionButtons
							addons={addons}
							saveAction={() =>
								saveForm({
									courseId: +courseId,
									isDefault: !isCustomPackage,
									locationPackageTypeId: isCustomPackage
										? +managePackageForm.rawData.customLocationPackageId
										: +locationPackageTypeId,
									sideEffect
								})
							}
						/>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default ManagePackage;
