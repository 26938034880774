import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { NotificationsModel } from "@remar/shared/dist/models";

import { CreateNotificationDto, UpdateNotificationDto } from "./dto";

import { URLS } from "../services.constants";
class NotificationsService extends BaseApiService<
	NotificationsModel,
	CreateNotificationDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<UpdateNotificationDto>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const notificationsService = new NotificationsService(URLS.NOTIFICATIONS);
