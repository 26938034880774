import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

export const AddSeatsCount = styled(Typography)`
	margin: 0 1.5rem;
	color: ${({ theme }) => theme.palette.text.secondary};
`;

export const AddSeatsWrapper = styled(Box)`
	display: flex;
	align-items: center;
	margin-top: 10px;
`;

export const LabelWrapper = styled(Box)`
	padding: 20px 40px;
	& .label.sm {
		font-family: var(--poppins);
		font-weight: bold;
		font-size: 18px;
	}
`;

export const AddSeatsCounterBox = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	width: 140px;
	padding: 7px;
	background: ${props => props.theme.palette.background.default};
	margin-left: auto;
	margin-right: 30px;

	& .addRemoveIcon {
		color: ${props => props.theme.palette.colors.basic[400]};
	}
	svg {
		rect {
			fill: ${props => props.theme.palette.colors.basic[1400]};
			width: 18px;
			height: 2.5px;
		}
	}
`;
