import React, { useState } from "react";

import { Box, Button, Card, CircularProgress, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { useStyles } from "@remar/shared/dist/styles";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";

interface IChangeSubStartDateProps {
	onClose: () => void;
	changeStartSubDate: (date: Date) => void;
	changeSubStartDateLoading: boolean;
	changeSubStartTodayLoading: boolean;
	startDay: Date;
}

const ChangeSubStartDate = ({
	onClose,
	changeStartSubDate,
	changeSubStartDateLoading,
	changeSubStartTodayLoading,
	startDay
}: IChangeSubStartDateProps) => {
	const classes = useStyles();
	const initialDateValue = new Date();
	const [subDate, setSubDate] = useState(initialDateValue);
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={true} onClose={onClose}>
			<Box width={450}>
				<Card>
					<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb="40px">
						<Typography className={classes.title} style={{ marginLeft: "40px" }}>
							Change Starting Date
						</Typography>
						<IconButton className={classes.closeIcon} onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<DatePicker
						autoOk
						open={false}
						disableToolbar
						orientation="portrait"
						variant="static"
						openTo="date"
						minDate={initialDateValue}
						maxDate={startDay}
						value={subDate}
						onChange={date => setSubDate(date)}
					/>
					<Box display="flex" mt={2} justifyContent={"center"}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => changeStartSubDate(initialDateValue)}
							disabled={changeSubStartDateLoading}
							startIcon={
								changeSubStartTodayLoading && (
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								)
							}
						>
							Start Today
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => changeStartSubDate(subDate)}
							style={{ marginLeft: "5px" }}
							disabled={changeSubStartDateLoading}
							startIcon={
								changeSubStartDateLoading && (
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								)
							}
						>
							Start on {formatDate(subDate)}
						</Button>
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default ChangeSubStartDate;
