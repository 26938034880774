import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, IconButton, Modal, TextField, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import Button from "@remar/shared/dist/components/Button";
import { Tag } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import { flatMap, isEmpty } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { getAllLessons, getAllLessonsState } from "store/features/Lesson/lesson.slice";
import { fetchSubjectLessons, getFullState as getFullSubjectState } from "store/features/Subjects/Subjects.slice";
import { createTag, getFullTagsState, updateTag } from "store/features/Tags/Tags.slice";

import { SelectWrapper } from "../style";

const AddEditTagModal = ({ onClose, data }: { onClose: (shouldUpdate?: boolean) => void; data: Tag }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const {
		subjectsLesson,
		subjectsLessonPage: page,
		subjectsLessonPerPage: perPage,
		isLoading
	} = useSelector(getFullSubjectState);
	const { isCreatingEditingDeleting } = useSelector(getFullTagsState);
	const lessons = useSelector(getAllLessonsState);

	const [tagName, setTagName] = useState("");
	const [selectedLessons, setSelectedLessons] = useState<{ name: string; id: number }[]>([]);
	const [selectedSubjects, setSelectedSubjects] = useState<{ name: string; id: number }[]>([]);

	const editMode = useMemo(() => !isEmpty(data), [data]);

	useEffect(() => {
		if (!subjectsLesson.length) dispatch(fetchSubjectLessons({ perPage }));
	}, [subjectsLesson.length]);

	useEffect(() => {
		if (!lessons?.items?.length) dispatch(getAllLessons({ page: 1 }));
	}, [lessons]);

	useEffect(() => {
		if (editMode) {
			setTagName(data.name);
			setSelectedLessons(data.sectionLessons.map(sl => ({ ...sl, id: sl.id, name: sl.lesson.name })));
			setSelectedSubjects(data.subjectLessons);
		}
	}, [editMode, data]);

	const lessonsOptions = useMemo(
		() =>
			flatMap(lessons?.items, ({ lessonSections, name: LessonName }) =>
				lessonSections!.map(l => ({
					id: l!.id,
					name: `${LessonName} - ${l.section!.name}`
				}))
			),
		[lessons]
	);

	const subjectsOptions = useMemo(() => subjectsLesson.map(({ name, id }) => ({ name, id })), [subjectsLesson]);

	const isModalValid = useMemo(
		() => Boolean(tagName && selectedLessons.length && selectedSubjects.length),
		[tagName, selectedLessons, selectedSubjects]
	);

	const loadMoreLessons = () => {
		dispatch(getAllLessons({ page: (lessons?.page || 0) + 1, infinite: true }));
	};

	const loadMoreSubjects = () => {
		dispatch(fetchSubjectLessons({ page: page + 1, perPage: 10, infinite: true }));
	};

	const searchLessons = text => {
		// search not supported yet by API
		console.log(text);
	};

	const searchSubjects = text => {
		dispatch(fetchSubjectLessons({ page: 1, searchKeyword: text }));
	};

	const handleCreateUpdatedTag = () => {
		const body: { id?: number; name: string; sectionLessonIds: number[]; subjectLessonIds: number[] } = {
			name: tagName,
			sectionLessonIds: selectedLessons.map(l => l.id),
			subjectLessonIds: selectedSubjects.map(l => l.id)
		};
		if (data?.id) {
			body.id = data.id;
			const { id, name, sectionLessonIds, subjectLessonIds } = body;
			return dispatch(
				updateTag({
					id,
					name,
					sectionLessonIds,
					subjectLessonIds,
					cb: () => {
						onClose(true);
					}
				})
			);
		}

		dispatch(
			createTag({
				...body,
				cb: () => {
					onClose(true);
				}
			})
		);
	};

	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={true} onClose={() => onClose()}>
			<Box width={500}>
				<Card style={{ padding: "20px 30px" }}>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Box display={"flex"} flexDirection={"column"}>
							<Typography variant="h6">{editMode ? "Edit Tag" : "Add New Tag"}</Typography>
							<Typography variant="caption">
								{editMode ? "Update Custom Tags" : "Create custom tag which you can assign to Lessons & Subjects"}
							</Typography>
						</Box>
						<IconButton onClick={() => onClose()}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<Box mt={2}>
						<Box mb={2}>
							<Box mb={1}>
								<Typography>Tag Name</Typography>
							</Box>
							<TextField
								fullWidth
								hiddenLabel
								InputProps={{ style: { color: "primary" }, disableUnderline: true }}
								inputProps={{ maxLength: 140 }}
								color="primary"
								id="tagName"
								name="tagName"
								onChange={e => setTagName(e.target.value)}
								variant="filled"
								size="small"
								placeholder="Tag Name"
								value={tagName}
							/>
						</Box>
						<Box mb={2}>
							<Box mb={1}>
								<Typography>Assign to Course Lesson</Typography>
							</Box>
							<SelectWrapper>
								<AutocompleteFilter
									options={lessonsOptions}
									value={selectedLessons}
									setValue={val => {
										setSelectedLessons(val);
									}}
									onTextInputChange={searchLessons}
									filterName="Lessons"
									loadMoreResults={loadMoreLessons}
									showChips={true}
								/>
							</SelectWrapper>
						</Box>
						<Box>
							<Box mb={1}>
								<Typography>Assign to Subject Lessons</Typography>
							</Box>
							<SelectWrapper>
								<AutocompleteFilter
									options={subjectsOptions}
									value={selectedSubjects}
									setValue={val => {
										setSelectedSubjects(val);
									}}
									onTextInputChange={searchSubjects}
									filterName="Subjects"
									loadMoreResults={loadMoreSubjects}
									showChips={true}
								/>
							</SelectWrapper>
						</Box>
						<Box mt={3} display={"flex"} justifyContent={"flex-end"}>
							<Button
								variant={"filled"}
								color={"primary"}
								style={{ marginLeft: "auto" }}
								disabled={!isModalValid || isLoading || isCreatingEditingDeleting}
								loading={isCreatingEditingDeleting}
								onClick={handleCreateUpdatedTag}
							>
								Save Tag
							</Button>
						</Box>
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default AddEditTagModal;
