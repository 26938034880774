import React from "react";

import { Box, Button, Card, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { styles } from "./interactiveBlocks.styles";

export const DeleteModalContent = ({
	onDelete,
	onClose,
	title = "Delete VIT Block",
	message = "Are you sure you want to delete this interactive block?"
}: {
	onDelete: () => void;
	onClose: () => void;
	title?: string;
	message?: string;
}) => {
	return (
		<Box width={550}>
			<Card style={styles.card}>
				<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb="40px">
					<Typography style={styles.title}>{title}</Typography>
					<IconButton style={styles.closeIcon} onClick={onClose}>
						<CloseIcon color="disabled" />
					</IconButton>
				</Box>
				<Box>
					<Typography style={styles.subTitle}>{message}</Typography>
				</Box>

				<Box display="flex" justifyContent="flex-end" alignItems="center" style={styles.modalFooter}>
					<Button style={styles.cancelBtn} size="medium" onClick={onClose}>
						No,Cancel
					</Button>
					<Button style={styles.deleteBtn} size="medium" onClick={() => onDelete()}>
						Yes, Delete
					</Button>
				</Box>
			</Card>
		</Box>
	);
};
