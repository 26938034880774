import React from "react";

import ChipInput from "material-ui-chip-input";

const TextFieldChipInput = ({ chips, handleAddChip, handleDeleteChip }) => {
	return (
		<ChipInput
			placeholder={"Email of Institute"}
			fullWidth
			value={chips}
			onAdd={chip => handleAddChip(chip)}
			onDelete={(chip, index) => handleDeleteChip(chip, index)}
		/>
	);
};

export default TextFieldChipInput;
