import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	CustomInputSelectOptionsItem,
	CustomInputType
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import {
	createForm,
	getPopulateInputsAction,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import { performFileUpload } from "@remar/shared/dist/utils/serviceUtils/uploaders";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { AppThunk, RootState } from "store";

import { FileVaultFolderState, fileVaultFolderService, filesService, lessonsService } from "store/services";

import {
	FileVaultFolderFormInputs,
	FileVaultFolderFormRawData,
	FileVaultPersonalStorageFolderFormInputs,
	FileVaultPersonalStorageFolderFormRawData,
	FileVaultSubFolderFormInputs,
	FileVaultSubFolderFormRawData,
	FileVaultUploadFormInputs,
	FileVaultUploadFormRawData
} from "./models";

import { emit } from "../notifications/notifications.slice";

export const fetchFolders = createAsyncThunk("fileVault/fetchFolders", async (searchText: string, { dispatch }) => {
	await dispatch(setStateValue({ key: "isLoading", value: true }));
	const filters = { isTopLevel: true };
	if (searchText) {
		filters["name"] = {
			$ilike: `%${searchText}%`
		};
	}
	try {
		const folders = await fileVaultFolderService.find({
			filters,
			findAll: true,
			orderBy: { createdAt: "ASC" },
			include: ["courses"]
		});

		await dispatch(setStateValue({ key: "content", value: folders.items }));
	} finally {
		await dispatch(setStateValue({ key: "isLoading", value: false }));
	}
});

export const fetchSubFolders = createAsyncThunk(
	"fileVault/fetchSubFolders",
	async (options: { parentId: number; searchText: string }, { dispatch }) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const { parentId, searchText } = options;
			const filters = { parentId, isTopLevel: false };
			if (searchText) {
				filters["name"] = {
					$ilike: `%${searchText}%`
				};
			}
			const { items: folders } = await fileVaultFolderService.find({
				filters,
				orderBy: { createdAt: "ASC" },
				findAll: true,
				include: ["files"]
			});

			await dispatch(setStateValue({ key: "subFolders", value: folders }));
		} finally {
			await dispatch(setStateValue({ key: "isLoading", value: false }));
		}
	}
);

export const fetchSubFolderFiles = createAsyncThunk(
	"fileVault/fetchSubFolderFiles",
	async (
		options: { page?: number; perPage?: number; folderId: number; courseId?: number; searchText?: string },
		{ dispatch, getState }
	) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const { page, perPage } = (getState() as RootState).fileVaultFolder.subFolderFiles;
			const { page: optPage, perPage: optPerPage, folderId, courseId, searchText } = options;
			let filters;
			if (courseId) {
				filters = {
					$or: [{ "folders.id": folderId }, { "lessons.lessonSections.section.chapter.course.id": courseId }]
				};
			} else {
				filters = { "folders.id": folderId };
			}

			if (searchText && searchText.length > 0) {
				filters = {
					...filters,
					fileName: {
						$ilike: `%${searchText}%`
					}
				};
			}

			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await filesService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { createdAt: "DESC" },
				include: ["lessons"],
				filters
			});

			await dispatch(setStateValue({ key: "subFolderFiles.files", value: items }));
			await dispatch(setStateValue({ key: "subFolderFiles.page", value: newPage }));
			await dispatch(setStateValue({ key: "subFolderFiles.perPage", value: newPerPage }));
			await dispatch(setStateValue({ key: "subFolderFiles.totalItems", value: totalItems }));
		} finally {
			await dispatch(setStateValue({ key: "isLoading", value: false }));
		}
	}
);

export const fetchPersonalStorageFolderFiles = createAsyncThunk(
	"fileVault/fetchPersonalStorageFolderFiles",
	async (
		options: { page?: number; perPage?: number; folderId: number; searchText?: string },
		{ dispatch, getState }
	) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const { page, perPage } = (getState() as RootState).fileVaultFolder.personalStorageFolderFiles;
			const { page: optPage, perPage: optPerPage, folderId, searchText = "" } = options;
			const filters = {
				"folders.id": folderId
			};
			if (searchText) {
				filters["name"] = {
					$ilike: `%${searchText}%`
				};
			}
			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await filesService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { createdAt: "DESC" },
				include: ["lessons"],
				filters
			});

			await dispatch(setStateValue({ key: "personalStorageFolderFiles.files", value: items }));
			await dispatch(setStateValue({ key: "personalStorageFolderFiles.page", value: newPage }));
			await dispatch(
				setStateValue({
					key: "personalStorageFolderFiles.perPage",
					value: newPerPage
				})
			);
			await dispatch(
				setStateValue({
					key: "personalStorageFolderFiles.totalItems",
					value: totalItems
				})
			);
		} finally {
			await dispatch(setStateValue({ key: "isLoading", value: false }));
		}
	}
);

export const createFolder = createAsyncThunk(
	"fileVault/createFolder",
	async (options: { sideEffect: (_bool: boolean) => void }, { dispatch, getState }) => {
		try {
			const data = (getState() as RootState).fileVaultFolder.fileVaultFolderForm.rawData;
			const contents = (getState() as RootState).fileVaultFolder.content;
			const existingFolder = contents.find(x =>
				data.id ? x.name === data.name && data.id !== x.id : x.name === data.name
			);
			if (existingFolder) {
				dispatch(emit({ message: "Folder name already exists.", color: "error" }));
				return;
			}
			options.sideEffect(false);
			if (data.id) {
				const { id, ...rest } = data;
				await fileVaultFolderService.update({
					filters: { id },
					data: { ...rest, courseIds: [data.courseId] }
				});
			} else {
				await fileVaultFolderService.create({ ...data, courseIds: [data.courseId] });
			}
			await dispatch(setStateValue({ key: "isLoading", value: true }));
			await dispatch(
				setStateValue({
					key: "fileVaultFolderForm",
					value: initialState.fileVaultFolderForm
				})
			);
			dispatch(fetchFolders(""));
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
		}
	}
);

export const createPersonalStorageFolder = createAsyncThunk(
	"fileVault/createPersonalStorageFolder",
	async (options: { sideEffect: () => void }, { dispatch, getState }) => {
		try {
			const data = (getState() as RootState).fileVaultFolder.fileVaultPersonalStorageFolderForm.rawData;
			const contents = (getState() as RootState).fileVaultFolder.content;
			const existingFolder = contents.find(x =>
				data.id ? x.name === data.name && data.id !== x.id : x.name === data.name
			);
			if (existingFolder) {
				dispatch(emit({ message: "Folder name already exists.", color: "error" }));
				return;
			}
			options.sideEffect();
			if (data.id) {
				const { id, ...rest } = data;
				await fileVaultFolderService.update({
					filters: { id },
					data: { ...rest }
				});
			} else
				await fileVaultFolderService.create({
					...data,
					isPrivate: true,
					isVisible: false
				});
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
		} finally {
			await dispatch(
				setStateValue({
					key: "fileVaultPersonalStorageFolderForm",
					value: initialState.fileVaultPersonalStorageFolderForm
				})
			);
			dispatch(fetchFolders(""));
		}
	}
);

export const createSubFolder = createAsyncThunk(
	"fileVault/createSubFolder",
	async (options: { parentId: number; sideEffect: (_bool: boolean) => void }, { dispatch, getState }) => {
		const { parentId } = options;

		try {
			const data = (getState() as RootState).fileVaultFolder.fileVaultSubFolderForm.rawData;
			const subFolders = (getState() as RootState).fileVaultFolder.subFolders;
			const existedSubFolder = subFolders.find(x =>
				data.id ? x.name === data.name && data.id !== x.id : x.name === data.name
			);
			if (existedSubFolder) {
				dispatch(emit({ message: "Sub Folder name already exists.", color: "error" }));
				return;
			}
			options.sideEffect(false);
			if (data.id) {
				const { id, ...rest } = data;
				await fileVaultFolderService.update({ filters: { id }, data: { ...rest, parentId } });
			} else await fileVaultFolderService.create({ ...data, parentId });

			await dispatch(setStateValue({ key: "isLoading", value: true }));
			await dispatch(
				setStateValue({
					key: "fileVaultSubFolderForm",
					value: initialState.fileVaultSubFolderForm
				})
			);
			dispatch(fetchSubFolders({ parentId, searchText: "" }));
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
		}
	}
);

export const updateFolderVisibility = createAsyncThunk(
	"fileVault/folderVisibility",
	async (options: { isVisible: boolean; index: number; id: number }, { dispatch }) => {
		try {
			const { isVisible, index, id } = options;
			dispatch(setFolderVisibility({ isVisible, index }));
			await fileVaultFolderService.update({ data: { isVisible }, filters: { id } });
		} catch (e) {
			return { error: e };
		}
	}
);

export const loadLessons = createAsyncThunk("fileVault/fetchAllLessons", async (_, { dispatch, rejectWithValue }) => {
	try {
		const { items: lessonsItems } = await lessonsService.find({
			filters: { isActive: true },
			orderBy: { name: "ASC" },
			findAll: true
		});
		const lessonOptions: CustomInputSelectOptionsItem[] = lessonsItems.map(lesson => {
			return { text: lesson.name, value: lesson.id };
		});
		dispatch(setStateValue({ key: "lessons", value: lessonsItems }));
		dispatch(
			setStateValue({
				key: "fileVaultUploadForm.inputs.lessonIds.selectOptions",
				value: lessonOptions
			})
		);
		dispatch(
			setStateValue({
				key: "lessonOptions",
				value: lessonOptions
			})
		);

		return { lessonsItems, lessonOptions };
	} catch (e) {
		const error = e as { message: string };
		rejectWithValue((error && error.message) || "An error has occurred.");
	}
});

export const uploadFileVaultFiles = createAsyncThunk(
	"fileVault/uploadMedia",
	async (
		data: {
			file: Partial<File>;
			statePath: string;
			options: {
				onError: () => void;
				onProgress: ({}: { loaded: number; total: number }) => void;
				onUploaded: () => void;
			};
		},
		{ dispatch, getState }
	) => {
		const { file, statePath, options } = data;
		const filesStatePath = `${statePath}.files`;
		try {
			const { key } = await performFileUpload({ file }, options);
			const newFile = {
				name: file.name!,
				fileName: key!,
				fileType: file.type,
				fileSizeInBytes: file.size
			};
			const existingFiles = (getState() as RootState).fileVaultFolder.uploadFiles.files;
			dispatch(setStateValue({ key: filesStatePath, value: [...existingFiles, newFile] }));
			options.onUploaded();
		} catch (e) {
			console.error(e);
			options.onError();
			dispatch(emit({ message: "An error has occured while uploading.", color: "error" }));
		}
	}
);

export const bulkUploadFiles = createAsyncThunk(
	"fileVault/uploadBulkFiles",
	async (data: { folderId: number; sideEffect?: () => void }, { getState }) => {
		const { folderId, sideEffect } = data;
		const {
			fileVaultFolder: {
				uploadFiles: { files },
				fileVaultUploadForm: {
					rawData: { lessonIds }
				}
			}
		} = getState() as RootState;
		const additionalFileData = {
			folderIds: [{ value: +folderId }],
			lessonIds: lessonIds?.map(lessonId => {
				return { value: lessonId! };
			})
		};
		const filesData = files.map(file => {
			return { ...file, ...additionalFileData };
		});
		const res = await filesService.bulkCreate(filesData);
		if (res) sideEffect && sideEffect();
	}
);

export const deleteFileVaultFile = createAsyncThunk(
	"fileVault/deleteFileVaultFile",
	async (options: { id: number; folderId: number; sideEffect: () => void }, { dispatch }) => {
		const { id, folderId, sideEffect } = options;
		await filesService.delete({
			filters: { id, "folders.id": folderId },
			deleteFromFolder: true
		});
		sideEffect();
		dispatch(
			emit({
				message: "File deleted successfully.",
				color: "success"
			})
		);
	}
);

export const deleteFileFromModal = createAsyncThunk(
	"fileVault/deleteFile",
	async (options: { fileName?: string; sideEffect: () => null }, { dispatch, getState }) => {
		const { fileName, sideEffect } = options;
		const existingFiles = (getState() as RootState).fileVaultFolder.uploadFiles.files;
		//use splice below
		const filteredFiles = existingFiles.filter(file => file.fileName !== fileName);
		dispatch(setStateValue({ key: "uploadFiles.files", value: [...filteredFiles] }));
		sideEffect();
		dispatch(
			emit({
				message: "File deleted successfully.",
				color: "success"
			})
		);
	}
);

export const initialState: FileVaultFolderState = {
	isLoading: false,
	isDeleteLoading: false,
	path: [],
	content: [],
	subFolders: [],
	subFolderFiles: { files: [], page: 1, perPage: 10, totalItems: 0 },
	personalStorageFolderFiles: { files: [], page: 1, perPage: 10, totalItems: 0 },
	lessons: [],
	lessonOptions: [],
	uploadFiles: { files: [], isLoading: false, isDeleteLoading: false },
	fileVaultFolderForm: createForm<FileVaultFolderFormInputs, FileVaultFolderFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "fileVaultFolderForm",
		inputs: {
			id: { type: CustomInputType.Number },
			name: {
				label: "Enter Folder Name",
				placeholder: "Enter Folder Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			},
			isVisible: {
				type: CustomInputType.Checkbox,
				defaultValue: false,
				validations: {}
			},
			courseId: {
				selectOptions: [],
				type: CustomInputType.Select,
				validations: { required: true }
			}
		}
	}),
	fileVaultSubFolderForm: createForm<FileVaultSubFolderFormInputs, FileVaultSubFolderFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "fileVaultSubFolderForm",
		inputs: {
			id: { type: CustomInputType.Number },
			name: {
				label: "Enter Sub Folder Name",
				placeholder: "Enter Sub Folder Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			},
			isNameVisible: {
				type: CustomInputType.Checkbox,
				validations: {}
			},
			includesLessonContent: {
				type: CustomInputType.Checkbox,
				defaultValue: false,
				validations: {}
			},
			isVisible: {
				type: CustomInputType.Checkbox,
				defaultValue: true,
				validations: {}
			}
		}
	}),
	fileVaultUploadForm: createForm<FileVaultUploadFormInputs, FileVaultUploadFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "fileVaultUploadForm",
		inputs: {
			lessonIds: {
				label: "Choose Lesson",
				placeholder: "Choose Lesson",
				selectOptions: [],
				type: CustomInputType.Chips,
				validations: {
					isArray: true
				}
			}
		}
	}),
	fileVaultPersonalStorageFolderForm: createForm<
		FileVaultPersonalStorageFolderFormInputs,
		FileVaultPersonalStorageFolderFormRawData,
		{}
	>({
		defaultValueGetters: {},
		statePath: "fileVaultPersonalStorageFolderForm",
		inputs: {
			id: { type: CustomInputType.Number },
			name: {
				label: "Folder Name",
				placeholder: "Enter Folder Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			},
			isNameVisible: {
				type: CustomInputType.Checkbox,
				validations: {}
			},
			includesLessonContent: {
				type: CustomInputType.Checkbox,
				defaultValue: false,
				validations: {}
			}
		}
	})
};

export const deleteFolder =
	(options: { folderId: number; parentId?: number }): AppThunk =>
	async dispatch => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		const { folderId, parentId } = options;
		await fileVaultFolderService.delete({ filters: { id: folderId } });
		parentId ? dispatch(fetchSubFolders({ parentId, searchText: "" })) : dispatch(fetchFolders(""));
	};

export const getFullState = ({ fileVaultFolder }: RootState): FileVaultFolderState => fileVaultFolder;
const utilsPopulateInputs = getPopulateInputsAction<FileVaultFolderState>({});

export const fileVaultFolderSlice = createSlice({
	name: "fileVaultFolder",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		populateInputs: utilsPopulateInputs,
		setFolderVisibility: (state, action: PayloadAction<{ index: number; isVisible: boolean }>) => {
			state.content[action.payload.index].isVisible = action.payload.isVisible;
		},
		resetModalForm: state => {
			state.fileVaultUploadForm = initialState.fileVaultUploadForm;
			state.uploadFiles = initialState.uploadFiles;
		},
		setSelectedFolder: (state, action: PayloadAction<{ folderId: number }>) => {
			state.selectedFolder = state.content!.find(({ id }) => id === action.payload.folderId);
		},
		setSelectedSubFolder: (state, action: PayloadAction<{ folderId: number }>) => {
			state.selectedSubFolder = state.subFolders!.find(({ id }) => id === action.payload.folderId);
		}
	},
	extraReducers: {
		[deleteFileVaultFile.pending.type]: state => {
			state.isDeleteLoading = true;
		},
		[deleteFileVaultFile.fulfilled.type]: state => {
			state.isDeleteLoading = false;
		},
		[deleteFileVaultFile.rejected.type]: state => {
			state.isDeleteLoading = false;
		}
	}
});

export const {
	validateForm,
	setStateValue,
	setFolderVisibility,
	populateInputs,
	resetModalForm,
	setSelectedFolder,
	setSelectedSubFolder
} = fileVaultFolderSlice.actions;

export default fileVaultFolderSlice.reducer;
// export const { fetchFolders } = fileVaultFolderSlice.actions;
