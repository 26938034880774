import React from "react";

import { makeStyles } from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

import { primary } from "@remar/shared/dist/colors";

const useSwitchStyles = makeStyles(() => ({
	folder: {
		background: "rgb(23, 46, 75, 0.4);",
		margin: "10px"
	},
	switchBase: {
		"&$checked": {
			color: primary[400]
		},
		"&$checked + $track": {
			backgroundColor: primary[200]
		}
	},
	checked: {},
	track: {}
}));

const SwitchInput = ({ name, checked, onChange, ...rest }) => {
	const classes = useSwitchStyles();
	return (
		<Switch
			checked={checked}
			onChange={onChange}
			name={name}
			classes={{
				switchBase: classes.switchBase,
				track: classes.track,
				checked: classes.checked
			}}
			{...rest}
		/>
	);
};

export default SwitchInput;
