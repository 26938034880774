import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { MAX_VIT_ANSWER_OPTIONS, QuestionTypes } from "@remar/shared/dist/constants";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { _emit } from "store/features/notifications/notifications.slice";

import AnswerOption from "../../../../QuestionBank/components/AnswerOption";
import AnswerOptionDropZone from "../../../../QuestionBank/components/AnswerOptionDropZone";
import { useTestOnlyQuestionStyles } from "../../../TestOnlyQuestions/testOnlyQuestions.styles";

const maxActionableVITAnswerOptionCount = MAX_VIT_ANSWER_OPTIONS - 1;
const VITSequencingQuestion = ({
	createQuestionAnswerOptionItem,
	getFullState,
	setStateValue,
	updateOptionOrder,
	validateForm,
	statePath = "",
	formName = "interactiveLessonFormGroup",
	templatePath = "questions.answerOptions"
}) => {
	const dispatch = useDispatch();
	let order = 0;
	const classes = useTestOnlyQuestionStyles();
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const { answerOptions: sourceAnswerOptions } = questionData;

	const answerOptions = [...sourceAnswerOptions].sort((a, b) => {
		if (a.order?.value === undefined || b.order?.value === undefined) return 0;
		return a.order.value - b.order.value;
	});

	useEffect(() => {
		if (answerOptions.length === 0) {
			dispatch(
				createQuestionAnswerOptionItem({
					formName,
					inputsPath: `${fullStatePath}.answerOptions`,
					parentPath: fullStatePath,
					templatePath
				})
			);
		}
	}, [answerOptions.length]);

	const nonDeletedAnswerOptsLength = answerOptions.filter(x => !x.deleted.value).length;

	return (
		<>
			<Box mt={3}>
				<Typography className={classes.addOptionHeader}>ANSWER OPTIONS</Typography>
			</Box>

			<DndProvider backend={HTML5Backend}>
				<AnswerOptionDropZone
					onDrop={({ order }) =>
						dispatch(
							updateOptionOrder({
								newOrder: 0,
								order,
								parentPath: `${formName}.${fullStatePath}`,
								formPath: formName
							})
						)
					}
					index={0}
				>
					<Box style={{ minHeight: 10 }} />
				</AnswerOptionDropZone>
				{answerOptions.map((answerOptionInputs, answerOptionIndex) => {
					const { deleted, isCorrect, text } = answerOptionInputs;
					if (deleted.value) {
						return null;
					}
					// eslint-disable-next-line
					order++;
					return (
						<AnswerOptionDropZone
							key={`answerOption.${answerOptionIndex}`}
							onDrop={({ order }) =>
								dispatch(
									updateOptionOrder({
										newOrder: answerOptionIndex + 1,
										order,
										parentPath: `${formName}.${fullStatePath}`,
										formPath: formName
									})
								)
							}
							index={answerOptionIndex + 1}
						>
							<AnswerOption
								formName={formName}
								statePath={statePath}
								defaultCustomInputOptions={defaultCustomInputOptions}
								order={order}
								typeId={QuestionTypes.Sequencing}
								isCorrect={isCorrect}
								text={text}
								answerOptionIndex={answerOptionIndex}
							/>
						</AnswerOptionDropZone>
					);
				})}
			</DndProvider>
			<AddButton
				isDisabled={nonDeletedAnswerOptsLength >= maxActionableVITAnswerOptionCount}
				onClick={() => {
					if (nonDeletedAnswerOptsLength < maxActionableVITAnswerOptionCount) {
						dispatch(
							createQuestionAnswerOptionItem({
								formName,
								inputsPath: `${fullStatePath}.answerOptions`,
								parentPath: fullStatePath,
								templatePath
							})
						);
					}
				}}
				label={"Add option"}
			/>
		</>
	);
};

export default VITSequencingQuestion;
