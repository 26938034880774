import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { LessonType } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class LessonTypesService extends BaseApiService<
	LessonType,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const lessonTypesService = new LessonTypesService(URLS.LESSON_TYPES);
