import React, { useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import basic from "@remar/shared/dist/colors/basic";

import { StyledSVG } from "../../styles";

const useStyles = makeStyles(() => ({
	questionBox: {
		display: "flex",
		justifyContent: "start",
		width: "260px",
		height: "98px",
		margin: "16px 24px 0 0",
		padding: "24px 38px 22px 24px",
		opacity: 0.8,
		borderRadius: "4px",
		background: props => `${props["bgColor"]}`
	},
	title: {
		fontSize: "15px",
		fontWeight: "normal",
		lineHeight: 1.33,
		letterSpacing: "normal",
		color: basic[100]
	},
	text: {
		fontSize: "26px",
		fontWeight: "bold",
		lineHeight: 1.23,
		letterSpacing: "normal",
		color: basic[100]
	}
}));

const QuestionInfo = ({ customBgColor = "", IconSvg, title, text }) => {
	const [randomValue] = useState(Math.floor(360 * Math.random()));
	const [bgColor] = useState(`hsl(${randomValue},70%,20%)`);
	const classes = useStyles({ bgColor: customBgColor ? customBgColor : bgColor });

	return (
		<Box className={classes.questionBox}>
			<Box mr={2}>{IconSvg ? <IconSvg /> : <StyledSVG $bgColor={bgColor} />}</Box>
			<Box>
				<Box className={classes.title}>{title}</Box>
				<Box className={classes.text}>{text || "0"}</Box>
			</Box>
		</Box>
	);
};

export default QuestionInfo;
