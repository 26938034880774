import React from "react";

import { FormControlLabel, FormGroup, Checkbox as MUICheckbox } from "@material-ui/core";

const Checkbox = ({ label, disabled, value, ...fieldProps }) => (
	<FormGroup>
		<FormControlLabel
			control={<MUICheckbox {...fieldProps} color={"primary"} value={value} checked={value} disabled={disabled} />}
			label={label}
		/>
	</FormGroup>
);

export default Checkbox;
