import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { FileVaultFolder } from "@remar/shared/dist/models";

import { FileVaultFolderCreateDto, FileVaultFolderUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class FileVaultService extends BaseApiService<
	FileVaultFolder,
	FileVaultFolderCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	FileVaultFolderUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const fileVaultFolderService = new FileVaultService(URLS.FILEVAULTFOLDER);
