import React from "react";

import { CancelRounded } from "@material-ui/icons";

import BASIC_COLORS from "../../../colors/basic";

interface proptypes {
	onClick?: () => void;
	color?: string;
	size?: string;
	fill?: string;
}
const CloseIcon = ({ onClick, color, size, fill = BASIC_COLORS[300] }: proptypes) => (
	<CancelRounded style={{ color, fontSize: size, cursor: "pointer", fill }} onClick={onClick} />
);

export default CloseIcon;
