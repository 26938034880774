import React, { ReactElement, useEffect, useState } from "react";

import { Box, Button, FormControl, MenuItem, Select } from "@material-ui/core";

import {
	CUSTOM_PACKAGE,
	LocationPackagesTypeEnum,
	UserSubscriptionTypeCategoriesEnum
} from "@remar/shared/dist/constants";
import { Wrapper } from "@remar/shared/dist/layouts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchAllCourses, getFullState as getCoursesFullState } from "store/features/Course/course.slice";
import { getDurationStringFromDuration } from "store/features/CourseAddEdit/courseAddEdit.slice";
import {
	fetchAllPackages,
	getFullState as getManagePackageFullState,
	resetPackages,
	setCourseId
} from "store/features/ManagePackage/managePackage.slice";

import { routes } from "core/constants";

import {
	AddPackage,
	AddPackageContainer,
	AddPackageIcon,
	AddPackageText,
	CircularProgress,
	DropdownContainer,
	PackagesContainer
} from "./styles";

import Package from "../components";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Locations", key: 1, link: routes.manageLocations.getPath() },
	{ title: "Package Overview", key: 2 }
];

const getPackageNameWithSpace = (pkgName: string): string => {
	return pkgName.substring(0, 7) + " " + pkgName.substring(7, pkgName.length);
};

const getPackageDetails = (ust, pkgType, isRecurring) => {
	const pkg = ust.find(
		pkg =>
			pkg.isActive &&
			pkg.userSubscriptionTypeCategoryId === pkgType &&
			pkg.isRecurring === isRecurring &&
			pkg.isTrial === false
	);
	if (!pkg) return { price: "-", duration: { days: 0, month: 0 } };
	const eIDParentData = pkg?.subTypeEIDItems.find(f => !f.parentId);
	return { price: eIDParentData!["data"]["price"], duration: pkg.duration };
};

const PackageOverview = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { courses } = useSelector(getCoursesFullState);
	const { isLoading, packages } = useSelector(getManagePackageFullState);
	const [currentCourseId, setCurrentCourseId] = useState("");

	useEffect(() => {
		dispatch(fetchAllCourses(true));
		return () => {
			dispatch(resetPackages());
		};
	}, []);

	const handleSelect = id => {
		setCurrentCourseId(id);
		dispatch(fetchAllPackages(+id));
		dispatch(setCourseId(id));
	};

	const keys = Object.keys(LocationPackagesTypeEnum).filter(v => Number(v));
	const getPackageName = key => {
		switch (LocationPackagesTypeEnum[key]) {
			case "Package12":
				return "Package 1+2";
			default:
				return getPackageNameWithSpace(LocationPackagesTypeEnum[key]);
		}
	};
	const getPackage = (locationPackage, i) => {
		const { price: initialPackagePrice, duration: initialDuration } = getPackageDetails(
			locationPackage!.allowedUST,
			UserSubscriptionTypeCategoriesEnum.LocationPackage,
			false
		);
		const { price: recurrringPackagePrice } = getPackageDetails(
			locationPackage!.allowedUST,
			UserSubscriptionTypeCategoriesEnum.LocationPackage,
			true
		);
		const { price: initialPackagePerSeatPrice, duration: initialPackagePerSeatDuration } = getPackageDetails(
			locationPackage!.allowedUST,
			UserSubscriptionTypeCategoriesEnum.LocationPerSeat,
			false
		);
		const { price: recurrringPackagePerSeatPrice } = getPackageDetails(
			locationPackage!.allowedUST,
			UserSubscriptionTypeCategoriesEnum.LocationPerSeat,
			true
		);
		return (
			<Package
				key={i}
				packageName={locationPackage.name}
				packageId={locationPackage.id}
				courseId={locationPackage.courseId}
				locationPackageTypeId={locationPackage.locationPackageTypeId}
				status={locationPackage.isActive}
				description={locationPackage.description}
				initialPrice={`$${initialPackagePrice}`}
				recurringPrice={`$${recurrringPackagePrice}`}
				initialPricePerSeat={`$${initialPackagePerSeatPrice}`}
				recurringPricePerSeat={`$${recurrringPackagePerSeatPrice}`}
				locationPackage={locationPackage}
				initialDuration={initialDuration && getDurationStringFromDuration(initialDuration)}
				initialDurationPerSeat={
					initialPackagePerSeatDuration && getDurationStringFromDuration(initialPackagePerSeatDuration)
				}
			/>
		);
	};
	return (
		<Wrapper
			heading={"Package Overview"}
			breadcrumb={breadcrumb}
			actions={
				<Box>
					<Button
						variant={"contained"}
						color={"primary"}
						disabled={!Boolean(currentCourseId)}
						onClick={() =>
							currentCourseId &&
							history.push(
								`${routes.manageLocations.getPath()}/package-overview/manage-package/${currentCourseId}/${CUSTOM_PACKAGE}/new`
							)
						}
					>
						Add Custom Package
					</Button>
				</Box>
			}
		>
			<Box>
				<h4>Pick Course</h4>
				<DropdownContainer>
					<FormControl hiddenLabel size="small" variant="filled">
						<Select
							disableUnderline
							value={currentCourseId}
							onChange={event => handleSelect(event.target.value)}
							displayEmpty
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left"
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left"
								},
								getContentAnchorEl: null
							}}
						>
							<MenuItem value="">Select Course</MenuItem>
							{courses?.map(({ id, name }) => (
								<MenuItem key={id} value={id}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</DropdownContainer>
			</Box>
			{isLoading ? (
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			) : (
				currentCourseId && (
					<>
						<PackagesContainer>
							{keys.map((key, i) => {
								const locationPackage = packages.find(pkg => pkg.isDefault && pkg.locationPackageTypeId === +key);
								if (locationPackage) {
									return getPackage(locationPackage, i);
								} else {
									return (
										<AddPackageContainer key={i}>
											<AddPackage
												onClick={() => {
													currentCourseId &&
														history.push(
															`${routes.manageLocations.getPath()}/package-overview/manage-package/${currentCourseId}/${key}/new`
														);
												}}
											>
												<AddPackageIcon width="40px" height="40px" />
												<AddPackageText>Add {getPackageName(key)}</AddPackageText>
											</AddPackage>
										</AddPackageContainer>
									);
								}
							})}
							{/*custom packages*/}
							{packages.reduce((acc: ReactElement[], curr, currentIndex) => {
								if (!curr.isDefault) {
									return [...acc, getPackage(curr, currentIndex)];
								}
								return acc;
							}, [])}
						</PackagesContainer>
					</>
				)
			)}
		</Wrapper>
	);
};

export default PackageOverview;
