import React, { useMemo, useState } from "react";

import { Box, Button, CircularProgress, Container, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { ChangeEmailHeading, EmailInput, StyledCard } from "./styles";

import { useStyles } from "../../styles";

import { validateEmail } from "../../utils/serviceUtils";

type ChangeStudentEmailProps = {
	fullName: string | undefined;
	changeEmailLoading: boolean;
	userEmail: string | undefined;
	onClose: () => void;
	handleSubmit: (newEmail: string) => void;
};

/**
 * ChangeStudentEmail Modal to change a student's email.
 *
 * @param {object} props - The component props.
 * @param {function} props.onClose - Function to close the component.
 * @param {function} props.handleSubmit - Function to handle the form submission.
 * @param {object} props.rest - Additional student props.
 * @param {string} props.rest.fullname - Student's fullname.
 * @param {string} props.rest.userEmail - Students's email.
 * @param {string} props.rest.changeEmailLoading - loader indicator.
 *
 * @returns {JSX.Element} - The ChangeStudentEmail component.
 */
const ChangeStudentEmail = ({ onClose, handleSubmit, ...rest }: ChangeStudentEmailProps) => {
	const classes = useStyles();
	const [newEmail, setNewEmail] = useState("");
	const [isEmailValid, setIsEmailValid] = useState(true);
	const { changeEmailLoading, userEmail, fullName } = rest || {};

	const showModal = useMemo(() => !!fullName && !!userEmail, [fullName, userEmail]);

	const closeModal = () => {
		if (!changeEmailLoading) {
			onClose();
		}
	};

	const handleEmailChange = (event: React.ChangeEvent<{ value: string }>) => {
		const email = event.target.value?.trim() as string;
		const isEmailValid = validateEmail(email);
		setIsEmailValid(isEmailValid);
		setNewEmail(email);
	};

	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={showModal} onClose={onClose}>
			<Container maxWidth="md">
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<StyledCard>
						<Box height={"85%"}>
							<Box display="flex" alignItems="center" justifyContent="space-between">
								<Typography className={classes.title} variant="h6">
									Change Students Email
								</Typography>
								<IconButton onClick={onClose}>
									<CloseIcon color="disabled" />
								</IconButton>
							</Box>
							<Box>
								<Box mt={3}>
									<ChangeEmailHeading variant="caption">Students Name</ChangeEmailHeading>
									<Typography>{fullName}</Typography>
								</Box>
								<Box mt={3}>
									<ChangeEmailHeading variant="caption">Students Current Email</ChangeEmailHeading>
									<Typography>{userEmail}</Typography>
								</Box>
								<Box mt={3}>
									<ChangeEmailHeading variant="caption">Students New Email</ChangeEmailHeading>
									<EmailInput
										InputProps={{ disableUnderline: true }}
										errorText={(!newEmail && "Required") || (!isEmailValid && "Invalid Email")}
										onChange={handleEmailChange}
										value={newEmail}
										type="text"
										placeholder="Enter New Email"
									/>
								</Box>
							</Box>
						</Box>
						<Box display="flex" justifyContent="flex-end" mt={3}>
							<Button variant="outlined" className={classes.cancelBtn} onClick={closeModal}>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.save}
								onClick={() => handleSubmit(newEmail)}
								disabled={!newEmail || !isEmailValid}
							>
								{changeEmailLoading ? (
									<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
								) : (
									<>Change Email</>
								)}
							</Button>
						</Box>
					</StyledCard>
				</Box>
			</Container>
		</Modal>
	);
};

export default ChangeStudentEmail;
