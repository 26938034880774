import { Button as CoreButton, Typography, makeStyles } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import styled from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
`;

export const CardContainer = styled.div`
	margin-right: 24px;
	margin-bottom: 24px;
`;

export const HeaderContainer = styled.div`
	margin-bottom: 24px;
	display: flex;
	margin-top: 36px;

	.button-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: auto;
		margin-right: 50px;
	}
`;

export const Header = styled(Typography)`
	font-size: 18px;
	justify-content: center;
	font-family: var(--poppins);
	align-items: center;
	display: flex;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const BtnSecondary = styled(CoreButton)`
	margin-right: 8px;
	background-color: ${({ theme }) => theme.palette.colors.basic[900]};
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	font-weight: bold;
`;

export const BtnPublish = styled(CoreButton)`
	margin-right: 8px;
	font-weight: bold;
`;

export const ButtonContainer = styled.div`
	position: absolute;
	right: 32px;
	bottom: 26px;
`;

export const ContentCards = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const BreadcrumbsTitle = styled(Typography)`
	font-family: var(--poppins);
	font-size: 18px;
`;

export const Breadcrumb = styled(Typography)`
	color: rgba(255, 255, 255, 0.6);
	font-size: 13px;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 20vw;
	white-space: nowrap;
	text-transform: capitalize;
`;

export const ButtonsContainer = styled.div`
	margin-right: 32px;
	flex-basis: 0;
	flex-grow: 1;
	justify-content: end;
	display: flex;

	> * {
		margin: 0 4px;
	}
`;

export const CancelButton = styled(CoreButton)`
	font-size: 16px;
	background: transparent;
	color: rgba(255, 255, 255, 0.6);
	border-color: #6b6e75;
	border-width: 1px;
	border-style: solid;
	padding: 7px 20px;

	&:hover {
		background-color: rgba(203, 219, 245);
		color: rgba(255, 0, 0, 0.9);
	}
`;

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	breadcrumbActive: {
		color: theme.palette.colors.info[750],
		fontSize: theme.typography.pxToRem(13)
	},
	draft: {
		backgroundColor: "#172e4b",
		color: "#4a99f9",
		"&:hover": {
			backgroundColor: "#295080"
		}
	},
	discard: {
		backgroundColor: "#46191e",
		color: "#ed5465",
		"&:hover": {
			color: "rgba(255, 0, 0, 0.9)"
		}
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
}));
