import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FindResponse, NumberItemDto } from "@remar/shared/dist/api/baseApiService";
import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import { SubjectLesson } from "@remar/shared/dist/models";
import {
	createForm,
	getPopulateInputsAction,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { uniqBy } from "lodash";

import { AppThunk, RootState } from "store";
import { SubjectState, subjectLessonService, subjectService } from "store/services";

import { SubjectFormInputs, SubjectFormRawData, SubjectLessonFormInputs, SubjectLessonFormRawData } from "./models";

import { emit } from "../notifications/notifications.slice";

export const fetchSubjects = createAsyncThunk(
	"subjects/fetchSubjects",
	async (options: { page?: number; perPage?: number }, { dispatch, getState }) => {
		try {
			const { isLoading, page, perPage } = (getState() as RootState).subjects;
			if (!isLoading) {
				await dispatch(setStateValue({ key: "isLoading", value: true }));
			}
			const { page: optPage, perPage: optPerPage } = options;
			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await subjectService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { createdAt: "DESC" },
				include: ["lessons", "lessons.tags", "allowedCourses"]
			});
			dispatch(setStateValue({ key: "page", value: newPage }));
			dispatch(setStateValue({ key: "perPage", value: newPerPage }));
			dispatch(
				setStateValue({
					key: "subjects",
					value: items.map(item => ({ ...item, hasChildren: true, childItems: item.lessons }))
				})
			);
			dispatch(setStateValue({ key: "totalItems", value: totalItems }));
		} catch (e) {
			dispatch(emit({ message: "An error has occurred.", color: "error" }));
		} finally {
			await dispatch(setStateValue({ key: "isLoading", value: false }));
		}
	}
);

export const fetchSubjectLessons = createAsyncThunk(
	"subjects/fetchSubjectLessons",
	async (options: { page?: number; perPage?: number; infinite?: boolean; searchKeyword?: string }, { dispatch }) => {
		try {
			const { page, perPage, searchKeyword } = options;
			return await subjectLessonService.find({
				page: page,
				perPage: perPage,
				orderBy: { id: "DESC" },
				searchKeyword
			});
		} catch (e) {
			dispatch(emit({ message: "An error has occurred.", color: "error" }));
		}
	}
);

export const createSubject = createAsyncThunk(
	"subjects/createSubject",
	async (onClose: () => void, { dispatch, getState }) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const data = (getState() as RootState).subjects.subjectForm.rawData;
			if (data.minimumPercentage >= data.maximumPercentage) {
				throw new Error("Maximum percentage must be greater than minimum");
			} else {
				onClose();
			}

			const courseIds: NumberItemDto[] | undefined =
				!data.allCourses && data?.courseIds?.length ? data.courseIds.map(c => ({ value: c.id })) : undefined;

			if (data.id) {
				const { id, ...rest } = data;
				await subjectService.update({ filters: { id }, data: { ...rest, courseIds } });
			} else await subjectService.create({ ...data, courseIds });
			await dispatch(setStateValue({ key: "subjectForm", value: initialState.subjectForm }));
			dispatch(fetchSubjects({}));
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			await dispatch(setStateValue({ key: "isLoading", value: false }));
		}
	}
);

export const createLesson = createAsyncThunk(
	"subjects/createLesson",
	async ({ tagIds, cb }: { tagIds?: number[]; cb?: () => void }, { dispatch, getState }) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const data = (getState() as RootState).subjects.lessonForm.rawData;
			if (data.id) {
				const { id, ...rest } = data;
				await subjectLessonService.update({ filters: { id }, data: { tagIds, ...rest } });
			} else await subjectLessonService.create({ tagIds, ...data });
			cb && cb();
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			await dispatch(setStateValue({ key: "lessonForm", value: initialState.lessonForm }));
			dispatch(fetchSubjects({}));
		}
	}
);

export const initialState: SubjectState = {
	isLoading: false,
	path: [],
	subjects: [],
	subjectsLesson: [],
	subjectsLessonPage: 1,
	subjectsLessonPerPage: 10,
	subjectsLessonTotalItems: 0,
	page: 1,
	perPage: 10,
	totalItems: 0,
	subjectForm: createForm<SubjectFormInputs, SubjectFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "subjectForm",
		inputs: {
			id: { type: CustomInputType.Number },
			name: {
				label: "Enter Subject Name",
				placeholder: "Enter Subject Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 150 }
			},
			description: {
				label: "Description for CAT",
				placeholder: "Enter Description",
				type: CustomInputType.Editor,
				validations: { required: true }
			},
			minimumPercentage: {
				defaultValue: 0,
				type: CustomInputType.Number,
				validations: { isNumber: true, max: 100, min: 0, required: true }
			},
			maximumPercentage: {
				label: "Max % for CAT",
				defaultValue: 100,
				type: CustomInputType.Number,
				validations: { isNumber: true, max: 100, min: 0, required: true }
			},
			isAvailableForTrial: {
				defaultValue: false,
				label: "Free Trial Only",
				type: CustomInputType.Checkbox
			},
			allCourses: {
				defaultValue: true,
				label: "Courses",
				type: CustomInputType.Radio,
				validations: { required: true },
				selectOptions: [
					{ text: "All Courses", value: true },
					{ text: "Selected Courses", value: false }
				]
			},
			courseIds: {
				label: "Select Courses",
				type: CustomInputType.AutoComplete,
				defaultValue: [],
				selectOptions: []
			}
		}
	}),
	lessonForm: createForm<SubjectLessonFormInputs, SubjectLessonFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "lessonForm",
		inputs: {
			id: { type: CustomInputType.Number },
			subjectId: { type: CustomInputType.Number },
			description: { type: CustomInputType.Text },
			name: {
				label: "Enter Lesson Name",
				placeholder: "Enter Lesson Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			}
		}
	})
};

export const deleteSubject =
	(id: number): AppThunk =>
	async dispatch => {
		try {
			dispatch(setStateValue({ key: "isLoading", value: true }));
			await subjectService.delete({ filters: { id } });
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(fetchSubjects({}));
		}
	};

export const deleteLesson =
	(id: number): AppThunk =>
	async dispatch => {
		try {
			dispatch(setStateValue({ key: "isLoading", value: true }));
			await subjectLessonService.delete({ filters: { id } });
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
		} finally {
			dispatch(fetchSubjects({}));
		}
	};

const utilsPopulateInputs = getPopulateInputsAction<SubjectState>({});
export const subjectSlice = createSlice({
	name: "subject",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		populateInputs: utilsPopulateInputs
	},
	extraReducers: builder =>
		builder
			.addCase(fetchSubjectLessons.pending.type, state => {
				state.isLoading = true;
			})
			.addCase(fetchSubjectLessons.fulfilled.type, (state, action: PayloadAction<FindResponse<SubjectLesson>>) => {
				const { meta, payload } = action;
				state.isLoading = false;
				state.subjectsLesson = meta.arg.infinite
					? uniqBy([...state.subjectsLesson, ...payload.items] as [], "id")
					: payload.items;
				state.subjectsLessonPage = payload.page;
				state.subjectsLessonPerPage = payload.perPage;
				state.subjectsLessonTotalItems = payload.totalItems || 0;
			})
			.addCase(fetchSubjectLessons.rejected.type, state => {
				state.isLoading = false;
			})
});

export const { validateForm, setStateValue, populateInputs } = subjectSlice.actions;

export const getFullState = ({ subjects }: RootState): SubjectState => subjects;

export default subjectSlice.reducer;
