import { Box, Typography } from "@material-ui/core";
import { alpha, createStyles, makeStyles } from "@material-ui/core/styles";

import styled from "styled-components";

import { IExtendedTheme } from "../../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column"
			}
		},
		info: { width: "73.8%" },
		loginHistory: { width: "25%" },
		userInfoCard: {
			[theme.breakpoints.down("sm")]: {
				padding: "8px"
			}
		},
		loginCard: {
			padding: 0
		},
		userInfoContainer: {
			[theme.breakpoints.down("xs")]: {
				marginRight: 0
			}
		},
		subscriptionCard: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			gap: "16px",
			[theme.breakpoints.down("sm")]: {
				width: "95vw"
			}.width,

			"& div[role='progressbar']": {
				height: "10px"
			}
		},
		subscriptionInfoContainer: {},
		userActivityInfoContainer: {
			width: "100%"
		},
		cardsContainer: {
			display: "flex",
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				flexFlow: "row wrap",
				overflow: "scroll"
			}
		},
		container: {
			[theme.breakpoints.down("xs")]: {
				height: "100%",
				overflow: "hidden"
			}
		},
		noCancel: {
			backgroundColor: "transparent",
			color: alpha(theme.palette.text.primary, 0.6),
			borderColor: "hsl(222,4%,44%)",
			borderWidth: "1px",
			borderStyle: "solid",
			marginRight: "10px"
		},
		breadcrumb: {
			color: alpha(theme.palette.text.primary, 0.6),
			fontSize: theme.typography.pxToRem(13)
		},
		breadcrumbActive: {
			color: theme.palette.colors.info[750],
			fontSize: theme.typography.pxToRem(13)
		},
		buttonContainer: {
			display: "flex",
			gap: "10px",
			[theme.breakpoints.down("xs")]: {
				justifyContent: "center",
				width: "100%",
				paddingRight: "5px"
			}
		},

		header: {
			width: "100%",
			maxWidth: "1124px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginBottom: 57,
			marginTop: 20,
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
				gap: 16,
				marginTop: 69
			}
		},
		dateText: {
			fontSize: 12,
			fontWeight: "normal",
			marginLeft: 18,
			color: theme.palette.colors.info[500],
			cursor: "pointer"
		},
		title: {
			fontSize: 25,
			fontWeight: 900,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			[theme.breakpoints.down("xs")]: {
				alignSelf: "flex-start",
				fontSize: 20
			}
		},
		cardHeader: {
			display: "flex",
			alignItems: "center",
			margin: 20,
			justifyContent: "space-between"
		},
		imgAndIconContainer: {
			position: "relative",
			width: 96
		},
		img: {
			display: "block"
		},
		callbackImg: {
			justifyContent: "center",
			display: "flex",
			alignItems: "center",
			height: "96px",
			width: "96px",
			borderRadius: "4px",
			backgroundColor: theme.palette.colors.primary[1500],
			color: theme.palette.colors.primary[1400]
		},
		initials: {
			color: theme.palette.colors.primary[1400],
			fontSize: `"${theme.typography.pxToRem(30)}"`,
			textTransform: "uppercase"
		},
		editIcon: {
			width: 25,
			height: 25,
			backgroundColor: "hsl(226,17%,85%)", // todo add into colorset
			position: "absolute",
			top: 0,
			right: 0,
			borderRadius: 4,
			marginTop: 1,
			marginRight: 1,
			padding: 5
		},
		nameContainer: {
			display: "flex",
			gap: 8,
			minWidth: 250,
			justifyContent: "flex-start"
		},
		name: {
			fontSize: 22,
			fontWeight: 900,
			marginLeft: 24,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			display: "flex",
			gap: "5px",
			alignItems: "center"
		},
		cardBody: {
			marginTop: 56,
			margin: 20
		},
		summaryCard: {
			display: "flex",
			width: "65%",
			gap: "24px"
		},
		bodyRow: {
			display: "flex",
			marginTop: 20,
			marginBottom: 10,
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column"
			}
		},
		infoContainer: {
			width: 400,
			display: "flex",
			alignItems: "center",
			marginLeft: 0
		},
		infoText: {
			fontSize: 15,
			fontWeight: 600,
			marginLeft: 18,
			marginTop: 0,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.6,
			[theme.breakpoints.down("xs")]: {
				width: "70vw"
			}
		},
		infoName: {
			fontSize: 12,
			fontWeight: "normal",
			marginLeft: 18,
			marginTop: 0,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.33,
			color: alpha(theme.palette.text.primary, 0.6)
		},
		subscriptionTitle: {
			fontSize: 15,
			fontWeight: 600,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.6,
			marginBottom: 20
		},
		cancelSubscriptionContainer: {
			width: "100%",
			padding: 2,
			borderTop: "solid 1px rgb(137, 143, 158)"
		},
		cancelSubscriptionBtn: {
			fontSize: 12,
			fontWeight: "bold",
			margin: "auto",
			display: "block",
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.33,
			color: alpha(theme.palette.colors.basic[100], 0.6),
			marginTop: 23
		},
		resumeSubscriptionBtn: {
			fontFamily: "var(--poppins)",
			fontSize: 14,
			fontWeight: "bold",
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.colors.primary[500],
			margin: "auto",
			display: "block"
		},
		manageSubscriptionBtn: {
			fontFamily: "var(--poppins)",
			fontSize: 14,
			fontWeight: "bold",
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.colors.basic[900],
			[theme.breakpoints.down("xs")]: {
				flex: 3
			}
		},
		editProfileBtn: {
			fontFamily: "var(--poppins)",
			fontSize: 14,
			fontWeight: "bold",
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.colors.primary[500],
			[theme.breakpoints.down("xs")]: {
				flex: 2
			}
		},
		card: {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			background: theme.palette.colors.basic[1000],
			flex: 5,
			minHeight: 630,
			[theme.breakpoints.down("xs")]: {
				minHeight: "100%"
			}
		},
		keepBtn: {
			backgroundColor: "transparent",
			color: theme.palette.text.primary,
			borderColor: "hsl(222,4%,44%)", // todo: add to colorset
			borderWidth: "1px",
			borderStyle: "solid",
			marginRight: "10px"
		},
		cancelBtn: {
			backgroundColor: theme.palette.colors.danger[900],
			color: theme.palette.colors.danger[500]
		},
		button: {
			lineHeight: 1.25,
			fontSize: `"${theme.typography.pxToRem(16)}"`,
			fontWeight: 900,
			padding: "13px 18px 15px"
		},
		filledFormTitle: {
			fontSize: theme.typography.pxToRem(26),
			fontWeight: 700,
			fontFamily: "var(--poppins)"
		}
	})
);

export const IconWrapper = styled(Box)`
	background-color: ${({ $bgColor }) => $bgColor}60;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TestSvgIcon = styled.img`
	width: 20px;
	height: 20px;

	path:first-of-type {
		fill: ${({ theme }) => theme.palette.colors.primary[1300]};
	}
`;

export const CheckSvgIcon = styled.img`
	width: 20px;
	height: 20px;
`;

export const CrossSvgIcon = styled.img`
	width: 20px;
	height: 20px;
`;

export const StyledAccountClassName = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.primary[1900]};
	font-weight: 400;
	font-family: var(--openSans);
	border: 1px solid ${({ theme }) => theme.palette.colors.primary[1900]};
	border-radius: 20px;
	padding: 3px 10px;
	font-size: 12px;
`;
