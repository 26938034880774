import { Box, Button, Typography } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: 13px;
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}

		div:last-child {
			color: #ffffff;
		}
	}
`;

export const CardContainer = styled.div`
	margin-right: 24px;
	margin-bottom: 24px;
`;

export const HeaderContainer = styled.div`
	margin-bottom: 24px;
	display: flex;
	margin-top: 24px;
`;

export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const AddNewCategory = styled(Button)`
	margin-left: auto;
	height: 36px;
	margin-top: 8px;
	margin-right: 32px;
`;

export const ButtonContainer = styled.div`
	position: absolute;
	right: 32px;
	bottom: 26px;
`;

export const SelectLocationWrapper = styled(Box)`
	button {
		width: 100%;
		span {
			margin-top: 5px;
			span {
				margin-left: auto;
			}
		}
	}
`;
