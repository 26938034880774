import { makeStyles } from "@material-ui/core";
import { primary } from "@remar/shared/dist/colors";

export const useStyles = makeStyles(() => ({
	modal: {
		display: "flex",
		alignItems: "center"
	},
	leftCol: {
		padding: "10px 8px 10px 0"
	},
	rightCol: {
		padding: "10px 0 10px 8px"
	},
	scroll: {
		overflowY: "auto",
		maxHeight: "100vh",
		width: "100%",
		padding: "20px 0",
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "start",
		justifyContent: "center"
	},
	content: {
		maxWidth: "1140px",
		width: "100%",
		margin: "0 5%",
		background: "#dbdee4"
	},
	header: {
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		justifyContent: "space-between",
		background: primary["600"],
		color: "#fff"
	},
	title: {
		lineHeight: "56px",
		padding: "0 16px",
		opacity: 0.87,
		fontFamily: "var(--openSans)",
		fontSize: "18px",
		fontWeight: "bold"
	},
	row: {
		display: "flex",
		flexFlow: "row nowrap",
		width: "100%",
		gap: "16px"
	},
	questionNumber: {
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6,
		fontFamily: "var(--openSans)",
		fontStretch: "normal",
		fontStyle: "normal",
		letterSpacing: "normal",
		color: "#4d5058"
	},
	col: {
		flexBasis: "100%"
	},
	text: {
		fontSize: "13px",
		fontWeight: 600,
		lineHeight: 1.85
	},
	questionText: {
		fontFamily: "var(--openSans)",
		fontSize: "13px",
		fontWeight: 600,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.85,
		letterSpacing: "normal",
		color: "#4d5058"
	},
	questionContainer: {
		gap: "16px",
		display: "flex",
		padding: "24px 0",
		minHeight: "550px",
		margin: "0 24px",
		color: "#4d5058",
		"&:not(:last-of-type)": {
			borderBottom: "solid 1px #b1b6c3"
		}
	},
	correct: {
		"& $radioLabel, & svg": {
			color: "#264fae"
		},
		"&$your $radioLabel, &$your svg": {
			color: "#23b34a"
		}
	},
	correctBg: {
		backgroundColor: "rgba(35, 179, 74, 0.2)",
		borderRadius: "4px",
		height: "60px"
	},
	yourBg: {
		backgroundColor: "#d3d6e0",
		borderRadius: "4px",
		height: "60px"
	},
	gap5: {
		gap: "5px"
	},
	correctOptBg: {
		borderRadius: "4px",
		height: "60px",
		backgroundColor: "#c0c9e5"
	},
	correctAnsText: {
		paddingLeft: "7px",
		color: "#23b34a",
		fontFamily: "var(--openSans)",
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6
	},
	correctOptText: {
		fontFamily: "var(--openSans)",
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6,
		color: "#264fae",
		paddingLeft: "7px"
	},
	yourAnsText: {
		opacity: 0.7,
		paddingLeft: "7px",
		fontFamily: "var(--openSans)",
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6,
		color: "#4d5058"
	},
	optText: {
		fontFamily: "var(--openSans)",
		fontSize: "15px",
		paddingLeft: "7px",
		fontWeight: 600,
		lineHeight: 1.6,
		color: "#4d5058"
	},
	your: {
		"& $radioLabel, & svg": {
			color: "#4d5058",
			opacity: 0.7
		}
	},
	radioLabel: {
		flexBasis: "100%",
		textAlign: "center",
		fontSize: "15px",
		fontWeight: 600,
		paddingBottom: "8px",
		fontFamily: "var(--openSans)"
	},
	rationaleContainer: {
		borderRadius: "3px",
		background: "#fff",
		padding: "16px"
	},
	rationaleHeader: {
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6,
		display: "flex",
		alignItems: "center",
		"&:first-child": {
			marginBottom: "16px"
		}
	},
	icon: {
		marginRight: "8px"
	},
	timer: {
		fontSize: "13px",
		lineHeight: 1.23,
		color: "#4d5058",
		display: "flex",
		alignItems: "center"
	},
	textArea: {
		width: "100%",
		border: "solid 1px #edf1f7",
		fontFamily: "var(--poppins)",
		fontSize: "13px"
	},
	notesTypography: {
		fontSize: "15px",
		fontWeight: 600,
		flexGrow: 1
	},
	purple: {
		backgroundColor: "#c0c9e5"
	},
	green: {
		backgroundColor: "#90EE90"
	},
	grey: {
		backgroundColor: "#d3d6e0;"
	},
	transparent: {
		backgroundColor: "transparent"
	},
	card: {
		borderRadius: "5px"
	},
	modalFooter: {
		width: "100%",
		padding: "14px 17px",
		display: "flex",
		justifyContent: "flex-end"
	}
}));
