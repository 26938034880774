import React, { useMemo, useState } from "react";

import { Box, TextField } from "@material-ui/core";

import convertSecToRemainingTime, {
	convertRemainingTimeToSec
} from "@remar/shared/dist/utils/convertSecToRemainingTime";

import { useStyles } from "../../../styles";
const maxVal = p => {
	switch (p) {
		case "h": {
			return 24;
		}
		case "m": {
			return 59;
		}
		case "s": {
			return 59;
		}
		default: {
			return 999;
		}
	}
};

const Field = ({ val, isFirst, isLast, placeholder, handleUpdateTime }) => {
	const classes = useStyles();

	const borderRadius = useMemo(() => {
		{
			if (isFirst) {
				return "4px 0 0 4px";
			}
			if (isLast) {
				return "0 4px 4px 0";
			}
			return 0;
		}
	}, []);

	return (
		<TextField
			hiddenLabel
			type="number"
			className={classes.timeInput}
			InputProps={{
				style: { color: "#fff", width: "50px", borderRadius: borderRadius },
				disableUnderline: true,
				inputProps: {
					max: maxVal(placeholder),
					min: 0
				},
				endAdornment: isLast ? null : <span>:</span>,
				classes: {
					adornedEnd: classes.adornedStyle
				}
			}}
			color="primary"
			disabled={false}
			onChange={(event: React.ChangeEvent<{ value: string }>) => {
				if (event.target.value) {
					handleUpdateTime(placeholder, +event.target.value);
				}
			}}
			variant="filled"
			size="small"
			placeholder={placeholder}
			value={val}
		/>
	);
};
const fields = ["h", "m", "s", "ms"];
type Type = "start" | "end";
const TimeInput = ({
	seconds = 0,
	index,
	handleUpdate,
	type
}: {
	seconds: number;
	index: number;
	handleUpdate: (data: { index: number; type: string; time: number }) => void;
	type: Type;
}) => {
	const { remainingHours, remainingMinutes, remainingSeconds, remainingMilliSeconds } = useMemo(
		() => convertSecToRemainingTime(seconds),
		[seconds]
	);
	const [time, setTime] = useState({
		h: remainingHours,
		m: remainingMinutes,
		s: remainingSeconds,
		ms: remainingMilliSeconds
	});

	const handleUpdateTime = (placeholder, time) => {
		setTime(prevState => {
			const _state = { ...prevState };
			_state[placeholder] = time;
			const updatedTime = convertRemainingTimeToSec(_state.h, _state.m, _state.s, _state.ms);
			handleUpdate({ index, type, time: updatedTime });
			return _state;
		});
	};

	return (
		<Box display={"flex"} justifyContent={"flex-start"}>
			{fields.map((f, i) => (
				<Box key={f}>
					<Field
						val={time[f]}
						placeholder={f}
						isFirst={i === 0}
						isLast={i === fields.length - 1}
						handleUpdateTime={handleUpdateTime}
					/>
				</Box>
			))}
		</Box>
	);
};

export default TimeInput;
