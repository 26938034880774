import React, { useState } from "react";

import { InputAdornment, TextField as MuiTextField, useTheme } from "@material-ui/core";

import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { format } from "date-fns";

import { StyledCalenderIcon } from "./styles";
const TextField = props => <MuiTextField {...props} variant="filled" hiddenLabel />;

enum ICON_LOCATION {
	start = "start",
	end = "end"
}

interface IDatePickerProps {
	name: string;
	value: Date;
	onChange: (e: React.EventHandler<unknown>) => void;
	minDate?: Date;
	iconLoc?: string;
	disabled?: boolean;
	maxDate?: Date;
}

const DatePickerComponent = ({
	name,
	value,
	minDate,
	maxDate,
	onChange,
	disabled = false,
	iconLoc = ICON_LOCATION.start
}: IDatePickerProps) => {
	const theme = useTheme<IExtendedTheme>();
	const [isDateOpen, setIsDateOpen] = useState(false);
	const iconProps =
		iconLoc === ICON_LOCATION.start
			? {
					startAdornment: (
						<InputAdornment position="start">
							<StyledCalenderIcon />
						</InputAdornment>
					)
			  }
			: {
					endAdornment: (
						<InputAdornment position="start">
							<StyledCalenderIcon />
						</InputAdornment>
					)
			  };
	return (
		<DatePicker
			onClose={() => setIsDateOpen(prevState => !prevState)}
			open={isDateOpen}
			name={name}
			fullWidth={true}
			autoOk
			format="EEE, dd MMM yyyy"
			value={value}
			minDate={minDate}
			maxDate={maxDate}
			disableToolbar
			orientation="portrait"
			disabled={disabled}
			openTo="date"
			onChange={onChange}
			TextFieldComponent={() => (
				<TextField
					style={{ width: "100%" }}
					value={format(value || new Date(), "EEE, dd MMM yyyy")}
					disabled={disabled}
					placeholder="MM/DD/YYYY"
					color="primary"
					InputProps={{
						style: {
							cursor: "pointer",
							color: theme.palette.text.primary,
							backgroundColor: theme.palette.colors.basic[800],
							height: 48,
							borderRadius: 4,
							padding: 10,
							width: "100%"
						},
						disabled,
						disableUnderline: true,
						onClick: () => !disabled && setIsDateOpen(prevState => !prevState),
						readOnly: true,
						...iconProps
					}}
				/>
			)}
		/>
	);
};
export default DatePickerComponent;
