import { ContentAccessTypes } from "@remar/shared/dist/models";

interface keyValue {
	key: string;
	value: string | number;
}

export interface CourseViewInterface {
	id?: number;
	title: {
		key: string;
		value: string;
		onClick?: () => void;
		subtitle?: string;
	};
	content: keyValue[];
	courseData?: { isActive: boolean };
	imgSrc: string;
	bottomComponent?: JSX.Element;
	rightComponent?: JSX.Element;
	contentAccessTypeId?: ContentAccessTypes;
}

export interface ChapterViewInterface {
	id: number;
	order: number;
	title: {
		key: string;
		value: string;
		onClick?: () => void;
	};
	content: keyValue[];
	imgSrc: string;
	bottomComponent?: JSX.Element;
	rightComponent?: JSX.Element;
}

export interface DraggableSrcInterface {
	droppableId: string;
	index: number;
}

export enum LocationOptions {
	all = 1,
	selectedLocation = 2
}
export enum LockChapterDuration {
	LIMITED = "limited",
	LIFETIME = "lifeTime"
}
export enum LockChapterPeriods {
	ONE_WEEK = 1,
	TWO_WEEKS,
	THREE_WEEKS,
	FOUR_WEEKS
}
