import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Breadcrumbs, Button, IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { NavigateNext, Search as SearchIcon } from "@material-ui/icons";
import { ReactComponent as FolderSvg } from "@remar/shared/dist/assets/icons/icon-folder.svg";
import { primary } from "@remar/shared/dist/colors";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";

import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import debounce from "lodash/debounce";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { getFullState as getAuthFullState } from "store/features/Auth/auth.slice";
import { fetchAllCourses, getFullState as getFullCoursesState } from "store/features/Course/course.slice";
import {
	createFolder,
	deleteFolder as deleteFolderAction,
	fetchFolders,
	getFullState,
	initialState,
	populateInputs,
	setStateValue,
	updateFolderVisibility,
	validateForm
} from "store/features/FileVault/FileVault.slice";
import { FileVaultFolderFormRawData } from "store/features/FileVault/models";
import { _emit } from "store/features/notifications/notifications.slice";

import CheckPermissions from "core/CheckPermissions";
import { routes } from "core/constants";
import theme from "theme/default";

import { CreatePersonalStorageModal } from "./CreatePersonalStorageModal";
import {
	ActiveLink,
	ButtonsRow,
	CheckboxContainer,
	CheckboxLabel,
	CircularProgress,
	CreatePersonalStorageFolderButton,
	DeleteCancelButton,
	DeleteConfirmButton,
	DeleteConfirmContent,
	EmptyPanel,
	Folder,
	FolderIconContainer,
	FolderSeparator,
	FolderTitle,
	FolderVisibility,
	FolderVisibilityContainer,
	FolderVisibilitySubText,
	FoldersContainer,
	FormModal,
	HeaderSplit,
	InputHolder,
	Link,
	RoundedIcon,
	Search,
	SearchPanel,
	SectionHeader,
	StyledCheckbox,
	CustomInput as StyledInput,
	SvgIcon,
	VaultContainer
} from "./styles";

const useSwitchStyles = makeStyles(() => ({
	folder: {
		background: "rgb(23, 46, 75, 0.4);",
		margin: "10px"
	},
	switchBase: {
		"&$checked": {
			color: primary[400]
		},
		"&$checked + $track": {
			backgroundColor: primary[200]
		}
	},
	checked: {},
	track: {}
}));

const FolderVisibilitySwitch = ({
	isVisible,
	index,
	id,
	isReadOnly = false
}: {
	isVisible: boolean;
	index: number;
	id: number;
	isReadOnly: boolean;
}) => {
	const classes = useSwitchStyles();
	const dispatch = useDispatch();

	const handleChange = () => {
		dispatch(updateFolderVisibility({ isVisible: !isVisible, index, id }));
	};
	return (
		<>
			<Switch
				checked={isVisible}
				onChange={handleChange}
				name="isVisible"
				inputProps={{ "aria-label": "primary checkbox" }}
				classes={{
					switchBase: classes.switchBase,
					track: classes.track,
					checked: classes.checked
				}}
				disabled={isReadOnly}
			/>
		</>
	);
};

const FileVault = () => {
	const dispatch = useDispatch();
	const { folderId } = useParams<{ folderId: string }>();
	const { courses } = useSelector(getFullCoursesState);
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getAuthFullState);
	const classes = useSwitchStyles();
	const {
		fileVaultFolderForm,
		fileVaultPersonalStorageFolderForm,
		isLoading,
		content: folders
	} = useSelector(getFullState);

	const currentFolder = folderId && folders.find(({ id }) => id === +folderId);

	const sortedCourses = useMemo(
		() =>
			[...(courses ?? [])].sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
			),
		[courses?.length]
	);
	const content = useMemo(
		() => folders.filter(({ parentId }) => (!parentId && !folderId) || +folderId === parentId),
		[folderId, folders.length, folders]
	);

	const [showModal, setShowModal] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [createPersonalStorageModal, setCreatePersonalStorageModal] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteFolder, setDeleteFolder] = useState<FileVaultFolderFormRawData | undefined>();

	useEffect(() => {
		dispatch(fetchFolders(""));
	}, [dispatch]);

	useEffect(() => {
		if (showModal && !courses) {
			dispatch(fetchAllCourses());
		}
	}, [showModal, courses, dispatch]);

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const handleDelete = folder => {
		setShowDeleteConfirm(true);
		setDeleteFolder(folder);
	};

	const onCloseModal = () => {
		dispatch(
			setStateValue({
				key: "fileVaultPersonalStorageFolderForm",
				value: initialState.fileVaultPersonalStorageFolderForm
			})
		);
		setCreatePersonalStorageModal(false);
	};

	const debouncedSearch = useCallback(
		debounce((searchText: string) => {
			dispatch(fetchFolders(searchText));
		}, 500),
		[]
	);

	return (
		<VaultContainer>
			<HeaderSplit>
				<div>
					<SectionHeader>FileVault</SectionHeader>
					<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
						<Link to="/">Dashboard</Link>
						{currentFolder ? (
							<Link to={routes.fileVault.getPath()}>File Vault</Link>
						) : (
							<ActiveLink to={routes.fileVault.getPath()}>File Vault</ActiveLink>
						)}
						{currentFolder && (
							<ActiveLink to={`${routes.fileVault.getPath()}/${currentFolder.id}`}>{currentFolder.name}</ActiveLink>
						)}
					</Breadcrumbs>
				</div>
				<div>
					<CheckPermissions>
						<CreatePersonalStorageFolderButton
							onClick={() => {
								setCreatePersonalStorageModal(true);
							}}
							disabled={isLoading}
						>
							Create Personal Storage Folder
						</CreatePersonalStorageFolderButton>
					</CheckPermissions>
					<CheckPermissions>
						<Button
							variant={"contained"}
							color={"primary"}
							style={{ fontWeight: "bold" }}
							onClick={() => {
								setShowModal(true);
								dispatch(
									setStateValue({
										key: "fileVaultFolderForm.valid",
										value: false
									})
								);
							}}
							disabled={isLoading}
						>
							Create new folder
						</Button>
					</CheckPermissions>
				</div>
			</HeaderSplit>
			<SearchPanel>
				<div>File Vault</div>
				<div>
					{
						<Search
							hiddenLabel
							InputProps={{
								autoFocus: true,
								style: { color: theme.palette.colors.basic[100], backgroundColor: theme.palette.colors.basic[1000] },
								disableUnderline: true,
								startAdornment: (
									<InputAdornment position="start">
										<IconButton size="small">
											<SearchIcon style={{ color: "#898f9e" }} height={20} width={20} />
										</IconButton>
									</InputAdornment>
								)
							}}
							color="primary"
							variant="filled"
							size="small"
							placeholder="Type to filter by keywords"
							value={inputValue}
							onChange={({ target: { value } }) => {
								setInputValue(value);
								debouncedSearch(value);
							}}
						/>
					}
				</div>
			</SearchPanel>
			{isLoading ? (
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			) : !content.length ? (
				<EmptyPanel>
					<SvgIcon>
						<FolderSvg />
					</SvgIcon>
					There is no folders yet
				</EmptyPanel>
			) : (
				<FoldersContainer>
					{content?.map((folder, index) => (
						<Box key={`folder-${folder.id}`} className={classes.folder}>
							<Folder>
								<RoundedIcon>
									<FolderSvg />
								</RoundedIcon>
								<FolderTitle
									to={
										folder.isPrivate
											? `${routes.fileVault.getPath()}/personal/${folder.id}`
											: `${routes.fileVault.getPath()}/${folder.id}`
									}
								>
									{folder.name}
								</FolderTitle>
								<ActionMenu
									customMenuItems={[
										{
											label: "Edit",
											onClick: () => {
												const { isVisible, name, id, courses, isPrivate, includesLessonContent, isNameVisible } =
													folder;
												if (isPrivate) {
													setCreatePersonalStorageModal(true);
													dispatch(
														setStateValue({
															key: "fileVaultPersonalStorageFolderForm.valid",
															value: false
														})
													);
													dispatch(
														populateInputs({
															inputsStatePath: "fileVaultPersonalStorageFolderForm.inputs",
															templatesStatePath: "fileVaultPersonalStorageFolderForm.templates",
															values: {
																id,
																isVisible,
																name,
																isPrivate,
																includesLessonContent,
																isNameVisible
															}
														})
													);
												} else {
													setShowModal(true);
													dispatch(
														setStateValue({
															key: "fileVaultFolderForm.valid",
															value: false
														})
													);
													dispatch(
														populateInputs({
															inputsStatePath: "fileVaultFolderForm.inputs",
															templatesStatePath: "fileVaultFolderForm.templates",
															values: {
																isVisible,
																name,
																id,
																courseId: [courses[0]?.id]
															}
														})
													);
												}
											},
											visible: true,
											disabled: !canEdit
										},
										{
											label: "Delete",
											onClick: () => handleDelete(folder),
											visible: true,
											disabled: !canEdit
										}
									]}
								/>
							</Folder>
							<FolderSeparator></FolderSeparator>
							{!folder.isPrivate && (
								<FolderVisibilityContainer>
									<FolderVisibility>Folder Visibility</FolderVisibility>
									<Box display="flex" justifyContent="space-between" alignItems="center">
										<FolderVisibilitySubText>
											{folder.isVisible ? "ON" : "OFF"} - Students will see this folder
										</FolderVisibilitySubText>
										<FolderVisibilitySwitch
											isVisible={!!folder.isVisible}
											index={index}
											id={folder.id}
											isReadOnly={!canEdit}
										/>
									</Box>
								</FolderVisibilityContainer>
							)}
						</Box>
					))}
				</FoldersContainer>
			)}
			<SimpleModal
				open={showModal}
				theme={theme}
				title={fileVaultFolderForm?.inputs?.id?.value ? "Update Folder" : "Create new folder"}
				onClose={() => {
					dispatch(
						setStateValue({
							key: "fileVaultFolderForm",
							value: initialState.fileVaultFolderForm
						})
					);
					setShowModal(false);
				}}
				extraContent={
					<>
						<StyledInput
							width={150}
							mr={2}
							theme={theme}
							options={{
								...defaultCustomInputOptions,
								inputData: fileVaultFolderForm.inputs.name
							}}
						/>

						<InputHolder>
							Folder Visibility
							<CheckboxContainer>
								<CheckboxLabel>
									<div>Allow Students to see this folder</div>
									<span>By selecting this, the students will be able to see an access this folder.</span>
								</CheckboxLabel>
								<StyledCheckbox
									width={150}
									mr={2}
									theme={theme}
									options={{
										...defaultCustomInputOptions,
										inputData: fileVaultFolderForm.inputs.isVisible
									}}
								/>
							</CheckboxContainer>
						</InputHolder>
						<InputHolder for>
							Assign to course
							<StyledInput
								width={150}
								mr={2}
								theme={theme}
								options={{
									...defaultCustomInputOptions,
									inputData: {
										...fileVaultFolderForm.inputs.courseId,
										selectOptions: sortedCourses?.map(({ id, name }) => ({
											value: id,
											text: name
										}))
									}
								}}
							/>
						</InputHolder>
					</>
				}
				footer={
					<ButtonsRow>
						<Button
							variant={"contained"}
							color="primary"
							style={{ fontWeight: "bold" }}
							onClick={() => {
								if (fileVaultFolderForm.valid) {
									dispatch(createFolder({ sideEffect: setShowModal }));
								}
							}}
						>
							{fileVaultFolderForm?.inputs?.id?.value ? "Update Folder" : "Create Folder"}
						</Button>
					</ButtonsRow>
				}
				text={""}
			/>
			<FormModal
				open={showDeleteConfirm}
				onClose={() => {
					setShowDeleteConfirm(false);
				}}
				disableEnforceFocus
				disableAutoFocus
			>
				<DeleteConfirmContent>
					<FolderIconContainer>
						<SvgIcon>
							<FolderSvg />
						</SvgIcon>
						<Box mt={2}>
							Are you sure you want to delete <b>&laquo;{deleteFolder?.name}&raquo;</b> folder?
						</Box>
					</FolderIconContainer>
					<ButtonsRow>
						<DeleteCancelButton variant={"contained"} onClick={() => setShowDeleteConfirm(false)}>
							Cancel
						</DeleteCancelButton>
						<DeleteConfirmButton
							variant={"contained"}
							onClick={() => {
								setShowDeleteConfirm(false);
								deleteFolder && dispatch(deleteFolderAction({ folderId: deleteFolder.id }));
							}}
						>
							Delete Folder
						</DeleteConfirmButton>
					</ButtonsRow>
				</DeleteConfirmContent>
			</FormModal>
			<CreatePersonalStorageModal
				createPersonalStorageModal={createPersonalStorageModal}
				onCloseModal={onCloseModal}
				defaultCustomInputOptions={defaultCustomInputOptions}
				fileVaultPersonalStorageFolderForm={fileVaultPersonalStorageFolderForm}
			/>
		</VaultContainer>
	);
};

export default FileVault;
