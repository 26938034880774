import React from "react";

import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import { AdobeReaderSvg } from "assets/icons";

type AttachmentPreviewProps = {
	attachment: { name: string; url: string } | null;
	onClose: () => void;
};

const AttachmentPreview = ({ attachment, onClose }: AttachmentPreviewProps) => {
	return (
		<SimpleModal
			title={attachment?.name}
			description="Before the Books Arrive Answer Key "
			modalWidth={800}
			open={!!attachment}
			onClose={onClose}
			attachmentContent={<AdobeReaderSvg width={40} height={40} />}
			footer={null}
		>
			Attachment
		</SimpleModal>
	);
};

export default AttachmentPreview;
