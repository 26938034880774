import React from "react";

import { Box, LinearProgress, Typography, makeStyles } from "@material-ui/core";
type UseProgressTypes = {
	barWidth: string | undefined;
};
const useProgressStyles = makeStyles(theme => ({
	root: {
		width: (props: UseProgressTypes) => (props.barWidth ? props.barWidth : "100%")
	},
	info: {
		marginTop: theme.spacing(1),
		width: "100%",
		display: "flex",
		justifyContent: "space-between"
	},
	title: {
		fontFamily: "var(--openSans)",
		fontSize: theme.typography.pxToRem(13),
		color: theme.palette.text.secondary
	},
	progress: {
		fontFamily: "var(--openSans)",
		fontSize: theme.typography.pxToRem(13),
		color: theme.palette.text.secondary
	}
}));

type ProgressStatusProps = {
	title: string;
	progress: number;
	barWidth?: string;
};

const ProgressStatus = ({ title, progress = 0, barWidth }: ProgressStatusProps) => {
	const classes = useProgressStyles({ barWidth });

	return (
		<Box className={classes.root}>
			<LinearProgress variant="determinate" value={progress} />
			<Box className={classes.info}>
				<Typography className={classes.title}>{title}</Typography>
				<Typography className={classes.progress}>{Math.round(progress)}%</Typography>
			</Box>
		</Box>
	);
};

export default ProgressStatus;
