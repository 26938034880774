import React, { useEffect, useState } from "react";

import { Box, Checkbox, FormControlLabel, Grid, TextField, useTheme } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import ForgotPwdBgImg from "@remar/shared/dist/assets/images/forgot-password.png";
import Button from "@remar/shared/dist/components/Button";
import { EntryScreenLayout } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { acceptInvite, getFullState, verifyInvite } from "store/features/Auth/auth.slice";

import { routes } from "core/constants";

import { validationSchema } from "./schema";

import { StyledVisibilityOffOutlined, StyledVisibilityOutlined, Terms } from "./styles";

interface IInitialValues {
	firstName: string;
	lastName: string;
	password: string;
	email: string;
	terms: boolean;
}
const initialValues: IInitialValues = {
	firstName: "",
	lastName: "",
	password: "",
	email: "",
	terms: false
};

const Invite = () => {
	const theme = useTheme<IExtendedTheme>();
	const { invitationId } = useParams<{ invitationId: string }>();
	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const { isVerifyingInvite, inviteData, isAcceptingInvite } = useSelector(getFullState);
	const inputStyle = {
		color: theme.palette.text.primary,
		background: theme.palette.colors.basic[800],
		height: 45,
		padding: "10px"
	};

	useEffect(() => {
		if (invitationId) {
			dispatch(verifyInvite({ accountClaimCode: invitationId, failedCB: () => history.push(routes.signIn.getPath()) }));
		}
	}, [invitationId]);
	useEffect(() => {
		if (inviteData) {
			setFieldValue("email", inviteData.email);
		}
	}, [inviteData]);

	const handleFormSubmit = values => {
		const { firstName, lastName, password } = values;
		dispatch(
			acceptInvite({
				data: { accountClaimCode: invitationId, firstName, lastName, password },
				cb: () => {
					window.location.replace("/");
				}
			})
		);
	};

	const {
		values: { firstName, lastName, email, password, terms },
		handleSubmit,
		handleChange,
		handleBlur,
		setFieldValue,
		isValid
	} = useFormik({ initialValues, validationSchema, onSubmit: handleFormSubmit });

	return (
		<EntryScreenLayout
			title={"Account Details"}
			customBgImage={ForgotPwdBgImg}
			logo={""}
			signUpRoute={""}
			disableOnMaintenanceMode={true}
		>
			{isVerifyingInvite ? (
				<ContentLoader />
			) : (
				<Box style={{ padding: "40px 60px" }}>
					<Grid container spacing={2} component={"form"} onSubmit={e => handleSubmit(e)}>
						<Grid xs={12} item>
							<Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
								<Box width={"100%"} mr={2}>
									<TextField
										fullWidth
										name={"firstName"}
										inputProps={{ maxLength: 40 }}
										InputProps={{
											style: inputStyle,
											disableUnderline: true
										}}
										value={firstName}
										placeholder={"First Name"}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</Box>
								<TextField
									fullWidth
									name={"lastName"}
									inputProps={{ maxLength: 40 }}
									InputProps={{
										style: inputStyle,
										disableUnderline: true
									}}
									value={lastName}
									placeholder={"Last Name"}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								name={"email"}
								InputProps={{
									style: {
										...inputStyle,
										border: `1px dashed ${theme.palette.colors.basic[500]}`,
										color: theme.palette.colors.basic[500]
									},
									disableUnderline: true
								}}
								value={email}
								placeholder={"Email"}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={true}
							/>
						</Grid>{" "}
						<Grid item xs={12}>
							<TextField
								fullWidth
								name={"password"}
								InputProps={{
									style: inputStyle,
									endAdornment: (
										<SvgIcon fontSize="small" cursor="pointer" onClick={() => setShowPassword(prevState => !prevState)}>
											{showPassword ? <StyledVisibilityOutlined /> : <StyledVisibilityOffOutlined />}
										</SvgIcon>
									),
									disableUnderline: true
								}}
								type={showPassword ? "text" : "password"}
								value={password}
								placeholder={"Password"}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={terms}
										onChange={() => setFieldValue!("terms", !terms)}
										color="primary"
										name="terms"
									/>
								}
								label={
									<Terms>
										I have read and agree to the
										<a target="_blank" rel="noreferrer" href="https://study.remarnurse.com/vit/terms-conditions/">
											<span>Terms & Conditions</span>
										</a>
									</Terms>
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant={"filled"}
								color={"primary"}
								fullWidth
								disabled={!isValid || isAcceptingInvite}
								loading={isAcceptingInvite}
								type={"submit"}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Box>
			)}
		</EntryScreenLayout>
	);
};

export default Invite;
