import React, { FC } from "react";

import { Box, Button, Card, CircularProgress, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "@remar/shared/dist/styles";

interface Props {
	isLoading: boolean;
	onDelete: () => void;
	onClose: () => void;
	title: string;
	message: string;
}

export const DeleteModalContent: FC<Props> = ({ onDelete, isLoading, onClose, title, message }) => {
	const classes = useStyles();
	return (
		<Box width={550}>
			<Card className={classes.card}>
				<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb="40px">
					<Typography className={classes.title}>{title}</Typography>
					<IconButton className={classes.closeIcon} onClick={onClose}>
						<CloseIcon color="disabled" />
					</IconButton>
				</Box>
				<Box>
					<Typography className={classes.subTitle}>{message}</Typography>
				</Box>

				<Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.modalFooter}>
					<Button variant={"contained"} className={classes.cancelBtn} size="medium" onClick={onClose}>
						No, Cancel
					</Button>

					<Button
						variant={"contained"}
						className={classes.deleteBtn}
						size="medium"
						onClick={onDelete}
						startIcon={
							isLoading && <CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
						}
					>
						Yes, Delete
					</Button>
				</Box>
			</Card>
		</Box>
	);
};
