import React, { useEffect, useRef, useState } from "react";

import { WebRTCHandler } from "@antmedia/videojs-webrtc-plugin";
import { Box, Card, TextField } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";

import { Wrapper } from "@remar/shared/dist/layouts";
import { useParams } from "react-router-dom";

import videojs from "video.js";

import "video.js/dist/video-js.min.css";

import { GLOBAL_CONSTANTS } from "core/constants";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Stream", key: 1 }
];

const StreamVideo = () => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const player = useRef<videojs.Player>(null);
	const webrtcHandler = useRef<typeof WebRTCHandler>(null);
	const { sId } = useParams<{ sId: string }>();

	const [isLoading, setIsLoading] = useState(false);
	const [streamId, setStreamId] = useState<string>(sId);
	const [isOnline, setIsOnline] = useState(false);

	useEffect(() => {
		if (sId) {
			joinToStream(sId);
		}
	}, [sId]);

	useEffect(() => {
		if (player.current) {
			console.log("Set Loading: ", isLoading);
			isLoading ? player.current.loadingSpinner.show() : player.current.loadingSpinner.hide();
		}
	}, [isLoading]);

	useEffect(() => {
		if (videoRef.current) {
			player.current = player.current ?? videojs(videoRef.current);

			player.current.on("error", (_, errors) => {
				console.error("Video.js encountered an error", errors);
			});

			player.current.on("ant-error", (_, errors) => console.error("Error during WebRTC playback:", errors));
			player.current.on("canplay", () => {
				setIsOnline(s => !s);
				setIsLoading(false);
				player.current.play();
			});
		}

		return () => {
			if (player.current) {
				player.current.dispose();
			}

			if (webrtcHandler.current) {
				webrtcHandler.current.leave();
			}
		};
	}, [videoRef]);

	const joinToStream = (sId: string) => {
		setIsLoading(true);
		webrtcHandler.current = new WebRTCHandler(
			{
				src: `${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_URL}/${sId}`,
				type: "video/webrtc",
				withCredentials: true,
				iceServers: GLOBAL_CONSTANTS.ICE_SERVERS,
				reconnect: false,
				callback: info => {
					console.log("callback info: " + info);

					if (info == "play_started") {
						console.log("publish started");
					} else if (info == "play_finished") {
						console.log("publish finished");
					}
				}
			},
			null,
			{
				playerId: "video-player"
			}
		);
	};

	const leaveStream = sId => {
		console.log("leaveStream");
		if (webrtcHandler.current) {
			webrtcHandler.current.stop(sId);
			setIsOnline(false);
		}
	};

	return (
		<Wrapper heading="Stream" breadcrumb={breadcrumb}>
			<Card>
				<video ref={videoRef} id="video-player" autoPlay className={"video-js vjs-default-skin vjs-fluid"} controls />

				<Box mt={2} display="flex" justifyContent="center" gridGap={16} alignItems="center">
					<TextField
						variant="outlined"
						disabled={isOnline}
						fullWidth
						value={streamId}
						onChange={e => setStreamId(e.target.value)}
					/>
					<Button
						variant="filled"
						size="large"
						loading={isLoading}
						color={isOnline ? "danger" : "success"}
						onClick={() => (isOnline ? leaveStream(streamId) : joinToStream(streamId))}
					>
						{isOnline ? "Leave" : "Join"}
					</Button>
				</Box>
			</Card>
		</Wrapper>
	);
};

export default StreamVideo;
