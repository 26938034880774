import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Card, IconButton, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";

import { School } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import { useDispatch, useSelector } from "react-redux";

import { populateInputs, save, setStateValue } from "store/features/AddSchools/addSchools.slice";
import { fetchSchoolRequests } from "store/features/ManageSchools/manageSchoolRequests.slice";
import {
	getFullState,
	save as mergeRequest,
	setMergeStateValue,
	validateForm
} from "store/features/MergeSchool/mergeSchools.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { EditSchoolType } from "../ManageSchools";

export const AddSchoolModel = ({
	onClose,
	title = "Add New School",
	editSchoolPayload,
	setAddSchool,
	setEditSchoolPayload,
	setIsMergeAction,
	isMergeAction,
	schools,
	isMergeRequest,
	setIsMergeRequest
}: {
	onClose: () => void;
	editSchoolPayload?: EditSchoolType;
	setEditSchoolPayload: () => void;
	setAddSchool: () => void;
	setIsMergeAction: () => void;
	title?: string;
	isMergeAction?: boolean;
	schools: School[];
	isMergeRequest: boolean;
	setIsMergeRequest: () => void;
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [schoolName, setSchoolName] = useState(editSchoolPayload?.name);
	const [schoolAcronym, setSchoolAcronym] = useState(editSchoolPayload?.acronym);
	const [mergeRequestId, setMergeRequestId] = useState("");
	const isFormValid = !schoolName && !schoolAcronym && !isMergeAction;

	const defaultCustomInputOptions = {
		_emit,
		dispatch,
		setStateValue: setMergeStateValue,
		validateForm
	};
	const { mergeNewSchoolForm } = useSelector(getFullState);
	const { inputs: mergeNewSchoolFormInputs } = mergeNewSchoolForm;
	useEffect(() => {
		dispatch(
			populateInputs({
				inputsStatePath: "addNewSchoolForm.inputs",
				templatesStatePath: "addNewSchoolForm.templates",
				values: { name: "", schoolId: "", acronym: "" }
			})
		);
	}, []);

	useEffect(() => {
		if (editSchoolPayload?.name) {
			setSchoolName(editSchoolPayload.name);
		}
	}, [editSchoolPayload?.name]);
	useEffect(() => {
		dispatch(
			setMergeStateValue({
				key: "mergeNewSchoolForm.inputs.schools.selectOptions",
				value: schools.map(x => {
					return { ...x, text: x.name, value: x.id };
				})
			})
		);
	}, [schools]);

	const submitSchool = useCallback(() => {
		setAddSchool();
		setIsMergeAction();
		if (isMergeAction) {
			setIsMergeAction();
		}

		if (isMergeRequest) {
			const numberId = editSchoolPayload?.id;
			dispatch(setMergeStateValue({ key: "mergeNewSchoolForm.rawData.id", value: mergeRequestId }));
			dispatch(setMergeStateValue({ key: "mergeNewSchoolForm.rawData.mergeId", value: numberId }));
			dispatch(
				mergeRequest({
					success: () => {
						setEditSchoolPayload();
						dispatch(fetchSchoolRequests({}));
					}
				})
			);
			setIsMergeRequest();
			return;
		} else {
			dispatch(
				setStateValue({
					key: "addNewSchoolForm.rawData.name",
					value: schoolName || editSchoolPayload?.name
				})
			);
			dispatch(
				setStateValue({
					key: "addNewSchoolForm.rawData.acronym",
					value: schoolAcronym || editSchoolPayload?.acronym
				})
			);
			dispatch(setStateValue({ key: "addNewSchoolForm.rawData.id", value: editSchoolPayload?.id }));
			dispatch(
				save({
					success: () => {
						setEditSchoolPayload();
						dispatch(fetchSchoolRequests({}));
					}
				})
			);
		}
	}, [schoolName, schoolAcronym, mergeRequestId]);

	return (
		<Box width={550}>
			<Card className={classes.card}>
				<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb="40px">
					<Typography className={classes.title}>{title}</Typography>
					<IconButton className={classes.closeIcon} onClick={onClose}>
						<CloseIcon color="disabled" />
					</IconButton>
				</Box>

				<Box>
					<Typography className={classes.subTitle}>
						{isMergeRequest ? "Requested School Name" : "School Name"}{" "}
					</Typography>
					{isMergeRequest ? (
						<Typography className={classes.subTitle}>{editSchoolPayload?.name} </Typography>
					) : (
						<TextField
							fullWidth
							hiddenLabel
							InputProps={{ style: { color: "primary" }, disableUnderline: true }}
							inputProps={{ maxLength: 140 }}
							color="primary"
							onChange={event => setSchoolName(event.target.value)}
							onBlur={() => setSchoolName(prev => prev?.trim())}
							variant="filled"
							size="small"
							placeholder="Enter School Name"
							value={schoolName}
							disabled={isMergeAction}
						/>
					)}
				</Box>

				{!isMergeRequest && (
					<Box mt={5}>
						<Typography className={classes.subTitle}>School Acronym (Optional)</Typography>
						<TextField
							fullWidth
							hiddenLabel
							InputProps={{ style: { color: "primary" }, disableUnderline: true }}
							inputProps={{ maxLength: 140 }}
							color="primary"
							onChange={event => setSchoolAcronym(event.target.value)}
							onBlur={() => setSchoolAcronym(prev => prev?.trim())}
							variant="filled"
							size="small"
							placeholder="School Acronym (Optional)"
							value={schoolAcronym}
						/>
					</Box>
				)}

				{isMergeRequest && (
					<Box mt={5}>
						<Typography className={classes.subTitle}>Existing School Name</Typography>

						<CustomInput
							width={"100%"}
							theme={theme}
							inputProps={{
								onChange: ({ target: { value } }) => {
									setMergeRequestId(value);
								},
								onKeyPress: e => e?.preventDefault()
							}}
							options={{
								...defaultCustomInputOptions,
								inputData: mergeNewSchoolFormInputs.schools
							}}
						/>
					</Box>
				)}

				<Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.modalFooter}>
					<Button
						size="medium"
						variant={"contained"}
						color={"primary"}
						onClick={() => {
							submitSchool();
						}}
						disabled={typeof editSchoolPayload?.name === undefined || !schoolName || isFormValid}
					>
						{isMergeRequest
							? "Merge"
							: isMergeAction
							? "Approve"
							: editSchoolPayload?.id
							? "Update School"
							: "Add New School"}
					</Button>
				</Box>
			</Card>
		</Box>
	);
};
