import React from "react";

import { CourseHeader, FeatureIcon, FeatureRow, Package, PackageName, PackageText, SelectedIcon } from "./styles";

import Button from "../Button";

type PackageItemProps = {
	id: number;
	isCurrent?: boolean;
	headerText?: string;
	name: string;
	description: string[];
	handleSelect: () => void;
};
const PackageItemCard = ({ id, headerText, name, description, isCurrent, handleSelect }: PackageItemProps) => (
	<Package $current={isCurrent}>
		<CourseHeader>
			{headerText}
			<PackageName>{name}</PackageName>
		</CourseHeader>
		{isCurrent && <SelectedIcon />}
		<PackageText>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/*@ts-ignore*/}
			{description.map((d, i) => (
				<FeatureRow key={`feature-${id}-${i}`}>
					<FeatureIcon />
					{d}
				</FeatureRow>
			))}
		</PackageText>
		<Button variant="filled" color="primary" disabled={isCurrent} size="large" onClick={handleSelect}>
			Select Package
		</Button>
	</Package>
);

export default PackageItemCard;
