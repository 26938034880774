import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

export const TypeItem = styled(Box)`
	padding: 11px 11px 7px 11px;
	border-radius: ${({ theme }) => `${theme.spacing(1.2)}px`};
	cursor: pointer;
	border: ${({ color, theme }) => (color ? `1px solid ${color}` : `1px solid ${theme.palette.colors.basic[800]}`)};
`;

export const TypeTitle = styled(Typography)`
	font-size: 13px;
	font-weight: 600;
	color: ${({ color, theme }) => color ?? theme.palette.colors.basic[800]};
`;
