import { Box, ButtonBase, Typography, alpha } from "@material-ui/core";

import { CheckCircle } from "@material-ui/icons";
import { ReactComponent as PlusIcon } from "@remar/shared/dist/assets/icons/icon-plus.svg";
import { ReactComponent as TrashIcon } from "@remar/shared/dist/assets/icons/icon-trash-filled.svg";
import styled from "styled-components";

import { PencilIconSvg } from "assets/icons";

export const NoBooksWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p {
		font-family: var(--poppins);
		font-weight: 700;
		font-size: 18px;
		margin-top: 10px;
	}
`;
export const BookWrapper = styled(Box)`
	margin-right: 10px;
	margin-bottom: 10px;
	width: 330px;
	p {
		font-family: var(--openSans);
		color: ${props => alpha(props.theme.palette.text.primary, 0.87)};
		margin-top: 10px;
	}
	.title {
		font-size: 15px;
		font-weight: 500;
	}
	.description {
		font-size: 13px;
		font-weight: 400;
	}
	.validity {
		font-size: 13px;
		font-weight: 600;
		margin: 0;
		color: ${props => props.theme.palette.colors.basic[400]};
	}
	.price {
		font-family: var(--poppins);
		font-weight: 700;
		font-size: 18px;
	}
	.status {
		margin: 0;
		font-size: 15px;
		font-weight: 600;
	}
`;
export const EditPackageContainer = styled(ButtonBase)`
	background: ${({ theme, disabled }) =>
		disabled ? theme.palette.colors.basic[750] : theme.palette.colors.primary[500]};
	width: 32px;
	display: flex;
	border-radius: 4px;
	height: 32px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
export const EditPackageIcon = styled(PencilIconSvg)`
	path {
		fill: ${props => props.theme.palette.colors.basic[100]};
	}
`;

export const SelectCourseWrapper = styled(Box)`
	margin-bottom: 10px;
	button {
		width: 100%;
		background-color: ${({ theme }) => theme.palette.colors.basic[800]};
	}
`;

export const DisplayInfo = styled(Typography)`
	font-size: 12px;
	font-family: var(--openSans);
	font-weight: 400;
	color: ${({ theme }) => theme.palette.colors.basic[400]};
	margin-top: 5px;
`;

export const AddPackageIcon = styled(PlusIcon)`
	path {
		fill: ${props => props.theme.palette.colors.basic[100]};
	}
`;

export const DropPackageIcon = styled(TrashIcon)`
	path {
		fill: ${props => props.theme.palette.colors.basic[100]};
	}
`;

export const FeatureIcon = styled(CheckCircle)`
	flex-grow: 0;
	margin-right: 4px;
	font-size: 16px;
	color: hsl(210, 94%, 72%);
`;
