import React from "react";

import { MenuItem, Select } from "@material-ui/core";

import { useStyles } from "./styles";

const SelectInput = ({ options, placeholder, value, ...rest }) => {
	const classes = useStyles();
	return (
		<Select
			displayEmpty
			disableUnderline
			style={{ width: "100%", height: 48 }}
			value={value === undefined ? "" : value}
			{...rest}
			className={classes.select}
		>
			<MenuItem value="" disabled>
				{placeholder}
			</MenuItem>
			{options.map(item => (
				<MenuItem key={item.label} value={item.value}>
					{item.label}
				</MenuItem>
			))}
		</Select>
	);
};
export default SelectInput;
