import React from "react";

import { useSelector } from "react-redux";

import { getFullState } from "store/features/Auth/auth.slice";

const CheckPermissions = ({ children }) => {
	const {
		accessPerRoute: { canEdit, canView }
	} = useSelector(getFullState);

	return React.cloneElement(canView ? children : <></>, { disabled: !canEdit });
};

export default CheckPermissions;
