import React, { useEffect, useMemo } from "react";

import { Box, IconButton, Typography } from "@material-ui/core";
import CancelRounded from "@material-ui/icons/CancelRounded";
import { MAX_VIT_ANSWER_OPTIONS } from "@remar/shared/dist/constants";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { CustomInput as StyledInput, useStyles } from "../../../../QuestionBank/AddQuestion/styles";

import { useTestOnlyQuestionStyles } from "../../../TestOnlyQuestions/testOnlyQuestions.styles";

const maxActionableVITAnswerOptionCount = MAX_VIT_ANSWER_OPTIONS - 1;

const VITMultipleChoiceQuestion = ({
	createQuestionAnswerOptionItem,
	getFullState,
	removeQuestionAnswerOptionItem,
	setStateValue,
	validateForm,
	formName = "interactiveLessonFormGroup",
	statePath = "",
	templatePath = "answerOptions"
}) => {
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };

	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";

	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const { answerOptions } = questionData;

	useEffect(() => {
		if (answerOptions.length === 0) {
			dispatch(
				createQuestionAnswerOptionItem({
					formName,
					inputsPath: `${fullStatePath}.answerOptions`,
					templatePath
				})
			);
		}
	}, [answerOptions.length]);

	const nonDeletedAnswerOptsLength = useMemo(() => answerOptions.filter(x => !x.deleted.value).length, [answerOptions]);

	return (
		<>
			<Typography className={classes.addOptionHeader}>ANSWER OPTIONS</Typography>
			{answerOptions.map(
				({ isCorrect, text, deleted }, answerOptionIndex) =>
					!deleted?.value && (
						<Box key={`answerOption-${answerOptionIndex}`} display="flex" flexDirection="row" mt={2} width="100%">
							<Box display="flex" flexDirection="row" width={"100%"}>
								{isCorrect && (
									<Box className={classes.alignSelf}>
										<StyledInput
											display="flex"
											alignItems="center"
											theme={theme}
											options={{ ...defaultCustomInputOptions, inputData: isCorrect }}
										/>
									</Box>
								)}
								<StyledInput
									style={{ flex: 1 }}
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: text }}
								/>
							</Box>
							<Box display="flex" alignItems="center" justifyContent="center" width="51px">
								<IconButton
									size="small"
									style={{ color: "#ffffff" }}
									onClick={() =>
										dispatch(
											removeQuestionAnswerOptionItem({
												formName,
												inputsPath: `${fullStatePath}.answerOptions.${answerOptionIndex}`
											})
										)
									}
								>
									<CancelRounded color="disabled" />
								</IconButton>
							</Box>
						</Box>
					)
			)}
			<Box mt={2}>
				<AddButton
					isDisabled={nonDeletedAnswerOptsLength >= maxActionableVITAnswerOptionCount}
					onClick={() => {
						if (nonDeletedAnswerOptsLength < maxActionableVITAnswerOptionCount) {
							dispatch(
								createQuestionAnswerOptionItem({
									formName,
									inputsPath: `${fullStatePath}.answerOptions`,
									templatePath
								})
							);
						}
					}}
					label={"Add option"}
				/>
			</Box>
		</>
	);
};

export default VITMultipleChoiceQuestion;
