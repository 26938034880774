import { request } from "@remar/shared/dist/api/request";

import { UploadFileDto } from "./dto";

import { URLS } from "../services.constants";

export class GenericService {
	constructor() {}

	getImageUrl(data: { imageKey: string }): Promise<{ url: string }> {
		return request(`${URLS.GET_IMAGE_URL}?imageKey=${data.imageKey}`, "GET") as Promise<{ url: string }>;
	}

	upload(data: UploadFileDto): Promise<{ key: string; url: string }> {
		return request(URLS.UPLOAD, "POST", data as unknown as Record<string, unknown>) as Promise<{
			key: string;
			url: string;
		}>;
	}
}

export const genericService = new GenericService();
