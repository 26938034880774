import React, { useEffect, useState } from "react";

import { Box, Modal, TextareaAutosize, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import NotesSvg from "../../../assets/icons/icon-clipboard-list-check-filled.svg";
import DownloadIconSvg from "../../../assets/icons/icon-download.svg";
import CheckMarkSvg from "../../../assets/icons/icon-checkmark.svg";
import Circle from "../../../assets/icons/icon-circle.svg";
import CrossSvg from "../../../assets/icons/icon-cross.svg";

import { FilesUploaded } from "../../FilesUploaded/FilesUploaded";
import { Spacer } from "../../Spacer";
import { File, QuestionAttemptsCount } from "models";
import { downloadURI } from "../../../utils/serviceUtils/helpers";

import ChartColumn from "../../../assets/icons/icon-chart-column.svg";

import Timer from "../../../assets/icons/icon-clock.svg";

import YellowHalf from "../../../assets/icons/icon-half.svg";

import { PreviewNotesText, QuestionRationalInfo, QuestionResult } from "./styles";

import { useStyles as LessonStyles } from "../styles";

import { CorrectAnswer, NotAttempted, NotesContainer, PercentageContainer, WrongAnswer, useStyles } from "../styles";
import { fetchQuestionAttemptsCount, getQuestionNotes } from "../../../utils/sliceUtils/questionResultInfo";

const GetPeerPercentage = ({ qId, questionAttemptsCount }) => {
	const attempt = questionAttemptsCount?.find(attempt => attempt.questionId === qId) as QuestionAttemptsCount?? 0;
	const percentage = (attempt?.totalCorrectAttempts / attempt?.totalAttempts) * 100;;
	return <PreviewNotesText>{percentage ? percentage : 0}% of peers have answered correctly</PreviewNotesText>;
};

const PeerPercentage = React.memo(GetPeerPercentage);

const GetRationaleImg = ({ url }) => {
	const classes = useStyles();
	return (
		<Box display={"flex"} justifyContent="center" flex={1}>
			<img alt="rationale" className={classes.rationaleImage} src={url} />
		</Box>
	);
};

const RationaleImage = React.memo(GetRationaleImg);

const QuestionResultInfo = ({ result }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();
	const [showNotes, setShowNotes] = useState(false);
	const [notesQuestionId, setNotesQuestionId] = useState(0);
	const [notesText, setNotesText] = useState("");
	const [questionAttemptsCount, setQuestionAttemptsCount] = useState<QuestionAttemptsCount[]>([]);
	const lessonClasses = LessonStyles();
	const currentTest = result.userCustomTestId;

	const handleShowNotes = questionId => {
		if (notesQuestionId !== questionId) {
			getQuestionNotes(questionId, currentTest).then(res => {
				setNotesText(res?.items[0]?.text ?? "");
				setNotesQuestionId(questionId);
			});
		}
		setShowNotes(true);
	};

	useEffect(() => {
		if (result?.questionId) {
			fetchQuestionAttemptsCount(result.questionId).then(res => {
				setQuestionAttemptsCount(res?.items ?? []);
			});
		}
	}, [result?.questionId]);
	const getResultInfo = result => {
		let resultStatusIcon = <NotAttempted src={Circle} alt="Circle" className={classes.icon} />;
		let resultStatusText = "Not Attempted";
		if (result) {
			if (result?.percentageGrade === 1) {
				resultStatusText = "Correct";
				resultStatusIcon = <CorrectAnswer src={CheckMarkSvg} />;
			} else if (result?.percentageGrade === 0) {
				resultStatusText = "Incorrect";
				resultStatusIcon = <WrongAnswer src={CrossSvg} alt="CrossSvg" className={classes.icon} />;
			} else {
				resultStatusText = "Partially Correct";
				resultStatusIcon = <img src={YellowHalf} alt="YellowHalf" />;
			}
		}
		return (
			<>
				{resultStatusIcon} <QuestionResult>{resultStatusText}</QuestionResult>
			</>
		);
	};

	return (
		<>
			<Box className={classes.resultInfo}>
				<Box className={classes.rationaleContainer}>
					<Box className={classes.rationaleHeader}>
						<Box display={"flex"} alignItems="center" flex={1}>
							<Box display={"flex"} flex={1}>
								{getResultInfo(result)}
							</Box>
							{result?.snapshot.mainImageKey && <RationaleImage url={result.snapshot.mainImageUrl} />}
						</Box>
					</Box>
					<Box className={classes.rationaleHeader}>Rational answer</Box>
					<QuestionRationalInfo
						dangerouslySetInnerHTML={{
							__html: result?.snapshot?.endOfQuestionSummary ?? ""
						}}
					></QuestionRationalInfo>
					{(result?.snapshot?.attachments as File[])?.length > 0 && (
						<Box width={isMobile ? "100%" : "60%"}>
							<FilesUploaded
								attachments={result?.snapshot?.attachments as File[]}
								IconSvg={() => <img src={DownloadIconSvg} alt="Download" />}
								showTitle={false}
								isDownload
								borderColor="#edf1f7"
								filenameColor="#4d5058"
								onClick={attachment => downloadURI(attachment.fileUrl, attachment.name)}
							/>
						</Box>
					)}
					<Spacer />
					<NotesContainer
						onClick={() => {
							handleShowNotes(!result?.snapshot?.forCaseStudy ? result?.snapshot?.id : result?.snapshot?.parentId);
						}}
					>
						<img src={NotesSvg} alt="Notes" />
						<PreviewNotesText>Preview notes</PreviewNotesText>
					</NotesContainer>
					<PercentageContainer>
						<img src={ChartColumn} alt="ChartColumn" />
						<PeerPercentage qId={result?.questionId} questionAttemptsCount={questionAttemptsCount} />
					</PercentageContainer>
					{result && (
						<Box className={classes.timer}>
							<img src={Timer} alt="Timer" />
							<PreviewNotesText> Time spent - {result?.timeSpent} seconds </PreviewNotesText>
						</Box>
					)}
				</Box>
			</Box>
			{showNotes && (
				<Modal
					onClose={() => setShowNotes(false)}
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexFlow: "column"
					}}
					disableEnforceFocus
					disableAutoFocus
					open={showNotes}
				>
					<>
						<Box width={310} display="flex" alignItems="center" className={lessonClasses.notesHeader}>
							<Typography className={classes.notesTypography}>Notes</Typography>
							<IconButton size="small" onClick={() => setShowNotes(false)}>
								<Close />
							</IconButton>
						</Box>
						<Box width={310} className={lessonClasses.notesBody}>
							<TextareaAutosize
								className={classes.textArea}
								value={notesText}
								readOnly
								maxRows={9}
								minRows={9}
								maxLength={500}
							/>
						</Box>
					</>
				</Modal>
			)}
		</>
	);
};

export default QuestionResultInfo;
