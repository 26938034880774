import React, { useCallback, useEffect, useState } from "react";

import { Box, CircularProgress, FormControl, Grid, MenuItem, Select } from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { insertSpaces } from "@remar/shared/dist/utils/textUtils";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import { fetchDurations, fetchModules, generateReport } from "store/features/Reports/Reports.slice";

import { Module } from "store/services/reports";

import theme from "theme/default";

import { Container, DownloadButton, Header, HeaderContainer, Heading, ModuleContainer, ModuleWrapper } from "./styles";

const Reports = () => {
	const dispatch = useDispatch();
	const [loadingLabel, setLoadingLabel] = useState("");
	const [periodPayload, setPeriodPayload] = useState<{ key: string; value: number; durationLabel: string }[]>();

	const {
		module: { isLoading: isModuleLoading, items: moduleItems },
		duration: { isLoading: isDurationLoading, items: durationItems }
	} = useSelector((state: RootState) => state.reports);

	useEffect(() => {
		dispatch(fetchModules());
		dispatch(fetchDurations());
	}, [dispatch]);

	const handleSelect = (value, label) => {
		if (value) {
			const { id, label: durationLabel } = JSON.parse(value);

			setPeriodPayload(ctx => {
				const index = ctx?.findIndex(v => v.key === label);
				const newValue = { key: label, value: id, durationLabel };

				if (typeof index === "number" && index >= 0) {
					ctx?.splice(index, 1, newValue);
					return ctx;
				}
				return ctx ? [...ctx, newValue] : [newValue];
			});
		}
	};

	const downloadHandler = useCallback(
		(item: Module) => {
			setLoadingLabel(item.label);
			const period = periodPayload?.find(r => r.key === item.label);
			if (period) {
				dispatch(generateReport({ period, report: item.id, cb: () => setLoadingLabel("") }));
			}
		},
		[dispatch, periodPayload]
	);

	return (
		<Container>
			<HeaderContainer>
				<Header>
					<Heading>Reports</Heading>
				</Header>
			</HeaderContainer>
			<ModuleContainer container>
				{isModuleLoading ? (
					<Box display="flex" alignItems="center" justifyContent="center" height={450} m={20} width="100%">
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</Box>
				) : (
					moduleItems.map((i, index) => (
						<Grid key={index} container item xs={12} sm={6} md={4}>
							<ModuleWrapper item xs={12} spacing={4}>
								<Box className="report-card">
									<Heading>{i.label}</Heading>
									<Box mt={4} display="flex" justifyContent="space-between">
										<FormControl className="durations" hiddenLabel size="small" variant="filled">
											<Select
												disableUnderline
												value={periodPayload?.find(v => v.key === i.label)?.value}
												onChange={event => handleSelect(event.target.value, i.label)}
												displayEmpty
												MenuProps={{
													anchorOrigin: { vertical: "bottom", horizontal: "left" },
													transformOrigin: { vertical: "top", horizontal: "left" },
													getContentAnchorEl: null
												}}
												IconComponent={() => <ExpandMoreIcon style={{ color: theme.palette.colors.basic[400] }} />}
											>
												<MenuItem value={undefined} disabled>
													Choose a duration
												</MenuItem>
												{durationItems.map(d => (
													<MenuItem key={d.id} value={JSON.stringify(d)}>
														{insertSpaces(d.name)}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<DownloadButton
											className="downloadBtn"
											disabled={
												i.label === loadingLabel ||
												isDurationLoading ||
												!periodPayload?.find(v => v.key === i.label)?.value
											}
											onClick={() => downloadHandler(i)}
											startIcon={
												i.label === loadingLabel && (
													<CircularProgress size="1rem" color="inherit" thickness={5} variant="indeterminate" />
												)
											}
										>
											Download Report
										</DownloadButton>
									</Box>
								</Box>
							</ModuleWrapper>
						</Grid>
					))
				)}
			</ModuleContainer>
		</Container>
	);
};

export default Reports;
