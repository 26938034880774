import React from "react";

import { Box, Button, Card, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "@remar/shared/dist/styles";

export const DeleteSchool = ({
	onDelete,
	onClose,
	title = "Delete",
	message = "Are you sure you want to delete this item ?"
}: {
	onDelete: (_withDeleted: boolean) => void;
	onClose: () => void;
	title?: string;
	message?: string;
}) => {
	const classes = useStyles();
	return (
		<Box width={550}>
			<Card className={classes.card}>
				<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb="40px">
					<Typography className={classes.title}>{title}</Typography>
					<IconButton className={classes.closeIcon} onClick={onClose}>
						<CloseIcon color="disabled" />
					</IconButton>
				</Box>
				<Box>
					<Typography className={classes.subTitle}>{message}</Typography>
				</Box>

				<Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.modalFooter}>
					<Button variant={"contained"} className={classes.cancelBtn} size="medium" onClick={onClose}>
						No,Cancel
					</Button>
					<Button variant={"contained"} className={classes.deleteBtn} size="medium" onClick={() => onDelete(false)}>
						Yes, Delete
					</Button>
				</Box>
			</Card>
		</Box>
	);
};
