import React, { useState } from "react";

import { Box, Card, IconButton, Modal, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@remar/shared/dist/components/Button";
import { useStyles } from "@remar/shared/dist/styles";

import { DatePickerComponent } from "../../../../../../Components/FormikFields";

interface IDateRangeFilterModal {
	onClose: () => void;
	applyFilter: (from: Date, to: Date) => void;
	isLoading: boolean;
}

const DateRangeFilterModal = ({ onClose, applyFilter, isLoading }: IDateRangeFilterModal) => {
	const classes = useStyles();
	const [fromDate, setFromDate] = useState(new Date());
	const [toDate, setToDate] = useState(new Date());
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={true} onClose={onClose}>
			<Box width={450}>
				<Card className={classes.card}>
					<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={"30px"}>
						<Typography className={classes.title}>Select Custom Date Range</Typography>
						<IconButton className={classes.closeIcon} onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<Box my={2}>
						<Box mb={3}>
							<DatePickerComponent
								disabled={false}
								name="fromDate"
								minDate={new Date(0)}
								value={fromDate}
								onChange={d => setFromDate(d)}
								iconLoc={"end"}
							/>
						</Box>
						<Box mb={3}>
							<DatePickerComponent
								disabled={false}
								name="toDate"
								minDate={fromDate}
								value={toDate}
								onChange={d => setToDate(d)}
								iconLoc={"end"}
							/>
						</Box>
					</Box>
					<Box display={"flex"} justifyContent={"flex-end"}>
						<Button variant={"ghost"} color={"basic"} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant={"filled"}
							color={"primary"}
							onClick={() => applyFilter(fromDate, toDate)}
							disabled={isLoading}
							loading={isLoading}
						>
							Apply Range
						</Button>
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default DateRangeFilterModal;
