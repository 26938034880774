import React, { FC, useMemo } from "react";

import { Button, CircularProgress, TextField } from "@material-ui/core";

import { Field, Form, Formik } from "formik";

import * as Yup from "yup";

import { AddUpdateText, FieldLabel, useStyles } from "./styles";

import { SimpleModal } from "../SimpleModal";
import { ManageSubject } from "../../models/manageSubjects.model";
import { IExtendedTheme } from "../../theme/default";

type CreateUpdateType = ManageSubject | { id: undefined } | null;
interface Props {
	theme: IExtendedTheme;
	onSubmit: (values: unknown, { resetForm }: { resetForm: () => void }) => void;
	isOpen: boolean;
	onClose: () => void;
	data?: CreateUpdateType;
	submitLoading: boolean;
}

const CreateUpdateSubjectModal: FC<Props> = ({ theme, onSubmit, data, isOpen, onClose, submitLoading }) => {
	const classes = useStyles();
	const initialVal = useMemo(
		() => ({
			subjectCategoryName: data?.id ? data.name : ""
		}),
		[data]
	);
	const validationSchema = Yup.object().shape({
		subjectCategoryName: Yup.string().required("This field is required.")
	});

	return (
		<Formik
			enableReinitialize
			validateOnChange
			initialValues={initialVal}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ errors, isValid, dirty, resetForm }) => {
				const valid = isValid && dirty;
				return (
					<Form id={"my-form"}>
						<SimpleModal
							theme={theme}
							title={data?.id ? "Rename Subject" : "Add New Subject"}
							open={isOpen}
							headerClass={classes.noHeaderMargin}
							onClose={() => {
								resetForm();
								onClose();
							}}
							text={""}
							modalWidth={600}
							extraContent={
								<>
									<FieldLabel>Subject Category Name</FieldLabel>
									<Field name="subjectCategoryName">
										{({ field }) => (
											<TextField
												{...field}
												placeholder="Enter New Subject"
												variant="filled"
												color="primary"
												size="small"
												fullWidth
												error={!!errors?.subjectCategoryName}
												helperText={errors?.subjectCategoryName}
												InputProps={{
													style: {
														color: "#fff",
														border: !!errors?.subjectCategoryName ? "1px solid #ed5465" : ""
													},
													disableUnderline: true
												}}
											/>
										)}
									</Field>
								</>
							}
							footer={
								<Button
									variant={"contained"}
									form="my-form"
									type="submit"
									color="primary"
									disabled={submitLoading || !valid}
								>
									{submitLoading && (
										<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
									)}
									<AddUpdateText $isLoading={submitLoading} $disabled={submitLoading || !valid} theme={theme}>
										{data?.id ? "Save Changes" : "Add New Subject"}
									</AddUpdateText>
								</Button>
							}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export default CreateUpdateSubjectModal;
