import React, { useEffect, useMemo, useState } from "react";

import { Box, Theme, Typography, useTheme } from "@material-ui/core";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import Button from "@remar/shared/dist/components/Button";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import {
	ColumnHeader,
	StyledCellText,
	StyledCellWrapper,
	StyledNameChip
} from "@remar/shared/dist/components/Table/styles";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";
import { Tag } from "@remar/shared/dist/models";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { deleteTag, fetchTags, getFullTagsState } from "store/features/Tags/Tags.slice";

import AddEditTagModal from "./AddEditTagModal";

const breadCrumbs = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Tags", key: 1 }
];

const Tags = () => {
	const history = useHistory();
	const theme = useTheme<Theme>();
	const dispatch = useDispatch();

	const [deleteModalId, setDeleteModalId] = useState<null | number>(null);
	const [addEditModal, setAddEditModal] = useState<Tag | null>(null);
	const [searchText, setSearchText] = useState("");

	const { tags, page, perPage, totalItems, isLoading, isCreatingEditingDeleting } = useSelector(getFullTagsState);

	useEffect(() => {
		dispatch(fetchTags({}));
	}, [dispatch]);

	const tableColumns: Array<IColumn<Tag>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Name</ColumnHeader>,
				width: 300,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>
							<StyledNameChip>{name}</StyledNameChip>
						</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: ({ rowData: { sectionLessons } }) => (
					<StyledCellWrapper>
						<Box width={"100%"}>
							<Typography align={"left"} style={{ color: theme.palette.colors.basic[400] }}>
								Course Lessons
							</Typography>
							<Box style={{ display: "flex", flexFlow: "row wrap" }}>
								{sectionLessons.map(l => (
									<StyledNameChip $color={theme.palette.colors.basic[600]} key={l.id} style={{ margin: "5px 2px" }}>
										{l.lesson.name}
									</StyledNameChip>
								))}
							</Box>
						</Box>
					</StyledCellWrapper>
				),
				childCellColSpan: 3,
				dataKey: "name"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Lessons</ColumnHeader>,
				width: 150,
				Cell: ({ rowData: { lessons } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{lessons}</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: ({ rowData: { subjectLessons } }) => (
					<StyledCellWrapper>
						<Box width={"100%"}>
							<Typography align={"left"} style={{ color: theme.palette.colors.basic[400] }}>
								Subject Lessons
							</Typography>
							<Box style={{ display: "flex", flexFlow: "row wrap" }}>
								{subjectLessons.map(l => (
									<StyledNameChip $color={theme.palette.colors.basic[600]} key={l.id} style={{ margin: "5px 2px" }}>
										{l.subject.name}
									</StyledNameChip>
								))}
							</Box>
						</Box>
					</StyledCellWrapper>
				),
				childCellColSpan: 3,
				dataKey: "lessons"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Subjects</ColumnHeader>,
				width: 150,
				Cell: ({ rowData: { subjects } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{subjects}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "subjects"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Created</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{formatDate(createdAt)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "created_at"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Last Updated</ColumnHeader>,
				Cell: ({ rowData: { updatedAt } }) => (
					<StyledCellWrapper center>
						<StyledCellText>{formatDate(updatedAt)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "updated_at"
			},
			{
				alignment: "left",
				width: 90,
				Cell: ({ rowData }) => (
					<ActionMenu
						customMenuItems={[
							{
								label: "Edit",
								onClick: () => setAddEditModal(rowData),
								disabled: false,
								visible: true
							},
							{
								label: "Delete",
								onClick: () => setDeleteModalId(rowData.id),
								disabled: false,
								visible: true
							}
						]}
					/>
				),
				dataKey: "menu"
			}
		],
		[tags]
	);

	const handleSearchBarChange = (searchText: string) => {
		dispatch(fetchTags({ searchKeyword: searchText }));
	};

	const handleCloseDeleteModal = () => {
		setDeleteModalId(null);
	};

	const handleTagDelete = id => {
		dispatch(
			deleteTag({
				id,
				cb: () => {
					dispatch(fetchTags({}));
					handleCloseDeleteModal();
				}
			})
		);
	};

	return (
		<WithTableContentLayout
			heading={"Manage Tags"}
			breadcrumb={breadCrumbs}
			actions={
				<Box>
					<Button variant="ghost" color="primary" onClick={() => history.push("/")} style={{ marginRight: 5 }}>
						Back
					</Button>
					<Button variant="filled" color="primary" onClick={() => setAddEditModal({} as Tag)}>
						New
					</Button>
				</Box>
			}
			tableTitle={"Tags"}
			emptyState={
				<EmptyState
					description="No Tags added yet!"
					buttonTitle="+ Add New Tag"
					onButtonClick={() => setAddEditModal({} as Tag)}
					placeHolder={""}
				/>
			}
			onChangePage={page => dispatch(fetchTags({ page }))}
			isLoading={isLoading}
			page={page}
			perPage={perPage}
			totalItems={totalItems}
			searchText={searchText}
			setSearchText={setSearchText}
			handleSearchBarChange={handleSearchBarChange}
			tableColumns={tableColumns}
			data={tags}
			height={600}
		>
			{addEditModal && (
				<AddEditTagModal
					data={addEditModal}
					onClose={shouldUpdate => {
						setAddEditModal(null);
						if (shouldUpdate) {
							dispatch(fetchTags({}));
						}
					}}
				/>
			)}
			{deleteModalId && (
				<DeleteModal
					open={true}
					title={"Delete Tag"}
					message={"Are you sure you want to delete this Tag?"}
					onClose={handleCloseDeleteModal}
					onDelete={() => handleTagDelete(deleteModalId)}
					cancelBtnText={"No,Cancel"}
					deleteBtnText={"Yes, Delete"}
					deleteLoading={isCreatingEditingDeleting}
				/>
			)}
		</WithTableContentLayout>
	);
};

export default Tags;
