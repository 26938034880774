import React, { useEffect, useRef, useState } from "react";

import { Box, IconButton, Typography } from "@material-ui/core";
import HotspotHighlightInput, {
	HighlightedNode
} from "@remar/shared/dist/components/HotspotHighlightInput/HotspotHighlightInput";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";
import { CancelRounded, InputStyles, InvisibleInput, CustomInput as StyledInput, useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const HotspotHighlightTableQuestion = ({
	clearArrayOfQuestionAnswerOption,
	createQuestionGap,
	getFullState,
	removeGap,
	removeQuestionAttachment,
	setArrayOfQuestionAnswerOption,
	setStateValue,
	uploadQuestionMaterial,
	validateForm,
	templatePath = "answerOptions",
	formName = "addNewQuestionForm",
	statePath = ""
}) => {
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const {
		text,
		description,
		attachments,
		endOfQuestionSummary,
		mainImageKey,
		answerOptionLabel,
		tableLabel,
		groups,
		_expanded
	} = questionData;

	const valueRef = useRef<{
		[key: string]: HighlightedNode[];
	}>({});

	const [value, setValue] = useState<{
		[key: string]: HighlightedNode[];
	}>(valueRef.current);

	useEffect(() => {
		const newValue = {};

		groups.forEach(({ answerOptions }, index) => {
			newValue[`${index}`] = answerOptions.map(({ text, isCorrect }) => ({
				text: text.value,
				highlighted: isCorrect?.value
			}));

			dispatch(
				setArrayOfQuestionAnswerOption({
					formName,
					inputsPath: `${fullStatePath}.groups.${index}.answerOptions`,
					templatePath,
					lengthOfOptions: newValue[`${index}`].length,
					optionsArray: newValue[`${index}`]
				})
			);
		});
		setValue(newValue);
		valueRef.current = newValue;
	}, [questionData.id]);

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(
				createQuestionGap({
					formName,
					statePath
				})
			);
		}
	}, [groups.length]);

	const handleUpdate = (newValue, index) => {
		valueRef.current[`${index}`] = newValue;
		setValue({ ...valueRef.current });
		dispatch(
			clearArrayOfQuestionAnswerOption({
				formName,
				inputsPath: `${fullStatePath}.groups.${index}.answerOptions`
			})
		);

		dispatch(
			setArrayOfQuestionAnswerOption({
				formName,
				inputsPath: `${fullStatePath}.groups.${index}.answerOptions`,
				templatePath,
				lengthOfOptions: newValue.length,
				optionsArray: newValue
			})
		);
	};

	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			statePath={statePath}
			key={statePath}
			setStateValue={setStateValue}
			validateForm={validateForm}
			expanded={_expanded?.value}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
			<Typography className={classes.addOptionHeader}>TABLE VIEW</Typography>
			<table className={classes.highlightTable} cellSpacing="0">
				<thead>
					<tr>
						<td>
							<InvisibleInput
								InputProps={{
									style: {
										...InputStyles,
										backgroundColor: "#262930"
									}
								}}
								noMargin
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: { ...tableLabel, label: "" } }}
							/>
						</td>
						<td>
							<InvisibleInput
								InputProps={{
									style: {
										...InputStyles,
										backgroundColor: "#262930"
									}
								}}
								noMargin
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: { ...answerOptionLabel, label: "" } }}
							/>
						</td>
					</tr>
				</thead>
				<tbody>
					{groups.map((group, index) =>
						group.deleted?.value ? null : (
							<tr key={`group-${group.id.value}`}>
								<td>
									<InvisibleInput
										InputProps={{
											style: {
												...InputStyles,
												backgroundColor: "#262930"
											}
										}}
										noMargin
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: group.text }}
									/>
								</td>
								<td>
									<Box className={classes.hotspotHighlightTextBox}>
										<HotspotHighlightInput
											value={value[`${index}`] ?? []}
											currentIndex={index}
											onChange={newValue => handleUpdate(newValue, index)}
										/>
									</Box>
									<Box display="flex" pr={2} mb={0.5} alignItems="center" justifyContent="end">
										<Box
											display="flex"
											alignItems="center"
											className={classes.cursorPointer}
											onClick={() => {
												dispatch(removeGap({ formName, statePath, gapIndex: index }));
											}}
										>
											<IconButton size="small">
												<CancelRounded color="disabled" />
											</IconButton>

											<Typography> Delete Row</Typography>
										</Box>
									</Box>
								</td>
							</tr>
						)
					)}
				</tbody>
				<Box mt={2}>
					<AddButton
						onClick={() => {
							dispatch(
								createQuestionGap({
									statePath,
									formName
								})
							);
						}}
						label={"Add Row"}
					/>
				</Box>
			</table>
		</CommonQuestionFields>
	);
};
