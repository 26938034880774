import React from "react";

import { Box } from "@material-ui/core";
import { mapUniqueIdsToList } from "@remar/shared/dist/utils/mapUniqueIdsToList";

import { ICue } from "store/features/LessonVideos/LessonVideos.slice";

import Cue from "./Cue";

const cueInitialVal: ICue = {
	id: "0",
	start: 0,
	end: 0,
	text: "",
	identifier: "",
	styles: "",
	error: null
};
const SubtitleEditor = ({
	cues,
	setCues
}: {
	cues: ICue[];
	setCues: React.Dispatch<React.SetStateAction<{ valid: boolean; cues: ICue[] }>>;
}) => {
	const handleCueUpdate = ({ index, type, time }) => {
		setCues(prevState => {
			const _cuesState = [...prevState.cues];
			_cuesState[index][type] = time;
			return { ...prevState, cues: _cuesState };
		});
	};
	const handleTextUpdate = ({ index, text }) => {
		setCues(prevState => {
			const _cuesState = [...prevState.cues];
			_cuesState[index].text = text;
			return { ...prevState, cues: _cuesState };
		});
	};

	const handleAddCue = index => {
		setCues(prevState => {
			let _cuesState = [...prevState.cues];
			_cuesState.splice(index + 1, 0, { ...cueInitialVal });
			_cuesState = mapUniqueIdsToList(_cuesState);
			return { ...prevState, cues: _cuesState };
		});
	};
	const handleRemoveCue = index => {
		setCues(prevState => {
			let _cuesState = [...prevState.cues];
			_cuesState.splice(index, 1);
			_cuesState = mapUniqueIdsToList(_cuesState);
			return { ...prevState, cues: _cuesState };
		});
	};

	return (
		<Box>
			{cues.map(({ id, start, end, text, error }, index) => (
				<Cue
					key={id}
					index={index}
					handleCueUpdate={handleCueUpdate}
					handleTextUpdate={handleTextUpdate}
					addCue={handleAddCue}
					removeCue={handleRemoveCue}
					start={start}
					end={end}
					text={text}
					error={error}
				/>
			))}
		</Box>
	);
};

export default SubtitleEditor;
