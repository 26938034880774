import React, { FC, useState } from "react";

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	TextField,
	Typography,
	makeStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { CustomInputSelectOptionsItem } from "@remar/shared/dist/components/CustomInput/customInput.model";

import { BaseModel } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { useDispatch, useSelector } from "react-redux";

import {
	fetchCourseChapterSectionList,
	filterChaptersAndSections,
	filterSections,
	getFullState,
	setStateValue
} from "store/features/CreateLesson/createLesson.slice";
import { _emit } from "store/features/notifications/notifications.slice";
import * as services from "store/services";

interface Props {
	open: boolean;
	onClose: () => void;
	type: null | "course" | "section" | "chapter";
}

const useStyles = makeStyles((theme: IExtendedTheme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
}));

const CreateItemDialog: FC<Props> = ({ open, onClose, type }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const state = useSelector(getFullState);
	const [parentId, setParentId] = useState<number | null>(null);
	const [name, setName] = useState("");
	const [duration, setDuration] = useState<number | null>(null);

	const canBeSaved = duration !== null && name && parentId !== null;

	const onSave = () => {
		if (canBeSaved) {
			let parentKey: string;
			let service: BaseApiService<
				BaseModel,
				unknown,
				BaseFindDto,
				BaseFindOneDto,
				BaseUpdateDto<Record<string, unknown>>,
				BaseDeleteDto
			>;
			if (type === "section") {
				parentKey = "chapterId";
				service = services.courseChapterSectionsService;
			} else {
				parentKey = "courseId";
				service = services.courseChaptersService;
			}
			const body = {
				name: name,
				totalTimeLengthInMinutes: duration,
				mainImageUrl: ""
			};
			body[parentKey] = parentId;
			service.create(body).then(
				async e => {
					await dispatch(fetchCourseChapterSectionList());
					const lessonDetailsForm = state.lessonDetailsForm.inputs;
					if (type === "section") {
						const selectedChapters = [
							...(lessonDetailsForm["chapterIds"].value ?? []).filter(item => item),
							e["chapterId"]
						] as number[];
						const selectedCourseIds = Array.from(
							new Set([
								...state.chapters.filter(c => selectedChapters.includes(c.id)).map(c => c.courseId),
								...(lessonDetailsForm.courseIds.value ?? []).filter(item => item)
							])
						);
						dispatch(
							setStateValue({
								key: `${lessonDetailsForm.courseIds.statePath}.value`,
								value: selectedCourseIds
							})
						);
						dispatch(
							setStateValue({
								key: `${lessonDetailsForm.chapterIds.statePath}.value`,
								value: selectedChapters
							})
						);
						dispatch(
							setStateValue({
								key: `${lessonDetailsForm.sectionIds.statePath}.value`,
								value: [...(lessonDetailsForm.sectionIds.value ?? []).filter(item => item), e.id]
							})
						);
						await dispatch(filterChaptersAndSections(selectedCourseIds));
						await dispatch(filterSections(selectedChapters));
					} else {
						const selectedCourseIds = [
							...(lessonDetailsForm.courseIds.value ?? []).filter(item => item),
							e["courseId"]
						] as number[];
						dispatch(
							setStateValue({
								key: `${lessonDetailsForm.chapterIds.statePath}.value`,
								value: [...(lessonDetailsForm.chapterIds.value ?? []).filter(item => item), e.id]
							})
						);
						dispatch(
							setStateValue({
								key: `${lessonDetailsForm.courseIds.statePath}.value`,
								value: selectedCourseIds
							})
						);
						dispatch(filterChaptersAndSections(selectedCourseIds));
					}
					handleClose();
				},
				err => {
					console.error(err);
					dispatch(_emit("Error creating the item.", "error"));
				}
			);
		}
	};

	const handleClose = () => {
		setParentId(null);
		setName("");
		setDuration(null);
		onClose();
	};

	const selectKey = type === "chapter" ? "courseOptions" : "chapterOptions";
	const selectOptions: CustomInputSelectOptionsItem[] = state[selectKey];
	return (
		<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth disableEnforceFocus>
			<DialogTitle disableTypography className={classes.root}>
				<Typography variant="h6">Create new {type}</Typography>
				{handleClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
						<Close />
					</IconButton>
				) : null}
			</DialogTitle>
			<DialogContent dividers>
				<Box p={2}>
					<Box display="flex" alignItems="center">
						<Box width={100}>
							<Typography>Name</Typography>
						</Box>
						<Box width={400}>
							<TextField
								hiddenLabel
								value={name}
								onChange={e => setName(e.target.value)}
								InputProps={{ disableUnderline: true }}
								color="primary"
								fullWidth
								variant="filled"
								size="small"
								placeholder="Start typing here"
							/>
						</Box>
					</Box>
					<Box display="flex" alignItems="center" mt={3}>
						<Box width={100}>
							<Typography>Duration in minutes</Typography>
						</Box>
						<Box width={400}>
							<TextField
								value={duration}
								hiddenLabel
								onChange={e => setDuration(+e.target.value)}
								type="number"
								inputProps={{
									min: 1
								}}
								InputProps={{ disableUnderline: true }}
								color="primary"
								fullWidth
								variant="filled"
								size="small"
								placeholder="Start typing here"
							/>
						</Box>
					</Box>

					<Box display="flex" alignItems="center" mt={3}>
						<Box width={100}>
							<Typography>{type === "section" ? "Chapter" : "Course"}</Typography>
						</Box>
						<Box width={400}>
							<FormControl hiddenLabel size="small" variant="filled" fullWidth>
								<Select
									label={`Select ${selectKey}`}
									disableUnderline
									onChange={e => {
										setParentId(Number(e.target.value));
									}}
								>
									{selectOptions.map((item, index) => (
										<MenuItem key={`${item.value}-${index}`} value={`${item.value}`}>
											{item.text}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="contained" color="default" size="large">
					Cancel
				</Button>
				<Button disabled={!canBeSaved} onClick={onSave} variant="contained" color="primary" size="large">
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateItemDialog;
