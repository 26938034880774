import React, { useMemo, useState } from "react";

import { Box, Card, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as IconEquals } from "@remar/shared/dist/assets/icons/icon-equals.svg";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { Spacer } from "@remar/shared/dist/components/GiftCard/styles";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import copyPasteOnlyInput from "@remar/shared/dist/utils/copyPasteOnlyInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

import { PAYMENT_CONSTANTS } from "store/features/CourseAddEdit/courseAddEdit.constants";
import {
	addPackageFeature,
	deletePackageFeature,
	fetchStripeData,
	updatePackageFeatures
} from "store/features/ManagePackage/managePackage.slice";

import theme from "theme/default";

import {
	CourseFeatures,
	DeleteFeature,
	DragFeatureContainer,
	FeatureText
} from "../../../CourseAddEdit/courseAddEdit.styles";
import { CheckedIcon } from "../../PackageOverview/styles";

import Books from "../../components/Books";

const getFeature = value => {
	return value.trim().length !== 0;
};

const PackageDetails = ({
	defaultCustomInputOptions,
	managePackageInputs,
	initialStripeItem,
	recurringStripeItem,
	initialStripeItemPerSeat,
	recurringStripeItemPerSeat,
	description,
	packageFeaturesFormInputs,
	perSeatAddons,
	isCustomPackage
}) => {
	const [isAddFeature, setIsAddFeature] = useState(false);
	const dispatch = useDispatch();
	let descriptionPointId = 0;
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}
		const items = reorder(description, result.source.index, result.destination.index) as {
			id: number;
			feature: string;
		}[];
		dispatch(updatePackageFeatures(items));
	};
	const memoizedDescriptionPoints = useMemo(() => {
		if (description.length !== 0) {
			return description.map(item => {
				return { id: descriptionPointId++, feature: item };
			});
		}
	}, [description]);
	return (
		<Box>
			<Card>
				<Box>
					<Box>
						<Typography>Package Name</Typography>
					</Box>
					<CustomInput
						width={400}
						theme={theme}
						options={{ ...defaultCustomInputOptions, inputData: managePackageInputs.packageName }}
					/>
				</Box>
				<Box display={"flex"} mt={2}>
					<Box width={"50%"} mr={1}>
						<Box>
							<Typography>Package Price</Typography>
						</Box>
						<CustomInput
							theme={theme}
							inputProps={{
								onChange: e =>
									dispatch(fetchStripeData(e.target.value, PAYMENT_CONSTANTS.INITIAL, "userSubscriptionTypesForm")),
								onKeyPress: copyPasteOnlyInput
							}}
							options={{ ...defaultCustomInputOptions, inputData: initialStripeItem.planId }}
						/>
					</Box>
					<Box width={"50%"}>
						<Box>
							<Typography>Recurring Monthly Package Price</Typography>
						</Box>
						<CustomInput
							width={400}
							theme={theme}
							inputProps={{
								onChange: e =>
									dispatch(fetchStripeData(e.target.value, PAYMENT_CONSTANTS.RECURRING, "userSubscriptionTypesForm")),
								onKeyPress: copyPasteOnlyInput
							}}
							options={{ ...defaultCustomInputOptions, inputData: recurringStripeItem.planId }}
						/>
					</Box>
				</Box>
				<Box display={"flex"} mt={2}>
					<Box width={"50%"} mr={1}>
						<Box>
							<Typography>Price Per Seat</Typography>
						</Box>
						<CustomInput
							width={400}
							theme={theme}
							inputProps={{
								onChange: e =>
									dispatch(
										fetchStripeData(e.target.value, PAYMENT_CONSTANTS.INITIAL, "userSubscriptionTypesFormPerSeat")
									),
								onKeyPress: copyPasteOnlyInput
							}}
							options={{
								...defaultCustomInputOptions,
								inputData: initialStripeItemPerSeat.planId
							}}
						/>
					</Box>
					<Box width={"50%"}>
						<Box>
							<Typography>Recurring Monthly Seat Price</Typography>
						</Box>
						<CustomInput
							width={400}
							theme={theme}
							inputProps={{
								onChange: e =>
									dispatch(
										fetchStripeData(e.target.value, PAYMENT_CONSTANTS.RECURRING, "userSubscriptionTypesFormPerSeat")
									),
								onKeyPress: copyPasteOnlyInput
							}}
							options={{
								...defaultCustomInputOptions,
								inputData: recurringStripeItemPerSeat.planId
							}}
						/>
					</Box>
				</Box>
				{isCustomPackage && (
					<Box display={"flex"} mt={2}>
						<Box display={"flex"} flexDirection={"column"}>
							<Box>
								<Typography>Package</Typography>
							</Box>
							<CustomInput
								width={400}
								theme={theme}
								// inputProps={{}}
								options={{
									...defaultCustomInputOptions,
									inputData: managePackageInputs.customLocationPackageId
								}}
							/>
						</Box>
					</Box>
				)}

				<Box mt={3}>
					<Spacer />
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{provided => (
								<Box {...provided.droppableProps} ref={provided.innerRef}>
									{memoizedDescriptionPoints?.map(keyItem => (
										<Draggable key={keyItem.id} draggableId={`${keyItem.id}`} index={keyItem.id}>
											{provided => (
												<DragFeatureContainer
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<Box display={"flex"} alignItems="center">
														<CheckedIcon fontSize="small" key={keyItem.id} />
														<CourseFeatures>
															<FeatureText title={keyItem.feature}>{keyItem.feature}</FeatureText>
															<Box>
																{" "}
																<SvgIcon fontSize="small" cursor="grab">
																	<IconEquals />
																</SvgIcon>{" "}
																<DeleteFeature onClick={() => dispatch(deletePackageFeature(keyItem.feature))} />
															</Box>
														</CourseFeatures>
													</Box>
												</DragFeatureContainer>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Box>
							)}
						</Droppable>
					</DragDropContext>
					{isAddFeature && (
						<Box ml={2} width={"50%"}>
							<CustomInput
								width={600}
								theme={theme}
								onBlur={e => {
									getFeature(e.target.value) && dispatch(addPackageFeature(e.target.value));
									getFeature(e.target.value) && setIsAddFeature(false);
								}}
								options={{ ...defaultCustomInputOptions, inputData: packageFeaturesFormInputs.feature }}
							/>
						</Box>
					)}
				</Box>
				{description.length !== 9 && (
					<AddButton
						onClick={() => {
							setIsAddFeature(true);
						}}
						label={"Add Feature"}
					/>
				)}
			</Card>
			<Books addons={perSeatAddons} />
		</Box>
	);
};

export default PackageDetails;
