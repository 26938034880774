import React, { useMemo } from "react";

import { Box } from "@material-ui/core";
import PackageItemCard from "@remar/shared/dist/components/CoursePackageItemCard/PackageItemCard";
import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import { Course, UserSubscriptionType } from "@remar/shared/dist/models";

type SelectPackageProps = {
	selectedCourse: Course;
	selectedPackageId: number | undefined;
	onSelectPackage: (p: UserSubscriptionType) => void;
};
const SelectPackage = ({ selectedPackageId, selectedCourse, onSelectPackage }: SelectPackageProps) => {
	const packages = useMemo(() => {
		return selectedCourse?.allowedForUST.filter(
			p =>
				p.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.Course &&
				!p.isRecurring &&
				p.isActive &&
				!p.isTrial &&
				!p.isAppleSubscription
		);
	}, [selectedCourse]);

	return (
		<Box display="flex" flexWrap="wrap" flexDirection="row" gridGap={20}>
			{packages?.map(pkg => (
				<PackageItemCard
					key={pkg.id}
					id={pkg.id}
					name={pkg.name}
					description={[pkg.description]}
					handleSelect={() => onSelectPackage(pkg)}
					headerText={`${pkg.duration.months} months`}
					isCurrent={selectedPackageId === pkg.id}
				/>
			))}
		</Box>
	);
};

export default SelectPackage;
