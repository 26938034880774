import React, { useState } from "react";

import { FormControl, MenuItem, makeStyles } from "@material-ui/core";

import { QuizQuestionAnswerOption, StyledDropdown } from "./style";

const useStyles = makeStyles(() => ({
	formControl: {
		verticalAlign: "middle"
	},
	list: {
		backgroundColor: "white",
		"& > li": {
			color: "#4d5058",
			fontSize: "0.9rem",
			fontWeight: "500"
		}
	}
}));

const ClozeDropDown = ({ groupId, answerOptions }) => {
	const classes = useStyles();
	const [currentId, setCurrentId] = useState("");
	const handleChange = val => {
		setCurrentId(val.id);
	};
	return (
		<FormControl className={classes.formControl}>
			<StyledDropdown
				disableUnderline
				value={currentId}
				onChange={event => handleChange({ id: event.target.value, groupId })}
				displayEmpty
				MenuProps={{
					classes: { list: classes.list },
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left"
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left"
					},
					getContentAnchorEl: null
				}}
			>
				<MenuItem value="" disabled>
					Select
				</MenuItem>
				{answerOptions.map(({ id, text }) => (
					<MenuItem key={id} value={id}>
						{text}
					</MenuItem>
				))}
			</StyledDropdown>
		</FormControl>
	);
};

const ClozeDropDownQuestionPreview = ({
	question: {
		data: { groups }
	}
}) => (
	<QuizQuestionAnswerOption>
		{groups?.map(({ id: groupId, text, answerOptions }) => (
			<span key={groupId}>
				{text}{" "}
				{answerOptions && answerOptions.length !== 0 && (
					<ClozeDropDown answerOptions={answerOptions} groupId={groupId} />
				)}
			</span>
		))}
	</QuizQuestionAnswerOption>
);

export default ClozeDropDownQuestionPreview;
