import React, { useState } from "react";

import { Box, Button, Checkbox, CircularProgress, FormControlLabel } from "@material-ui/core";
import { TodaySharp } from "@material-ui/icons";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import { useDispatch, useSelector } from "react-redux";

import { cancelSubscription, getAllStudents, getFullState } from "store/features/Students/students.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { Input, useStyles } from "../../styles";

const AmountInput = ({ refund, setRefund, amount, setAmount }) => {
	const classes = useStyles();

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const amount = event.target.value as number;
		setAmount(amount);
	};

	return (
		<>
			<Box className={classes.input}>
				<Box display="flex" justifyContent="space-between">
					<Box className={classes.refundText} ml={2} mt={1}>
						Do you want to send a refund?
					</Box>
					<FormControlLabel
						label=""
						className={classes.amountCheckbox}
						control={
							<Checkbox value="refund" checked={refund} onChange={() => setRefund(refund => !refund)} color="primary" />
						}
					/>
				</Box>
				{refund && (
					<Input
						errorText={!amount && "Required"}
						onChange={handleChange}
						value={amount}
						type="number"
						placeholder="Enter Amount in $"
					/>
				)}
			</Box>
		</>
	);
};

const CancelSubscriptionModal = ({ modalOpen, setModalOpen, text, subscriptionId }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { manageSubscriptionLoading } = useSelector(getFullState);
	const [refund, setRefund] = useState(false);
	const [amount, setAmount] = useState();

	const onClose = () => {
		if (!manageSubscriptionLoading) {
			setModalOpen(false);
		}
	};

	const sideEffect = (e, notificationType) => {
		if (!manageSubscriptionLoading) {
			notificationType !== "error" && setModalOpen(false);
			dispatch(_emit(notificationType === "error" ? e.message : e, notificationType));
			notificationType === "success" && dispatch(getAllStudents({}));
		}
	};

	const handleCancelSubscription = () => {
		dispatch(cancelSubscription({ subscriptionId, amount, sideEffect, refund }));
	};

	return (
		<>
			<SimpleModal
				theme={theme}
				open={modalOpen}
				onClose={onClose}
				text={text}
				extraContent={<AmountInput refund={refund} setRefund={setRefund} amount={amount} setAmount={setAmount} />}
				icon={<TodaySharp style={{ color: "#586179", width: "50px", height: "50px" }} />}
				footer={
					<>
						<Button className={classes.cancelBtn} size="medium" disabled={manageSubscriptionLoading} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							className={classes.save}
							onClick={handleCancelSubscription}
							disabled={refund && !amount}
						>
							{manageSubscriptionLoading ? (
								<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
							) : (
								<>Cancel Subscription</>
							)}
						</Button>
					</>
				}
			/>
		</>
	);
};

export default CancelSubscriptionModal;
