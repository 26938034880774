import React from "react";

import { ButtonBase } from "@material-ui/core";

import styled from "styled-components";

const StyledButton = styled(ButtonBase)`
	border-radius: 50%;
	background: ${props => (props.disabled ? "hsl(220,13%,22%)" : "hsl(221,61%,38%)")};
	padding: 8px;
`;

const IconRoundButton = ({ Icon, ...rest }) => {
	return <StyledButton {...rest}>{Icon}</StyledButton>;
};

export default IconRoundButton;
