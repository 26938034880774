import React, { FC, ReactNode, useEffect, useState } from "react";

import { Box } from "@material-ui/core";

import { DndProvider, useDrag, useDrop } from "react-dnd";

import { HTML5Backend } from "react-dnd-html5-backend";

import {
	AnswerOptionText,
	AnswerOptionTextContainer,
	DragIconContainer,
	GroupingQuestionPreviewContainer,
	GroupsContainer,
	GroupsTextContainer,
	GroupsTextWrapper,
	TestQuizQuestionText
} from "./style";

import { MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH } from "../../../constants";

interface GroupAnswerOptions {
	id: string;
	text: string;
	order: number;
	deleted: boolean;
}

interface Group {
	id: string;
	text: string;
	answerOptions: GroupAnswerOptions[];
}
const AnswerOptionDropZone: FC<{
	canAlwaysDrop?: boolean;
	children: ReactNode | ReactNode[];
	dropZoneIndex?: number;
	index: number;
	onDrop: (_options: { newDropZoneIndex?: number; oldDropZoneIndex?: number; order: number }) => unknown;
	style?: { [fieldName: string]: unknown };
}> = ({ canAlwaysDrop, children, dropZoneIndex, index, onDrop, style }) => {
	const [dragObjectData, drop] = useDrop({
		accept: "box",
		drop: item =>
			onDrop({
				newDropZoneIndex: dropZoneIndex,
				oldDropZoneIndex: (item as unknown as Record<string, unknown>).dropZoneIndex as number,
				order: item.index
			}),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
		canDrop: (dropItem: { index: number }) =>
			canAlwaysDrop || (dropItem.index !== index && dropItem.index !== index - 1)
	});
	const { isOver, canDrop } = dragObjectData as Record<string, boolean>;
	const overStyles = {
		border: "1px dashed #fff",
		height: 48,
		margin: "8px 8px 0 0"
	};
	return (
		<div ref={drop} style={style || {}}>
			{children}
			<div style={isOver && canDrop ? overStyles : {}} />
		</div>
	);
};

const AnswerOption: FC<{
	answerOptionIndex: number;
	dropZoneIndex?: number;
	text: string;
}> = ({ answerOptionIndex, dropZoneIndex, text }) => {
	const [canDrag, setCanDrag] = useState(false);
	const [, drag] = useDrag({
		item: { dropZoneIndex, index: answerOptionIndex },
		type: "box",
		canDrag: () => canDrag,
		end: () => setCanDrag(false)
	});

	return (
		<div ref={drag}>
			<GroupingQuestionPreviewContainer>
				<AnswerOptionTextContainer>
					<AnswerOptionText>{text}</AnswerOptionText>
				</AnswerOptionTextContainer>
				<DragIconContainer onMouseDown={() => setCanDrag(true)}>=</DragIconContainer>
			</GroupingQuestionPreviewContainer>
		</div>
	);
};

const GroupingQuestionPreview = ({ question }) => {
	const [groups, setGroups] = useState<Group[]>([]);
	useEffect(() => {
		if (question.groups.length > 0) {
			setGroups(question.groups);
		}
	}, [question.groups.length]);

	const updateOptionOrder = ({ order, newOrder, groupIndex }) => {
		let newGroups = [...groups];

		const { answerOptions } = newGroups[groupIndex];
		if (newOrder === order) {
			return;
		}

		let newAnswerOptions = [...answerOptions].sort((a, b) => {
			if (a.order === undefined || b.order === undefined) return 0;
			return a.order - b.order;
		});
		newAnswerOptions = newAnswerOptions.map((x, i) => {
			return order === i ? { ...x, order: newOrder } : { ...x, order: i + 1 };
		});

		if (newOrder > order) {
			newAnswerOptions.slice(order + 1, newOrder).forEach(({ order }) => {
				// eslint-disable-next-line no-param-reassign
				order !== undefined && order--;
			});
		} else {
			newAnswerOptions.slice(newOrder, order).forEach(({ order }) => {
				// eslint-disable-next-line no-param-reassign
				order !== undefined && order++;
			});
		}

		newAnswerOptions.sort((a, b) => {
			if (a.order === undefined || b.order === undefined) return 0;
			return a.order - b.order;
		});

		// newGroups[groupIndex].answerOptions = newAnswerOptions;

		newGroups = newGroups.map((x, i) => {
			return groupIndex === i ? { ...x, answerOptions: newAnswerOptions } : x;
		});
		setGroups(newGroups);
	};

	const changeGroupForQuestionAnswerOption = ({ oldOptionIndex, newOptionIndex, newGroupIndex, oldGroupIndex }) => {
		if (newGroupIndex === oldGroupIndex) {
			return;
		}
		let newGroups = [...groups];
		let oldGroupAnswerOptions = [...newGroups[oldGroupIndex].answerOptions];
		const slicedItem = oldGroupAnswerOptions[oldOptionIndex];
		oldGroupAnswerOptions.splice(oldOptionIndex, 1);
		oldGroupAnswerOptions = oldGroupAnswerOptions.map((x, i) => {
			return { ...x, order: i + 1 };
		});

		let newGroupAnswerOptions = [...newGroups[newGroupIndex].answerOptions];

		newGroupAnswerOptions.splice(newOptionIndex, 0, slicedItem);

		newGroupAnswerOptions = newGroupAnswerOptions.map((x, i) => {
			return { ...x, order: i + 1 };
		});

		newGroups = newGroups.map((x, i) => {
			return i === oldGroupIndex
				? { ...x, answerOptions: oldGroupAnswerOptions }
				: { ...x, answerOptions: newGroupAnswerOptions };
		});

		setGroups(newGroups);
	};

	const moveGroupQuestionAnswerOption = ({ newGroupIndex, oldGroupIndex, oldOptionIndex, newOptionIndex }) => {
		if (newGroupIndex === oldGroupIndex) {
			updateOptionOrder({
				newOrder: newOptionIndex,
				order: oldOptionIndex,
				groupIndex: newGroupIndex
			});
		} else {
			changeGroupForQuestionAnswerOption({
				newGroupIndex,
				oldGroupIndex,
				oldOptionIndex,
				newOptionIndex
			});
		}
	};

	return (
		<>
			<TestQuizQuestionText>{question?.text}</TestQuizQuestionText>
			<DndProvider backend={HTML5Backend}>
				<GroupsContainer>
					{groups?.map((groups, groupIndex) => {
						const { answerOptions, text } = groups;
						return (
							<GroupsTextContainer key={`createLesson-qestionItem-answerGroup-${groupIndex}`}>
								<GroupsTextWrapper>
									<TestQuizQuestionText>{text}</TestQuizQuestionText>
								</GroupsTextWrapper>

								<AnswerOptionDropZone
									canAlwaysDrop={true}
									index={0}
									onDrop={data => {
										const { oldDropZoneIndex, order } = data;
										if (
											groupIndex === oldDropZoneIndex ||
											answerOptions.length < MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH
										) {
											moveGroupQuestionAnswerOption({
												newGroupIndex: groupIndex!,
												oldGroupIndex: oldDropZoneIndex!,
												oldOptionIndex: order,
												newOptionIndex: 0
											});
										}
									}}
								>
									<Box style={{ minHeight: 10 }} />
								</AnswerOptionDropZone>
								{answerOptions?.map((answerOption, answerOptionIndex) => {
									const { text } = answerOption;
									return (
										<AnswerOptionDropZone
											canAlwaysDrop={true}
											dropZoneIndex={groupIndex}
											key={`createLesson-qestionItem-answerGroup-${groupIndex}-answerOption-${answerOptionIndex}`}
											index={groupIndex}
											onDrop={data => {
												const { oldDropZoneIndex, newDropZoneIndex, order } = data;
												if (
													oldDropZoneIndex === newDropZoneIndex ||
													answerOptions.length < MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH
												) {
													moveGroupQuestionAnswerOption({
														newGroupIndex: groupIndex!,
														oldGroupIndex: oldDropZoneIndex!,
														oldOptionIndex: order,
														newOptionIndex: answerOptionIndex + 1
													});
												}
											}}
										>
											<AnswerOption
												answerOptionIndex={answerOptionIndex}
												dropZoneIndex={groupIndex}
												key={`createLesson-qestionItem-answerGroup-${groupIndex}-answerOption-${answerOptionIndex}`}
												text={text}
											/>
										</AnswerOptionDropZone>
									);
								})}
							</GroupsTextContainer>
						);
					})}
				</GroupsContainer>
			</DndProvider>
		</>
	);
};

export default GroupingQuestionPreview;
