import React from "react";

import { Modal } from "@material-ui/core";

import { UserSubscription } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import AddDaysInSubscription from "../ManageStudents/components/AddDaysInSubscription";

interface IAddDays {
	fullName: string;
	userId: number;
	userEmail: string;
	subscription: UserSubscription;
}

const AddDaysModal = ({
	setShowAddDaysModal,
	showAddDaysModal: { fullName, userId, userEmail, subscription },
	fetchAccount = false
}: {
	setShowAddDaysModal: React.Dispatch<React.SetStateAction<IAddDays | null>>;
	showAddDaysModal: IAddDays;
	fetchAccount?: boolean;
}) => {
	const classes = useStyles();
	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus
			disableAutoFocus
			open={true}
			onClose={() => setShowAddDaysModal(null)}
		>
			<AddDaysInSubscription
				fullName={fullName}
				userId={userId}
				userEmail={userEmail}
				subscription={subscription}
				onClose={() => setShowAddDaysModal(null)}
				fetchAccount={fetchAccount}
			/>
		</Modal>
	);
};

export default AddDaysModal;
