import React from "react";

import { Box } from "@material-ui/core";
import styled from "styled-components";

const CardBlockWrapper = styled(Box)`
	display: flex;
	flex-flow: column;
	align-content: center;
	background-color: ${({ theme }) => theme.palette.colors.basic[1000]};

	.card-header {
		height: 64px;
		background: ${({ theme }) => theme.palette.colors.basic[900]};
		padding: 12px 16px;
		display: flex;
		gap: 16px;
		color: ${({ theme }) => theme.palette.text.ternary};
		align-items: center;

		.main-title {
			font-size: ${({ theme }) => theme.typography.pxToRem(15)};
			font-weight: 600;
			color: ${({ theme }) => theme.palette.text.tertiary};
		}

		.card-title {
			font-size: ${({ theme }) => theme.typography.pxToRem(15)};
			font-weight: 600;
			color: ${({ theme }) => theme.palette.text.secondary};
		}
	}

	.card-content {
		padding: 16px;

		.card-small-text {
			font-size: ${({ theme }) => theme.typography.pxToRem(13)};
			color: ${({ theme }) => theme.palette.text.tertiary};
			opacity: 0.87;
		}

		.s-block-title {
			margin-left: 8px;
			margin-top: 26px;
			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
			font-weight: 600;
			color: ${({ theme }) => theme.palette.text.ternary};
			opacity: 0.87;
			display: flex;
			gap: 8px;
			align-items: center;
		}

		.card-info-block {
			margin: 16px 0;

			.card-info-block-title {
				opacity: 0.87;
			}

			.chip {
				margin-top: 4px;
				margin-right: 16px;
				background: ${({ theme }) => theme.palette.common.white};
				color: ${({ theme }) => theme.palette.common.black};
			}
		}
	}
`;

export const CardBlock = ({ title, children }) => (
	<CardBlockWrapper>
		<Box className="card-header">{title}</Box>
		<Box className="card-content">{children}</Box>
	</CardBlockWrapper>
);
