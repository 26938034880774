import { Box, Typography } from "@material-ui/core";

import styled from "styled-components";

export const Label = styled(Typography)`
	font-size: 15px;
	font-weight: 600;
	font-family: var(--openSans);
	line-height: 1.85;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.colors.basic[400]};
`;

export const AddCATWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	.title {
		font-weight: 700;
		font-size: 18px;
		font-family: var(--poppins);
		opacity: 0.87;
	}
	.info {
		font-weight: 600;
		font-size: 13px;
		color: ${({ theme }) => theme.palette.colors.basic[400]};
	}
`;
