import React from "react";

import { Box, Typography } from "@material-ui/core";

import { SubscriptionStatusIcon, SubscriptionStatusWrapper } from "./styles";
type Colors = "basic" | "primary" | "success" | "info" | "warning" | "danger";
interface IStatusComponent {
	color: Colors;
	text: string;
	subText?: string;
}
const StatusComponent = ({ color, text, subText }: IStatusComponent) => (
	<SubscriptionStatusWrapper>
		<Box display={"flex"} alignItems={"center"}>
			<SubscriptionStatusIcon color={color} /> <Typography className={"status"}>{text}</Typography>
		</Box>
		{subText && <Typography className={"subText"}>{subText}</Typography>}
	</SubscriptionStatusWrapper>
);

export default StatusComponent;
