import styled from "styled-components";

export const MessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 40vh;
	background: black;
	padding: 20px;
	text-align: center;
`;

export const VideoContainerDiv = styled.div`
	.video-js {
		padding-top: 0;
		height: auto;
	}

	.vjs-tech {
		max-height: 45vw;

		position: static;
	}
`;
