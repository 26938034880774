import React from "react";

import { Box, Chip, Typography } from "@material-ui/core";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import { EmailLogItem } from "store/services/emailLogService";

import { AdobeReaderSvg } from "assets/icons";

import AttachmentPreview from "./AttachmentPreview";

type EmailPreviewProps = {
	email: EmailLogItem | null;
	onClose: () => void;
};
const EmailPreview = ({ email, onClose }: EmailPreviewProps) => {
	const [attachmentPreview, setAttachmentPreview] = React.useState<{ name: string; url: string } | null>(null);

	const footer = (
		<Box width="100%">
			<Typography>Attachments:</Typography>
			<Box display="flex" justifyContent="start" gridGap={16} mt={2.5}>
				{email?.compiledData.attachments.map((a, i) => (
					<Box
						key={i}
						alignSelf="start"
						display="flex"
						flexDirection="column"
						alignItems="center"
						style={{ cursor: "pointer" }}
						onClick={() => setAttachmentPreview(a)}
					>
						<AdobeReaderSvg width={64} height={64} style={{ marginBottom: 8 }} />
						<Typography>{a.name}</Typography>
					</Box>
				))}
			</Box>
		</Box>
	);

	return (
		<>
			<SimpleModal
				title="Email Preview"
				open={!!email}
				onClose={onClose}
				footer={!!email?.compiledData.attachments.length ? footer : null}
				modalWidth={800}
				extraContent={
					<>
						<Box borderBottom="1px solid hsl(223, 14%, 26%)" display="flex" gridGap={20} pb={6}>
							<Box display="flex" gridGap={8}>
								<Typography>From</Typography>
								<Chip size="small" color="default" label={email?.compiledData.from} />
							</Box>
							<Box display="flex" gridGap={8}>
								<Typography>To</Typography>
								<Chip size="small" color="default" label={email?.compiledData.to} />
							</Box>
						</Box>
						<div
							style={{ margin: "20px 0 24px" }}
							dangerouslySetInnerHTML={{ __html: email?.compiledData.body || "" }}
						/>
					</>
				}
			/>
			<AttachmentPreview attachment={attachmentPreview} onClose={() => setAttachmentPreview(null)} />
		</>
	);
};

export default EmailPreview;
