import React, { useEffect, useMemo, useState } from "react";

import { Box, IconButton, Typography } from "@material-ui/core";
import CancelRounded from "@material-ui/icons/CancelRounded";
import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";
import { GroupContainer, RemoveGapWrapper, CustomInput as StyledInput, useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const DragAndDropQuestion = ({
	addOneMoreQuestionAnswerOption,
	createQuestionGap,
	getFullState,
	removeGap,
	removeQuestionAnswerOptionItem,
	removeQuestionAttachment,
	setStateValue,
	uploadQuestionMaterial,
	validateForm,
	templatePath = "answerOptions",
	formName = "addNewQuestionForm",
	statePath = ""
}) => {
	const dispatch = useDispatch();

	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";

	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const [gapIndex, setGapIndex] = useState(0);
	const { groups, answerOptions, _expanded, description, attachments, endOfQuestionSummary, mainImageKey } =
		questionData;

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const notDeletedGaps = groups.filter(({ deleted }) => !deleted.value).length;

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(createQuestionGap({ start: true, statePath, formName }));
		}
	}, [groups.length]);

	useEffect(() => {
		if (answerOptions.length === 0) {
			dispatch(
				addOneMoreQuestionAnswerOption({
					formName,
					inputsPath: `${fullStatePath}.answerOptions`,
					templatePath
				})
			);
		}
	}, [answerOptions.length]);

	const nonDeletedAnswerOptsLength = useMemo(() => answerOptions.filter(x => !x.deleted.value).length, [answerOptions]);

	const isAlreadyOptSelected = id => {
		return groups
			.filter(({ deleted }) => !deleted.value)
			.some(function (elem) {
				return elem.selectedAnswerOptions.value.includes(id);
			});
	};

	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			statePath={statePath}
			setStateValue={setStateValue}
			expanded={_expanded?.value}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			{groups.map(
				({ id, text, selectedAnswerOptions, deleted }, groupIndex) =>
					!deleted.value && (
						<GroupContainer display="flex" key={id.value} error={text.error}>
							<Box width="calc(70% - 22px)" mr={1}>
								<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
							</Box>

							<Box width="calc(29% - 22px)" display="flex" alignItems="center">
								{groupIndex < groups.length - 1 ? (
									<StyledInput
										mr={2}
										theme={theme}
										inputProps={{
											onChange: ({ target: { value } }) => {
												dispatch(
													setStateValue({
														key: `${formName}.${fullStatePath}.groups.${groupIndex}.selectedAnswerOptions.value`,
														value: [value]
													})
												);
												dispatch(validateForm({ formStatePath: formName }));
											}
										}}
										options={{
											...defaultCustomInputOptions,
											inputData: {
												type: CustomInputType.Select,
												defaultValue: "",
												value: selectedAnswerOptions.value[0] || "",
												selectOptions: answerOptions
													.filter(({ deleted }) => !deleted.value)
													.map(({ text, id }) => ({
														text: text.value,
														value: id.value,
														disabled: isAlreadyOptSelected(id.value)
													})),
												pristine: true
											}
										}}
									/>
								) : (
									" "
								)}
							</Box>
							<RemoveGapWrapper>
								{groupIndex < groups.length - 1 && notDeletedGaps > 2 ? (
									<IconButton
										size="small"
										onClick={() => {
											dispatch(removeGap({ formName, statePath, gapIndex: groupIndex }));
											if (groupIndex === gapIndex) {
												setGapIndex(groups.findIndex(({ deleted }, index) => !deleted.value && index !== groupIndex));
											}
										}}
									>
										<CancelRounded color="disabled" />
									</IconButton>
								) : (
									" "
								)}
							</RemoveGapWrapper>
						</GroupContainer>
					)
			)}
			<Box mt={2}>
				<AddButton
					onClick={() => {
						dispatch(
							createQuestionGap({
								statePath,
								formName
							})
						);
					}}
					label={"Add Gap"}
				/>
			</Box>
			{answerOptions.map(
				({ id, text, deleted }, answerOptionIndex) =>
					!deleted?.value && (
						<GroupContainer key={id.value} display="flex" alignItems="center" error={text.error}>
							<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
							{nonDeletedAnswerOptsLength > 1 && (
								<Box flex="0" ml={1}>
									<IconButton
										size="small"
										onClick={() =>
											dispatch(
												removeQuestionAnswerOptionItem({
													formName,
													inputsPath: `${fullStatePath}.answerOptions.${answerOptionIndex}`,
													removeSelectedOpts: true,
													statePath: fullStatePath,
													id: answerOptions[answerOptionIndex].id.value
												})
											)
										}
									>
										<CancelRounded color="disabled" />
									</IconButton>
								</Box>
							)}
						</GroupContainer>
					)
			)}
			<Box mt={2}>
				<AddButton
					onClick={() => {
						dispatch(
							addOneMoreQuestionAnswerOption({
								formName,
								inputsPath: `${fullStatePath}.answerOptions`,
								templatePath
							})
						);
					}}
					label={"Add Option"}
				/>
			</Box>
		</CommonQuestionFields>
	);
};
