import React, { useEffect, useState } from "react";

import { Box, CircularProgress, Typography } from "@material-ui/core";
import { StyledCheckbox } from "@remar/shared/dist/ui/Buttons/styles";
import { validateEmail } from "@remar/shared/dist/utils/serviceUtils/validators";
import { useDispatch, useSelector } from "react-redux";

import { login, selectAuth } from "store/features/Auth/auth.slice";

import Logo from "assets/images/logo.svg";

import {
	Button,
	Container,
	FooterCTA,
	ForgetPassword,
	Header,
	Input,
	LoggedInText,
	LoginContainer,
	LogoContainer,
	Overlay,
	VideoContainer
} from "./styles";

export interface SignInProps {}

interface loginFormDataType {
	email: string;
	password: string;
	rememberMe: boolean;
}

const initalFormData = {
	email: "",
	password: "",
	rememberMe: false
};

const SignIn: React.FC<SignInProps> = () => {
	const { isLoggedIn, isLoading, errorMessage } = useSelector(selectAuth);

	const dispatch = useDispatch();
	const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
	const [isValid, setIsValid] = useState<boolean>(true);

	const [formData, setFormData] = useState<loginFormDataType>({ ...initalFormData });

	const handleRememberMeChange = (event: React.ChangeEvent<{ checked: unknown }>) => {
		const formDataCopy = { ...formData };
		formDataCopy.rememberMe = event.target.checked as boolean;
		setFormData(formDataCopy);
	};

	const validateLoginEmail = () => {
		const { email } = formData;
		if (email) {
			const isValid = validateEmail(email);
			setIsEmailValid(isValid);
		}
		setIsValid(checkIsValid(formData));
	};

	useEffect(() => {
		if (isLoggedIn) {
			setFormData({ ...initalFormData });
		}
	}, [isLoggedIn]);

	const handleEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const formDataCopy = { ...formData };
		const email = event.target.value as string;
		if (!isEmailValid && email) {
			const isValid = validateEmail(email);
			setIsEmailValid(isValid);
		}
		formDataCopy.email = email;
		setFormData(formDataCopy);
		setIsValid(checkIsValid(formDataCopy));
	};

	const handleLogin = () => {
		dispatch(login(formData));
	};

	const checkIsValid = formDataCopy => {
		const isValidate = validateEmail(formDataCopy.email) && formDataCopy.password !== "";
		return isValidate;
	};

	const handlePasswordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const formDataCopy = { ...formData };
		formDataCopy.password = event.target.value as string;
		setFormData(formDataCopy);
		setIsValid(checkIsValid(formDataCopy));
	};

	const videoSource = "https://css-tricks-post-videos.s3.us-east-1.amazonaws.com/708209935.mp4";
	const ErrorMessage = errorMessage => (
		<Typography style={{ fontSize: "12px", color: "red", textAlign: "start", margin: "10px" }}>
			{errorMessage}
		</Typography>
	);
	return (
		<Container>
			<Overlay />
			<LoginContainer>
				<form
					onSubmit={e => {
						e.preventDefault();
						handleLogin();
					}}
				>
					<LogoContainer>
						<img alt="logo" src={Logo} />
					</LogoContainer>
					<Header>Welcome Back!</Header>
					<Input
						onBlur={validateLoginEmail}
						error={!isEmailValid}
						onChange={handleEmailChange}
						value={formData.email}
						type="email"
						placeholder="Email Address"
					/>
					{!isEmailValid && ErrorMessage("This email isn't valid")}
					<Input
						error={!!errorMessage}
						onChange={handlePasswordChange}
						value={formData.password}
						placeholder="Password"
						type="password"
					/>
					{!!errorMessage && ErrorMessage(errorMessage)}
					<FooterCTA>
						<div>
							<StyledCheckbox checked={formData.rememberMe} onChange={handleRememberMeChange} />{" "}
							<LoggedInText>Keep me logged in</LoggedInText>
						</div>
						<ForgetPassword>Forgot Password</ForgetPassword>
					</FooterCTA>
					<Button variant={"contained"} color={"primary"} disabled={!isValid} fullWidth type="submit">
						{isLoading ? (
							<Box width={50}>
								<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
							</Box>
						) : (
							<>Log In</>
						)}
					</Button>
				</form>
			</LoginContainer>
			<VideoContainer autoPlay={true} loop={true} muted>
				<source src={videoSource} type="video/mp4" />
				Your browser does not support the video tag.
			</VideoContainer>
		</Container>
	);
};

export default SignIn;
