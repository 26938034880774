import { passwordMatch } from "@remar/shared/dist/schemas";
import * as Yup from "yup";

export const validationSchema = Yup.object({
	firstName: Yup.string().required(),
	lastName: Yup.string().required(),
	password: passwordMatch().required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	terms: Yup.bool().oneOf([true], "You should accept these terms to sign up")
});
