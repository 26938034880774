import React, { FC, memo, useEffect } from "react";

import { getJwt } from "@remar/shared/dist/api/jwt";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useDispatch, useSelector } from "react-redux";

import { Route, RouteProps, useHistory } from "react-router-dom";

import { RootState } from "store";
import { logout, setRoutePermissions } from "store/features/Auth/auth.slice";

import { routes } from "core/constants";

import usePermissions from "./CheckPermissions/usePermissions";

const AuthRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
	const { isLoading, isLoggedIn } = useSelector((state: RootState) => state.auth);
	const { canEdit, canView, accessAreas, isAccessReady } = usePermissions();
	const history = useHistory();
	const dispatch = useDispatch();
	const RenderComponent = Component;

	useEffect(() => {
		dispatch(setRoutePermissions({ canEdit, canView, accessAreas, isAccessReady }));
	}, [canView, canEdit, accessAreas, isAccessReady]);

	useEffect(() => {
		if (isAccessReady && !canView) {
			history.push("/");
		}
	}, [canView, isAccessReady]);

	useEffect(() => {
		const tokens = getJwt();
		const pathname = history.location.pathname;
		const signInRoute = routes.signIn.getPath();
		const invitationRoute = routes.invitation.getPath();
		if (isLoggedIn) {
			if (!tokens) {
				dispatch(logout());
				history.push(signInRoute);
				return;
			}
			if (pathname === signInRoute) {
				history.push("/");
				return;
			}
		} else if (pathname !== signInRoute && !pathname.includes(invitationRoute)) {
			history.push(signInRoute);
			return;
		}
	}, [isLoggedIn, history.location.pathname]);

	if ((isLoggedIn && !isAccessReady) || isLoading) {
		return <ContentLoader />;
	}
	if (!canView) {
		return <></>;
	}
	return <Route {...rest} component={RenderComponent} />;
};

export default memo(AuthRoute);
