import React, { useEffect, useMemo } from "react";

import { Box, useTheme } from "@material-ui/core";
import { ReactComponent as GiftIllustration } from "@remar/shared/dist/assets/images/content-illustrations-gift-blue.svg";
import Button from "@remar/shared/dist/components/Button";

import { Gift } from "@remar/shared/dist/components/GiftCard";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { EmptyState, Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { fetchGifts, getFullGiftState, setActive } from "store/features/Gifts";

import { UpdateGift } from "store/services";

import CheckPermissions from "core/CheckPermissions";

import { routes } from "core/constants";

import { ITEMS_PER_PAGE } from "./constants";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Shop", key: 1 },
	{ title: "Gifts", key: 2 }
];

const NoGifts = () => <EmptyState description="There are no Gifts yet" placeHolder={<GiftIllustration />} />;

const Gifts = () => {
	const dispatch = useDispatch();
	const theme = useTheme<IExtendedTheme>();
	const history = useHistory();

	const { gifts, isFetchingGifts } = useSelector(getFullGiftState);

	useEffect(() => {
		if (!gifts) dispatch(fetchGifts({ include: ["books", "userSubscriptionType"], page: 1, perPage: ITEMS_PER_PAGE }));
	}, [gifts, dispatch]);

	const { giftList, page, perPage, totalItems } = useMemo(() => {
		if (!gifts) {
			return {
				giftList: [],
				page: 1,
				perPage: ITEMS_PER_PAGE,
				totalItems: ITEMS_PER_PAGE
			};
		}
		return {
			giftList: gifts.items,
			page: gifts.page,
			perPage: gifts.perPage,
			totalItems: gifts.totalItems
		};
	}, [gifts]);

	const handleEditGift = g => {
		history.push(`${routes.gifts.getPath()}/edit/${g.id}`);
	};

	const handleSetActive = ({ id, isActive }: UpdateGift) => {
		dispatch(setActive({ id, isActive: !isActive }));
	};

	return (
		<Wrapper
			heading={"Gifts"}
			breadcrumb={breadcrumb}
			actions={
				<Box>
					<CheckPermissions>
						<Button
							variant={"filled"}
							color={"primary"}
							onClick={() => history.push(`${routes.gifts.getPath()}/add`)}
							disabled={isFetchingGifts}
						>
							Add New
						</Button>
					</CheckPermissions>
				</Box>
			}
		>
			{isFetchingGifts ? (
				<ContentLoader height={700} />
			) : giftList.length ? (
				<Box display={"flex"} flexDirection="row" flexWrap="wrap">
					{giftList.map(g => (
						<Gift key={g.id} {...g} onEdit={() => handleEditGift(g)} onSetActive={() => handleSetActive(g)} />
					))}
				</Box>
			) : (
				<NoGifts />
			)}
			{!!giftList.length && (
				<TablePagination
					count={totalItems}
					page={page}
					onChange={(_, page) => dispatch(fetchGifts({ page: page, perPage: ITEMS_PER_PAGE, include: ["books"] }))}
					rowsPerPage={perPage}
					bgColor={theme.palette.background.default}
				/>
			)}
		</Wrapper>
	);
};

export default Gifts;
