import React from "react";

import { Box, Card, Grid, Typography } from "@material-ui/core";
import { ErrorRounded } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { Book, Course, UserSubscriptionType } from "@remar/shared/dist/models";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { getFullGiftState } from "store/features/Gifts";
import { useAppSelector } from "store/hooks";
import styled from "styled-components";

const Wrapper = styled(Box)`
	display: flex;
	padding: 20px;
	flex-direction: column;
	border-top: 1px solid hsl(223, 14%, 26%);
`;

const ValidityText = styled(Typography)`
	font-family: var(--openSans);
	font-weight: 600;
	color: hsl(223, 13%, 73%);
	font-size: 13px;
`;

const BlockTitle = styled(Typography)`
	font-family: var(--openSans);
	opacity: 0.87;
	font-weight: 600;
	font-size: 15px;
`;

const BlockText = styled(Typography)`
	opacity: 0.87;
	font-family: var(--poppins);
	font-weight: 700;
	font-size: 17px;
`;

type GiftSummaryProps = {
	name: string;
	validity: number;
	selectedCourse?: Course;
	selectedPackage?: UserSubscriptionType;
	selectedBooks?: Book[];
};
const GiftSummary = ({ name, validity, selectedCourse, selectedPackage, selectedBooks }: GiftSummaryProps) => {
	const { giftPrice, isFetchingPrice } = useAppSelector(getFullGiftState);
	return (
		<Card style={{ padding: 0 }}>
			<Grid container style={{ padding: 20 }}>
				<Grid item xs={9}>
					<BlockText style={{ fontSize: 18 }}>{name}</BlockText>
					{!!validity && <ValidityText>{validity} days validity</ValidityText>}
				</Grid>
				<Grid item xs={3}>
					<BlockText style={{ textAlign: "right" }}>
						{isFetchingPrice ? <Skeleton variant="text" /> : formatUSD(giftPrice)}
					</BlockText>
				</Grid>
			</Grid>

			{selectedCourse ? (
				<Wrapper>
					<BlockTitle>Course</BlockTitle>
					<BlockText>{selectedCourse.name}</BlockText>
				</Wrapper>
			) : (
				<Wrapper>
					<Box bgcolor="hsl(224, 14%, 15%)" borderRadius={4} display="flex" alignItems="center">
						<Box component="span" padding={2}>
							<ErrorRounded color="disabled" />
						</Box>
						<ValidityText>Your Customise Gift Card details will appear here.</ValidityText>
					</Box>
				</Wrapper>
			)}

			{selectedPackage && (
				<Wrapper>
					<BlockTitle>Package</BlockTitle>
					<BlockText>{selectedPackage.name}</BlockText>
				</Wrapper>
			)}

			{!!selectedBooks?.length && (
				<Wrapper>
					<BlockTitle>Books</BlockTitle>
					{selectedBooks.map(book => (
						<Box
							key={book.id}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							my={1}
							padding={"4px 10px"}
							bgcolor={"hsl(223, 14%, 26%)"}
							borderRadius={4}
						>
							<img src={book.imageUrl} height={32} />
							<Box display="flex" flexDirection="column" minWidth={180}>
								<Typography>{book.name}</Typography>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									width={100}
									bgcolor={"hsl(222, 13%, 19%)"}
									style={{ color: "hsl(46, 66%, 52%)", borderRadius: 15, fontWeight: 400, fontSize: 12 }}
									px={1}
									py={0.4}
								>
									{"Physical Book"}
								</Box>
							</Box>
							<Typography>{formatUSD(book.data.price)}</Typography>
						</Box>
					))}
				</Wrapper>
			)}
		</Card>
	);
};

export default GiftSummary;
