import React from "react";

import ChangeStudentEmail from "@remar/shared/dist/modals/ChangeStudentEmail";

import { useDispatch, useSelector } from "react-redux";

import { changeEmail, getAllStudents, getFullState } from "store/features/Students/students.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import { UserChangeEmailDto } from "store/services/students/dto/students.changeEmail.dto";

const ChangeEmail = ({ showChangeEmailModal: { fullName, userId, userEmail }, onClose }) => {
	const dispatch = useDispatch();

	const { changeEmailLoading } = useSelector(getFullState);

	const closeModal = () => {
		if (!changeEmailLoading) {
			onClose();
		}
	};

	const sideEffect = (e, notificationType) => {
		if (!changeEmailLoading) {
			notificationType !== "error" && onClose();
			dispatch(_emit(notificationType === "error" ? e.message : e, notificationType));
			notificationType === "success" && dispatch(getAllStudents({}));
		}
	};

	const handleSubmit = newEmail => {
		const payload = { userId, currentEmail: userEmail, newEmail } as UserChangeEmailDto;
		dispatch(changeEmail({ payload, sideEffect }));
	};

	return (
		<ChangeStudentEmail
			changeEmailLoading={changeEmailLoading}
			userEmail={userEmail}
			onClose={closeModal}
			handleSubmit={handleSubmit}
			fullName={fullName}
		/>
	);
};

export default ChangeEmail;
