import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import { Package as LocationPackage } from "@remar/shared/dist/models";
import { getDurationStringFromDuration } from "store/features/CourseAddEdit/courseAddEdit.slice";

type MappedListType = { path: string; value: number | string | string[] | boolean }[];

export const mapLocationPackage = (state: LocationPackage): MappedListType => {
	const { allowedUST, name: packageName, isActive, description, id, isDefault, locations } = state;
	const getPackageDetails = (ust, pkgType, isRecurring, isTrial = false) => {
		const pkg = ust.find(
			pkg =>
				pkg.isActive &&
				pkg.userSubscriptionTypeCategoryId === pkgType &&
				pkg.isRecurring === isRecurring &&
				pkg.isTrial === isTrial
		);
		const eIDParentData = pkg?.subTypeEIDItems.find(f => !f.parentId);
		const addons = pkg?.subTypeEIDItems.filter(f => f.parentId) || [];
		return pkg ? { id: pkg.id, data: !isTrial ? eIDParentData["data"] : null, duration: pkg?.duration, addons } : null;
	};
	const initialPackageData = getPackageDetails(allowedUST, UserSubscriptionTypeCategoriesEnum.LocationPackage, false);

	const recurrringPackageData = getPackageDetails(allowedUST, UserSubscriptionTypeCategoriesEnum.LocationPackage, true);

	const trialPackageData = getPackageDetails(
		allowedUST,
		UserSubscriptionTypeCategoriesEnum.LocationPackage,
		false,
		true
	);
	const initialPackagePerSeatData = getPackageDetails(
		allowedUST,
		UserSubscriptionTypeCategoriesEnum.LocationPerSeat,
		false
	);
	const recurrringPackagePerSeatData = getPackageDetails(
		allowedUST,
		UserSubscriptionTypeCategoriesEnum.LocationPerSeat,
		true
	);
	const mappedList: MappedListType = [
		{ path: "data.id", value: id },
		{ path: "data.description", value: description },
		{ path: "data.isActive", value: isActive },
		{ path: "data.locations", value: locations as [] },
		{ path: "data.isDefault", value: isDefault },
		{
			path: "managePackageForm.inputs.packageName.value",
			value: packageName
		},
		{
			path: "managePackageForm.rawData.packageName",
			value: packageName
		},
		{
			path: "managePackageForm.rawData.isActive",
			value: isActive
		}
	];
	if (initialPackageData) {
		// userSubscriptionTypesForm 	Initial
		mappedList.push(
			{
				path: "userSubscriptionTypesForm.inputs.initial.id.value",
				value: initialPackageData.id
			},
			{
				path: "userSubscriptionTypesForm.inputs.initial.stripeItem.planId.value",
				value: initialPackageData.data.planId
			},
			{
				path: "userSubscriptionTypesForm.data.externalIntegrationData.initial.stripePlanId",
				value: initialPackageData.data.planId
			},
			{
				path: "userSubscriptionTypesForm.inputs.initial.stripeItem.price.value",
				value: initialPackageData.data.price
			},
			{
				path: "userSubscriptionTypesForm.inputs.initial.stripeItem.name.value",
				value: initialPackageData.data.name
			},
			{
				path: "userSubscriptionTypesForm.inputs.initial.stripeItem.duration.value",
				value: getDurationStringFromDuration(initialPackageData.duration)
			}
		);
	}
	if (recurrringPackageData) {
		//	userSubscriptionTypesForm recurring
		mappedList.push(
			{
				path: "userSubscriptionTypesForm.inputs.recurring.id.value",
				value: recurrringPackageData.id
			},
			{
				path: "userSubscriptionTypesForm.inputs.recurring.stripeItem.planId.value",
				value: recurrringPackageData.data.planId
			},
			{
				path: "userSubscriptionTypesForm.data.externalIntegrationData.recurring.stripePlanId",
				value: recurrringPackageData.data.planId
			},
			{
				path: "userSubscriptionTypesForm.inputs.recurring.stripeItem.price.value",
				value: recurrringPackageData.data.price
			},
			{
				path: "userSubscriptionTypesForm.inputs.recurring.stripeItem.name.value",
				value: recurrringPackageData.data.name
			},
			{
				path: "userSubscriptionTypesForm.inputs.recurring.stripeItem.duration.value",
				value: getDurationStringFromDuration(recurrringPackageData.duration)
			}
		);
	}
	if (trialPackageData) {
		//	userSubscriptionTypesForm trial
		mappedList.push(
			{
				path: "userSubscriptionTypesForm.inputs.trial.id.value",
				value: trialPackageData.id
			},
			{
				path: "userSubscriptionTypesForm.inputs.hasTrial.value",
				value: true
			}
		);
	}
	if (initialPackagePerSeatData) {
		mappedList.push(
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.initial.id.value",
				value: initialPackagePerSeatData.id
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.initial.stripeItem.planId.value",
				value: initialPackagePerSeatData.data.planId
			},
			{
				path: "userSubscriptionTypesFormPerSeat.data.externalIntegrationData.initial.stripePlanId",
				value: initialPackagePerSeatData.data.planId
			},
			{
				path: "userSubscriptionTypesFormPerSeat.data.externalIntegrationData.initial.addons",
				value: initialPackagePerSeatData.addons.filter(item => item.isActive)
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.initial.stripeItem.price.value",
				value: initialPackagePerSeatData.data.price
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.initial.stripeItem.name.value",
				value: initialPackagePerSeatData.data.name
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.initial.stripeItem.duration.value",
				value: getDurationStringFromDuration(initialPackagePerSeatData.duration)
			}
		);
	}
	if (recurrringPackagePerSeatData) {
		//	userSubscriptionTypesFormPerSeat recurring
		mappedList.push(
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.recurring.id.value",
				value: recurrringPackagePerSeatData.id
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.recurring.stripeItem.planId.value",
				value: recurrringPackagePerSeatData.data.planId
			},
			{
				path: "userSubscriptionTypesFormPerSeat.data.externalIntegrationData.recurring.stripePlanId",
				value: recurrringPackagePerSeatData.data.planId
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.recurring.stripeItem.price.value",
				value: recurrringPackagePerSeatData.data.price
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.recurring.stripeItem.name.value",
				value: recurrringPackagePerSeatData.data.name
			},
			{
				path: "userSubscriptionTypesFormPerSeat.inputs.recurring.stripeItem.duration.value",
				value: getDurationStringFromDuration(recurrringPackagePerSeatData.duration)
			}
		);
	}
	return mappedList;
};
