import React from "react";

import { Card } from "@material-ui/core";

import FolderImg from "assets/images/emptyViewFolder.svg";

import { Box, Img, Title } from "./styles";

interface EmptyViewCardProps {
	title: string;
}
const EmptyViewCard = ({ title }: EmptyViewCardProps) => {
	return (
		<Card>
			<Box p={8} justifyContent="center">
				<Img src={FolderImg} />
				<Title>{title}</Title>
			</Box>
		</Card>
	);
};

export default EmptyViewCard;
