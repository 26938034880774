import React, { FC, ReactNode } from "react";

import { useDrop } from "react-dnd";

const AnswerOptionDropZone: FC<{
	canAlwaysDrop?: boolean;
	children: ReactNode | ReactNode[];
	dropZoneIndex?: number;
	index: number;
	onDrop: (_options: { newDropZoneIndex?: number; oldDropZoneIndex?: number; order: number }) => unknown;
	style?: { [fieldName: string]: unknown };
}> = ({ canAlwaysDrop, children, dropZoneIndex, index, onDrop, style }) => {
	const [dragObjectData, drop] = useDrop({
		accept: "box",
		drop: item =>
			onDrop({
				newDropZoneIndex: dropZoneIndex,
				oldDropZoneIndex: (item as unknown as Record<string, unknown>).dropZoneIndex as number,
				order: item.index
			}),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
		canDrop: (dropItem: { index: number }) =>
			canAlwaysDrop || (dropItem.index !== index && dropItem.index !== index - 1)
	});
	const { isOver, canDrop } = dragObjectData as Record<string, boolean>;
	const overStyles = {
		border: "1px dashed #fff",
		height: 48,
		margin: "8px 8px 0 0"
	};
	return (
		<div ref={drop} style={style || {}}>
			{children}
			<div style={isOver && canDrop ? overStyles : {}} />
		</div>
	);
};

export default AnswerOptionDropZone;
