import React, { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export interface TestOnlyQuestionsFormAnswerOptionRawData {
	id: string;
	text: string;
	order: number;
	isCorrect: boolean;
	canAttachFiles: boolean;
	note: string;
	deleted: boolean;
}

import AnswerOptionDropZone from "./SequenceAnswerOptionDropZone";

import { reorderList } from "../../../utils/reorderList";

const SequencingQuestionPreview = ({ question, SequenceAnswerOption }) => {
	const [answerOptions, setAnswerOptions] = useState<TestOnlyQuestionsFormAnswerOptionRawData[]>([]);

	useEffect(() => {
		const _answerOptions = [...question.answerOptions].sort((a, b) => (!a.order || !b.order ? 0 : a.order - b.order));
		setAnswerOptions(_answerOptions);
	}, [question]);

	const handleDrop = payload => {
		const { oldOptionIndex, newOptionIndex } = payload;
		const __answerOptions = reorderList(oldOptionIndex, newOptionIndex, answerOptions);
		setAnswerOptions(__answerOptions);
	};

	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<AnswerOptionDropZone
					canAlwaysDrop
					onDrop={({ index: oldOptionIndex, id }) => {
						const payload = {
							oldOptionIndex,
							newOptionIndex: 0,
							id
						};
						handleDrop!(payload);
					}}
					index={0}
				>
					<Box style={{ minHeight: 10 }} />
				</AnswerOptionDropZone>
				{answerOptions.map(({ deleted, text }, answerOptionIndex) =>
					deleted ? null : (
						<SequenceAnswerOption
							text={text ?? ""}
							index={answerOptionIndex}
							handleDrop={payload => handleDrop!(payload)}
							id={answerOptionIndex}
							key={answerOptionIndex}
						/>
					)
				)}
			</DndProvider>
		</>
	);
};

export default SequencingQuestionPreview;
