import { Box as MuiBox, Typography } from "@material-ui/core";

import styled from "styled-components";

export const Title = styled(Typography)`
	opacity: 0.6;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const Box = styled(MuiBox)`
	text-align: center;
`;

export const Img = styled.img`
	width: 56px;
	height: 56px;
`;
