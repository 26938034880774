import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomInputModel, CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import { Course, CourseChapter } from "@remar/shared/dist/models";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { AppThunk, RootState } from "store";
import {
	CourseChapterCreateDto,
	CourseChapterSectionCreateDto,
	CourseChapterSectionLessonUpdateDto,
	CourseChapterSectionUpdateDto,
	CourseChapterUpdateDto,
	courseChapterSectionLessonsService,
	courseChapterSectionsService,
	courseChaptersService,
	coursesService,
	userSubscriptionTypesService
} from "store/services";

import { _emit, emit } from "../notifications/notifications.slice";

interface CourseState {
	courseFilters: { isActive: CustomInputModel<string> };
	courses: Course[] | null;
	activeCourse: Course | null;
	activeChapter: CourseChapter | null;
	isLoading: boolean;
	errorMessage: string;
	editChapterData: CourseChapterUpdateDto | null;
	editSectionData: CourseChapterSectionUpdateDto | null;
	addChapterData: CourseChapterUpdateDto | null;
	addSectionData: CourseChapterSectionUpdateDto | null;
	uploadingImage: boolean;
}

const initialState: CourseState = {
	activeChapter: null,
	activeCourse: null,
	courseFilters: {
		isActive: {
			error: null,
			label: "Status",
			pristine: true,
			placeholder: "Status",
			selectOptions: [
				{ text: "All", value: "all" },
				{ text: "Published", value: "true" },
				{ text: "Unpublished", value: "false" }
			],
			statePath: "courseFilters.isActive",
			type: CustomInputType.Select,
			value: "all"
		}
	},
	courses: null,
	editChapterData: null,
	editSectionData: null,
	addChapterData: null,
	addSectionData: null,
	errorMessage: "",
	isLoading: false,
	uploadingImage: false
};

export const createChapter =
	(payload: CourseChapterCreateDto): AppThunk =>
	dispatch => {
		dispatch(setLoading(true));
		courseChaptersService
			.create(payload)
			.then(r => {
				dispatch(fetchCourse(r.courseId));
			})
			.catch(e => {
				dispatch(
					emit({
						message:
							e.message === 'courseChapters "courseId", name needs to be unique'
								? "Chapter Name already exists"
								: e.message,
						color: "error"
					})
				);
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const createSection =
	(payload: CourseChapterSectionCreateDto): AppThunk =>
	dispatch => {
		dispatch(setLoading(true));
		courseChapterSectionsService
			.create(payload)
			.then(r => {
				dispatch(fetchChapter(r!.chapterId));
			})
			.catch(err => {
				dispatch(_emit(err.message, "error"));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const deleteChapter =
	(
		deleteChapterPayload: {
			chapterId: number;
			deleteVideo: boolean;
		},
		onSuccess: () => void
	): AppThunk =>
	dispatch => {
		dispatch(setLoading(true));
		courseChaptersService
			.delete({ filters: { id: deleteChapterPayload.chapterId }, deleteVideo: deleteChapterPayload.deleteVideo })
			.then(() => {
				onSuccess();
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const editChapter =
	(payload: CourseChapterUpdateDto, withLoader = true, isFetchRecord = true): AppThunk =>
	dispatch => {
		withLoader && dispatch(setLoading(true));
		return courseChaptersService
			.update(payload)
			.then(() => {
				if (isFetchRecord) {
					dispatch(fetchChapter((payload.filters as { id: number }).id));
				}
			})
			.catch(err => {
				dispatch(_emit(err.message, "error"));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const editSection =
	(payload: CourseChapterUpdateDto, chapterId?: number, withLoader?: boolean): AppThunk =>
	dispatch => {
		withLoader && dispatch(setLoading(true));
		courseChapterSectionsService
			.update(payload)
			.then(() => {
				chapterId && dispatch(fetchChapter(chapterId));
			})
			.catch(err => {
				dispatch(_emit(err.message, "error"));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const fetchAllCourses =
	(isActive?: boolean): AppThunk =>
	dispatch => {
		dispatch(setLoading(true));
		coursesService
			.find({
				findAll: true,
				include: ["allowedForUST"],
				orderBy: { createdAt: "ASC" },
				filters: isActive ? { isActive, "allowedForUST.isActive": true } : {}
			})
			.then(r => {
				dispatch(setStateValue({ key: "courses", value: r.items }));
			})
			.catch(err => {
				dispatch(failed(err));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const fetchChapter =
	(id: number): AppThunk =>
	(dispatch, getState) => {
		dispatch(setLoading(true));
		courseChaptersService
			.findOne(id, {
				include: ["allowedLocations", "sections.sectionLessons.lesson.drafts"],
				orderBy: { "sections.order": "ASC" }
			})
			.then(r => {
				const chapters = [...getState().course.activeCourse!.chapters!];
				const chapterIndex = chapters.findIndex(item => item.id === id);
				if (chapterIndex === -1) {
					chapters.push(r!);
				} else {
					chapters[chapterIndex] = r!;
				}
				dispatch(setStateValue({ key: "activeCourse.chapters", value: chapters }));
				dispatch(setStateValue({ key: "activeChapter", value: r! }));
			})
			.catch(err => {
				dispatch(failed(err));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const fetchStripePrice = createAsyncThunk(
	"course/fetchStripePrice",
	async (planId: string, { rejectWithValue }) =>
		userSubscriptionTypesService
			.getPaymentProviderPriceData(ExternalIntegrationIds.Stripe, planId)
			.catch(rejectWithValue)
);

export const fetchAppleProductInfo = createAsyncThunk(
	"course/fetchAppleProductInfo",
	async (planId: string, { rejectWithValue }) => {
		return userSubscriptionTypesService.getAppleProductInfo(planId).catch(rejectWithValue);
	}
);

export const fetchCourse =
	(courseId: number, withLoader = true): AppThunk =>
	dispatch => {
		withLoader && dispatch(setLoading(true));
		coursesService
			.findOne(courseId, { include: ["chapters", "chapters.allowedLocations"], orderBy: { "chapters.order": "ASC" } })
			.then(r => {
				dispatch(setStateValue({ key: "activeCourse", value: r! }));
			})
			.catch(err => {
				dispatch(failed(err));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const reorderLesson =
	(payload: CourseChapterSectionLessonUpdateDto): AppThunk =>
	dispatch => {
		courseChapterSectionLessonsService.update(payload).catch(() => {
			dispatch(_emit("Error happened trying to reorder lessons", "error"));
		});
	};

export const sectionLessonDelete =
	(deleteLessonPayload: { lessonId: number; deleteVideo: boolean }, chapterId: number): AppThunk =>
	dispatch => {
		dispatch(setLoading(true));
		courseChapterSectionLessonsService
			.delete({ filters: { id: deleteLessonPayload.lessonId }, deleteVideo: deleteLessonPayload.deleteVideo })
			.then(res => {
				const message = res.lessonDeleted
					? res.videoDeleted
						? "Lesson and video deleted"
						: "Lesson deleted"
					: "Lesson removed from section";
				dispatch(emit({ message, color: "info" }));
			})
			.then(() => dispatch(fetchChapter(chapterId)))
			.catch(() => {
				dispatch(
					emit({ message: "Sorry, there was an error while deleting lesson or video please try again", color: "error" })
				);
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const courseSlice = createSlice({
	name: "course",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue
	},
	extraReducers: builder => {
		builder
			.addCase(fetchStripePrice.pending, state => {
				state.editChapterData.data.lockedChapterData.isPriceLoading = true;
			})
			.addCase(fetchStripePrice.fulfilled, (state, { payload }) => {
				state.editChapterData.data.lockedChapterData.price = payload.price;
				state.editChapterData.data.lockedChapterData.isPriceLoading = false;
			})
			.addCase(fetchStripePrice.rejected, state => {
				state.editChapterData.data.lockedChapterData.isPriceLoading = false;
			})
			.addCase(fetchAppleProductInfo.pending, state => {
				state.editChapterData.data.lockedChapterData.isAppleKeyInfoLoading = true;
				state.editChapterData.data.lockedChapterData.appleKeyError = "";
			})
			.addCase(fetchAppleProductInfo.fulfilled, (state, { payload }) => {
				state.editChapterData.data.lockedChapterData.applePlanName = payload.name;
				state.editChapterData.data.lockedChapterData.isAppleKeyInfoLoading = false;
			})
			.addCase(fetchAppleProductInfo.rejected, (state, { payload }) => {
				state.editChapterData.data.lockedChapterData.isAppleKeyInfoLoading = false;
				state.editChapterData.data.lockedChapterData.appleKeyError = payload.message;
			});
	}
});

export const { setLoading, failed, setStateValue } = courseSlice.actions;

export function getFullState(state: RootState): CourseState {
	return state.course;
}

export default courseSlice.reducer;
