import React from "react";

import { VITLessonAppearance } from "@remar/shared/dist/components/VITLessonAppearance";
import { LessonVideoSegmentTypes, QuestionTypes } from "@remar/shared/dist/constants";

import { Question, QuestionGroup } from "@remar/shared/dist/models";
import { useSelector } from "react-redux";

import { getFullState, transformInputsToDuration } from "store/features/CreateLesson/createLesson.slice";

import { getQuestionGroups } from "./utils";

interface VitPreviewProps {
	onComplete: () => void;
}

const VitPreview = ({ onComplete }: VitPreviewProps) => {
	const {
		interactiveLessonFormGroup: {
			rawData: { interactiveBlocks }
		},
		selectedMainLessonVideo
	} = useSelector(getFullState);

	const questions: Question[] = [];
	const interactiveData = {};
	interactiveBlocks.forEach(block => {
		if (block?.question?.id || block?.question.questionDraftId) {
			if (block?.question.typeId === QuestionTypes.Grouping) {
				questions.push({
					...block.question,
					data: {
						groups: getQuestionGroups(block.question) as unknown as QuestionGroup[]
					},
					correctGroups: block.question.groups as unknown as QuestionGroup[]
				});
			} else {
				questions.push({
					...block!.question,
					data: { answerOptions: block?.question.answerOptions }
				} as unknown as Question);
			}
		}

		const updatedBlock = { ...block };
		for (const key in LessonVideoSegmentTypes) {
			const segmentType = LessonVideoSegmentTypes[key];
			updatedBlock[segmentType] = {
				...updatedBlock[segmentType],
				startsAtMilliseconds: transformInputsToDuration(
					updatedBlock[segmentType].startMin,
					updatedBlock[segmentType].startSec
				)
			};
			if (segmentType == LessonVideoSegmentTypes.Start) {
				updatedBlock[segmentType] = {
					...updatedBlock[segmentType],
					endsAtMilliseconds: transformInputsToDuration(
						updatedBlock[LessonVideoSegmentTypes.Question].startMin,
						updatedBlock[LessonVideoSegmentTypes.Question].startSec
					)
				};
			} else {
				updatedBlock[segmentType] = {
					...updatedBlock[segmentType],
					endsAtMilliseconds: transformInputsToDuration(
						updatedBlock[segmentType].endMin,
						updatedBlock[segmentType].endSec
					)
				};
			}
		}
		interactiveData[block?.question.id || block?.question.questionDraftId] = updatedBlock;
	});

	return (
		<VITLessonAppearance
			lesson={{ interactiveBlocks: [{ video: selectedMainLessonVideo }] }}
			isFinished={false}
			questions={questions}
			interactiveBlocks={interactiveData}
			onComplete={onComplete}
		/>
	);
};

export default VitPreview;
