import { RefObject, useLayoutEffect } from "react";

const useScrollTo = (elementRef: RefObject<HTMLDivElement>, scroll: boolean) => {
	useLayoutEffect(() => {
		if (!elementRef?.current) {
			return;
		}
		if (scroll) {
			elementRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [scroll]);
};

export default useScrollTo;
