import { BaseApiService, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { Lesson } from "@remar/shared/dist/models";

import { LessonCreateDto, LessonDeleteDto, LessonDeleteResponseDto, LessonUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class LessonsService extends BaseApiService<
	Lesson,
	LessonCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	LessonUpdateDto,
	LessonDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	delete(data: LessonDeleteDto): Promise<LessonDeleteResponseDto> {
		return BaseApiService.prototype.delete.call(this, data);
	}
}

export const lessonsService = new LessonsService(URLS.LESSONS);
