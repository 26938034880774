import { Grid } from "@material-ui/core";

import styled from "styled-components";

export const EmptyStateWrapper = styled(Grid)`
	background: ${({ theme }) => theme.palette.background.default};
	height: 700px;
	margin-right: 24px;
	display: Grid;

	&:last-child {
		grid-row: 2/3;
	}
`;

export const HeaderWrapper = styled.div`
	padding: 10px 20px;
	font-size: 15px;
	color: ${props => props.theme.palette.text.primary};
	line-height: 1.6;
	font-weight: 600;
	font-family: var(--openSans);
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	.item {
		margin: 15px 0;
		&:nth-child(2) {
			font-family: var(--poppins);
			font-weight: 700;
			font-size: 18px;
			color: ${props => props.theme.palette.text.secondary};
		}
	}
`;
