import React from "react";

import { NotificationsWrapper as SharedNotificationsWrapper } from "@remar/shared/dist/components/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedIn } from "store/features/Auth/auth.slice";
import { pushSocketNotifications } from "store/features/notifications/notifications.slice";

import SnackBarNotifications from "core/Notifications";

const NotificationsWrapper = ({ children }) => {
	const dispatch = useDispatch();

	const isLoggedIn = useSelector(selectLoggedIn);

	return (
		<SharedNotificationsWrapper
			subscribeFirebaseNotifications={() => {}}
			pushSocketNotifications={notificationItem => dispatch(pushSocketNotifications(notificationItem))}
			isLoggedIn={isLoggedIn}
			snackBarNotifications={<SnackBarNotifications />}
		>
			{children}
		</SharedNotificationsWrapper>
	);
};

export default NotificationsWrapper;
