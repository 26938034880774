import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import CancelRounded from "@material-ui/icons/CancelRounded";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";

import { FlexStyledInput, IconButtonStyled, CustomInput as StyledInput, useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const SingleChoiceQuestion = ({
	createQuestionAnswerOptionItem,
	getFullState,
	removeQuestionAnswerOptionItem,
	removeQuestionAttachment,
	setStateValue,
	updateAnswerOptsFlag,
	initSingleChoiceQuestion,
	uploadQuestionMaterial,
	validateForm,
	formName = "addNewQuestionForm",
	statePath = "",
	templatePath = "answerOptions"
}) => {
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };

	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";

	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);

	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const { answerOptions, _expanded, text, description, attachments, endOfQuestionSummary, mainImageKey } = questionData;

	useEffect(() => {
		if (answerOptions.length === 0) {
			dispatch(
				createQuestionAnswerOptionItem({
					formName,
					inputsPath: `${fullStatePath}.answerOptions`,
					templatePath,
					type: "radio"
				})
			);
		}
	}, [answerOptions.length]);

	useEffect(() => {
		initSingleChoiceQuestion &&
			dispatch(
				initSingleChoiceQuestion({
					formName,
					statePath: fullStatePath
				})
			);
	}, []);

	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			expanded={_expanded?.value}
			statePath={statePath}
			setStateValue={setStateValue}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
			<Typography className={classes.addOptionHeader}>ANSWER OPTIONS</Typography>
			{answerOptions.map(
				({ isCorrect, text, deleted }, answerOptionIndex) =>
					!deleted?.value && (
						<Box key={`answerOption-${answerOptionIndex}`} display="flex" flexDirection="row" mt={2} width="100%">
							<Box display="flex" flexDirection="row" width={"100%"}>
								{isCorrect && (
									<Box className={classes.alignSelf}>
										<StyledInput
											display="flex"
											alignItems="center"
											theme={theme}
											inputProps={{
												onChange: () => {
													dispatch(
														updateAnswerOptsFlag({
															inputsPath: `${fullStatePath}.answerOptions`,
															formName,
															answerOptionIndex
														})
													);
												}
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: { ...isCorrect, type: "radio" }
											}}
										/>
									</Box>
								)}

								<FlexStyledInput theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
							</Box>
							<Box display="flex" alignItems="center" justifyContent="center" width="51px">
								<IconButtonStyled
									size="small"
									onClick={() =>
										dispatch(
											removeQuestionAnswerOptionItem({
												formName,
												inputsPath: `${fullStatePath}.answerOptions.${answerOptionIndex}`
											})
										)
									}
								>
									<CancelRounded color="disabled" />
								</IconButtonStyled>
							</Box>
						</Box>
					)
			)}

			<AddButton
				onClick={() => {
					dispatch(
						createQuestionAnswerOptionItem({
							formName,
							inputsPath: `${fullStatePath}.answerOptions`,
							templatePath,
							type: "radio"
						})
					);
				}}
				label={"Add option"}
			/>
		</CommonQuestionFields>
	);
};
