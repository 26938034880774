import React, { useEffect, useRef, useState } from "react";

import { Box, Card, Grid, TextField, Typography } from "@material-ui/core";

import Button from "@remar/shared/dist/components/Button";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { performFileUpload } from "@remar/shared/dist/utils/serviceUtils";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { cloneDeep } from "lodash";
import { compile } from "node-webvtt";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import {
	ICue,
	ISubtitleData,
	clearVideoState,
	cueInitialVal,
	getVideo,
	loadSubtitles,
	updateVideo
} from "store/features/LessonVideos/LessonVideos.slice";
import { emit } from "store/features/notifications/notifications.slice";

import "video.js/dist/video-js.min.css";
import videojs from "video.js";

import { routes } from "core/constants";

import SubtitleEditor from "./SubtitleEditor";

const validateCues = (cues: ICue[]): ISubtitleData => {
	const _cues = cloneDeep(cues);
	let valid = true;
	for (let c = 0; c < _cues.length; c++) {
		_cues[c].error = null;
		if (_cues[c].end < _cues[c].start) {
			_cues[c].error = "Invalid timestamp";
			valid = false;
			break;
		}
		if (c > 0 && _cues[c].start < _cues[c - 1].end) {
			_cues[c].error = "Invalid timestamp";
			valid = false;
			break;
		}
	}

	return { valid, cues: _cues };
};

const breadcrumb = [
	{ title: "Videos", key: 0, link: routes.videos.getPath() },
	{ title: "Edit Videos", key: 1 }
];

const CUES_PER_PAGE = 10;
const EditVideo = () => {
	const player = useRef<videojs.Player>();
	const videoRef = useRef<HTMLVideoElement>(null);
	const dispatch = useDispatch();
	const { videoId } = useParams<{ videoId: string }>();
	const [isSaving, setIsSaving] = useState(false);
	const [subtitleData, setSubtitleData] = useState<ISubtitleData>({ valid: true, cues: [] });

	const {
		video: videoData,
		isLoading,
		isUpdatingVideo,
		isLoadingSubtitles,
		subtitleData: storeSubtitleData
	} = useSelector((state: RootState) => state.lessonVideos);
	const [videoName, setVideoName] = useState("");
	useEffect(() => {
		dispatch(getVideo({ videoId }));
	}, [videoId]);

	useEffect(() => {
		if (videoData?.name) {
			setVideoName(videoData.name);
		}
	}, [videoData?.name]);

	useEffect(() => {
		if (videoData) {
			player.current = player.current || getVideoPlayer(videoRef!.current!, videoData.videoUrl);
		}
		return () => {
			if (videoData) dispatch(clearVideoState());
			player.current = undefined;
		};
	}, [videoData?.videoUrl]);

	useEffect(() => {
		if (videoData) {
			dispatch(
				loadSubtitles({
					url: videoData.subtitleUrl,
					cb: data => {
						const cues = data.cues.length ? cloneDeep(data.cues.slice(0, CUES_PER_PAGE)) : [{ ...cueInitialVal }];
						const _data = { ...data, cues, page: 1 };
						setSubtitleData(_data);
					}
				})
			);
			if (player.current) {
				player.current.addRemoteTextTrack(
					{ src: videoData.subtitleUrl, kind: "subtitles", srcLang: "en", label: "English", id: "test", default: true },
					false
				);
			}
		}
	}, [videoData?.subtitleUrl]);

	const handleSaveCues = async () => {
		try {
			setIsSaving(true);
			const { valid, cues } = validateCues(subtitleData.cues);
			setSubtitleData(prevState => ({
				...prevState,
				cues
			}));
			if (!valid) {
				throw new Error("Invalid timestamps");
			}
			const page = subtitleData.page || 1;
			const cuesToCompile = [...subtitleData.cues, ...storeSubtitleData.cues.slice(page * CUES_PER_PAGE)];
			const compiled = compile({ valid, cues: cuesToCompile });
			const timeStamp = new Date().getTime();
			const blob = new Blob([compiled], { type: "text/vtt" });
			const file = new File([blob], `${videoData?.subtitleFileName || videoData?.name}-${timeStamp}`, {
				type: "text/vtt"
			});
			const { key } = await performFileUpload({ file });
			dispatch(
				updateVideo({
					videoId,
					data: { name: videoName, subtitleFileName: `${key}` },
					cb: () => {
						// workaround to re-fetch video data after saving subtitles as it takes some time be saved
						setTimeout(() => {
							dispatch(getVideo({ videoId, disableLoading: true }));
						}, 1000);
					}
				})
			);
		} catch (e) {
			dispatch(
				emit({ message: "Invalid timestamps: please make sure end time is greater than start time", color: "error" })
			);
		} finally {
			setIsSaving(false);
		}
	};

	const handleScroll = e => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setSubtitleData(prevState => {
				const { page = 1 } = prevState;
				const newPage = page + 1;
				const newSliceStartIndex = page * CUES_PER_PAGE;
				const newPaginatedCues = cloneDeep(
					storeSubtitleData.cues.slice(newSliceStartIndex, newSliceStartIndex + CUES_PER_PAGE)
				);
				return {
					...prevState,
					cues: [...prevState.cues, ...newPaginatedCues],
					page: newPage
				};
			});
		}
	};

	const actions = (
		<Box>
			<Button variant={"outlined"} color={"primary"} onClick={() => {}}>
				Cancel
			</Button>{" "}
			<Button
				variant={"filled"}
				color={"primary"}
				onClick={handleSaveCues}
				disabled={isSaving || isUpdatingVideo}
				loading={isSaving || isUpdatingVideo}
			>
				Save
			</Button>
		</Box>
	);
	if (isLoading) {
		return <ContentLoader />;
	}
	return (
		<Wrapper heading={"Edit Video"} breadcrumb={breadcrumb} actions={actions}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={7}>
					<video ref={videoRef} className="video-js vjs-big-play-centered vjs-16-9" crossOrigin="anonymous" />
				</Grid>
				<Grid item xs={12} lg={5}>
					<Box mb={1}>
						<Typography style={{ marginBottom: "5px" }}>Video Title</Typography>
						<TextField
							fullWidth
							hiddenLabel
							InputProps={{ style: { color: "primary" }, disableUnderline: true }}
							inputProps={{ maxLength: 140 }}
							color="primary"
							onChange={e => {
								setVideoName(e.target.value);
							}}
							variant="filled"
							size="small"
							placeholder="Enter Video name"
							value={videoName}
							disabled={false}
						/>
					</Box>
					<Box mt={1}>
						<Typography style={{ marginBottom: "5px" }}>Subtitles</Typography>
						<Card style={{ padding: "20px", overflow: "scroll", maxHeight: "80vh" }} onScroll={handleScroll}>
							{isLoadingSubtitles ? (
								<ContentLoader height={200} size={"5rem"} />
							) : (
								<SubtitleEditor cues={subtitleData.cues} setCues={setSubtitleData} />
							)}
						</Card>
					</Box>
				</Grid>
			</Grid>
		</Wrapper>
	);
};

export default EditVideo;
