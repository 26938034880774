import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { Question, QuestionsCount } from "@remar/shared/dist/models";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { AppThunk, RootState } from "store";
import { questionTypesService, questionsService } from "store/services";

import { emit } from "../notifications/notifications.slice";

export type filterType = "all" | "cat" | "trial";

const typeFilters: Record<filterType, string> = {
	all: "",
	cat: "forCAT",
	trial: "subjectLessons.subject.isAvailableForTrial"
} as const;

export interface QuestionBankState {
	isLoading: boolean;
	isDeleting: boolean;
	isCloning: boolean;
	page: number;
	perPage: number;
	questions: Question[];
	questionsCount: QuestionsCount;
	questionTypes: { label: string; id: number; checked: boolean }[];
	totalItems: number;
	errorMessage: string;
	filter: filterType;
}

const initialState: QuestionBankState = {
	isLoading: false,
	isDeleting: false,
	isCloning: false,
	page: 1,
	perPage: 10,
	totalItems: 0,
	questions: [],
	questionsCount: { totalQuestionsCount: 0, courses: [], isLoading: false },
	questionTypes: [],
	errorMessage: "",
	filter: "all"
};

export const questionBankSlice = createSlice({
	name: "questionBank",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setDeleting: (state, action: PayloadAction<boolean>) => {
			state.isDeleting = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		resetCourses: state => {
			state.questionsCount.courses = [];
		},
		setStateValue: utilsSetStateValue
	},
	extraReducers: builder => {
		builder.addCase(fetchQuestionTypes.fulfilled, (state, { payload }) => {
			state.questionTypes = payload!.items.map(({ name, id }) => ({ label: name, id, checked: false }));
		});
	}
});
const mapQuestionItems = (items, searchText) => {
	let isFirstRowExpanded = false;
	return items.map(item => {
		if (item.typeId === QuestionTypes.CaseStudy) {
			let isExpanded = false;
			if (searchText) {
				for (let q = 0; q < item.caseStudyQuestions!.length; q++) {
					const doesTextExist = item.caseStudyQuestions![q].text.toLowerCase().includes(searchText.toLowerCase());
					isExpanded = doesTextExist && !isFirstRowExpanded;
					if (doesTextExist) {
						isFirstRowExpanded = true;
						break;
					}
				}
			}
			return {
				...item,
				hasChildren: true,
				childItems: item.caseStudyQuestions,
				isExpanded
			};
		}
		return item;
	});
};
export const fetchQuestions = createAsyncThunk(
	"questionBank/fetchQuestions",
	async (
		options: {
			page?: number;
			perPage?: number;
			searchText?: string;
			courseId?: number;
			locationId?: number;
			typeId?: string[];
			filter?: filterType;
		},
		{ dispatch, getState }
	) => {
		try {
			const { isLoading, page, perPage, filter } = (
				getState() as {
					questionBank: QuestionBankState;
				}
			).questionBank;
			if (!isLoading) {
				dispatch(setLoading(true));
			}
			const { page: optPage, perPage: optPerPage, searchText: searchText, courseId, locationId, typeId } = options;
			const filters = {
				forCaseStudy: false,
				hasCaseStudy: false,
				isPublicForCustomTests: true,
				...(locationId && { "locations.id": locationId }),
				...(courseId && { "courses.id": [courseId] })
			};
			if (typeId) {
				filters["typeId"] = typeId;
			}
			if (searchText) {
				filters["text"] = searchText;
			}

			const type = options.filter || filter;
			if (type !== "all") {
				filters[typeFilters[type]] = true;
			}

			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await questionsService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { id: "DESC" },
				include: [
					"type",
					"attachments",
					"caseStudyQuestions",
					"caseStudyQuestions.type",
					"subjectLessons.subject",
					"clonedBy"
				],
				filters
			});
			dispatch(setStateValue({ key: "page", value: newPage }));
			dispatch(setStateValue({ key: "perPage", value: newPerPage }));
			const mappedItems: Question[] = mapQuestionItems(items, searchText);
			dispatch(
				setStateValue({
					key: "questions",
					value: mappedItems
				})
			);
			dispatch(setStateValue({ key: "totalItems", value: totalItems }));
			dispatch(setStateValue({ key: "filter", value: type }));
		} catch (e) {
			dispatch(setLoading(false));
			return { error: e };
		}
		dispatch(setLoading(false));
	}
);

export const fetchQuestionsCount = createAsyncThunk(
	"questionBank/fetchQuestionsCount",
	async (_, { dispatch, getState }) => {
		try {
			const { isLoading } = (
				getState() as {
					questionBank: QuestionBankState;
				}
			).questionBank;
			if (!isLoading) {
				dispatch(setStateValue({ key: "questionsCount.isLoading", value: true }));
			}
			const { totalQuestionsCount, courses } = await questionsService.getQuestionsCount();
			dispatch(setStateValue({ key: "questionsCount.totalQuestionsCount", value: totalQuestionsCount }));
			dispatch(setStateValue({ key: "questionsCount.courses", value: courses }));
		} catch (e) {
			dispatch(setStateValue({ key: "questionsCount.isLoading", value: false }));
			return { error: e };
		}
		dispatch(setStateValue({ key: "questionsCount.isLoading", value: false }));
	}
);

export const addQuestionToCommunity = createAsyncThunk(
	"questionBank/addQuestionToCommunity",
	async ({ id, subjectId, cb }: { id: number; subjectId: string; cb: () => void }, { dispatch, getState }) => {
		try {
			const { isCloning } = (getState() as { questionBank: QuestionBankState }).questionBank;
			if (!isCloning) dispatch(setStateValue({ key: "isCloning", value: true }));

			const { updatedAt } = await questionsService.addQuestionToCommunity({
				id,
				subjectCategoryIds: [{ value: subjectId }]
			});
			if (updatedAt) {
				cb();
				dispatch(emit({ message: "Added question to the community pool successfully", color: "success" }));
			}
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
			return { error: e };
		} finally {
			dispatch(setStateValue({ key: "isCloning", value: false }));
		}
	}
);

export const questionDelete =
	(deleteQuestionPayload: { id: number; deleteClones: boolean; cb: () => void }): AppThunk =>
	async dispatch => {
		const { deleteClones, id, cb } = deleteQuestionPayload;
		dispatch(setDeleting(true));
		try {
			await questionsService.delete({
				deleteClones: deleteClones,
				filters: { id }
			});
			dispatch(emit({ message: "Question has been deleted.", color: "success" }));
			cb();
		} catch {
			dispatch(
				emit({ message: "Sorry, there was an error while deleting this question please try again", color: "error" })
			);
		} finally {
			dispatch(setDeleting(false));
		}
	};

export const fetchQuestionTypes = createAsyncThunk("questionBank/fetchQuestionTypes", async (_, { dispatch }) => {
	try {
		return await questionTypesService.find({
			findAll: true,
			orderBy: { name: "ASC" },
			filters: { forQuestionBank: true }
		});
	} catch (e) {
		dispatch(emit({ message: e.message, color: "error" }));
	}
});

export function getFullState(state: RootState): QuestionBankState {
	return state.questionBank;
}

export const { setLoading, failed, setStateValue, resetCourses, setDeleting } = questionBankSlice.actions;

export default questionBankSlice.reducer;
