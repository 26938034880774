import { Box } from "@material-ui/core";
import styled from "styled-components";

export const QuizResultsContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 50px 100px;
	${props => props.theme.breakpoints.down("sm")} {
		margin: 10px;
	}
`;

export const QuizResultsWrapper = styled(Box)`
	height: 750px;
	overflow-y: scroll;
	${props => props.theme.breakpoints.down("sm")} {
		height: 750px;
	}
`;
