import React from "react";

import styled from "styled-components";

export interface TempPageProps {}

const Container = styled.div`
	background: ${({ theme }) => theme.palette.colors.primary[100]} !important;
`;
const TempPage: React.FC<TempPageProps> = () => <Container>Temp</Container>;

export default TempPage;
