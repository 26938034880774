import { BaseListModel, BaseModel } from "@remar/shared/dist/models";

export interface IUserActivityLogType extends BaseModel {
	name: string;
	isActive: boolean;
	tableName: string;
	mappingColumn: string;
	isForAdmin: boolean;
	isForLocation: boolean;
	actor: string;
}
export interface UserActivityLogDataDto {
	change?: unknown;
	previous?: unknown;
	current?: unknown;
	actedOn?: unknown;
}
export interface IActivityLog extends BaseModel {
	userActivityLogTypeId: number;
	data: UserActivityLogDataDto | null;
	identifier: number;
	userId: number;
	isArchived: boolean;
	actionTakenBy: number;
	userActivityLogType: IUserActivityLogType;
}

export interface IActivityLogList extends BaseListModel {
	items: IActivityLog[];
}

export enum UserActivityLogTypeStudentEnum {
	Login = 1,
	LoginFacebook,
	LoginGoogle,
	Logout,
	QuestionBankRetake,
	LessonRetake,
	SessionShare,
	ChangeSubscriptionDate = 17,
	AddCATQuota
}

export enum UserActivityLogTypeLocationEnum {
	IACanceledInvite = 8,
	institutionAdminCanceledSubscription,
	InstitutionAdminRevokedSubscription,
	AddCATQuota = 20
}

export enum UserActivityLogTypeAdminEnum {
	SubscriptionPaused = 11,
	SubscriptionResumed,
	AddDaysToSubscription,
	ResetUserProgress,
	ChangeSubscriptionDate,
	ChangeEmail,
	AddCATQuota = 19,
	AssignSubscription = 21
}
