import { questionsAttemptsCountService } from "../../services/questionAttemptsCount"
import { userQuestionNotesService } from "../../services/userQuestionNotes/userQuestionNotes.service"

export const getQuestionNotes = (questionId: number, customTestId: number) => {
    return userQuestionNotesService
        .find({ filters: { questionId: questionId, customTestId: customTestId as unknown as number } })
}

export const fetchQuestionAttemptsCount = (questionId: number) => {
    return questionsAttemptsCountService.find({ filters: { questionId: questionId } })
}
