import React, { useState } from "react";

import { HotspotHighlightQuestionBox } from "./style";

import HotspotHighlightInput from "../../HotspotHighlightInput/HotspotHighlightInput";

const HotspotHighlightQuestionPreview = ({ question: { data } }) => {
	const [value, setValue] = useState<
		{
			highlighted: boolean;
			text: string;
		}[]
	>([{ highlighted: false, text: data.text }]);

	const disableInput = event => {
		event.preventDefault();
	};

	return (
		<HotspotHighlightQuestionBox onKeyPress={disableInput} onKeyDown={disableInput} onPaste={disableInput}>
			<HotspotHighlightInput value={value} onChange={newValue => setValue(newValue)} student={true} />
		</HotspotHighlightQuestionBox>
	);
};

export default HotspotHighlightQuestionPreview;
