import React, { useMemo } from "react";

import { Box, Button, Card, IconButton, Typography, useTheme } from "@material-ui/core";

import { ChevronLeft, ChevronRight, Person } from "@material-ui/icons";

import { useHistory } from "react-router-dom";

import {
	CourseBlock,
	DatePickerComponent,
	FilterBlock,
	StudentBlock,
	StyledCourseIcon,
	TitleWrapper,
	useStyles
} from "./styles";

import { MonthChange } from "./types";

import { LocationPackagesTypeEnum } from "../../constants";

import { Loader, Wrapper } from "../../layouts";
import { IExtendedTheme } from "../../theme/default";
import { BarChart } from "../Charts";
import { IBreadCrumb } from "../HeaderContainer/HeaderContainer";
import StatisticsCard from "../StatisticsCard";
import ProgressStatus from "../StudentSubscriptionCard/ProgressStatus";

type DateRangeType = {
	startDate: Date;
	endDate: Date;
};

type StudentReportComponentProps = {
	studentInfo: {
		firstName: string;
		lastName: string;
	};
	breadcrumbs?: IBreadCrumb[];
	StatisticsBlocks: Array<{
		title: string;
		text: string;
		Icon: React.ElementType;
		customBgColor: string;
	}>;
	data: { labels: string[]; datasets: { data: number[]; backgroundColor: string | undefined; barThickness: number }[] };
	dateRange: DateRangeType;
	setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
	locationPackageTypeId: number;
	coursesProgress: number;
	quizzesProgress: number;
	loadingStudentReports: boolean;
	loadingStudentLoginReports: boolean;
	monthName: string;
	loginChartDate: { month: number; year: number };
	handleMonthChange: (type: MonthChange) => void;
};
const StudentReportComponent = ({
	studentInfo,
	breadcrumbs,
	StatisticsBlocks,
	data,
	dateRange,
	setDateRange,
	locationPackageTypeId,
	coursesProgress,
	quizzesProgress,
	loadingStudentLoginReports,
	loadingStudentReports,
	monthName,
	loginChartDate,
	handleMonthChange
}: StudentReportComponentProps) => {
	const theme = useTheme<IExtendedTheme>();
	const history = useHistory();
	const classes = useStyles();

	const options = useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				x: {
					ticks: {
						maxRotation: 90,
						minRotation: 90,
						color: theme.palette.colors.basic[1200],
						labelOffset: -7 // aligns label after rotation
					},
					grid: {
						borderColor: theme.palette.colors.basic[1300]
					}
				},
				y: {
					ticks: {
						color: theme.palette.colors.basic[1200],
						precision: 0
					},
					grid: {
						color: theme.palette.colors.basic[1300],
						borderColor: theme.palette.colors.basic[1300]
					}
				}
			}
		}),
		[theme]
	);

	return (
		<Wrapper
			heading={`Report for ${studentInfo.firstName} ${studentInfo.lastName}`}
			breadcrumb={breadcrumbs}
			customActionStyle={{ paddingRight: 0 }}
			actions={
				<Button className={classes.noCancel} style={{ marginRight: 0 }} onClick={() => history.goBack()}>
					Back
				</Button>
			}
		>
			<Card>
				<Box display={"Flex"} justifyContent={"space-between"}>
					<StudentBlock>
						<Person />
						<TitleWrapper>
							<Typography>{`${studentInfo.firstName} ${studentInfo.lastName}`}</Typography>
							<Typography>Student</Typography>
						</TitleWrapper>
					</StudentBlock>
					<FilterBlock>
						<TitleWrapper>
							<Box display={"flex"} alignItems={"center"}>
								<Box mr={1}>
									<DatePickerComponent
										name={"startDate"}
										label={"Start Date"}
										value={dateRange}
										onChange={data => {
											setDateRange(prevState => ({
												...prevState,
												startDate: data
											}));
										}}
									/>
								</Box>
								<Box>
									<DatePickerComponent
										name={"endDate"}
										label={"End Date"}
										value={dateRange}
										onChange={data =>
											setDateRange(prevState => ({
												...prevState,
												endDate: data
											}))
										}
									/>
								</Box>
							</Box>
						</TitleWrapper>
					</FilterBlock>
					<CourseBlock>
						<StyledCourseIcon />
						<TitleWrapper>
							<Typography>NCLEX RN COURSE</Typography>
							<Typography>Student’s Course</Typography>
						</TitleWrapper>
						{(locationPackageTypeId === LocationPackagesTypeEnum.Package1 ||
							locationPackageTypeId === LocationPackagesTypeEnum.Package12) && (
							<Box ml={2}>
								<ProgressStatus title="Course" progress={coursesProgress} barWidth={"150px"} />
							</Box>
						)}
						{(locationPackageTypeId === LocationPackagesTypeEnum.Package2 ||
							locationPackageTypeId === LocationPackagesTypeEnum.Package12) && (
							<Box ml={2}>
								<ProgressStatus title="Test" progress={quizzesProgress} barWidth={"150px"} />
							</Box>
						)}
					</CourseBlock>
				</Box>
			</Card>
			{loadingStudentReports ? (
				<Loader height={130} size={"4rem"} />
			) : (
				<Box display={"flex"} flexWrap={"wrap"} mt={2}>
					{StatisticsBlocks.map(({ title, text, Icon, customBgColor }, index) => (
						<StatisticsCard
							key={title}
							title={title}
							text={text}
							IconSvg={Icon}
							customBgColor={customBgColor}
							customStyle={{ flexGrow: 1, marginRight: StatisticsBlocks.length - 1 === index ? "0" : "20px" }}
						/>
					))}
				</Box>
			)}
			<Box mt={2}>
				<Card>
					<Box display={"flex"} justifyContent={"space-between"} mb={2}>
						<Typography>Student Activity</Typography>
						<Box display={"flex"} alignItems={"center"}>
							<Box mr={1}>
								<Typography>{`${monthName} ${loginChartDate.year}`}</Typography>
							</Box>
							<Box display={"flex"} alignItems={"center"}>
								<IconButton onClick={() => handleMonthChange(MonthChange.DECREMENT)}>
									<ChevronLeft />
								</IconButton>
								<IconButton onClick={() => handleMonthChange(MonthChange.INCREMENT)}>
									<ChevronRight />
								</IconButton>
							</Box>
						</Box>
					</Box>
					<Box style={{ height: "470px" }}>
						{loadingStudentLoginReports ? (
							<Loader height={130} size={"4rem"} />
						) : (
							<BarChart options={options} data={data} />
						)}
					</Box>
				</Card>
			</Box>
		</Wrapper>
	);
};

export default StudentReportComponent;
