import React, { useEffect, useMemo, useState } from "react";

import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import Button from "@remar/shared/dist/components/Button";

import { useDispatch, useSelector } from "react-redux";

import {
	createLesson,
	getFullState as getFullSubjectsState,
	initialState,
	setStateValue,
	validateForm
} from "store/features/Subjects/Subjects.slice";
import { fetchTags, getFullTagsState } from "store/features/Tags/Tags.slice";

import theme from "theme/default";

import {
	ButtonsRow,
	FieldLabel,
	FormModal,
	ModalTitle,
	CustomInput as StyledInput,
	SubjectModalContent
} from "../styles";

const AddEditLesson = ({ onClose, defaultCustomInputOptions, lessonForm, tags: initialTags }) => {
	const dispatch = useDispatch();
	const [selectedTags, setSelectedTags] = useState<{ name: string; id: number }[]>(initialTags);

	const { tags, page } = useSelector(getFullTagsState);
	const { isLoading } = useSelector(getFullSubjectsState);

	useEffect(() => {
		dispatch(fetchTags({}));
	}, [dispatch]);

	const tagOptions = useMemo(() => tags.map(({ name, id }) => ({ name, id })), [tags]);

	const searchTags = text => {
		dispatch(fetchTags({ searchKeyword: text, page: 1 }));
	};

	const loadMoreTags = () => {
		dispatch(fetchTags({ page: page + 1, infinite: true }));
	};

	return (
		<FormModal
			open={true}
			onClose={() => {
				dispatch(setStateValue({ key: "lessonForm", value: initialState.lessonForm }));
				onClose();
			}}
			disableEnforceFocus
			disableAutoFocus
		>
			<SubjectModalContent>
				<ModalTitle>{`${lessonForm?.inputs?.id?.value ? "Rename" : "Add New"} Lesson`}</ModalTitle>

				<FieldLabel>
					Lesson Name
					<StyledInput
						width={150}
						mr={2}
						theme={theme}
						options={{ ...defaultCustomInputOptions, inputData: lessonForm.inputs.name }}
					/>
				</FieldLabel>
				<AutocompleteFilter
					options={tagOptions}
					value={selectedTags}
					setValue={val => {
						dispatch(validateForm({ formStatePath: "lessonForm", markInputsAsDirty: true }));
						setSelectedTags(val);
					}}
					onTextInputChange={searchTags}
					filterName="Tags"
					loadMoreResults={loadMoreTags}
					showChips={true}
				/>
				<ButtonsRow>
					<Button
						variant={"filled"}
						color="primary"
						disabled={isLoading || !lessonForm.valid}
						loading={isLoading}
						onClick={() => {
							dispatch(
								createLesson({
									tagIds: selectedTags.map(t => t.id),
									cb: () => onClose()
								})
							);
						}}
					>
						{lessonForm?.inputs?.id?.value ? "Save Changes" : "Add New Lesson"}
					</Button>
				</ButtonsRow>
			</SubjectModalContent>
		</FormModal>
	);
};

export default AddEditLesson;
