import React from "react";

import { Modal } from "@material-ui/core";

import { Course, UserAllowedCourse, UserSubscription } from "@remar/shared/dist/models";

import { useStyles } from "@remar/shared/dist/styles";

import ManageSubscription from "../../App/View/Container/ManageStudents/components/ManageSubscription";

export interface IChangeSubscription {
	fullName: string;
	userId: number;
	subscriptionId: number;
	hasExpired: boolean;
	isCancelled: boolean;
	isTrial: boolean | undefined;
	allowedCourses: UserAllowedCourse[] | undefined;
	notAllowedCourses: Course[] | null;
	subscription: UserSubscription;
}

const ChangeSubscriptionModal = ({
	setShowChangeSubscriptionModal,
	showChangeSubscriptionModal: {
		allowedCourses,
		notAllowedCourses,
		userId,
		fullName,
		subscription,
		subscriptionId,
		hasExpired,
		isCancelled,
		isTrial
	},
	fetchAccount = false
}: {
	setShowChangeSubscriptionModal: React.Dispatch<React.SetStateAction<IChangeSubscription | null>>;
	showChangeSubscriptionModal: IChangeSubscription;
	fetchAccount?: boolean;
}) => {
	const classes = useStyles();
	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus
			disableAutoFocus
			open={true}
			onClose={() => setShowChangeSubscriptionModal(null)}
		>
			<ManageSubscription
				onClose={() => setShowChangeSubscriptionModal(null)}
				allowedCourses={allowedCourses}
				notAllowedCourses={notAllowedCourses}
				fullName={fullName}
				subscription={subscription}
				subscriptionId={subscriptionId}
				disabled={hasExpired || isCancelled || isTrial || isTrial === undefined}
				userId={userId}
				fetchAccount={fetchAccount}
			/>
		</Modal>
	);
};

export default ChangeSubscriptionModal;
