import React, { useEffect, useState } from "react";

import { BreadcrumbItem, Breadcrumbs } from "./Styles";

interface TriangleBreadcrumbItem {
	label: string;
	onClick?: () => void;
}

interface TriangleBreadcrumbsProps {
	breadcrumb: TriangleBreadcrumbItem[];
	activeIndex: number;
}

export default function TriangleBreadcrumbs(props: TriangleBreadcrumbsProps) {
	const { breadcrumb, activeIndex } = props;

	const [canChangeTab, setCanChangeTab] = useState(true);

	useEffect(() => {
		setCanChangeTab(true);
	}, [activeIndex]);

	const handleClick = (callback: () => void) => {
		// prevent handle click until tab is actually changed in useEffect before
		// we can't use isLoading flag from state, beacuse it changes with long delay after
		// click so it can't help block multiple clicks
		if (canChangeTab) {
			callback();
			setCanChangeTab(false);
		}
	};

	return (
		<Breadcrumbs>
			{breadcrumb.map((el, i) => (
				<BreadcrumbItem
					key={el.label}
					isActive={activeIndex === i}
					onClick={() => activeIndex !== i && handleClick(() => el?.onClick && el?.onClick())}
					notFirstIndex={i !== 0}
				>
					{el.label}
				</BreadcrumbItem>
			))}
		</Breadcrumbs>
	);
}
