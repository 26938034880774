import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 30px;
	align-items: center;
`;

export const ReportButton = styled(Button)`
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	background-color: ${({ theme }) => theme.palette.colors.basic[800]};
	font-weight: bold;
	padding: 0.4rem 2.5rem;
	&:hover {
		background-color: ${({ theme }) => theme.palette.colors.basic[900]};
	}
`;

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	position: relative;
`;

export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	margin-top: 48px;
	color: #ffffff;
	font-family: var(--poppins);
`;

export const InfoHead = styled(Typography)`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: 30px;
	font-weight: 300;
	line-height: 1.33;
	color: #ffffff;
`;

export const Info = styled(InfoHead)`
	font-weight: normal;
	font-family: var(--poppins);
`;

export const BackgroundImg = styled.img`
	width: 98%;
	height: 403px;
	border-radius: 4px;
`;

export const InfoContainer = styled.div`
	position: absolute;
	top: 105px;
	left: 32px;
`;

export const Gradient = styled.div`
	background: radial-gradient(
		circle at 9% 66%,
		#13161e,
		rgba(19, 22, 30, 0.97) 25%,
		rgba(19, 22, 30, 0.8) 42%,
		rgba(19, 22, 30, 0) 81%
	);
	width: 98%;
	height: 404px;
	position: absolute;
`;

export const QuickLink = styled(Link)`
	margin: 12px 24px 0 0;
	cursor: pointer;
	width: 218px;
	height: 218px;
	text-align: center;
	padding: 48px 33px 56px 37px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.palette.colors.basic[900]};

	.title {
		opacity: 0.87;
		margin-top: 24px;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.33;
		color: #ffffff;
	}

	.quicklinks-img {
		height: 72px;
		width: 72px;
	}

	${({ disabled }) =>
		disabled
			? css`
					background-color: ${({ theme }) => theme.palette.colors.basic[700]};
					pointer-events: none;
			  `
			: ""}
`;

export const QuickLinksContainer = styled.div`
	display: flex;
`;

export const SectionContainer = styled.div`
	top: 300px;
	width: 96%;
	position: absolute;
	left: 32px;
`;

export const MetricsContainer = styled.div`
	width: 100%;
	height: 162px;
	width: 98%;
	color: white;
	margin-top: 24px;
	background-color: ${({ theme }) => theme.palette.colors.basic[900]};
`;
