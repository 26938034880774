import React, { useEffect } from "react";

import { Box, IconButton, Typography } from "@material-ui/core";
import CancelRounded from "@material-ui/icons/CancelRounded";
import { QuestionTypes } from "@remar/shared/dist/constants";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import cn from "classnames";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { editBowtieGroups } from "store/features/AddQuestion/addQuestion.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";

import { CustomInput as StyledInput, useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const BowTieQuestion = ({
	createGroupAnswerOptionItem,
	getFullState,
	initBowtieGroups,
	removeQuestionAnswerOptionItem,
	removeQuestionAttachment,
	setStateValue,
	uploadQuestionMaterial,
	validateForm,
	formName = "addNewQuestionForm",
	statePath = ""
}) => {
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";

	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };

	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const { groups, text, _expanded, description, attachments, endOfQuestionSummary, mainImageKey } = questionData;

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(initBowtieGroups({ statePath }));
		} else {
			dispatch(editBowtieGroups({ statePath }));
		}
	}, [groups.length]);

	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			expanded={_expanded?.value}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			statePath={statePath}
			setStateValue={setStateValue}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
			<Typography className={classes.addOptionHeader}>BOWTIE</Typography>
			<div className={classes.schemaContainer}>
				<div className={classes.shortSchemaColumn}>
					<div className={cn(classes.shcemaBox, classes.centerAlign)}>Actions To Take</div>
					<div className={cn(classes.shcemaBox, classes.centerAlign)}>Actions To Take</div>
				</div>
				<div className={classes.schemaSeparator}>
					<div className={classes.schemaTopDiag}>
						<i></i>
						<i></i>
					</div>
					<div className={classes.schemaBottomDiag}>
						<i></i>
						<i></i>
					</div>
				</div>
				<div className={classes.longSchemaColumn}>
					<div className={cn(classes.shcemaBox, classes.centerAlign)}>Conditions Most Likely Experiencing</div>
				</div>
				<div className={classes.schemaSeparator}>
					<div className={classes.schemaBottomDiag}>
						<i></i>
						<i></i>
					</div>
					<div className={classes.schemaTopDiag}>
						<i></i>
						<i></i>
					</div>
				</div>
				<div className={classes.shortSchemaColumn}>
					<div className={cn(classes.shcemaBox, classes.centerAlign)}>Parameters To Monitor</div>
					<div className={cn(classes.shcemaBox, classes.centerAlign)}>Parameters To Monitor</div>
				</div>
			</div>
			<div className={classes.groupsContainer}>
				{groups.map(({ answerOptions }, groupIndex) => {
					const notDeletedOptions = answerOptions.filter(({ deleted }) => !deleted?.value).length;
					return (
						<div
							key={`group-${groupIndex}`}
							className={`${classes.flexTop} ${
								groupIndex === 1 ? classes.longSchemaColumn : classes.shortSchemaColumn
							}`}
						>
							{answerOptions.map(
								({ isCorrect, text, deleted }, answerOptionIndex) =>
									!deleted?.value && (
										<Box
											key={`answerOption-${answerOptionIndex}`}
											display="flex"
											flexDirection="row"
											mt={2}
											width="100%"
										>
											<Box display="flex" flexDirection="row" width={"100%"}>
												{isCorrect && (
													<Box className={classes.alignSelf}>
														<StyledInput
															display="flex"
															alignItems="center"
															theme={theme}
															options={{
																...defaultCustomInputOptions,
																inputData: {
																	...isCorrect,
																	readonly:
																		!isCorrect?.value &&
																		answerOptions.filter(({ isCorrect, deleted }) => isCorrect?.value && !deleted.value)
																			.length === (groupIndex === 1 ? 1 : 2)
																}
															}}
														/>
													</Box>
												)}
												<StyledInput theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
											</Box>
											<Box display="flex" alignItems="center" justifyContent="center" width="51px">
												{((groupIndex === 1 && notDeletedOptions > 2) || notDeletedOptions > 3) && (
													<IconButton
														size="small"
														style={{ color: "#ffffff" }}
														onClick={() =>
															dispatch(
																removeQuestionAnswerOptionItem({
																	formName,
																	inputsPath: `${fullStatePath}.groups.${groupIndex}.answerOptions.${answerOptionIndex}`
																})
															)
														}
													>
														<CancelRounded color="disabled" />
													</IconButton>
												)}
											</Box>
										</Box>
									)
							)}

							{notDeletedOptions < 6 && (
								<Box mt={2}>
									<AddButton
										onClick={() =>
											dispatch(
												createGroupAnswerOptionItem({
													index: groupIndex,
													statePath,
													questionType: QuestionTypes.BowTie
												})
											)
										}
										label={"Add option"}
									/>
								</Box>
							)}
						</div>
					);
				})}
			</div>
		</CommonQuestionFields>
	);
};
