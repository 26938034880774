import React, { useEffect } from "react";

import { Box, IconButton, Typography } from "@material-ui/core";
import CancelRounded from "@material-ui/icons/CancelRounded";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";

import { CustomInput as StyledInput, useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const MultipleResponseGroupQuestion = ({
	createGroupAnswerOptionItem,
	createQuestionGap,
	getFullState,
	removeGap,
	removeGapOption,
	removeQuestionAttachment,
	setStateValue,
	uploadQuestionMaterial,
	validateForm,
	formName = "addNewQuestionForm",
	statePath = ""
}) => {
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";

	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };

	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const {
		groups,
		text,
		description,
		attachments,
		endOfQuestionSummary,
		mainImageKey,
		tableLabel,
		answerOptionLabel,
		_expanded
	} = questionData;

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(createQuestionGap({ statePath, formName }));
		}
	}, [groups.length]);

	const groupsAmount = groups.filter(({ deleted }) => !deleted?.value).length;
	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			statePath={statePath}
			expanded={_expanded?.value}
			setStateValue={setStateValue}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
			<Typography className={classes.addOptionHeader}>TABLE VIEW</Typography>
			<table className={classes.multipleResponsegGroupTable}>
				<tr>
					<td>
						<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: tableLabel }} />
					</td>
					<td>
						{" "}
						<StyledInput
							mr={2}
							theme={theme}
							options={{ ...defaultCustomInputOptions, inputData: answerOptionLabel }}
						/>
					</td>
				</tr>
				{groups.map(
					({ id, text, answerOptions, deleted }, groupIndex) =>
						!deleted?.value && (
							<tr key={`tr-${id?.value}`}>
								<td>
									<Box display="flex" flexWrap="nowrap" alignItems="center">
										<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />

										{groupsAmount > 1 && (
											<Box flex="0" ml={1}>
												<IconButton
													size="small"
													onClick={() => dispatch(removeGap({ formName, gapIndex: groupIndex, statePath }))}
												>
													<CancelRounded color="disabled" />
												</IconButton>
											</Box>
										)}
									</Box>
								</td>
								<td>
									{answerOptions.map(
										({ id, text, deleted, isCorrect }, answerOptionIndex) =>
											!deleted?.value && (
												<Box key={id.value} display="flex" alignItems="center" mb={2}>
													{isCorrect && (
														<Box className={classes.alignSelf}>
															<StyledInput
																display="flex"
																alignItems="center"
																theme={theme}
																options={{ ...defaultCustomInputOptions, inputData: isCorrect }}
															/>
														</Box>
													)}
													<StyledInput
														mr={2}
														theme={theme}
														options={{ ...defaultCustomInputOptions, inputData: text }}
													/>
													<Box flex="0" ml={1}>
														<IconButton
															size="small"
															onClick={() =>
																dispatch(removeGapOption({ gapIndex: groupIndex, answerOptionIndex, statePath }))
															}
														>
															<CancelRounded color="disabled" />
														</IconButton>
													</Box>
												</Box>
											)
									)}
									<Box mt={2}>
										<AddButton
											onClick={() => {
												dispatch(createGroupAnswerOptionItem({ index: groupIndex, statePath }));
											}}
											label={"Add Option"}
										/>
									</Box>
								</td>
							</tr>
						)
				)}
			</table>
			<Box mt={2}>
				<AddButton
					onClick={() => {
						dispatch(
							createQuestionGap({
								statePath,
								formName
							})
						);
					}}
					label={"Add group"}
				/>
			</Box>
		</CommonQuestionFields>
	);
};
