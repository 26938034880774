import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearJwt, getJwt, setJwt } from "@remar/shared/dist/api/jwt";

import { Country, InviteData, User } from "@remar/shared/dist/models";

import { AppThunk, RootState } from "store";
import { UserLoginDto, UserLoginResponseDto, countriesService, usersService } from "store/services";

import { IPermissions, PermissionType, permissions } from "core/CheckPermissions/permissions";

import { emit } from "../notifications/notifications.slice";

interface IAccessPerRoute {
	canView: boolean;
	canEdit: boolean;
	accessAreas?: PermissionType[];
	isAccessReady?: boolean;
}
interface AuthState {
	token: string | null;
	user: User | null;
	userAccess: IPermissions;
	accessPerRoute: IAccessPerRoute;
	isLoading: boolean;
	isLoggedIn: boolean;
	errorMessage: string;
	countries: Country[];
	isVerifyingInvite: boolean;
	inviteData: InviteData | null;
	isAcceptingInvite: boolean;
	firebaseErrorMessage: string;
}

const initialToken = getJwt();

const initialState: AuthState = {
	token: initialToken || null,
	user: null,
	isLoading: false,
	userAccess: permissions,
	accessPerRoute: { canView: false, canEdit: false, accessAreas: [], isAccessReady: false },
	isLoggedIn: !!initialToken,
	errorMessage: "",
	countries: [],
	isVerifyingInvite: false,
	inviteData: null,
	isAcceptingInvite: false,
	firebaseErrorMessage: ""
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setLoading: state => {
			state.errorMessage = "";
			state.isLoading = true;
		},
		setUser: (state, action: PayloadAction<{ user: User }>) => {
			state.user = action.payload.user;
		},
		success: state => {
			state.errorMessage = "";
			state.isLoading = false;
		},
		failed: (state, action: PayloadAction<{ message: string }>) => {
			state.errorMessage = action.payload.message;
			state.isLoading = false;
		},
		setAuth: (state, action: PayloadAction<{ token: string; user: User }>) => {
			state.isLoggedIn = true;
			state.token = action.payload.token;
			state.user = action.payload.user;
		},
		clearAuth: state => {
			state.isLoggedIn = false;
			state.token = null;
			state.user = null;
		},
		setRoutePermissions: (state, action: PayloadAction<IAccessPerRoute>) => {
			state.accessPerRoute = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchCountries.fulfilled, (state, action) => {
				const countries = action.payload.items as Country[];
				const USA = countries.find(({ code }) => code === "US")!;
				if (USA) {
					countries.unshift(USA);
				}
				state.countries = countries;
			})
			.addCase(verifyInvite.pending.type, state => {
				state.isVerifyingInvite = true;
			})
			.addCase(verifyInvite.fulfilled.type, (state, action: PayloadAction<InviteData>) => {
				state.isVerifyingInvite = false;
				state.inviteData = action.payload;
			})
			.addCase(verifyInvite.rejected.type, state => {
				state.isVerifyingInvite = false;
			})
			.addCase(acceptInvite.pending.type, state => {
				state.isAcceptingInvite = true;
			})
			.addCase(acceptInvite.fulfilled.type, state => {
				state.isAcceptingInvite = false;
			})
			.addCase(acceptInvite.rejected.type, state => {
				state.isAcceptingInvite = false;
			});
	}
});

export const setUpToken = createAsyncThunk("auth/setUpToken", async (data: UserLoginResponseDto, { dispatch }) => {
	setJwt(data.sessionToken, "", data.refreshToken);
	await dispatch(setAuth({ token: data.sessionToken, user: data.user }));
	await dispatch(success());
});

export const login =
	(data: UserLoginDto): AppThunk =>
	dispatch => {
		dispatch(setLoading());
		usersService
			.login(data)
			.then(r => {
				dispatch(setUpToken(r));
			})
			.catch(e => {
				dispatch(failed(e));
			});
	};

export const whoami = (): AppThunk => dispatch => {
	dispatch(setLoading());
	usersService
		.whoami()
		.then(({ user }) => {
			dispatch(setUser({ user }));
			dispatch(success());
		})
		.catch(e => {
			dispatch(failed(e));
		});
};

export const logout = (): AppThunk => dispatch => {
	usersService
		.logout()
		.then(() => {
			clearJwt();
			dispatch(clearAuth());
			dispatch(success());
		})
		.catch(e => {
			dispatch(failed(e));
		});
};

export const fetchCountries = createAsyncThunk(
	"students/fetchCountries",
	async (subscriptionTypeId: number, { rejectWithValue }) => {
		return countriesService
			.find({
				...(subscriptionTypeId && { filters: { "shippingPlans.subscriptionTypes.id": subscriptionTypeId } }),
				orderBy: { name: "ASC" },
				findAll: true
			})
			.catch(error => rejectWithValue(error.message));
	}
);

export const verifyInvite = createAsyncThunk(
	"auth/verifyInvite",
	async (
		{ accountClaimCode, failedCB }: { accountClaimCode: string; failedCB: () => void },
		{ dispatch, rejectWithValue }
	) => {
		try {
			return await usersService.verifyInvite({ accountClaimCode });
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
			failedCB && failedCB();
			return rejectWithValue(e.message);
		}
	}
);

export const acceptInvite = createAsyncThunk(
	"auth/acceptInvite",
	async (
		{
			data,
			cb
		}: {
			data: { accountClaimCode: string; firstName: string; lastName: string; password: string };
			cb: () => void;
		},
		{ dispatch, rejectWithValue }
	) => {
		try {
			const res = await usersService.acceptInvite(data);
			dispatch(setUpToken(res));
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const { setAuth, clearAuth, failed, success, setLoading, setUser, setRoutePermissions } = authSlice.actions;
export const selectUser = (state: RootState): User | null => state.auth.user;
export const selectLoggedIn = (state: RootState): boolean => state.auth.isLoggedIn;
export const selectAuth = (state: RootState): { errorMessage: string; isLoading: boolean; isLoggedIn: boolean } => {
	const { errorMessage, isLoading, isLoggedIn } = state.auth;
	return { errorMessage, isLoading, isLoggedIn };
};
export const getFullState = (state: RootState): AuthState => state.auth;
export default authSlice.reducer;
