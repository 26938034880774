import React, { useEffect, useState } from "react";

import { Box, IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import CancelRounded from "@material-ui/icons/CancelRounded";
import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";
import { GroupContainer, InputStyles, CustomInput as StyledInput, useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const DropDownTableQuestion = ({
	createGroupAnswerOptionItem,
	createQuestionGroup,
	getFullState,
	removeGap,
	removeGapOption,
	removeQuestionAttachment,
	setStateValue,
	updateCorrectFlag,
	uploadQuestionMaterial,
	validateForm,
	formName = "addNewQuestionForm",
	statePath = ""
}) => {
	const dispatch = useDispatch();

	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";

	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const [gapIndex, setGapIndex] = useState(0);
	const {
		text,
		groups,
		description,
		attachments,
		endOfQuestionSummary,
		mainImageKey,
		answerOptionLabel,
		tableLabel,
		_expanded
	} = questionData;
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const notDeletedGaps = groups.filter(({ deleted }) => !deleted.value).length;

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(createQuestionGroup({ statePath, formName }));
		}
	}, [groups.length]);

	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			expanded={_expanded?.value}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			statePath={statePath}
			setStateValue={setStateValue}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
			<Typography className={classes.addOptionHeader}>Table View</Typography>

			<Box display="flex" flexDirection="row" width="100%">
				<Box display="flex" flexDirection="row" alignItems="baseline" width="calc(100% - 30px)">
					<Box display="flex" flexDirection="row" width="70%">
						<StyledInput
							theme={theme}
							noMargin
							InputProps={{
								style: {
									...InputStyles,
									backgroundColor: "#262930"
								}
							}}
							options={{ ...defaultCustomInputOptions, inputData: tableLabel }}
						/>
					</Box>

					<Box display="flex" flexDirection="row" width="30%">
						<StyledInput
							theme={theme}
							noMargin
							InputProps={{
								style: {
									...InputStyles,
									backgroundColor: "#262930"
								}
							}}
							options={{ ...defaultCustomInputOptions, inputData: answerOptionLabel }}
						/>
					</Box>
				</Box>
			</Box>

			{groups.map(
				({ id, text, answerOptions, deleted }, groupIndex) =>
					!deleted.value && (
						<GroupContainer display="flex" key={id.value} width="100%" error={text.error}>
							<Box display="flex" width="calc(100% - 30px)" border="solid 1px rgb(151, 151, 151, 0.3)">
								<Box maxWidth={"70%"} flexBasis="70%" flexGrow="0">
									<StyledInput
										theme={theme}
										InputProps={{
											style: {
												borderRight: "solid 1px rgb(151, 151, 151, 0.3)",
												borderRadius: 0,
												backgroundColor: "#2a2e37",
												paddingTop: "12px"
											}
										}}
										noMargin
										options={{ ...defaultCustomInputOptions, inputData: text }}
									/>
								</Box>

								<Box maxWidth={"30%"} flexBasis="30%" display="flex" alignItems="center">
									<StyledInput
										noMargin
										theme={theme}
										inputProps={{
											onChange: ({ target: { value } }) =>
												dispatch(updateCorrectFlag({ groupIndex, answerOptionIndex: value, statePath }))
										}}
										InputProps={{
											style: {
												...InputStyles,
												backgroundColor: "#2a2e37"
											}
										}}
										options={{
											...defaultCustomInputOptions,
											inputData: {
												type: CustomInputType.Select,
												defaultValue: "",
												value: (index => (index === -1 ? "" : index))(
													answerOptions.findIndex(({ isCorrect }) => isCorrect.value)
												),
												selectOptions: answerOptions
													.filter(({ deleted }) => !deleted.value)
													.map(({ text }, value) => ({ text: text.value, value })),
												pristine: true
											}
										}}
									/>
								</Box>
							</Box>

							<Box width="30px" display="flex" alignItems="center">
								{notDeletedGaps > 2 ? (
									<IconButton
										size="small"
										onClick={() => {
											dispatch(removeGap({ formName, statePath, gapIndex: groupIndex }));
											if (groupIndex === gapIndex) {
												setGapIndex(groups.findIndex(({ deleted }, index) => !deleted.value && index !== groupIndex));
											}
										}}
									>
										<CancelRounded color="disabled" />
									</IconButton>
								) : (
									" "
								)}
							</Box>
						</GroupContainer>
					)
			)}
			<Box mt={2}>
				<AddButton
					onClick={() => {
						dispatch(
							createQuestionGroup({
								statePath,
								formName
							})
						);
					}}
					label={"Add Row"}
				/>
			</Box>
			{groups.length > 0 && (
				<>
					<Select
						className={classes.select}
						disableUnderline
						value={gapIndex}
						onChange={({ target: { value } }) => setGapIndex(value as number)}
					>
						{groups
							.map(({ deleted }, realIndex) => ({ realIndex, deleted: deleted.value }))
							.filter(({ deleted }) => !deleted)
							.map(({ realIndex }, index) => (
								<MenuItem key={realIndex} value={realIndex}>
									Word choices for Row {index + 1}
								</MenuItem>
							))}
					</Select>
					{groups[gapIndex]?.answerOptions.map(
						({ id, text, deleted }, answerOptionIndex) =>
							!deleted?.value && (
								<GroupContainer key={id.value} display="flex" alignItems="center" error={text.error}>
									<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
									<Box flex="0" ml={1}>
										<IconButton
											size="small"
											onClick={() => dispatch(removeGapOption({ gapIndex, answerOptionIndex, statePath }))}
										>
											<CancelRounded color="disabled" />
										</IconButton>
									</Box>
								</GroupContainer>
							)
					)}
					<Box mt={2}>
						<AddButton
							onClick={() => {
								dispatch(createGroupAnswerOptionItem({ index: gapIndex, statePath }));
							}}
							label={"Add Option"}
						/>
					</Box>
				</>
			)}
		</CommonQuestionFields>
	);
};
