import { Box, Paper } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import styled, { css } from "styled-components";

export const FeatureIcon = styled(CheckCircle)`
	flex-grow: 0;
	margin-right: 4px;
	font-size: 16px;
	color: ${({ theme }) => theme.palette.customSelectionBox.backgroundColor};
`;

export const Package = styled(Paper)`
	position: relative;
	flex: 0 0 calc((100% - 41px) / 3);
	padding: 20px;
	color: ${({ $current, theme }) => ($current ? theme.palette.common.white : theme.palette.text.secondary)};
	background: ${({ $current, theme }) =>
		$current ? theme.palette.customSelectionBox.backgroundColor : theme.palette.background.default};
	display: flex;
	flex-flow: column;
	gap: 20px;
	overflow: hidden;
	border-radius: 4px;

	${({ $current }) =>
		$current &&
		css`
			${FeatureIcon} {
				color: ${props => props.theme.palette.colors.basic[100]};
			}
		`}
`;

export const Course = styled(Package)`
	color: ${({ theme, $current }) => ($current ? theme.palette.common.white : theme.palette.text.primary)};
	width: 296px;
	position: relative;
`;

export const CourseHeader = styled.h2`
	margin: 0;
	padding: 0;
	font: 700 22px/100% var(--poppins);
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	${({ current }) =>
		current &&
		css`
			color: ${({ theme }) => theme.palette.colors.basic[100]};
			padding-right: 24px;
		`}
`;

export const CourseText = styled.p`
	font-size: 13px;
	font-weight: 400;
	padding: 0;
	margin: 0;
	flex: 0 1 0;
`;

export const SelectedIcon = styled(Check)`
	font-size: 22px;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	position: absolute;
	top: 18px;
	right: 20px;
`;

export const FeatureRow = styled(Box)`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 2px 0;
	margin-bottom: 2px;
`;

export const PackageName = styled.span`
	text-transform: none;
	font-weight: 600;
	font-size: 15px;
	line-height: 24px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`;

export const PackageText = styled(Box)`
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	flex-grow: 1;
`;
