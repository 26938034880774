import React, { useEffect, useMemo } from "react";

import { BellNotificationsBlock } from "@remar/shared/dist/components/Notifications";
import { NotificationDeliveryMethodEnum } from "@remar/shared/dist/models";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	getNotificationFullState,
	getUserNotifications,
	markAsRead
} from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

const BellNotifications = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { bellNotifications: bellNotificationsState } = useSelector(getNotificationFullState);

	useEffect(() => {
		dispatch(
			getUserNotifications({
				filters: {
					"notification.deliveryMethod": NotificationDeliveryMethodEnum.Socket
				}
			})
		);
	}, [dispatch]);

	const bellNotifications = useMemo(
		() =>
			bellNotificationsState?.items.map(b => ({
				...b,
				action:
					b.notification.title === "AdminCommentsNotification"
						? () => history.push(`${routes.lesson.getPath()}/${b?.data!.lessonId}`)
						: undefined
			})) || [],
		[bellNotificationsState?.items, history]
	);

	return <BellNotificationsBlock bellNotifications={bellNotifications} markAsRead={() => dispatch(markAsRead())} />;
};

export default BellNotifications;
