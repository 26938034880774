import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, Grid, IconButton, Modal, TextField, Typography, useTheme } from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import Button from "@remar/shared/dist/components/Button";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";
import { Course } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import copyPasteOnlyInput from "@remar/shared/dist/utils/copyPasteOnlyInput";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
	createBook,
	deleteBook,
	fetchBookStripePrice,
	fetchBooks,
	fetchShipStationData,
	getFullBookState as getBooksFullState,
	resetBookCover,
	resetShipmentData,
	setStateValue,
	updateBook,
	validateForm
} from "store/features/Books";
import { fetchAllCourses, getFullState as getCourseFullState } from "store/features/Course/course.slice";
import { _emit } from "store/features/notifications/notifications.slice";
import { genericService } from "store/services";

import { SwitchInput, TextAreaInput } from "modules/Components/FormikFields";

import { InputField } from "modules/Components/InputField";

import { validationSchema } from "./schema";

import { ITEMS_PER_PAGE } from "../constants";

import { DisplayInfo, SelectCourseWrapper } from "../styles";
interface IInitialValues {
	name: string;
	description: string;
	imageKey: string;
	imageUrl: string;
	courseIds: Course[];
	planId: string;
	productSKU: string;
	isAvailableForTrial: boolean;
}
const initialValues: IInitialValues = {
	name: "",
	description: "",
	imageKey: "",
	imageUrl: "",
	courseIds: [],
	productSKU: "",
	planId: "",
	isAvailableForTrial: false
};

const InputLoader = () => (
	<Box position={"absolute"} right={10} top={15}>
		<ContentLoader size={"1rem"} height={"30px"} />
	</Box>
);

const AddEditBook = ({ book, onClose }) => {
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useDispatch();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const { courses } = useSelector(getCourseFullState);
	const {
		bookCover,
		bookPrice,
		isFetchingPrice,
		isFetchingShipmentData,
		shipmentData,
		isCreatingUpdatingDeletingBook
	} = useSelector(getBooksFullState);
	const editMode = !isEmpty(book);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const inputStyle = {
		color: theme.palette.text.primary,
		background: theme.palette.colors.basic[800],
		height: 45,
		padding: "5px"
	};
	const updateCreateDeleteCb = () => {
		dispatch(fetchBooks({ include: ["courses"], page: 1, perPage: ITEMS_PER_PAGE }));
		setValues(initialValues);
		onClose();
	};
	const handleFormSubmit = ({ name, description, courseIds, planId, isAvailableForTrial, imageKey, productSKU }) => {
		const options = {
			name,
			description,
			imageKey,
			courseIds: courseIds.map(c => ({ value: c.id })),
			planId,
			productSKU,
			isAvailableForTrial
		};

		if (editMode) {
			return dispatch(
				updateBook({
					options: { id: book.id, ...options },
					cb: updateCreateDeleteCb
				})
			);
		}
		dispatch(
			createBook({
				options,
				cb: updateCreateDeleteCb
			})
		);
	};
	const {
		values: { name, description, courseIds, planId, isAvailableForTrial, imageUrl, imageKey, productSKU },
		isValid,
		dirty,
		handleChange,
		handleBlur,
		handleSubmit,
		setFieldValue,
		setValues,
		validateField
	} = useFormik({ initialValues, validationSchema, onSubmit: handleFormSubmit });

	useEffect(() => {
		if (!courses) dispatch(fetchAllCourses());
	}, [courses, dispatch]);

	useEffect(() => {
		if (bookCover) {
			setFieldValue("imageUrl", bookCover.imageUrl || "");
			setFieldValue("imageKey", bookCover.value || "");
			setTimeout(() => {
				validateField("imageUrl");
			}, 1000);
		}
	}, [bookCover]);

	useEffect(() => {
		if (editMode) {
			const { name, description, data, isAvailableForTrial, courses, imageKey, imageUrl } = book;
			dispatch(fetchShipStationData(data.productSKU));
			setValues({
				name,
				description,
				planId: data.planId,
				isAvailableForTrial,
				courseIds: courses.map(c => ({ id: c.id, name: c.name })),
				imageKey,
				imageUrl,
				productSKU: data.productSKU
			});
		}
	}, [editMode, book]);

	const Price = useMemo(() => {
		if (!!bookPrice) return formatUSD(bookPrice);
		if (book) return formatUSD(book.data?.price || 0);
		return null;
	}, [book, bookPrice]);

	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus
			disableAutoFocus
			open={true}
			onClose={() => {
				setValues(initialValues);
				dispatch(resetBookCover());
				dispatch(resetShipmentData());
				onClose();
			}}
		>
			<Box width={800}>
				<Card component="form" onSubmit={e => handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>)}>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Typography variant="h6">{!editMode ? "Add New Book" : "Edit Book"}</Typography>
						<IconButton
							onClick={() => {
								setValues(initialValues);
								onClose();
								dispatch(resetBookCover());
								dispatch(resetShipmentData());
							}}
						>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<Box mt={3}>
						<Grid container spacing={3}>
							<Grid item xs={7}>
								<InputField
									title={"Book Title"}
									inputComponent={
										<TextField
											fullWidth
											name={"name"}
											inputProps={{ maxLength: 40 }}
											InputProps={{
												style: inputStyle,
												disableUnderline: true
											}}
											value={name}
											placeholder={"Enter Book title"}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									}
								/>
								<InputField
									title={"Description"}
									inputComponent={
										<TextAreaInput
											placeholder={"Description"}
											name={"description"}
											value={description}
											onChange={handleChange}
											onBlur={handleBlur}
											maxLength={150}
										/>
									}
								/>
								<InputField
									title={"Course ID"}
									inputComponent={
										<SelectCourseWrapper>
											<AutocompleteFilter
												options={(courses || []).map(c => ({ id: c.id, name: c.name }))}
												value={courseIds}
												setValue={val => {
													setFieldValue("courseIds", val);
												}}
												filterName="Courses"
											/>
										</SelectCourseWrapper>
									}
								/>
								<InputField
									title={"Product SKU"}
									inputComponent={
										<Box position={"relative"}>
											<TextField
												fullWidth
												name={"productSKU"}
												InputProps={{
													style: inputStyle,
													disableUnderline: true
												}}
												value={productSKU}
												onKeyDown={copyPasteOnlyInput}
												placeholder={"Enter product SKU"}
												onChange={e => {
													handleChange(e);
													dispatch(fetchShipStationData(e.target.value));
												}}
											/>
											{isFetchingShipmentData && <InputLoader />}
											{shipmentData && <DisplayInfo>{shipmentData.name}</DisplayInfo>}
										</Box>
									}
								/>
								<InputField
									title={"Price"}
									inputComponent={
										<Box position={"relative"}>
											<TextField
												fullWidth
												name={"planId"}
												value={planId}
												onChange={e => {
													handleChange(e);
													dispatch(fetchBookStripePrice(e.target.value));
												}}
												onKeyDown={copyPasteOnlyInput}
												InputProps={{
													style: inputStyle,
													disableUnderline: true
												}}
												placeholder={"Stripe Link"}
											/>
											{isFetchingPrice && <InputLoader />}
											<DisplayInfo>{Price}</DisplayInfo>
										</Box>
									}
								/>
								<InputField
									title={""}
									inputComponent={
										<Box display={"flex"} alignItems={"center"}>
											<SwitchInput name={"isAvailableForTrial"} checked={isAvailableForTrial} onChange={handleChange} />
											<Typography>Add Book in Free Trial</Typography>
										</Box>
									}
								/>
							</Grid>
							<Grid item xs={5}>
								<Box display={"flex"} justifyContent={"center"}>
									<CustomInput
										width={240}
										height={240}
										theme={theme}
										inputProps={{
											fileUploaderDescription: <>Drag and Drop File Here or Browse to Choose a File</>,
											uploaderWidth: 240
										}}
										options={{
											...defaultCustomInputOptions,
											genericApiService: genericService,
											inputData: {
												imageExtensionName: "png, jpeg, jpg",
												isImageFile: true,
												statePath: "bookCover",
												label: "Upload Book Cover",
												placeholder: "Upload Book Cover",
												type: CustomInputType.File,
												uploaderIconName: "camera",
												uploaderStateLoaderKey: "saveIsLoading",
												imageUrl: imageUrl,
												value: imageKey,
												error: null
											}
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box display={"flex"} justifyContent={"flex-end"}>
						<Button variant={"outlined"} disabled={isCreatingUpdatingDeletingBook}>
							Cancel
						</Button>
						{editMode && (
							<Button
								variant={"filled"}
								color={"danger"}
								style={{ marginLeft: "5px" }}
								onClick={() => setShowDeleteModal(true)}
							>
								Delete Book
							</Button>
						)}
						<Button
							variant={"filled"}
							color={"primary"}
							type="submit"
							style={{ marginLeft: "5px" }}
							disabled={!isValid || !dirty || isCreatingUpdatingDeletingBook}
							loading={isCreatingUpdatingDeletingBook}
						>
							Save
						</Button>
					</Box>
				</Card>
				<DeleteModal
					open={showDeleteModal}
					title={"Delete Book"}
					message={"Are you sure you want to delete this Book?"}
					onClose={() => setShowDeleteModal(false)}
					onDelete={() => dispatch(deleteBook({ id: book.id, cb: updateCreateDeleteCb }))}
					cancelBtnText={"No,Cancel"}
					deleteBtnText={"Yes, Delete"}
					deleteLoading={isCreatingUpdatingDeletingBook}
				/>
			</Box>
		</Modal>
	);
};

export default AddEditBook;
