import React, { FC, useMemo, useState } from "react";

import { Box, Collapse } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

import cn from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getFullState as getFullAuthState } from "store/features/Auth/auth.slice";

import {
	BannersSvg,
	ChevronDownSvg,
	Coupons,
	FileSvg,
	FolderSvg,
	HomeSvg,
	IconComments,
	IconStore,
	IconVideos,
	LocationSvg,
	MediaSvg,
	QuestionSvg,
	SettingsSvg,
	TagSvg,
	UserSvg
} from "assets/icons";

import Logo from "assets/images/logo.svg";

import { routes } from "core/constants";

import { Container, LogoContainer, NavItem, NavItemContainer } from "./styles";

const navList: (SideNavItem | SideNavItemNested)[] = [
	{
		id: "dashboard",
		title: "Dashboard",
		route: "/",
		icon: HomeSvg,
		isVisible: true
	},
	{
		id: routes.course.getElementKey(),
		title: "Courses",
		route: routes.course.getPath(),
		icon: FolderSvg,
		isVisible: true
	},
	{
		id: "shop",
		title: "Shop",
		icon: IconStore,
		isVisible: true,
		subItems: [
			{
				id: routes.books.getElementKey(),
				route: routes.books.getPath(),
				title: "Books",
				isVisible: true
			},
			{
				id: routes.gifts.getElementKey(),
				route: routes.gifts.getPath(),
				title: "Gifts",
				isVisible: true
			}
		]
	},
	{
		id: routes.tags.getElementKey(),
		title: "Tags",
		route: routes.tags.getElementKey(),
		icon: TagSvg,
		isVisible: true
	},
	{
		id: routes.questionbank.getElementKey(),
		title: "Question Bank",
		icon: QuestionSvg,
		isVisible: true,
		subItems: [
			{
				id: routes.questionbank.getElementKey(),
				route: routes.questionbank.getPath(),
				title: "Questions",
				isVisible: true
			},
			{
				id: routes.communityPool.getElementKey(),
				route: routes.communityPool.getPath(),
				title: "Community Pool",
				isVisible: true
			}
		]
	},
	{
		id: routes.manageStudents.getElementKey(),
		title: "Users",
		icon: UserSvg,
		isVisible: true,
		subItems: [
			{
				id: routes.manageStudents.getElementKey(),
				route: routes.manageStudents.getPath(),
				title: "Students",
				isVisible: true
			},
			{
				id: routes.manageAdmins.getElementKey(),
				route: routes.manageAdmins.getPath(),
				title: "Manage Admins",
				isVisible: true
			}
		]
	},
	// {
	// 	id: routes.reports.getElementKey(),
	// 	title: "Reports",
	// 	route: routes.reports.getPath(),
	// 	icon: ClipboardSvg
	// },
	// {
	// 	id: routes.mediaLibrary.getElementKey(),
	// 	title: "Media Library",
	// 	route: routes.mediaLibrary.getPath(),
	// 	icon: MediaSvg
	// },
	{
		id: routes.fileVault.getElementKey(),
		title: "File vault",
		route: routes.fileVault.getPath(),
		icon: MediaSvg,
		isVisible: true
	},
	{
		id: routes.manageLocations.getElementKey(),
		title: "Manage Locations",
		route: routes.manageLocations.getPath(),
		icon: LocationSvg,
		isVisible: true
	},
	{
		id: routes.coupons.getElementKey(),
		title: "Coupons",
		route: routes.coupons.getPath(),
		icon: Coupons,
		isVisible: true
	},
	{
		id: routes.notifications.getElementKey(),
		title: "Notifications",
		route: routes.notifications.getPath(),
		icon: FolderSvg,
		isVisible: true
	},
	{
		id: routes.discussionBoard.getElementKey(),
		title: "Discussion Board",
		route: routes.discussionBoard.getPath(),
		icon: IconComments,
		isVisible: true
	},
	{
		id: routes.banners.getElementKey(),
		title: "Business Banners",
		route: routes.banners.getPath(),
		icon: BannersSvg,
		isVisible: true
	},
	{
		id: routes.videos.getElementKey(),
		title: "Videos",
		route: routes.videos.getPath(),
		icon: IconVideos,
		isVisible: true
	},
	{
		id: routes.settings.getElementKey(),
		title: "Settings",
		icon: SettingsSvg,
		isVisible: true,
		subItems: [
			{
				id: routes.manageTutorial.getElementKey(),
				route: `${routes.manageTutorial.getPath()}?isIntro=true`,
				title: "Manage Tutorial",
				isVisible: true
			},
			{
				id: routes.manageSchools.getElementKey(),
				route: routes.manageSchools.getPath(),
				title: "Manage Schools",
				isVisible: true
			},
			{
				id: routes.catAvailability.getElementKey(),
				route: routes.catAvailability.getPath(),
				title: "CAT Availability",
				isVisible: true
			}
		]
	},
	{
		id: routes.reports.getElementKey(),
		title: "Reports",
		route: routes.reports.getPath(),
		icon: FileSvg,
		isVisible: true
	}
];

interface SideNavProps {}

interface NestedSideNavItemProps {
	Icon?: FC;
	title: string;
	subItems: SideNavItem[];
}

interface SideNavItem {
	id: string;
	route: string;
	icon?: FC;
	title: string;
	isVisible?: boolean;
}
interface SideNavItemNested {
	id: string;
	icon: FC;
	title: string;
	subItems: SideNavItem[];
	isVisible?: boolean;
}

const NestedSideNavItem: FC<NestedSideNavItemProps> = ({ Icon, title, subItems }) => {
	const [expanded, setExpanded] = useState(false);
	const history = useHistory();
	return (
		<NavItem onClick={() => setExpanded(!expanded)} className="navItem">
			<Box display="flex" flexDirection="column" width="100%">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
					className={cn("item-box", { highlight: expanded })}
				>
					<Box display="flex">
						{Icon && (
							<SvgIcon>
								<Icon />
							</SvgIcon>
						)}
						<div className="title">{title}</div>
					</Box>
					<SvgIcon style={{ transform: `rotate(${expanded ? "0deg" : "270deg"})` }} className="expand-chevron">
						<ChevronDownSvg />
					</SvgIcon>
				</Box>
				<Collapse in={expanded} unmountOnExit>
					{subItems.map(item =>
						item.isVisible ? (
							<NavItem
								route
								onClick={() => history.push(item.route)}
								className="navItem"
								key={`${item.id}-nested-inner`}
								highlight={history.location.pathname === item.route}
							>
								<div className="item-box">
									<div className="title nested">{item.title}</div>
								</div>
							</NavItem>
						) : (
							<></>
						)
					)}
				</Collapse>
			</Box>
		</NavItem>
	);
};

const SideNav: React.FC<SideNavProps> = () => {
	const history = useHistory();
	const {
		accessPerRoute: { accessAreas }
	} = useSelector(getFullAuthState);
	const { location } = history;

	const navItemList: (SideNavItem | SideNavItemNested)[] = useMemo(() => {
		if (!accessAreas) {
			return navList;
		}
		const list = [...navList].map(item => {
			const parentItem = { ...item };

			const findMatchingRouteIndex = accessAreas?.findIndex(
				area => (item as SideNavItem).route && (item as SideNavItem).route.includes(area.path)
			);
			if (findMatchingRouteIndex > -1) {
				parentItem.isVisible = accessAreas[findMatchingRouteIndex].canView;
			}
			if ((item as SideNavItemNested).subItems) {
				const _subItems = (item as SideNavItemNested).subItems.map(subItem => {
					const findMatchingRouteIndex = accessAreas?.findIndex(
						area => (subItem as SideNavItem).route && (subItem as SideNavItem).route.includes(area.path)
					);
					if (findMatchingRouteIndex > -1) {
						return {
							...subItem,
							isVisible: accessAreas[findMatchingRouteIndex].canView
						};
					}
					return subItem;
				});
				(parentItem as SideNavItemNested).subItems = _subItems;
			}
			return parentItem;
		});

		return list;
	}, [navList, accessAreas]);

	return (
		<Container>
			<LogoContainer>
				<img alt="logo" src={Logo} />
			</LogoContainer>
			<NavItemContainer>
				{navItemList.map(item => {
					const Icon = item.icon;
					const single = item as SideNavItem;
					const nested = item as SideNavItemNested;
					// If item have one-level depth
					if (single.route && single.isVisible) {
						const { id, route, title } = single;
						let highlight = false;
						if (route !== "/") {
							highlight = location.pathname.includes(route);
						} else if (location.pathname === "/" && route === location.pathname) {
							highlight = true;
						}
						return (
							<NavItem
								onClick={() => history.push(route)}
								className="navItem"
								key={`${id}-single`}
								route
								highlight={highlight}
							>
								<div className="item-box">
									{Icon && (
										<SvgIcon>
											<Icon />
										</SvgIcon>
									)}
									<div className="title">{title}</div>
								</div>
							</NavItem>
						);
					}

					// If item have child routes
					if ((item as SideNavItemNested).subItems) {
						const { id, subItems, title } = nested;
						return <NestedSideNavItem subItems={subItems} title={title} Icon={Icon} key={`${id}-nested`} />;
					}
				})}
			</NavItemContainer>
		</Container>
	);
};

export default SideNav;
