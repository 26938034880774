import { Box, FormControl, Typography } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: 13px;
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}

		div:last-child {
			color: #ffffff;
		}
	}
`;

export const HeaderContainer = styled.div`
	margin-bottom: 24px;
	display: flex;
	margin-top: 24px;
`;

export const Heading = styled(Typography)`
	opacity: 0.87;
	font-size: 18px;
	font-weight: bold;
	font-family: var(--poppins);
	line-height: 1.33;
	color: #ffffff;
`;

export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const THeaderWrapper = styled(Box)`
	background-color: #22252d;
	padding: 20px;
	border-radius: 4px;
`;

export const TFormControl = styled(FormControl)`
	flex: 1;
	> div {
		width: fit-content;
	}
`;
