import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, Divider, Typography, useTheme } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";
import { IVideoModel } from "@remar/shared/dist/models/video.model";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { formatDate, formatTime } from "@remar/shared/dist/utils/myAccountUtils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getFullState as getFullAuthState } from "store/features/Auth/auth.slice";
import { deleteVideo, getFullLessonVideosState, getVideos } from "store/features/LessonVideos/LessonVideos.slice";

import { routes } from "core/constants";

const VIDEOS_PER_PAGE = 10;
const Videos = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const theme = useTheme<IExtendedTheme>();
	const [deleteModalId, setDeleteModalId] = useState<null | number>(null);
	const [searchText, setSearchText] = useState("");
	const { isLoading, videos: videoState, isDeletingVideo } = useSelector(getFullLessonVideosState);
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getFullAuthState);

	const { videos, page, perPage, totalItems } = useMemo(() => {
		if (!videoState) {
			return { videos: [] as IVideoModel[], page: 1, perPage: VIDEOS_PER_PAGE, totalItems: 0 };
		}
		return {
			videos: videoState?.items || ([] as IVideoModel[]),
			page: videoState?.page,
			perPage: videoState?.perPage,
			totalItems: videoState?.totalItems
		};
	}, [videoState]);

	useEffect(() => {
		dispatch(getVideos({ page: 1, perPage: VIDEOS_PER_PAGE, searchKeyword: "" }));
	}, [dispatch]);

	const handleSearchBarChange = text => {
		dispatch(getVideos({ page: 1, perPage: VIDEOS_PER_PAGE, searchKeyword: text }));
	};
	const handleCloseDeleteModal = () => {
		setDeleteModalId(null);
	};
	return (
		<Wrapper heading={"Videos"}>
			<Card>
				<Box display={"flex"} justifyContent={"space-between"}>
					<Typography>Uploaded Videos</Typography>
					<SearchBarComponent
						inputValue={searchText}
						setInputValue={setSearchText}
						onChange={handleSearchBarChange}
						isDebounced={true}
						debounceTime={1000}
						placeHolder={"Type to filter by keywords"}
					/>
				</Box>
				<Box mt={3}>
					{isLoading ? (
						<ContentLoader />
					) : (
						<Box display={"flex"} flexDirection={"column"}>
							{videos.map((item, index) => (
								<Box key={item.id}>
									<Box display={"flex"} height={120}>
										<Box mr={2}>
											<img
												width={200}
												src={item.thumbnailUrl || "https://picsum.photos/200"}
												alt={`${item.name} preview image`}
											/>
										</Box>
										<Box display={"flex"} flexDirection={"column"} flexBasis={"40%"}>
											<Typography variant={"caption"} style={{ color: alpha(theme.palette.text.primary, 0.6) }}>
												Uploaded on {formatDate(item.createdAt!)} at {formatTime(item.createdAt!)}
											</Typography>
											<Typography>{item.name}</Typography>
										</Box>
										<Box ml={4}>
											<ActionMenu
												customMenuItems={[
													{
														label: "Edit",
														onClick: () => history.push(`${routes.videos.getPath()}/${item.id}`),
														visible: true,
														disabled: !canEdit
													},
													{
														label: "Delete",
														onClick: () => setDeleteModalId(item.id),
														visible: true,
														disabled: !canEdit
													}
												]}
											/>
										</Box>
									</Box>
									{index !== videos.length - 1 && (
										<Box my={1}>
											<Divider />
										</Box>
									)}
								</Box>
							))}
						</Box>
					)}
				</Box>
				<TablePagination
					count={totalItems}
					page={page}
					onChange={(_, page) => dispatch(getVideos({ page, perPage: VIDEOS_PER_PAGE, searchKeyword: "" }))}
					rowsPerPage={perPage}
				/>
			</Card>
			{deleteModalId && (
				<DeleteModal
					open={true}
					title={"Delete Video"}
					message={"Are you sure you want to delete this video?"}
					onClose={handleCloseDeleteModal}
					onDelete={() =>
						dispatch(
							deleteVideo({
								videoId: deleteModalId,
								cb: () => {
									handleCloseDeleteModal();
									dispatch(getVideos({ page: 1, perPage: VIDEOS_PER_PAGE, searchKeyword: "" }));
								}
							})
						)
					}
					cancelBtnText={"No,Cancel"}
					deleteBtnText={"Yes, Delete"}
					deleteLoading={isDeletingVideo}
				/>
			)}
		</Wrapper>
	);
};

export default Videos;
