import React, { Suspense } from "react";

import { Button, CssBaseline } from "@material-ui/core";
import { MuiThemeProvider, StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import ErrorBoundary from "@remar/shared/dist/components/ErrorBoundary";
import { ConfirmProvider } from "material-ui-confirm";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { store } from "store";
import { ThemeProvider } from "styled-components";
import "./index.css";

import NotificationsWrapper from "modules/Layout/NotificationsWrapper";

import Routes from "./core/routes";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme/default";

const generateClassName = createGenerateClassName({
	/* THIS!!! fixes the issue with wrong / duplicate styles due to improper classnames, e.g.:
	 * MuiBox-root-2 appearing in totally unrelated places and causing wrong style overwrites
	 */
	disableGlobal: true,
	seed: "ReMar"
});
const Loading: React.FC = () => <span>Loading...</span>;

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<NotificationsWrapper>
					<Suspense fallback={<Loading />}>
						{/* THIS button is very important, as it UNFUCKS the css/jss injection order.
				    Previously, after some dependency updates, most data-jss style tags from MUI used to be injected AFTER the custom theme, overwriting it.
						With this button here, we force styled-components to inject all its shit before
				 */}
						<Button style={{ display: "none", visibility: "hidden" }}></Button>
						<MuiThemeProvider theme={theme}>
							<StylesProvider injectFirst generateClassName={generateClassName}>
								<ThemeProvider theme={theme}>
									<ConfirmProvider>
										<CssBaseline />
										<React.Fragment>
											<Routes />
										</React.Fragment>
									</ConfirmProvider>
								</ThemeProvider>
							</StylesProvider>
						</MuiThemeProvider>
					</Suspense>
				</NotificationsWrapper>
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
