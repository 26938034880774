import React, { useMemo, useState } from "react";

import { useStyles } from "./styles";

import { getSanitizedText } from "../../utils/textUtils";
import { StyledCellText } from "../Table/styles";

type ReadMoreProps = {
	children: string;
	onClick?: () => void;
	maxCharCount?: number;
};

const ReadMore: React.FC<ReadMoreProps> = ({ children, onClick, maxCharCount = 100 }) => {
	const classes = useStyles();
	const sanitizedText = getSanitizedText(children);
	const isLongText = useMemo(() => sanitizedText.length > maxCharCount, [maxCharCount, sanitizedText.length]);
	const [showMore, setShowMore] = useState(!isLongText);

	return (
		<StyledCellText $wrapText>
			<span className={classes.preview} onClick={onClick}>
				{showMore ? sanitizedText : sanitizedText.substring(0, maxCharCount)}
			</span>
			{isLongText && (
				<span className={classes.viewMore} onClick={() => setShowMore(s => !s)}>
					...View {showMore ? "less" : "more"}
				</span>
			)}
		</StyledCellText>
	);
};

export default ReadMore;
