import React, { useMemo } from "react";

import InstitutePlus from "@remar/shared/dist/assets/icons/institutePlus.svg";
import InstituteAdd from "@remar/shared/dist/assets/icons/institutionAdd.svg";
// import MoneyBox from "assets/images/money-box.svg";
import StudentsImg from "@remar/shared/dist/assets/icons/students.svg";
import VideoImg from "@remar/shared/dist/assets/icons/video.svg";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";

import { useSelector } from "react-redux";

import { getFullState as getFullAuthState } from "store/features/Auth/auth.slice";

import DashboardBg from "assets/images/dashboardHeader.png";

import { routes } from "core/constants";

import {
	BackgroundImg,
	Container,
	Gradient,
	Header,
	Info,
	InfoContainer,
	InfoHead,
	// MetricsContainer,
	QuickLink,
	QuickLinksContainer,
	SectionContainer
} from "./styles";

interface HomeLinks {
	title: string;
	img: string;
	link: string;
	isVisible: boolean;
}
const Home = () => {
	const {
		user,
		accessPerRoute: { accessAreas }
	} = useSelector(getFullAuthState);

	const quickLinksData: HomeLinks[] = useMemo(() => {
		let list = [
			{
				title: "Add New Question",
				img: InstitutePlus,
				link: `${routes.questionbank.getPath()}/add-new-question`,
				isVisible: true
			},
			{
				title: "Add New Lesson",
				img: VideoImg,
				link: routes.createLesson.getPath(),
				isVisible: true
			},
			// {
			// 	title: "Manage Plans",
			// 	img: MoneyBox,
			// 	link: "/"
			// },
			{
				title: "Manage Students",
				img: StudentsImg,
				link: routes.manageStudents.getPath(),
				isVisible: true
			},
			{
				title: "Manage Locations",
				img: InstituteAdd,
				link: routes.manageLocations.getPath(),
				isVisible: true
			},
			{
				title: "Community Pool",
				img: InstituteAdd,
				link: routes.communityPool.getPath(),
				isVisible: true
			}
		];
		if (!accessAreas || accessAreas.length === 0) {
			return list;
		}
		list = list.map(item => {
			const _accessAreas = [...accessAreas.slice(1)];
			const areaIndex = _accessAreas.findIndex(area => item.link.includes(area.path));
			if (areaIndex > -1) {
				const { canView } = _accessAreas[areaIndex];
				return {
					...item,
					isVisible: canView
				};
			}
			return item;
		});
		return list;
	}, [accessAreas]);

	if (!user) {
		return <ContentLoader />;
	}
	return (
		<Container>
			<Header>Dashboard </Header>
			<Gradient />
			<BackgroundImg src={DashboardBg} />
			<InfoContainer>
				<InfoHead>Hi there!</InfoHead>
				<Info>What would you like to do today?</Info>
			</InfoContainer>
			<SectionContainer>
				<QuickLinksContainer>
					{quickLinksData.map(el =>
						el.isVisible ? (
							<QuickLink to={el.link} key={el.title}>
								<img alt={`${el.title} preview icon`} className="quicklinks-img" src={el.img} />
								<div className="title">{el.title}</div>
							</QuickLink>
						) : null
					)}
				</QuickLinksContainer>
				{/* <MetricsContainer>Metrics</MetricsContainer> */}
			</SectionContainer>
		</Container>
	);
};

export default Home;
