import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, Typography, useTheme } from "@material-ui/core";

import { ReactComponent as BookSvg } from "@remar/shared/dist/assets/icons/book.svg";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Book as IBook } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { useDispatch, useSelector } from "react-redux";

import { fetchBooks, getFullBookState } from "store/features/Books";

import CheckPermissions from "core/CheckPermissions";

import AddEditBook from "./AddEditBook";

import BookItem from "./BookItem";
import { ITEMS_PER_PAGE } from "./constants";

import { NoBooksWrapper } from "./styles";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Shop", key: 1 },
	{ title: "Books", key: 2 }
];

const NoBooks = () => {
	return (
		<Card>
			<NoBooksWrapper py={10}>
				<BookSvg />
				<Typography>There are no books yet</Typography>
			</NoBooksWrapper>
		</Card>
	);
};

const Books = () => {
	const [openAddEditBook, setOpenAddEditBook] = useState<IBook | null>(null);
	const dispatch = useDispatch();
	const theme = useTheme<IExtendedTheme>();
	const { books, isFetchingBooks } = useSelector(getFullBookState);

	useEffect(() => {
		if (!books) dispatch(fetchBooks({ include: ["courses"], page: 1, perPage: ITEMS_PER_PAGE }));
	}, [books]);

	const { bookList, page, perPage, totalItems } = useMemo(() => {
		if (!books) {
			return {
				bookList: [],
				page: 1,
				perPage: ITEMS_PER_PAGE,
				totalItems: ITEMS_PER_PAGE
			};
		}
		return {
			bookList: books.items,
			page: books.page,
			perPage: books.perPage,
			totalItems: books.totalItems
		};
	}, [books]);

	const handleEditBook = book => {
		setOpenAddEditBook(book);
	};

	return (
		<Wrapper
			heading={"Books"}
			breadcrumb={breadcrumb}
			actions={
				<Box>
					<CheckPermissions>
						<Button
							variant={"contained"}
							color={"primary"}
							onClick={() => setOpenAddEditBook({} as IBook)}
							disabled={isFetchingBooks}
						>
							Add Book
						</Button>
					</CheckPermissions>
				</Box>
			}
		>
			{isFetchingBooks ? (
				<ContentLoader height={700} />
			) : bookList.length ? (
				<Box display={"flex"} flexDirection="row" flexWrap="wrap">
					{bookList.map(book => (
						<BookItem key={book.id} {...book} onAction={() => handleEditBook(book)} />
					))}
				</Box>
			) : (
				<NoBooks />
			)}
			<TablePagination
				count={totalItems}
				page={page}
				onChange={(_, page) => dispatch(fetchBooks({ page: page, perPage: ITEMS_PER_PAGE, include: ["courses"] }))}
				rowsPerPage={perPage}
				bgColor={theme.palette.background.default}
			/>
			{!!openAddEditBook && <AddEditBook book={openAddEditBook} onClose={() => setOpenAddEditBook(null)} />}
		</Wrapper>
	);
};

export default Books;
