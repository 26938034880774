import * as Yup from "yup";

export const editBannerSchema = Yup.object().shape({
	trigger: Yup.string().required(),
	audience: Yup.string().required(),
	hourDuration: Yup.number().required(),
	minDuration: Yup.number().required(),
	isDismissible: Yup.boolean().required(),
	countDown: Yup.boolean().required(),
	messageText: Yup.string().required(),
	buttonSuccessText: Yup.string().required(),
	couponId: Yup.number().when("buttonAction", {
		is: "apply-coupon",
		then: s => s.required("You have to select coupon"),
		otherwise: s => s.optional()
	}),
	buttonAction: Yup.mixed()
		.oneOf(["apply-coupon", "dismiss", "open-link"])
		.required("You have to select Button action type"),
	buttonUrl: Yup.string().when("buttonAction", {
		is: "open-link",
		then: s => s.url("Button url should be valid").required("You have to enter Button URL"),
		otherwise: s => s.optional()
	})
});
