import React from "react";

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

export type RadioGroupOption = {
	value: string | number;
	label: string;
	disabled?: boolean;
};

type RadioGroupSelectionProps<T> = {
	name: string;
	options: RadioGroupOption[];
	onChange: (value: T) => void;
	value: T;
	defaultValue?: T;
};

const RadioGroupSelection = <T extends number | string | boolean>({
	options,
	value,
	onChange,
	defaultValue
}: RadioGroupSelectionProps<T>) => (
	<RadioGroup
		name={name}
		style={{ display: "flex", flexFlow: "row nowrap" }}
		value={value?.toString()}
		defaultValue={!value ? defaultValue?.toString() : undefined}
		onChange={({ target: { value: v } }) => {
			const res = parseInt(v, 10);
			onChange(isNaN(res) ? v : res);
		}}
	>
		{options?.map(o => (
			<FormControlLabel
				key={o.label}
				value={o.value.toString()}
				control={<Radio color="primary" disabled={o.disabled} />}
				label={o.label}
			/>
		))}
	</RadioGroup>
);

export default RadioGroupSelection;
