import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress } from "@material-ui/core";
import convertMinsToHrsMins from "@remar/shared/dist/utils/convertMinsToHrsMins";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { fetchAllCourses, getFullState } from "store/features/Course/course.slice";

import CheckPermissions from "core/CheckPermissions";
import { routes } from "core/constants";

import Card from "modules/Components/Card";

import { ButtonContainer, CardContainer, Container, ContentCards, Header, HeaderContainer } from "./styles";
import { CourseViewInterface } from "./types";

const Content = () => {
	const history = useHistory();
	const { courseFilters, courses, isLoading } = useSelector(getFullState);
	const [courseView, setCourseView] = useState<CourseViewInterface[]>([]);
	const dispatch = useDispatch();
	let filteredCourseActiveState: boolean | null = null;
	if (courseFilters.isActive.value === "true") {
		filteredCourseActiveState = true;
	} else if (courseFilters.isActive.value === "false") {
		filteredCourseActiveState = false;
	}

	useEffect(() => {
		dispatch(fetchAllCourses());
	}, []);

	const populateData = () => {
		const data: CourseViewInterface[] = [];

		courses?.forEach(el => {
			const { name, totalChapters, totalTimeLengthInMinutes, id, totalLessons, isActive } = el;
			const singleCourse: CourseViewInterface = {
				title: {
					key: "Title",
					subtitle: isActive ? "Published" : "Unpublished",
					value: name
				},
				content: [
					{ key: "Chapters", value: `${totalChapters}` },
					{ key: "Total Lessons", value: `${totalLessons}` },
					{ key: "Course Length", value: convertMinsToHrsMins(totalTimeLengthInMinutes) }
				],
				courseData: { isActive },
				imgSrc: el.mainImageUrl!,
				bottomComponent: (
					<ButtonContainer>
						<CheckPermissions>
							<Button
								variant="text"
								style={{ color: "#fff", fontWeight: "bold", marginRight: "5px" }}
								onClick={() => history.push(`${routes.courseAddEdit.getPath()}/${id}`)}
							>
								Edit Course
							</Button>
						</CheckPermissions>
						<Button
							variant="contained"
							color="primary"
							onClick={() => history.push(`${routes.course.getPath()}/${id}`)}
						>
							View Course
						</Button>
					</ButtonContainer>
				)
			};
			data.push(singleCourse);
		});
		setCourseView(data);
	};

	useEffect(() => {
		if (!!courses) {
			populateData();
		}
	}, [courses]);

	return (
		<Container>
			<HeaderContainer>
				<Header>Courses</Header>
				<Box className="button-container">
					<CheckPermissions>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								history.push(`${routes.courseAddEdit.getPath()}`);
							}}
						>
							Create New Course
						</Button>
					</CheckPermissions>
				</Box>
			</HeaderContainer>
			<ContentCards>
				{isLoading ? (
					<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</Box>
				) : (
					<>
						{courseView
							.filter(
								(el: CourseViewInterface) =>
									filteredCourseActiveState === null || el.courseData!.isActive === filteredCourseActiveState
							)
							.map((el: CourseViewInterface, index: number) => (
								<CardContainer key={`${el.title.key}-${index}`}>
									<Card
										width={544}
										title={el.title}
										content={el.content}
										imgSrc={el.imgSrc}
										bottomComponent={el.bottomComponent}
									/>
								</CardContainer>
							))}
					</>
				)}
			</ContentCards>
		</Container>
	);
};

export default Content;
