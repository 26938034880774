import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { School } from "@remar/shared/dist/models";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { AppThunk, RootState } from "store";

import { schoolsService } from "store/services";

import { emit } from "../notifications/notifications.slice";

export interface ManageSchoolsState {
	isLoading: boolean;
	page: number;
	perPage: number;
	schools: School[];
	allSchools: School[];
	totalItems: number;
	errorMessage: string;
}

const initialState: ManageSchoolsState = {
	isLoading: false,
	page: 1,
	perPage: 10,
	totalItems: 0,
	schools: [],
	allSchools: [],
	errorMessage: ""
};

export const manageSchoolsSlice = createSlice({
	name: "schools",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue
	}
});

export const fetchAllSchools = createAsyncThunk("manageSchools/fetchAllSchools", async (_, { dispatch }) => {
	try {
		const filters = {};
		filters["approved"] = true;

		const { items } = await schoolsService.find({
			findAll: true,
			orderBy: { createdAt: "DESC" },
			filters
		});
		dispatch(setStateValue({ key: "allSchools", value: items }));
	} catch (e) {
		return { error: e };
	}
});

export const fetchSchools = createAsyncThunk(
	"manageSchools/fetchSchools",
	async (options: { page?: number; perPage?: number; searchText?: string }, { dispatch, getState }) => {
		try {
			const { isLoading, page, perPage } = (
				getState() as {
					schools: ManageSchoolsState;
				}
			).schools;
			if (!isLoading) {
				dispatch(setLoading(true));
			}
			const { page: optPage, perPage: optPerPage, searchText: searchText } = options;
			let pageNumber = optPage || page;

			let filters = {};
			if (searchText) {
				filters = {
					$or: [{ name: { $ilike: `%${searchText}%` } }, { acronym: { $ilike: `%${searchText}%` } }]
				};
				pageNumber = 1;
			}

			filters["approved"] = true;

			const {
				page: newPage,
				perPage: newPerPage,
				items,
				totalItems
			} = await schoolsService.find({
				page: pageNumber,
				perPage: optPerPage || perPage,
				orderBy: { createdAt: "DESC" },
				filters
			});
			dispatch(setStateValue({ key: "page", value: newPage }));
			dispatch(setStateValue({ key: "perPage", value: newPerPage }));
			dispatch(setStateValue({ key: "schools", value: items }));
			dispatch(setStateValue({ key: "totalItems", value: totalItems }));
		} catch (e) {
			dispatch(setLoading(false));
			return { error: e };
		}
		dispatch(setLoading(false));
	}
);

export const deleteSchool =
	(id: number): AppThunk =>
	async dispatch => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		await schoolsService.delete({ filters: { id } });
		dispatch(emit({ message: "School has been deleted.", color: "success" }));

		dispatch(fetchSchools({}));
	};

export function getFullState(state: RootState): ManageSchoolsState {
	return state.schools;
}

export const { setLoading, failed, setStateValue } = manageSchoolsSlice.actions;

export default manageSchoolsSlice.reducer;
