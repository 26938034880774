import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@material-ui/core";

import { cloneDeep } from "lodash";

import ClozeDropDownQuestionPreview from "./ClozeDropDownQuestionPreview";
import DropDownTableQuestionPreview from "./DropDownTableQuestionPreview";
import HighlightTableQuestionPreview from "./HighlightTableQuestionPreview";
import HotspotHighlightQuestionPreview from "./HotspotHighlightQuestionPreview";
import MatrixSingleChoiceQuestionPreview from "./MatrixSingleChoiceQuestionPreview";
import MultipleChoiceQuestionPreview from "./MultipleChoiceQuestionPreview";
import MultipleResponseGroupQuestionPreview from "./MultipleResponseGroupQuestionPreview";
import SingleChoiceQuestionPreview from "./SingleChoiceQuestionPreview";
import {
	CaseStudyColumn,
	CaseStudyRow,
	CaseStudySubQuestionText,
	CaseStudyTab,
	CaseStudyTabContent,
	CaseStudyTabs,
	Page,
	Pages
} from "./style";

import { QuestionTypes } from "../../../constants";

import { getSanitizedHtmlText } from "../../../utils/serviceUtils";

interface UserAnswerQuestionIdDto {
	[id: number]: UserQuestionAnswerDto[];
}

interface UserQuestionAnswerDto {
	id: string;
	groupId?: string;
	order?: number;
}
//TODO: Refactor props drilling. Should not have to pass components in shared package as props to another member of the same package
const CaseStudyQuestionPreview = ({ question, DragAndDropQuestionPreview, IconEquals }) => {
	const {
		data: { tabs: parentTabs },
		caseStudyQuestions: unsortedQuestions
	} = question;

	const [activeTab, setActiveTab] = useState(0);
	const [activeQuestion, setActiveQuestion] = useState(0);
	const [activeQuestionForChange, setActiveQuestionForChange] = useState(0);
	const questions = useMemo(() => {
		const clone = cloneDeep(unsortedQuestions);
		clone.sort((a, b) => a.order - b.order);
		return clone;
	}, [unsortedQuestions]);
	const currentQuestion = questions[activeQuestion];
	const {
		data: { tabs: currentQuestionTabs }
	} = currentQuestion;

	const tabs = currentQuestionTabs || parentTabs;
	const ref = useRef<UserAnswerQuestionIdDto>({});
	const [userAnswers, setUserAnswers] = useState<UserQuestionAnswerDto[]>(ref.current[activeQuestion] ?? []);

	useEffect(() => {
		setUserAnswers(ref.current[activeQuestion] ?? []);
	}, [activeQuestion]);

	const setActiveQuestionIndex = (index: number) => {
		setActiveTab(0);
		setActiveQuestion(index);
	};

	useEffect(() => {
		setActiveQuestionIndex(0);
	}, [question.id]);

	useEffect(() => {
		ref.current[activeQuestion] = userAnswers;
	}, [userAnswers]);

	const getQuestion = useCallback(
		question => {
			const uniqueIdentifier = `case-study-question-${question.id}`;

			switch (question.typeId) {
				case QuestionTypes.MatrixSingleChoice:
					return <MatrixSingleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.MultipleChoiceSN:
				case QuestionTypes.MultipleChoiceSATA:
					return <MultipleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.MultipleResponseGroup:
					return <MultipleResponseGroupQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.DragAndDrop:
				case QuestionTypes.RationalScoringDragAndDrop:
					return <DragAndDropQuestionPreview question={question} key={uniqueIdentifier} IconEquals={IconEquals} />;
				case QuestionTypes.DropDownTable:
					return <DropDownTableQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.MatrixMultipleChoice:
					return <MatrixSingleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.ClozeDropDown:
				case QuestionTypes.RationalScoringDropDown:
					return <ClozeDropDownQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.SingleChoice:
					return <SingleChoiceQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.HotspotHighlight:
					return <HotspotHighlightQuestionPreview question={question} key={uniqueIdentifier} />;
				case QuestionTypes.HighlightTable:
					return <HighlightTableQuestionPreview question={question} key={uniqueIdentifier} />;
				default:
					return null;
			}
		},
		[DragAndDropQuestionPreview]
	);

	useEffect(() => {
		if (activeQuestion !== activeQuestionForChange) {
			setUserAnswers([]);
			setActiveQuestionIndex(activeQuestionForChange);
		}
	}, [activeQuestionForChange]);

	const handleChangeQuestion = questionIndex => {
		if (activeQuestion !== questionIndex) {
			setActiveQuestionForChange(questionIndex);
		}
	};

	return (
		<>
			<CaseStudyRow>
				<CaseStudyColumn>
					<CaseStudyTabs>
						{tabs.map(({ title, id }, tabIndex) => (
							<CaseStudyTab key={`tab-${id}`} active={tabIndex === activeTab} onClick={() => setActiveTab(tabIndex)}>
								{title}
							</CaseStudyTab>
						))}
					</CaseStudyTabs>
					{activeTab !== undefined && (
						<CaseStudyTabContent dangerouslySetInnerHTML={{ __html: tabs[activeTab].text }}></CaseStudyTabContent>
					)}
				</CaseStudyColumn>
				<CaseStudyColumn>
					<Box dangerouslySetInnerHTML={{ __html: currentQuestion.description }} />
					<CaseStudySubQuestionText>{getSanitizedHtmlText(currentQuestion.text)}</CaseStudySubQuestionText>
					{currentQuestion && getQuestion(currentQuestion)}
				</CaseStudyColumn>
			</CaseStudyRow>
			<Pages>
				<Page onClick={() => handleChangeQuestion(Math.max(activeQuestion - 1, 0))}>&lt;</Page>
				{questions?.map((_, questionIndex) => (
					<Page
						active={activeQuestion === questionIndex}
						key={`question-${questionIndex}`}
						onClick={() => handleChangeQuestion(questionIndex)}
					>
						{questionIndex + 1}
					</Page>
				))}
				<Page onClick={() => handleChangeQuestion(Math.min(activeQuestion + 1, questions.length - 1))}>&gt;</Page>
			</Pages>
		</>
	);
};

export default CaseStudyQuestionPreview;
