import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { validateFormAction as utilsValidateFormAction } from "@remar/shared/dist/utils/form/form.utils";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { hasTextUrl } from "@remar/shared/dist/utils/textUtils";
import { RootState } from "store";

import { Module, ModulesResponseDto, reportService } from "store/services/reports";

import { emit } from "../notifications/notifications.slice";

interface ReportsState {
	isChartLoading: boolean;
	isSummaryLoading: boolean;
	error: string;
	module: {
		isLoading: boolean;
		page: number;
		perPage: number;
		items: Module[];
		totalItems: number;
	};
	duration: {
		isLoading: boolean;
		page: number;
		perPage: number;
		items: Module[];
		totalItems: number;
	};
}

export const initialState: ReportsState = {
	isChartLoading: false,
	isSummaryLoading: false,
	error: "",
	module: {
		isLoading: false,
		page: 1,
		perPage: 10,
		items: [],
		totalItems: 0
	},
	duration: {
		isLoading: false,
		page: 1,
		perPage: 10,
		items: [],
		totalItems: 0
	}
};

export const reportsSlice = createSlice({
	name: "reports",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		setModuleOrDuration: (
			state,
			action: PayloadAction<{
				key: "module" | "duration";
				response: ModulesResponseDto;
			}>
		) => {
			const { key, response } = action.payload;
			const { page, perPage, items, totalItems } = response;
			state[key].page = page || 1;
			state[key].perPage = perPage || 10;
			state[key].items = items || [];
			state[key].totalItems = totalItems || 0;
		}
	}
});

export const generateReport = createAsyncThunk(
	"reports/generateReport",
	async (
		options: { period: { key: string; value: number; durationLabel: string }; report: number; cb: () => void },
		{ dispatch }
	) => {
		try {
			const response = await reportService.generateReport(options.report, options.period.value);
			if (!hasTextUrl(response)) throw Error();
			downloadURI(response, `${options.period.key} - ${options.period.durationLabel}`);
		} catch (_) {
			dispatch(emit({ message: "An error has occurred.", color: "error" }));
		} finally {
			options.cb();
		}
	}
);

export const fetchModules = createAsyncThunk("reports/fetchModules", async (_, { dispatch, getState }) => {
	try {
		const { isLoading } = (getState() as RootState).reports.module;
		if (!isLoading) dispatch(setStateValue({ key: "module.isLoading", value: true }));

		const response = await reportService.getModules();
		dispatch(setModuleOrDuration({ key: "module", response }));
	} catch (_) {
		dispatch(emit({ message: "An error has occurred.", color: "error" }));
	} finally {
		dispatch(setStateValue({ key: "module.isLoading", value: false }));
	}
});

export const fetchDurations = createAsyncThunk("reports/fetchDurations", async (_, { dispatch, getState }) => {
	try {
		const { isLoading } = (getState() as RootState).reports.duration;
		if (!isLoading) dispatch(setStateValue({ key: "duration.isLoading", value: true }));

		const response = await reportService.getDuration();
		dispatch(setModuleOrDuration({ key: "duration", response }));
	} catch (_) {
		dispatch(emit({ message: "An error has occurred.", color: "error" }));
	} finally {
		dispatch(setStateValue({ key: "duration.isLoading", value: false }));
	}
});

export const { validateForm, setStateValue, setModuleOrDuration } = reportsSlice.actions;

export const getFullState = (state: RootState): ReportsState => state.reports;

export default reportsSlice.reducer;
