export const getSanitizedText = (text: string): string => text?.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", " ");

export const insertSpaces = (text: string): string => {
	return text
		.replace(/([a-z])([A-Z])/g, "$1 $2")
		.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
		.replace(/(\d+)/g, (_, num) => " " + num + " ");
};

export const hasTextUrl = (text: string): boolean => {
	return new RegExp(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/).test(
		text
	);
};
