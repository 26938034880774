import React from "react";

import { Box, useTheme } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { BannerDto } from "store/features/Banners";

type MarkerProps = {
	status: BannerDto["status"];
};
const Marker = ({ status }: MarkerProps) => {
	const theme = useTheme<IExtendedTheme>();
	return (
		<Box
			width={5}
			height={5}
			borderRadius="50%"
			marginRight={1}
			display={"inline-block"}
			bgcolor={status === "active" ? theme.palette.colors.success[500] : theme.palette.colors.danger[500]}
		/>
	);
};

export default Marker;
