import React, { useEffect } from "react";

import { Box, Card } from "@material-ui/core";

import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { getActivityLogs, getFullState } from "store/features/ActivityLogs/ActivityLogs.slice";

import Activity from "./Activity";

const LOGS_PER_PAGE = 15;
const StudentActivity = () => {
	const dispatch = useDispatch();
	const { id: userId } = useParams<{ id: string }>();

	const { logs, isLoadingLogs } = useSelector(getFullState);

	useEffect(() => {
		if (userId) dispatch(getActivityLogs({ userId, perPage: LOGS_PER_PAGE }));
	}, [dispatch, userId]);

	if (isLoadingLogs) {
		return <ContentLoader />;
	}
	return (
		<Box width={"80%"}>
			<Card>
				{logs?.items.map(item => (
					<Box key={item.id} my={1}>
						<Activity log={item} />
					</Box>
				))}
				<TablePagination
					count={logs?.totalItems || LOGS_PER_PAGE}
					page={logs?.page || 1}
					onChange={(_, page) => {
						dispatch(getActivityLogs({ userId, page, perPage: LOGS_PER_PAGE }));
					}}
					rowsPerPage={LOGS_PER_PAGE}
				/>
			</Card>
		</Box>
	);
};

export default StudentActivity;
