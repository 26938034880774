import React, { useState } from "react";

import { Box, FormControl, MenuItem, makeStyles } from "@material-ui/core";

import { MatrixTableBody, MatrixTableHeader, StyledDropdown, StyledText } from "./style";

const useStyles = makeStyles(() => ({
	formControl: {
		verticalAlign: "middle"
	},
	list: {
		backgroundColor: "white",
		"& > li": {
			color: "#4d5058",
			fontSize: "0.9rem",
			fontWeight: "500"
		}
	}
}));

export const DropDown = ({ groupId, answerOptions }) => {
	const classes = useStyles();
	const [currentId, setCurrentId] = useState("");

	const handleChange = val => {
		setCurrentId(val.id);
	};
	return (
		<>
			<FormControl fullWidth={true} className={classes.formControl}>
				<>
					<StyledDropdown
						disableUnderline
						inTable
						value={currentId}
						onChange={event => handleChange({ id: event.target.value, groupId })}
						displayEmpty
						MenuProps={{
							classes: { list: classes.list },
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left"
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left"
							},
							getContentAnchorEl: null
						}}
					>
						<MenuItem value="" disabled>
							Select...
						</MenuItem>
						{answerOptions.map(({ id, text }) => (
							<MenuItem key={id} value={id}>
								{text}
							</MenuItem>
						))}
					</StyledDropdown>
				</>
			</FormControl>
		</>
	);
};

const DropDownTableQuestionPreview = ({
	question: {
		data: { answerOptionLabel, tableLabel, groups }
	}
}) => (
	<>
		<Box mt={2}>
			<MatrixTableHeader display="flex" maxWidth={"650px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<Box
						display="flex"
						borderRight={"solid 1px rgba(151, 151, 151, 0.3)"}
						flexDirection="row"
						width="50%"
						height={"100%"}
						alignItems="center"
						pl={"22px"}
					>
						<StyledText>{tableLabel}</StyledText>
					</Box>

					<Box height={"100%"} display="flex" flexDirection="row" width="50%">
						<Box
							justifyContent="center"
							display="flex"
							alignItems="center"
							height={"100%"}
							flexDirection="row"
							width={"100%"}
							borderRight="solid 1px rgba(151, 151, 151, 0.3)"
						>
							<StyledText>{answerOptionLabel}</StyledText>
						</Box>
					</Box>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody>
				{groups.map(({ text, answerOptions, id: gId }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
						height={"51px"}
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<Box
								display="flex"
								flexDirection="row"
								borderRight="solid 1px rgba(151, 151, 151, 0.3)"
								alignItems="center"
								height={"100%"}
								width="50%"
								pl={"22px"}
							>
								<StyledText>{text}</StyledText>
							</Box>
							<Box display="flex" flexDirection="row" width="50%">
								<DropDown groupId={gId} answerOptions={answerOptions} />
							</Box>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</Box>
	</>
);

export default DropDownTableQuestionPreview;
