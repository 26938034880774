import React from "react";

import { Box, Button, TextField, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import { ContentAccessTypes } from "@remar/shared/dist/models/courseChapter.model";
import { StyledCheckbox, TransparentButton } from "@remar/shared/dist/ui/Buttons/styles";
import { validatePositiveNumber } from "@remar/shared/dist/utils/serviceUtils/validators";

import { fetchAppleProductInfo, fetchStripePrice } from "store/features/Course/course.slice";

import { useAppDispatch } from "store/hooks";

import { RadioGroupSelection, SelectInput, SwitchInput, TextAreaInput } from "modules/Components/FormikFields";

import { ButtonContainer, SelectLocationWrapper } from "./styles";

import { LocationOptions, LockChapterDuration } from "../types";
import { lockChapterDurationOptions, lockChapterPeriodOptions } from "../utils";

const EditFields = (
	updateVal,
	name,
	calculateTimeLengthAutomatically,
	progressTrackingEnabled,
	updateChapterLength,
	chapterLength,
	disabledSaveBtn,
	cancel,
	save,
	updatedChapterLength?,
	excludedFromCourseRequirement?,
	contentAccessTypeId?,
	accessOptions?,
	targetLocationOptions?,
	locations?,
	selectedLocations?,
	searchLocations?,
	loadMoreLocations?,
	lockedChapter?,
	lockedChapterData?,
	targetLocation = LocationOptions.all,
	defaultAccessTypeId = ContentAccessTypes.Paid
) => {
	const dispatch = useAppDispatch();
	return (
		<>
			<Box display="flex" alignItems="center">
				<Box width={120} mr={3}>
					<Typography align="right">Chapter Title</Typography>
				</Box>
				<Box width={350}>
					<TextField
						fullWidth
						hiddenLabel
						InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
						inputProps={{ maxLength: 140 }}
						color="primary"
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							updateVal(event.target.value, "name");
						}}
						variant="filled"
						size="small"
						placeholder="Enter Chapter Title"
						value={name}
					/>
				</Box>
			</Box>
			<Box display="flex" alignItems="center" mt={4} mb={2}>
				<Box width={120} mr={3}>
					<Typography align="right">Chapter Length</Typography>
				</Box>
				<Box width={167} mr={2}>
					<TextField
						hiddenLabel
						type="number"
						InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
						color="primary"
						disabled={calculateTimeLengthAutomatically}
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							if ((event.target.value as number) <= 1000) updateChapterLength(event.target.value, "hours");
						}}
						onKeyUp={(event: React.KeyboardEvent) => {
							if (!validatePositiveNumber(event.key)) {
								event.preventDefault();
							}
						}}
						variant="filled"
						size="small"
						placeholder="Hours"
						value={
							chapterLength.hours === "" && updatedChapterLength ? updatedChapterLength.hours : chapterLength.hours
						}
					/>
				</Box>
				<Box width={167}>
					<TextField
						hiddenLabel
						disabled={calculateTimeLengthAutomatically}
						type="number"
						InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
						color="primary"
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							if ((event.target.value as number) < 60) updateChapterLength(event.target.value, "mins");
						}}
						variant="filled"
						size="small"
						onKeyUp={(event: React.KeyboardEvent) => {
							if (!validatePositiveNumber(event.key)) {
								event.preventDefault();
							}
						}}
						value={chapterLength.mins === "" && updatedChapterLength ? updatedChapterLength.mins : chapterLength.mins}
						placeholder="Minutes"
					/>
				</Box>
			</Box>
			<Box ml={18}>
				<StyledCheckbox
					checked={calculateTimeLengthAutomatically}
					onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
						updateVal(event.target.checked, "calculateTimeLengthAutomatically")
					}
				/>
				<span>Calculate Chapter Length Automatically</span>
			</Box>

			<Box ml={18}>
				<StyledCheckbox
					checked={progressTrackingEnabled}
					onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
						updateVal(event.target.checked, "progressTrackingEnabled")
					}
				/>
				<span>Enable Progress Tracking</span>
			</Box>
			<Box display={"flex"} alignItems={"center"}>
				<Box width={120} mr={3}>
					<Typography align="right">Access</Typography>
				</Box>
				<Box width={360} ml="10px">
					<RadioGroupSelection
						name={"contentAccessTypeId"}
						options={accessOptions}
						defaultValue={defaultAccessTypeId}
						onChange={v => updateVal(v, "contentAccessTypeId")}
						value={contentAccessTypeId}
					/>
				</Box>
			</Box>
			<Box display={"flex"} alignItems={"center"} mt={2}>
				<Box width={120} mr={3}>
					<Typography align="right">Location</Typography>
				</Box>
				<Box width={360} ml="10px">
					<RadioGroupSelection
						name={"targetLocation"}
						options={targetLocationOptions}
						defaultValue={targetLocation}
						onChange={v => updateVal(v, "targetLocation")}
						value={targetLocation}
					/>
				</Box>
			</Box>
			<Box display={"flex"} alignItems={"center"} mb={2}>
				<Box width={120} mr={3}></Box>
				<Box width={360} ml="10px">
					{targetLocation == LocationOptions.selectedLocation && (
						<SelectLocationWrapper>
							<AutocompleteFilter
								options={locations}
								value={selectedLocations || []}
								setValue={val => updateVal(val, "selectedLocations")}
								onTextInputChange={searchLocations}
								filterName="locations"
								loadMoreResults={loadMoreLocations}
							/>
						</SelectLocationWrapper>
					)}
				</Box>
			</Box>
			<Box display={"flex"} alignItems={"center"}>
				<Box width={120} mr={3}>
					<Typography align="right">Extra</Typography>
				</Box>
				<Box width={350}>
					<StyledCheckbox
						checked={excludedFromCourseRequirement}
						onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
							updateVal(event.target.checked, "excludedFromCourseRequirement")
						}
					/>
					<span>Exclude from course requirement </span>
				</Box>
			</Box>
			<Box mb={7}>
				<Box display={"flex"} alignItems={"center"}>
					<Box width={120} mr={3}>
						<Typography align="right">Lock Chapter</Typography>
					</Box>
					<Box width={350} display="flex" gridGap={20}>
						<SwitchInput
							name={"lockedChapter"}
							checked={lockedChapter}
							onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
								updateVal(event.target.checked, "lockedChapter")
							}
						/>
						<Box display="flex" justifyContent="flex-start" alignItems="center">
							<StyledCheckbox
								checked={lockedChapterData.isPaid}
								onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
									updateVal(
										{
											...lockedChapterData,
											isPaid: event.target.checked
										},
										"lockedChapterData"
									)
								}
							/>
							<Typography variant={"subtitle1"} color={"textPrimary"}>
								Pay to unlock
							</Typography>
						</Box>
					</Box>
				</Box>
				{lockedChapter && (
					<Box display={"flex"} alignItems={"center"} mb={2}>
						<Box width={120} mr={3}></Box>
						<Box width={360} ml="10px">
							<Box mb={2}>
								<Box mb={1}>
									<Typography variant={"subtitle1"} color={"textSecondary"}>
										Disclaimer
									</Typography>
								</Box>
								<TextAreaInput
									placeholder={"Enter the text for disclaimer"}
									value={lockedChapterData?.disclaimer}
									maxLength={250}
									onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
										updateVal(
											{
												...lockedChapterData,
												disclaimer: event.target.value
											},
											"lockedChapterData"
										)
									}
								/>
							</Box>
							<Box mb={2}>
								<Box mb={1}>
									<Typography variant={"subtitle1"} color={"textSecondary"}>
										Button Text
									</Typography>
								</Box>
								<TextField
									fullWidth
									hiddenLabel
									value={lockedChapterData?.buttonText}
									InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
									variant="filled"
									color={"primary"}
									placeholder={"Enter button text"}
									inputProps={{ maxLength: 40 }}
									onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
										updateVal(
											{
												...lockedChapterData,
												buttonText: event.target.value
											},
											"lockedChapterData"
										)
									}
								/>
							</Box>
							<Box mb={2}>
								<Box mb={1}>
									<Typography variant={"subtitle1"} color={"textSecondary"}>
										Duration
									</Typography>
								</Box>
								<Box display={"flex"}>
									{" "}
									<RadioGroupSelection
										name={"duration"}
										options={lockChapterDurationOptions}
										onChange={v =>
											updateVal(
												{
													...lockedChapterData,
													lifetimeAccess: v === LockChapterDuration.LIFETIME
												},
												"lockedChapterData"
											)
										}
										value={
											lockedChapterData?.lifetimeAccess ? LockChapterDuration.LIFETIME : LockChapterDuration.LIMITED
										}
									/>
									{!lockedChapterData?.lifetimeAccess && (
										<SelectInput
											options={lockChapterPeriodOptions}
											placeholder={"Select Time"}
											displayEmpty
											value={lockedChapterData?.period}
											onChange={e =>
												updateVal(
													{
														...lockedChapterData,
														period: e.target.value
													},
													"lockedChapterData"
												)
											}
										/>
									)}
								</Box>
							</Box>
							{lockedChapterData.isPaid && (
								<>
									<Box mb={2}>
										<Box mb={1}>
											<Typography variant={"subtitle1"} color={"textSecondary"}>
												Stripe Link for Price
											</Typography>
										</Box>
										<TextField
											fullWidth
											hiddenLabel
											value={lockedChapterData?.planId}
											InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
											variant="filled"
											color={"primary"}
											placeholder={"Paste Stripe link here"}
											onBlur={e => dispatch(fetchStripePrice(e.target.value))}
											onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
												updateVal(
													{
														...lockedChapterData,
														planId: event.target.value
													},
													"lockedChapterData"
												)
											}
										/>
									</Box>
									{lockedChapterData.isPriceLoading && (
										<Box display="flex" justifyContent="space-between">
											<Skeleton variant="text" height={40} width={100} />
											<Skeleton variant="text" height={40} width={60} />
										</Box>
									)}
									{lockedChapterData.price && (
										<Box display="flex" justifyContent="space-between">
											<Typography variant={"subtitle1"} color={"textPrimary"}>
												Chapter Price
											</Typography>
											<Typography>
												<strong>$ {lockedChapterData.price}</strong>
											</Typography>
										</Box>
									)}
									<Box mt={2}>
										<Box mb={1}>
											<Typography variant={"subtitle1"} color={"textSecondary"}>
												Apple Product key
											</Typography>
										</Box>
										<TextField
											fullWidth
											required
											hiddenLabel
											error={!!lockedChapterData?.appleKeyError}
											helperText={
												!!lockedChapterData?.appleKeyError ? `Error: ${lockedChapterData.appleKeyError}` : undefined
											}
											value={lockedChapterData?.applePlanId}
											inputProps={{ maxLength: 42, minLength: 22, pattern: "^com\\.remarnurse\\.mobile\\.\\d+$" }}
											InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
											variant="filled"
											color={"primary"}
											placeholder={"com.remarnurse.mobile."}
											onBlur={e => dispatch(fetchAppleProductInfo(e.target.value))}
											onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
												updateVal(
													{
														...lockedChapterData,
														applePlanId: event.target.value
													},
													"lockedChapterData"
												)
											}
										/>
									</Box>
									{lockedChapterData.isAppleKeyInfoLoading && (
										<Box display="flex" justifyContent="space-between">
											<Skeleton variant="text" height={40} width={100} />
											<Skeleton variant="text" height={40} width={60} />
										</Box>
									)}
									{lockedChapterData.applePlanName && (
										<Box display="flex" justifyContent="space-between">
											<Typography variant={"subtitle1"} color={"textPrimary"}>
												Product Name
											</Typography>
											<Typography>
												<strong>{lockedChapterData.applePlanName}</strong>
											</Typography>
										</Box>
									)}
								</>
							)}
						</Box>
					</Box>
				)}
			</Box>
			<ButtonContainer>
				<TransparentButton variant="text" onClick={cancel}>
					Cancel
				</TransparentButton>
				<Button
					variant={"contained"}
					color={"primary"}
					style={{ fontWeight: "bold" }}
					disabled={
						disabledSaveBtn ||
						name.trim().length < 2 ||
						(lockedChapter && (!lockedChapterData.buttonText || !lockedChapterData.disclaimer))
					}
					onClick={save}
				>
					Save
				</Button>
			</ButtonContainer>
		</>
	);
};

export default EditFields;
