import { NotificationTypeId } from "@remar/shared/dist/models";

type Conflict = {
	title: string;
	type: NotificationTypeId;
	message: string;
	description: string;
	cancelText?: string;
	submitText?: string;
};

const conflictData: Conflict[] = [
	{
		title: "Banners Conflict",
		type: NotificationTypeId.AdminGeneratedBanner,
		message: "Only one banner can be active at a time.",
		description:
			"You can continue and replace the *notification name* with the new one or go back and adjust the time to avoid banner overlap."
	},
	{
		title: "Push Conflict",
		type: NotificationTypeId.AdminGeneratedPush,
		message: "Only one push notification can be sent at a time.",
		description:
			"You can continue and replace the *notification name* with the new one or go back and adjust the time to avoid overlap."
	},
	{
		title: "Multiple Announcements",
		type: NotificationTypeId.AdminGeneratedAnnouncement,
		message: "Multiple announcements have the same time.",
		description:
			"You can continue and show multiple announcements in the same box or replace the *notification name* that is scheduled at the same time.",
		cancelText: "Replace",
		submitText: "Continue & show multiple"
	}
];

export const getContentByType = (type: NotificationTypeId | null): Conflict => {
	const conflict = conflictData.find(conflict => conflict.type === type);
	return conflict ?? ({} as Conflict);
};
