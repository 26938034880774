import { routes } from "core/constants";

export interface IAccess {
	canEdit: boolean;
	canView: boolean;
	accessAreas?: PermissionType[];
	isAccessReady?: boolean;
}
export const DEFAULT_ACCESS: IAccess = {
	canEdit: false,
	canView: true,
	accessAreas: [],
	isAccessReady: false
};
export interface PermissionType {
	path: string;
	canView: boolean;
	canEdit: boolean;
}
export enum UserTypeEnum {
	CustomerSupport = 7,
	ContentManager = 8
}
export interface IPermissions {
	[key: number]: {
		accessAreas: PermissionType[];
	};
}
export const permissions: IPermissions = {
	[UserTypeEnum.CustomerSupport]: {
		accessAreas: [
			{
				path: routes.courseAddEdit.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.course.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.lesson.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.createLesson.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.books.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.gifts.getPath(),
				canView: true,
				canEdit: false
			},
			// sub route of question bank, has to be before "routes.questionbank.getPath()" parent path
			{
				path: "/add-new-question",
				canView: false,
				canEdit: false
			},
			{
				path: routes.questionbank.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.communityPool.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.manageSubjects.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.manageStudents.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.fileVault.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.manageLocations.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.coupons.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.notifications.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.banners.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.videos.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.reports.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.manageSchools.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.manageTutorial.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.catAvailability.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.manageAdmins.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.discussionBoard.getPath(),
				canView: true,
				canEdit: true
			},
			// keep at the bottom
			{ path: "/", canEdit: true, canView: true }
		]
	},
	[UserTypeEnum.ContentManager]: {
		accessAreas: [
			{
				path: routes.courseAddEdit.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.course.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.lesson.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.createLesson.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.books.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.gifts.getPath(),
				canView: true,
				canEdit: false
			},
			// sub route of question bank, has to be before "routes.questionbank.getPath()" parent path
			{
				path: "/add-new-question",
				canView: true,
				canEdit: true
			},
			{
				path: routes.questionbank.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.communityPool.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.manageSubjects.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.manageStudents.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.fileVault.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.manageLocations.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.coupons.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.notifications.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.banners.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.videos.getPath(),
				canView: true,
				canEdit: true
			},
			{
				path: routes.reports.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.manageSchools.getPath(),
				canView: true,
				canEdit: false
			},
			{
				path: routes.manageTutorial.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.catAvailability.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.manageAdmins.getPath(),
				canView: false,
				canEdit: false
			},
			{
				path: routes.discussionBoard.getPath(),
				canView: true,
				canEdit: true
			},
			// keep at the bottom
			{ path: "/", canEdit: true, canView: true }
		]
	}
};
