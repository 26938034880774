import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { LessonDraft } from "@remar/shared/dist/models";

import { LessonDraftCreateDto, LessonDraftUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class LessonDraftsService extends BaseApiService<
	LessonDraft,
	LessonDraftCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	LessonDraftUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const lessonDraftsService = new LessonDraftsService(URLS.LESSON_DRAFTS);
