import React from "react";

import { ArcElement, Chart, Legend, Title } from "chart.js";

import { Doughnut as DoughnutCjs } from "react-chartjs-2";

Chart.register(ArcElement, Legend, Title);

const Doughnut = ({ options, data }) => <DoughnutCjs options={options} data={data} />;

export default Doughnut;
