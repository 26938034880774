import React, { useState } from "react";

import { Radio } from "@material-ui/core";

import { QuizQuestionAnswerOption, StyledText } from "./style";

const SingleChoiceQuestionPreview = ({
	question: {
		data: { answerOptions }
	}
}) => {
	const [userAnswer, setUserAnswer] = useState<number | null>(null);

	return answerOptions?.map(({ id, text }) => (
		<QuizQuestionAnswerOption key={id}>
			<Radio color="default" checked={userAnswer == id} onChange={() => setUserAnswer(id)} />
			<StyledText>{text}</StyledText>
		</QuizQuestionAnswerOption>
	));
};

export default SingleChoiceQuestionPreview;
