import React from "react";

import { Box, Modal, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import Button from "@remar/shared/dist/components/Button";
import {
	BowTieQuestionPreview,
	CaseStudyQuestionPreview,
	ClozeDropDownQuestionPreview,
	DragAndDropQuestionPreview,
	DropDownTableQuestionPreview,
	HighlightTableQuestionPreview,
	HotspotHighlightQuestionPreview,
	MatrixMultipleChoicePreview,
	MatrixSingleChoiceQuestionPreview,
	MultipleChoiceQuestionPreview,
	MultipleResponseGroupQuestionPreview,
	SingleChoiceQuestionPreview
} from "@remar/shared/dist/components/PreviewQuestion/Components";

import { QuestionTypes } from "@remar/shared/dist/constants";
import { Question } from "@remar/shared/dist/models";
import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils";

import { IconEquals } from "assets/icons";

import { useStyles } from "./styles";

type PreviewQuestionProps = {
	question: Question;
	onClose: () => void;
	onAddToMyPool?: () => void;
};

const PreviewQuestion = ({ question, onClose, onAddToMyPool }: PreviewQuestionProps) => {
	const classes = useStyles();

	const getQuestionType = question => {
		switch (question.typeId) {
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoicePreview question={question} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return <MultipleChoiceQuestionPreview question={question} />;
			case QuestionTypes.SingleChoice:
				return <SingleChoiceQuestionPreview question={question} />;
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceQuestionPreview question={question} />;
			case QuestionTypes.CaseStudy:
				return <CaseStudyQuestionPreview question={question} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightQuestionPreview question={question} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableQuestionPreview question={question} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupQuestionPreview question={question} />;
			case QuestionTypes.BowTie:
				return <BowTieQuestionPreview question={question} IconEquals={IconEquals} />;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return <DragAndDropQuestionPreview question={question} IconEquals={IconEquals} />;
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <ClozeDropDownQuestionPreview question={question} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableQuestionPreview question={question} />;
			default:
				return null;
		}
	};

	return (
		<Modal open onClose={onClose} className={classes.modal}>
			<div className={classes.scroll}>
				<div className={classes.content}>
					<div className={classes.header}>
						<div className={classes.title}>Question Preview</div>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					<div className={classes.questionContainer}>
						<div className={classes.row}>
							<div className={classes.col}>
								{" "}
								<Box mb={1} dangerouslySetInnerHTML={{ __html: question?.description }} />
								<Typography className={classes.questionNumber}>
									Question: {getSanitizedHtmlText(question?.text)}
								</Typography>
								{getQuestionType(question)}
							</div>
						</div>
					</div>
					{onAddToMyPool && (
						<div className={classes.modalFooter}>
							<Button
								variant="filled"
								color="primary"
								onClick={() => {
									onClose();
									onAddToMyPool();
								}}
							>
								Add to My Pool
							</Button>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default PreviewQuestion;
