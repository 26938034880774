import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILocation } from "@remar/shared/dist/models";
import { uniqBy } from "lodash";

import { RootState } from "../../index";

import { locationsService } from "../../services";
import { emit } from "../notifications/notifications.slice";

interface ManageLocationsState {
	loading: boolean;
	locations: Array<ILocation>;
	totalItems: number;
	page: number;
}

const initialState: ManageLocationsState = {
	loading: false,
	locations: [],
	totalItems: 0,
	page: 1
};

/**
 * Fetches locations from the API.
 *
 * @async
 * @function fetchLocations
 * @param {Object} options - The options to customize the fetch operation.
 * @param {number} options.perPage - The number of locations to fetch per page.
 * @param {number} options.page - The page number to fetch.
 * @param {string} options.searchKeyword - The search keyword to filter locations.
 * @param {Object} [options.filters] - Additional filters to apply when fetching locations.
 * @param {boolean} [options.infinite] - Indicates whether to fetch locations infinitely.
 * @returns {Promise} - A Promise that resolves with the reducer.
 */
export const fetchLocations = createAsyncThunk(
	"manageLocations/fetchLocations",
	async (
		{
			filters,
			page,
			perPage,
			searchKeyword
		}: {
			perPage: number;
			page: number;
			searchKeyword: string;
			filters?: Record<string, unknown>;
			infinite?: boolean;
		},
		{ rejectWithValue }
	) => {
		return await locationsService
			.find({
				page,
				perPage,
				searchKeyword,
				filters
			})
			.catch(rejectWithValue);
	}
);

/**
 * Adds seats to a location asynchronously.
 *
 * @param {Object} params - The parameters for adding seats.
 * @param {number} params.locationId - The ID of the location.
 * @param {number} params.numberOfSeats - The number of seats to add.
 * @param {Function} params.sideEffect - The side effect to perform after adding seats.
 * @return {Promise} - A promise that resolves after adding seats and executing the side effect.
 */
export const addSeats = createAsyncThunk(
	"manageLocations/addSeats",
	async (
		{
			locationId,
			numberOfSeats,
			sideEffect
		}: {
			locationId: number;
			numberOfSeats: number;
			sideEffect: () => void;
		},
		{ dispatch, rejectWithValue }
	) => {
		return await locationsService
			.addSeats(locationId, numberOfSeats)
			.then(() => {
				dispatch(emit({ color: "success", message: "Seats has been added successfully" }));
				sideEffect();
			})
			.catch(rejectWithValue);
	}
);

export const manageLocationsSlice = createSlice({
	name: "manageLocations",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchLocations.pending, state => {
			state.loading = true;
		});
		builder.addCase(fetchLocations.fulfilled, (state, { payload: { items, page, totalItems }, meta: { arg } }) => {
			state.locations = arg.infinite ? (uniqBy([...state.locations, ...items] as [], "id") as []) : items;
			state.totalItems = totalItems as number;
			state.page = page;
			state.loading = false;
		});
		builder.addCase(fetchLocations.rejected, state => {
			state.loading = false;
		});
		builder.addCase(addSeats.pending, state => {
			state.loading = true;
		});
		builder.addCase(addSeats.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(addSeats.rejected, state => {
			state.loading = false;
		});
	}
});

export const selectManageLocationsFullState = (state: RootState): ManageLocationsState => state.manageLocations;

export default manageLocationsSlice.reducer;
