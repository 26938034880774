import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";

export function populateData<State, InputsObjectDataType>(
	state: State,
	action: PayloadAction<InputsObjectDataType>
): void {
	const data = action.payload;
	for (const fieldName in data) {
		set(state as Record<string, unknown>, fieldName, data[fieldName]);
	}
}
