import { ContentAccessTypes } from "@remar/shared/dist/models";

import { CourseViewInterface } from "modules/App/View/Container/Courses/types";

type TextParamNameType = "text" | "label";

interface AccessOption extends Record<TextParamNameType, "Paid Only" | "Paid & Trial" | "Trial Only"> {
	value: CourseViewInterface["contentAccessTypeId"];
	disabled?: boolean;
}

export const getAccessOptions = (
	{ contentAccessTypeId }: Pick<CourseViewInterface, "contentAccessTypeId"> = {},
	textParamName: TextParamNameType = "label"
): Array<AccessOption> => {
	const opts = [
		{ value: ContentAccessTypes.Paid, [textParamName]: "Paid Only" },
		{ value: ContentAccessTypes.Mixed, [textParamName]: "Paid & Trial" },
		{ value: ContentAccessTypes.Trial, [textParamName]: "Trial Only" }
	] as AccessOption[];

	if (!contentAccessTypeId) return opts;

	if (contentAccessTypeId === ContentAccessTypes.Paid) {
		opts[1]["disabled"] = opts[2]["disabled"] = true;
	}

	if (contentAccessTypeId === ContentAccessTypes.Trial) {
		opts[0]["disabled"] = opts[1]["disabled"] = true;
	}

	return opts;
};
