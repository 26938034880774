import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FindResponse } from "@remar/shared/dist/api/baseApiService";
import { DiscussionBoardProps, File, IBaseState, Lesson, LessonComment } from "@remar/shared/dist/models";

import { uniqBy } from "lodash";

import { RootState } from "store";

import {
	LessonCommentsItemCreateDto,
	LessonCommentsItemUpdateDto,
	lessonCommentsService,
	lessonsService
} from "store/services";

type LessonType = Pick<Lesson, "name"> & {
	sections: Array<string>;
	chapters: Array<string>;
	courses: Array<string>;
	attachments: File;
};

interface LessonState extends IBaseState {
	currentLesson?: LessonType;
	lessons?: FindResponse<Lesson> | null;
	discussionBoard: DiscussionBoardProps;
}

const initialState: LessonState = {
	discussionBoard: {
		comments: [],
		isLoading: false,
		errorMessage: "",
		totalItems: 0,
		order: "MostRelevant",
		page: 1,
		perPage: 10,
		more: false,
		scrollTo: 0
	},
	lessons: null,
	isLoading: false,
	error: ""
};

export const createLessonComment = createAsyncThunk(
	"lesson/createLessonComment",
	async (data: LessonCommentsItemCreateDto, { rejectWithValue }) => {
		try {
			const res = await lessonCommentsService.create(data);
			return { res, parentId: data?.parentId };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "Cannot post comment.");
		}
	}
);

export const updateLessonComment = createAsyncThunk(
	"lesson/updateLessonComment",
	async (data: LessonCommentsItemUpdateDto, { rejectWithValue }) => {
		try {
			const res = await lessonCommentsService.update(data);
			return { res };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "Cannot update comment.");
		}
	}
);

export const deleteLessonComment = createAsyncThunk("lesson/deleteLessonComment", async (id: number) => {
	try {
		const res = await lessonCommentsService.delete({ filters: { id } });
		return { res };
	} catch (e) {
		console.error(e);
	}
});

export const getLessonComments = createAsyncThunk(
	"lesson/getLessonComments",
	async (
		{ lessonId, order, page: optPage, viewMore }: { page?: number; lessonId: number; viewMore: boolean; order: string },
		{ getState, rejectWithValue }
	) => {
		try {
			const { discussionBoard } = (getState() as RootState).lesson as LessonState;
			const res = await lessonCommentsService.find({
				...(viewMore && { page: optPage || discussionBoard.page }),
				filters: { lessonId, isTopLevel: true },
				include: ["user", "user.badges", "user.assignedUserTypes", "replies.votes", "votes.user", "replies.user"],
				orderBy: {
					...(order === "MostRecent" && { createdAt: "DESC" }),
					...(order === "MostRelevant" && { totalLike: "DESC", createdAt: "DESC" })
				}
			});
			return { res, viewMore };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const getAllComments = createAsyncThunk(
	"lesson/getAllComments",
	async ({ page = 1, courseId }: { page?: number; courseId?: number }, { rejectWithValue }) => {
		try {
			return await lessonCommentsService.find({
				page,
				perPage: 10,
				filters: { isTopLevel: true, courseId },
				include: [
					"user",
					"lesson",
					"user.badges",
					"user.assignedUserTypes",
					"replies.votes",
					"votes.user",
					"replies.user"
				],
				orderBy: { createdAt: "DESC" }
			});
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const lessonCommentsVoting = createAsyncThunk(
	"lesson-comments/lessonCommentsVoting",
	async ({ commentId, isLike }: { commentId?: number; isLike: boolean }, { rejectWithValue }) => {
		try {
			const res = await lessonCommentsService.commentVote({ commentId, isLike });
			return { ...res };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const deleteCommentVote = createAsyncThunk(
	"lesson-comment/deleteVote",
	async (
		{
			commentId,
			isLike,
			isTopLevel,
			voteId
		}: { commentId: number; voteId?: number; isLike: boolean | undefined; isTopLevel: boolean },
		{ rejectWithValue }
	) => {
		try {
			await lessonCommentsService.deleteCommentVote({ filters: { id: voteId } });
			return { commentId, voteId, isLike, isTopLevel };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const pinComment = createAsyncThunk(
	"lesson-comment/pinComment",
	async (
		{ commentId, isPinned, cb }: { commentId: number; isPinned: boolean; cb: () => void },
		{ rejectWithValue }
	) => {
		await lessonCommentsService.update({ filters: { id: commentId }, data: { isPinned } }).catch(rejectWithValue);
		cb();
	}
);

export const getLesson = createAsyncThunk(
	"lesson/getLesson",
	async ({ lessonId }: { lessonId: number }, { rejectWithValue }) => {
		return await lessonsService
			.findOne(lessonId, {
				include: ["lessonSections.section.chapter.course", "attachments"]
			})
			.catch(rejectWithValue);
	}
);

export const getAllLessons = createAsyncThunk(
	"lesson/getAllLessons",
	async ({ page, perPage = 10 }: { page: number; perPage?: number; infinite?: boolean }, { rejectWithValue }) => {
		return await lessonsService
			.find({ page, perPage, filters: { isActive: true }, include: ["lessonSections", "lessonSections.section"] })
			.catch(rejectWithValue);
	}
);

export const lessonSlice = createSlice({
	name: "lessonComments",
	initialState,
	reducers: {
		setCommentsOrder: (state, { payload }) => {
			state.discussionBoard.order = payload;
			state.discussionBoard.page = 1;
		},
		resetLessonComments: state => {
			state.discussionBoard.comments = [];
		},
		mockUpdateLessonComment: (state, { payload: { commentId, parentId, text } }) => {
			if (parentId) {
				const updatedReplyParent = state.discussionBoard.comments.find(({ id }) => id === parentId);
				if (updatedReplyParent) {
					const updatedReply = updatedReplyParent.replies.find(({ id }) => id === commentId);
					updatedReply!.text = text;
				}
			} else {
				const updatedComment = state.discussionBoard.comments.find(({ id }) => id === commentId);
				if (updatedComment) {
					updatedComment.text = text;
				}
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(createLessonComment.fulfilled, (state, { payload }) => {
				if (payload.parentId) {
					state.discussionBoard.comments
						?.find(comment => comment.id === payload.parentId)
						?.replies?.unshift(payload.res);
				} else {
					state.discussionBoard.comments.unshift(payload.res);
					state.discussionBoard.totalItems++;
				}
			})
			.addCase(createLessonComment.rejected, (state, { payload }) => {
				state.discussionBoard.errorMessage = payload;
			})
			.addCase(
				updateLessonComment.fulfilled,
				(
					state,
					{
						payload: {
							res: { raw }
						}
					}
				) => {
					if (raw[0].parentId) {
						const updatedReplyParent = state.discussionBoard.comments.find(({ id }) => id === raw[0].parentId);
						if (updatedReplyParent) {
							const updatedReply = updatedReplyParent.replies.find(({ id }) => id === raw[0].id);
							updatedReply!.text = raw[0].text;
						}
					} else {
						const updatedComment = state.discussionBoard.comments.find(({ id }) => id === raw[0].id);
						if (updatedComment) {
							updatedComment.text = raw[0].text;
						}
					}
				}
			)
			.addCase(updateLessonComment.rejected, (state, { payload }) => {
				state.discussionBoard.errorMessage = payload;
			})
			.addCase(deleteLessonComment.fulfilled, (state, { payload: { res } }) => {
				const { parentId } = res.raw[0];
				if (!parentId) {
					state.discussionBoard.totalItems--;
				}
			})
			.addCase(getLessonComments.pending, state => {
				state.discussionBoard.isLoading = true;
			})
			.addCase(
				getLessonComments.fulfilled,
				(
					state,
					{
						payload: {
							res: { items, more, page, totalItems },
							viewMore
						}
					}
				) => {
					if (viewMore) {
						const prevCommentsLength = state.discussionBoard.comments.length;
						state.discussionBoard.comments.push(...items);
						state.discussionBoard.page = page;
						state.discussionBoard.scrollTo = prevCommentsLength;
					} else {
						state.discussionBoard.comments = items;
						state.discussionBoard.scrollTo = 0;
					}
					state.discussionBoard.more = more;
					state.discussionBoard.totalItems = totalItems;
					state.discussionBoard.isLoading = false;
				}
			)
			.addCase(getLessonComments.rejected, (state, { payload }) => {
				state.discussionBoard.errorMessage = payload;
				state.discussionBoard.isLoading = false;
			})
			.addCase(
				lessonCommentsVoting.fulfilled,
				(state, { payload: { id, isTopLevel, totalDislike, totalLike, votes } }) => {
					state.discussionBoard.comments.forEach((element: LessonComment) => {
						if (isTopLevel) {
							if (element.id === id) {
								element.totalDislike = totalDislike;
								element.totalLike = totalLike;
								element.votes = votes;
							}
						} else {
							const replyIndex = element.replies.findIndex(x => x.id === id);
							if (element.replies.length && replyIndex > -1) {
								element.replies[replyIndex].totalDislike = totalDislike;
								element.replies[replyIndex].totalLike = totalLike;
								element.replies[replyIndex].votes = votes;
							}
						}
					});
				}
			)
			.addCase(deleteCommentVote.fulfilled, (state, { payload: { commentId, isLike, isTopLevel, voteId } }) => {
				state.discussionBoard.comments.forEach((element: LessonComment) => {
					if (isTopLevel) {
						if (element.id === commentId) {
							if (isLike) element.totalLike = element.totalLike - 1;
							if (!isLike) element.totalDislike = element.totalDislike - 1;
							element.votes = element.votes.filter(({ id }) => id !== voteId);
						}
					} else {
						const replyIndex = element.replies.findIndex(({ id }) => id === commentId);
						if (element.replies.length && replyIndex > -1) {
							if (isLike) element.replies[replyIndex].totalLike = element.replies[replyIndex].totalLike - 1;
							if (!isLike) element.replies[replyIndex].totalDislike = element.replies[replyIndex].totalDislike - 1;
							element.replies[replyIndex].votes = element.replies[replyIndex].votes.filter(x => x.id !== voteId);
						}
					}
				});
			})
			.addCase(getLesson.pending, state => {
				state.isLoading = true;
			})
			.addCase(getLesson.fulfilled, (state, { payload: { attachments, name, lessonSections } }) => {
				const getAddArray = (array: string[], value: string) => {
					return array.some(item => item === value) ? array : [...array, value];
				};
				const res = lessonSections.reduce((acc, item) => {
					acc.sections = !acc.sections ? [item.section.name] : getAddArray(acc.sections, item.section.name);
					acc.chapters = !acc.chapters
						? [item.section.chapter.name]
						: getAddArray(acc.chapters, item.section.chapter.name);
					acc.courses = !acc.courses
						? [item.section.chapter.course.name]
						: getAddArray(acc.courses, item.section.chapter.course.name);
					return acc;
				}, {} as Omit<LessonType, "name">);
				state.currentLesson = { attachments, name, ...res };
				state.isLoading = false;
			})
			.addCase(getLesson.rejected, (state, { payload }) => {
				state.error = payload.message;
				state.isLoading = false;
			})
			.addCase(getAllComments.pending, state => {
				state.discussionBoard.isLoading = true;
			})
			.addCase(getAllComments.fulfilled, (state, action) => {
				const { items, page, perPage, totalItems } = action.payload;
				state.discussionBoard.comments = items;
				state.discussionBoard.page = page;
				state.discussionBoard.perPage = perPage;
				state.discussionBoard.totalItems = totalItems;
				state.discussionBoard.isLoading = false;
			})
			.addCase(getAllComments.rejected, state => {
				state.discussionBoard.isLoading = false;
			})
			.addCase(getAllLessons.pending, state => {
				state.isLoading = true;
			})
			.addCase(getAllLessons.fulfilled, (state, action: PayloadAction<FindResponse<Lesson>>) => {
				const { meta, payload } = action;
				state.lessons = {
					items: meta.arg.infinite
						? (uniqBy([...state.lessons!.items, ...payload.items] as [], "id") as [])
						: payload.items,
					page: payload.page,
					perPage: payload.perPage
				} as FindResponse<Lesson>;
				state.isLoading = false;
			})
			.addCase(getAllLessons.rejected, state => {
				state.isLoading = false;
			});
	}
});

export const { setCommentsOrder, resetLessonComments, mockUpdateLessonComment } = lessonSlice.actions;

export const getCurrentLesson = ({ lesson }: RootState): LessonType | undefined => lesson.currentLesson;
export const getFullDiscussionBoardState = ({ lesson }: RootState): DiscussionBoardProps => lesson.discussionBoard;
export const getIsLoading = ({ lesson }: RootState): boolean => lesson.isLoading;
export const getAllLessonsState = ({ lesson }: RootState): FindResponse<Lesson> | null => lesson.lessons!;

export default lessonSlice.reducer;
