import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, FormControl, IconButton, MenuItem, Modal, Select, Tooltip } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import Button from "@remar/shared/dist/components/Button";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { RemarBadge } from "@remar/shared/dist/components/RemarBadge";

import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal";
import { Country, ILocation, UserSubscription } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import { formatAdminDate, formatDate, getBadgeTooltip } from "@remar/shared/dist/utils/myAccountUtils";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";

import { format, formatISO, subDays, subYears } from "date-fns";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { RootState } from "store";
import { fetchCountries, getFullState as getAuthFullState } from "store/features/Auth/auth.slice";
import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";
import { fetchLocations, selectManageLocationsFullState } from "store/features/ManageLocations/manageLocations.slice";
import {
	clearResetPwdLink,
	getAllStudents,
	getFullState,
	getTotalStudentsByCourse,
	resetUserPassword,
	unsubscribeStudent
} from "store/features/Students/students.slice";

import { UserModelDto } from "store/services";

import { UserGroupSvg, UsersSvg } from "assets/icons";
import CheckPermissions from "core/CheckPermissions";
import { routes } from "core/constants";

import { trimText } from "utils/trimText";

import AddCATExams from "./components/AddCATExams";
import AddDaysInSubscription from "./components/AddDaysInSubscription";
import ChangeEmail from "./components/ChangeEmail";
import DateRangeFilterModal from "./components/DateRangeFilterModal/DateRangeFilterModal";
import SubscriptionStatus from "./components/SubscriptionStatus";

import {
	PaidBlueIcon,
	PaidYellowIcon,
	StyledCalenderIcon,
	StyledLocationIcon,
	TrialGreenIcon,
	TrialPinkIcon
} from "./styles";

import ChangeSubscriptionModal from "../../../../Components/ChangeSubscriptionModal";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Students", key: 1 }
];

enum DATE_RANGE {
	last7 = "Last 7 Days",
	last30 = "Last 30 Days",
	last90 = "Last 90 Days",
	yearToDate = "Year to Date",
	lastYear = "Last Year",
	allTime = "All time",
	custom = "Custom"
}

interface IAddDays {
	fullName: string;
	userId: number;
	userEmail: string;
	subscription: UserSubscription;
}
interface IChangeEmail {
	fullName: string;
	userEmail: string;
	userId: number;
}

interface InfoBlock {
	title: string;
	text: number;
	customBgColor: string;
	IconSvg: SVGElement;
	percentageChange?: number;
}

const LOCATION_PER_PAGE = 20;
const AddDaysModal = ({
	setShowAddDaysModal,
	showAddDaysModal: { fullName, userId, userEmail, subscription }
}: {
	setShowAddDaysModal: React.Dispatch<React.SetStateAction<IAddDays | null>>;
	showAddDaysModal: IAddDays;
}) => {
	const classes = useStyles();
	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus
			disableAutoFocus
			open={true}
			onClose={() => setShowAddDaysModal(null)}
		>
			<AddDaysInSubscription
				fullName={fullName}
				userId={userId}
				userEmail={userEmail}
				subscription={subscription}
				fetchAccount={false}
				onClose={() => setShowAddDaysModal(null)}
			/>
		</Modal>
	);
};

const ManageStudents = () => {
	const dispatch = useDispatch();

	const history = useHistory();
	const [selectedCountries, setSelectedCountries] = useState<Array<Country>>([]);
	const [selectedLocations, setSelectedLocations] = useState<Array<ILocation>>([]);
	const [searchText, setSearchText] = useState("");
	const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
	const [showChangeEmailModal, setShowChangeEmailModal] = useState<IChangeEmail | null>(null);
	const [showAddDaysModal, setShowAddDaysModal] = useState<IAddDays | null>(null);
	const [showAddCATModal, setShowAddCATModal] = useState<IAddDays | null>(null);
	const [showCustomDateRangeFilter, setShowCustomDateRangeFilter] = useState<DATE_RANGE | string | null>(
		DATE_RANGE.allTime
	);
	const [unsubscribeUser, setUnsubscribeUser] = useState<{
		userId: number;
		userSubscriptionId: number;
	} | null>(null);

	const {
		isLoading,
		students,
		page,
		perPage,
		totalItems,
		isStudentsCountLoading,
		studentStats,
		resetUserPasswordLoadingId
	} = useSelector(getFullState);
	const { countries } = useSelector((state: RootState) => state.auth);
	const { locations, page: locationPage } = useSelector(selectManageLocationsFullState);
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getAuthFullState);
	const { courses } = useSelector(getFullCourseState);
	const countriesHasObj = useMemo(
		() =>
			countries?.reduce((a, i) => {
				const { id, code, name } = i;
				a[id] = { code, name };
				return a;
			}, {}),
		[countries]
	);

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(getAllStudents({ searchText, page: 1, selectedCountries, selectedLocations }));
		},
		[dispatch, selectedCountries, selectedLocations]
	);

	useEffect(() => {
		dispatch(getTotalStudentsByCourse({}));
	}, [dispatch]);

	useEffect(() => {
		dispatch(getAllStudents({ selectedCountries, selectedLocations }));
	}, [selectedCountries, selectedLocations, dispatch]);

	useEffect(() => {
		if (!courses) {
			dispatch(fetchAllCourses(true));
		}
	}, [courses, dispatch]);

	useEffect(() => {
		if (!countries.length) {
			dispatch(fetchCountries(0));
		}
	}, [countries, dispatch]);

	useEffect(() => {
		if (!locations.length) {
			dispatch(fetchLocations({ perPage: LOCATION_PER_PAGE, page: locationPage, searchKeyword: "", filters: {} }));
		}
	}, [locations, dispatch]);

	const dateRangeOptions = useMemo(() => {
		const options: { label: DATE_RANGE | string }[] = [
			{ label: DATE_RANGE.last7 },
			{ label: DATE_RANGE.last30 },
			{ label: DATE_RANGE.last90 },
			{ label: DATE_RANGE.yearToDate },
			{ label: DATE_RANGE.lastYear },
			{ label: DATE_RANGE.allTime },
			{ label: DATE_RANGE.custom }
		];
		if (showCustomDateRangeFilter?.includes("-")) {
			options.push({ label: showCustomDateRangeFilter });
		}

		return options;
	}, [showCustomDateRangeFilter]);

	const tableColumns: Array<IColumn<UserModelDto>> = useMemo(
		() => [
			{
				alignment: "left",
				width: 400,
				label: <ColumnHeader>Students</ColumnHeader>,
				Cell: ({ rowData }: { rowData: UserModelDto }) => {
					const { id, firstName, lastName, profileImageUrl, email, userShippingDetails, badges } = rowData;
					const fullName = [firstName, lastName].join(" ");
					const { createdAt, typeId } = badges[0] || {};
					const toolTipText = getBadgeTooltip(badges, createdAt);

					return (
						<StyledCellWrapper>
							<Avatar
								variant="rounded"
								fullName={fullName}
								style={{ cursor: "pointer" }}
								profileImageUrl={profileImageUrl!}
								onClick={() =>
									history.push({
										pathname: `${routes.manageStudents.getPath()}/${id}`,
										state: {
											studentInfo: rowData
										}
									})
								}
							/>
							<Box>
								<StyledCellText margin="5px 0 0 0">
									{fullName}
									{!!badges?.length && (
										<Tooltip title={toolTipText}>
											<IconButton size="small">
												<RemarBadge type={typeId} />
											</IconButton>
										</Tooltip>
									)}
								</StyledCellText>
								<StyledCellText margin="0 0 5px 0">{email}</StyledCellText>
								<StyledCellText margin="0 0 5px 0">
									Country:{" "}
									{userShippingDetails && countriesHasObj && countriesHasObj[userShippingDetails.countryId]?.name}
								</StyledCellText>
								<StyledCellText margin="0 0 5px 0">Phone Number: {userShippingDetails?.phoneNumber}</StyledCellText>
							</Box>
						</StyledCellWrapper>
					);
				},
				dataKey: "name"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subscription</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => (
					<StyledCellWrapper>
						<StyledCellText>
							<SubscriptionStatus subscriptions={subscriptions!} />
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "subscription"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Location</ColumnHeader>,
				Cell: ({ rowData: { isMainLocation, allowedLocations } }) => (
					<StyledCellWrapper>
						<StyledCellText>
							{isMainLocation ? (
								"Main"
							) : (
								<Box display={"flex"} flexDirection={"column"}>
									{(allowedLocations || []).map(loc => (
										<Box key={loc.id}>{loc.name}</Box>
									))}
								</Box>
							)}
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "location"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Enrolled Course</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => {
					const type = getCurrentSubscription(subscriptions).type;
					const allowedCourseName = type ? type.allowedCourses![0].name : "-";
					return (
						<StyledCellWrapper>
							<StyledCellText>{allowedCourseName.toUpperCase()}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "course"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Role</ColumnHeader>,
				Cell: ({}) => (
					<StyledCellWrapper>
						<StyledCellText>Student</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "role"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Enrollment Date</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => {
					return (
						<StyledCellWrapper>
							<StyledCellText>{createdAt && formatAdminDate(createdAt)}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "day"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subscription Start Date</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => {
					const { createdAt, data } = getCurrentSubscription(subscriptions);
					const subStartDate = data?.subscriptionStartDate ?? createdAt;
					return (
						<StyledCellWrapper>
							<StyledCellText>{subStartDate ? formatAdminDate(subStartDate) : "-"}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "startDate"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData: { subscriptions, firstName, lastName, id, email, isCATAvailable, resetPwdLink } }) => {
					const fullName = [firstName, lastName].join(" ");
					const subscription = getCurrentSubscription(subscriptions);
					const { type } = subscription;
					const { isTrial } = type ?? {};
					return (
						<ActionMenu
							customMenuItems={[
								{
									label: "Change Subscription",
									onClick: () => setSelectedUserId(id),
									visible: !!type,
									disabled:
										!canEdit ||
										(type && type.userSubscriptionTypeCategoryId !== UserSubscriptionTypeCategoriesEnum.Course)
								},
								{
									label: "Change Email",
									onClick: () => setShowChangeEmailModal({ fullName, userEmail: email, userId: id }),
									visible: true,
									disabled: !canEdit
								},
								{
									label: "Add Days",
									onClick: () =>
										setShowAddDaysModal({
											fullName,
											userId: id,
											userEmail: email,
											subscription
										}),
									visible: !!type && !isTrial,
									disabled: !canEdit
								},
								{
									label: "Add CAT exams",
									onClick: () => setShowAddCATModal({ fullName, userId: id, userEmail: email, subscription }),
									visible: !isTrial && isCATAvailable,
									disabled: !canEdit
								},
								{
									label: resetPwdLink ? "Copy Link" : "Reset Password",
									onClick: resetPwdLink
										? async () => {
												await navigator.clipboard.writeText(resetPwdLink).then(() => {
													dispatch(clearResetPwdLink(id));
												});
										  }
										: () => dispatch(resetUserPassword(id)),
									visible: true,
									disabled: !canEdit || id === resetUserPasswordLoadingId
								},
								{
									label: "Unsubscribe",
									onClick: () => setUnsubscribeUser({ userId: id, userSubscriptionId: subscription.id }),
									visible: !!type && !isTrial,
									disabled: !canEdit
								}
							]}
						/>
					);
				},
				dataKey: "menu"
			}
		],
		[countriesHasObj, dispatch, history, resetUserPasswordLoadingId]
	);

	const showChangeSubscriptionModal = useMemo(() => {
		if (!selectedUserId) return null;
		const selectedUser = students?.find(s => s.id === selectedUserId);
		if (!selectedUser) return null;
		const { subscriptions, firstName, lastName, id } = selectedUser;
		const fullName = [firstName, lastName].join(" ");
		const subscription = getCurrentSubscription(subscriptions);
		const { id: subscriptionId, hasExpired, isCancelled, type } = subscription;
		const { isTrial, allowedCourses } = type ?? {};
		const notAllowedCourses = allowedCourses
			? courses?.filter(course => course.id !== allowedCourses![0].id) || []
			: courses;
		return {
			fullName,
			userId: id,
			subscriptionId,
			hasExpired,
			isCancelled,
			isTrial,
			allowedCourses,
			notAllowedCourses,
			subscription
		};
	}, [courses, selectedUserId, students]);

	const infoBlockItems = useMemo(() => {
		return (
			!isStudentsCountLoading && studentStats
				? [
						{
							title: "Total Students",
							text: studentStats?.totalStudents,
							customBgColor: "hsla(213, 53%, 19%, 0.4)",
							IconSvg: UsersSvg
						},
						{
							title: "Recurring",
							text: studentStats?.totalRecurring,
							customBgColor: "hsla(280, 31%, 31%, 0.4)",
							IconSvg: UserGroupSvg
						},

						...studentStats?.courses.reduce((a: InfoBlock[], c, i) => {
							const {
								courseName,
								trialUsersCount,
								initialUsersCount,
								recurringUsersCount,
								totalPaidUsersPercentageChange,
								trialUsersPercentageChange
							} = c;
							a.push({
								title: `${trimText(courseName)} Paid`,
								text: initialUsersCount + recurringUsersCount,
								customBgColor: i % 2 ? "hsla(200, 75%, 21%, 0.4)" : "hsla(45, 58%, 19%, 0.4)",
								IconSvg: i % 2 ? PaidBlueIcon : PaidYellowIcon,
								percentageChange: totalPaidUsersPercentageChange ? Math.round(totalPaidUsersPercentageChange) : 0
							});
							a.push({
								title: `${trimText(courseName)} Trial`,
								text: trialUsersCount,
								customBgColor: i % 2 ? "hsla(309, 31%, 31%, 0.4)" : "hsla(154, 83%, 21%, 0.4)",
								IconSvg: i % 2 ? TrialPinkIcon : TrialGreenIcon,
								percentageChange: trialUsersPercentageChange ? Math.round(trialUsersPercentageChange) : 0
							});
							return a;
						}, [])
				  ]
				: undefined
		) as InfoBlock[] | undefined;
	}, [isStudentsCountLoading, studentStats]);

	const handleApplyFilterRange = (from, to) => {
		const options: {
			page?: number;
			perPage?: number;
			searchText?: string;
			selectedCountries?: Country[];
			selectedLocations?: ILocation[];
			from?: string;
			to?: string;
		} = {
			searchText,
			page: 1,
			selectedCountries,
			selectedLocations
		};

		if (from) {
			options.from = formatISO(from);
		}
		if (to) {
			options.to = formatISO(to);
		}

		dispatch(
			getAllStudents({
				...options,
				cb: () => {
					if (showCustomDateRangeFilter === DATE_RANGE.custom) {
						setShowCustomDateRangeFilter(`${formatDate(from)} - ${formatDate(to)}`);
					}
				}
			})
		);
		const metricOptions: { from?: string; to?: string } = {} as { from: string; to: string };
		if (from) {
			metricOptions.from = format(from, "M/d/yyyy");
		}
		metricOptions.to = to ? format(to, "M/d/yyyy") : format(new Date(), "M/d/yyyy");

		dispatch(getTotalStudentsByCourse(metricOptions));
	};

	const handleFilterChange = e => {
		const val = e.target.value;
		const today = new Date();
		switch (val) {
			case DATE_RANGE.last7: {
				setShowCustomDateRangeFilter(DATE_RANGE.last7);
				return handleApplyFilterRange(subDays(today, 7), null);
			}
			case DATE_RANGE.last30: {
				setShowCustomDateRangeFilter(DATE_RANGE.last30);
				return handleApplyFilterRange(subDays(today, 30), null);
			}
			case DATE_RANGE.last90: {
				setShowCustomDateRangeFilter(DATE_RANGE.last90);
				return handleApplyFilterRange(subDays(today, 90), null);
			}
			case DATE_RANGE.yearToDate: {
				setShowCustomDateRangeFilter(DATE_RANGE.yearToDate);
				return handleApplyFilterRange(subYears(today, 1), null);
			}
			case DATE_RANGE.lastYear: {
				setShowCustomDateRangeFilter(DATE_RANGE.lastYear);
				return handleApplyFilterRange(subYears(today, 2), subYears(today, 1));
			}
			case DATE_RANGE.custom: {
				return setShowCustomDateRangeFilter(DATE_RANGE.custom);
			}
			default: {
				setShowCustomDateRangeFilter(DATE_RANGE.allTime);
				return handleApplyFilterRange(null, null);
			}
		}
	};

	return (
		<>
			<WithTableContentLayout
				heading="Manage Students"
				breadcrumb={breadcrumb}
				isLoading={isLoading}
				actions={
					<Box display={"flex"} flexDirection={"row"}>
						<Box mx={1}>
							<FormControl hiddenLabel size="small" variant="filled">
								<Select
									style={{ height: "48px" }}
									disableUnderline
									value={showCustomDateRangeFilter}
									onChange={handleFilterChange}
									displayEmpty
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left"
										},
										getContentAnchorEl: null
									}}
									IconComponent={() => <StyledCalenderIcon />}
								>
									{dateRangeOptions.map(o => (
										<MenuItem key={o.label} value={o.label}>
											{o.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<CheckPermissions>
							<Button
								color="info"
								variant="filled"
								size="large"
								onClick={() => history.push(routes.manageStudents.subscriptionCancellations.getPath())}
							>
								Subscription Cancellations
							</Button>
						</CheckPermissions>
					</Box>
				}
				emptyState={<EmptyState description="No Students yet" />}
				infoBlockItems={infoBlockItems}
				onChangePage={page =>
					dispatch(
						getAllStudents({
							page,
							selectedCountries,
							selectedLocations,
							...(searchText && { searchText })
						})
					)
				}
				tableTitle="Student Accounts"
				filterBlock={
					<>
						<Box mx={1}>
							<AutocompleteFilter
								options={locations}
								value={selectedLocations}
								setValue={setSelectedLocations}
								filterName={"Locations"}
								filterIcon={<StyledLocationIcon />}
								noOptionsText={"No Location Selected"}
								loadMoreResults={() => {
									dispatch(
										fetchLocations({
											perPage: LOCATION_PER_PAGE,
											page: locationPage + 1,
											searchKeyword: "",
											filters: {},
											infinite: true
										})
									);
								}}
								onTextInputChange={text => {
									dispatch(
										fetchLocations({
											perPage: LOCATION_PER_PAGE,
											page: 1,
											searchKeyword: text,
											filters: {}
										})
									);
								}}
							/>
						</Box>
						<Box mx={1}>
							<AutocompleteFilter
								options={countries as Country[]}
								value={selectedCountries}
								setValue={setSelectedCountries}
								filterName={"Countries"}
								filterIcon={<Language htmlColor="#C5CEE0" />}
								noOptionsText={"No countries found"}
							/>
						</Box>
					</>
				}
				totalItems={totalItems}
				totalEntities={studentStats?.totalStudents}
				perPage={perPage}
				setSearchText={setSearchText}
				searchText={searchText}
				handleSearchBarChange={handleSearchBarChange}
				page={page}
				tableColumns={tableColumns}
				data={students || []}
			/>
			{!!showChangeEmailModal && (
				<ChangeEmail showChangeEmailModal={showChangeEmailModal} onClose={() => setShowChangeEmailModal(null)} />
			)}
			{!!showChangeSubscriptionModal && (
				<ChangeSubscriptionModal
					setShowChangeSubscriptionModal={setSelectedUserId}
					showChangeSubscriptionModal={showChangeSubscriptionModal}
				/>
			)}
			{!!showAddDaysModal && (
				<AddDaysModal setShowAddDaysModal={setShowAddDaysModal} showAddDaysModal={showAddDaysModal} />
			)}
			{!!showAddCATModal && <AddCATExams showAddCATModal={showAddCATModal} onClose={() => setShowAddCATModal(null)} />}
			{!!unsubscribeUser && (
				<DeleteModal
					open={!!unsubscribeUser}
					title="Unsubscribe student"
					message="Are you sure you want to unsubscribe this user from future renewals?"
					cancelBtnText="Cancel"
					deleteBtnText="Yes, unsubscribe"
					deleteLoading={isLoading}
					onClose={() => setUnsubscribeUser(null)}
					onDelete={() => {
						dispatch(unsubscribeStudent(unsubscribeUser)).then(() => setUnsubscribeUser(null));
					}}
				/>
			)}
			{showCustomDateRangeFilter === DATE_RANGE.custom && (
				<DateRangeFilterModal
					onClose={() => setShowCustomDateRangeFilter(null)}
					applyFilter={handleApplyFilterRange}
					isLoading={isLoading}
				/>
			)}
		</>
	);
};

export default ManageStudents;
