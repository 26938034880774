import React, { useEffect, useMemo } from "react";

import SharedStudentTestResults from "@remar/shared/dist/components/StudentTestResults";

import { pick } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	getFullState,
	getStudentAccount,
	getStudentTest,
	getStudentTests
} from "store/features/Students/students.slice";

import { routes } from "core/constants";

const StudentTestResults = () => {
	const { id: userId } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const history = useHistory();

	const { studentAccountInfo, studentTests, isLoading, loadingStudentTests } = useSelector(getFullState);

	const {
		location: {
			state: { studentInfo }
		}
	} = history;

	const { firstName, lastName } = useMemo(
		() => pick(studentAccountInfo || studentInfo || {}, ["firstName", "lastName"]),
		[studentAccountInfo, studentInfo]
	);

	useEffect(() => {
		userId && !studentAccountInfo && !studentInfo && dispatch(getStudentAccount(userId));
	}, [userId, studentAccountInfo, dispatch, studentInfo]);

	const breadcrumb = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Manage Students", key: 1, link: routes.manageStudents.getPath() },
			{ title: `${firstName} ${lastName}`, key: 2, link: `${routes.manageStudents.getPath()}/${userId}` },
			{
				title: `Test Results for ${firstName} ${lastName}`,
				key: 3,
				link: `${routes.manageStudents.getPath()}/${userId}/test-results`
			}
		],
		[firstName, lastName, userId]
	);

	return (
		<SharedStudentTestResults
			userId={userId}
			isLoading={isLoading}
			studentTests={studentTests}
			firstName={firstName}
			lastName={lastName}
			breadcrumb={breadcrumb}
			loadingStudentTests={loadingStudentTests}
			onBack={() => history.push(`${routes.manageStudents.getPath()}/${userId}`)}
			getStudentTests={args => dispatch(getStudentTests(args))}
			getStudentTest={args => dispatch(getStudentTest(args))}
		/>
	);
};

export default StudentTestResults;
