import { Box, Button, Card as MuiCard, Typography } from "@material-ui/core";
import { Pagination as MuiPagination } from "@material-ui/lab";

import styled, { css } from "styled-components";

export const Container = styled.div`
	margin: 60px;
`;

export const Card = styled(MuiCard)`
	display: flex;
	color: white;
	border-radius: 0;
	padding: 18px;

	div: first-child {
		width: 60%;
		padding: 0;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 12px;
		width: 15%;
		padding: 0;
	}
`;

export const Pagination = styled(MuiPagination)`
	li {
		button {
			color: white;
		}
	}
	margin-bottom: 36px;
`;

export const ContainerTableSection = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: start;
	margin: 0 -10px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	button {
		margin-left: 10px;
	}
`;

export const TableContent = styled(Box)`
	width: 62%;
`;

const disabledStyle = css`
	background-color: ${({ theme }) => theme.palette.colors.basic[700]};
`;
export const AddButton = styled(Button)`
	margin: 0 0 0 2.5px;
	font-family: var(--poppins);
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #4a99f9;
	background-color: transparent;
	&:hover {
		background: transparent;
	}
	svg {
		fill: #4a99f9;
		margin-right: 4px;
	}
	${({ disabled }) => (disabled ? disabledStyle : "")}
`;

export const MergeButton = styled(Button)`
	margin: 0 0 0 2.5px;
	font-family: var(--poppins);
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #23b34a;
	background-color: transparent;
	&:hover {
		background: transparent;
	}
	svg {
		fill: #23b34a;
		margin-right: 4px;
	}
	${({ disabled }) => (disabled ? disabledStyle : "")}
`;

export const DeleteButton = styled(Button)`
	margin: 0 0 0 2.5px;
	font-family: var(--poppins);
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ed5465;
	background-color: transparent;
	&:hover {
		background: transparent;
	}
	svg {
		fill: #ed5465;
		margin-right: 4px;
	}
	${({ disabled }) => (disabled ? disabledStyle : "")}
`;

export const RequestItemContainer = styled.div`
	background-color: #22252d;
`;
export const RequestContainer = styled.div`
	width: 33%;
	margin-left: 0.5rem;
`;

export const RedCircle = styled.span`
	width: 24px;
	height: 24px;
	margin: 0 12px 0 0;
	padding: 4px;
	background-color: #ed5465;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	opacity: 0.87;
`;

export const RequestMessage = styled.p`
	color: #ffffff;
	opacity: 0.87;
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
`;
export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const THeaderTitle = styled(Typography)`
	font-size: 15px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
	margin-left: 1rem;
`;

export const RequestTitle = styled.div`
	font-size: 15px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
	border-radius: 4px;
	background-color: #22252d;
	padding: 10px 12px;
	opacity: 0.87;
`;

export const RequestListTitle = styled.div`
	font-size: 12px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
	border-radius: 4px;
	background-color: #22252d;
	padding: 10px 12px;
	opacity: 0.87;
`;

export const ColumnHeader = styled(Typography)`
	font-size: 12px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.6);
	padding: 0;
	margin: 0;
`;
