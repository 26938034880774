import { Button as MuiButton, Input as MuiInput } from "@material-ui/core";
import styled from "styled-components";

export const LogoContainer = styled.div`
	height: 100px;
	img {
		width: 158px;
		height: 80px;
	}
`;

export const Header = styled.div`
	font-weight: 500;
	margin-top: 32px;
	margin-bottom: 80px;
	font-size: 30px;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
`;

export const Input = styled(MuiInput)`
	margin-bottom: ${props => (props.error ? 0 : "24px")};
	width: 100%;
	position: relative;
	&:before {
		display: none;
	}

	input {
		border-radius: 4px;
		background: ${({ theme }) => theme.palette.colors.basic[100]};
		height: 35px;
		padding: 10px;
		color: ${({ theme }) => theme.palette.colors.basic[800]};
	}
	fieldset {
		top: -2px !important;
		bottom: -1px !important;
	}
`;

export const Container = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
`;

export const LoginContainer = styled.div`
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	position: absolute;
	text-align: center;
	top: 15%;
	width: 400px;
	left: 8%;
	z-index: 3;
`;

export const VideoContainer = styled.video`
	min-width: 100%;
	min-height: 100vh;
	z-index: 1;
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: radial-gradient(
		circle at 11% 50%,
		#13161e,
		rgba(19, 22, 30, 0.95) 46%,
		rgba(19, 22, 30, 0.85) 56%,
		rgba(19, 22, 30, 0.67) 74%,
		rgba(19, 22, 30, 0.2) 104%
	);
	z-index: 2;
`;

export const Button = styled(MuiButton)`
	width: 340px;
`;

export const LoggedInText = styled.span`
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: rgba(255, 255, 255, 0.6);
`;

export const FooterCTA = styled.div`
	display: flex;
	margin-bottom: 32px;
`;

export const ForgetPassword = styled.div`
	font-size: 13px;
	margin-left: auto;
	margin-top: 13px;
`;
