import React, { useEffect, useMemo, useState } from "react";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { FilterTypes, IColumn, IColumnFilter } from "@remar/shared/dist/components/MaterialTable";
import { ColumnHeader, StyledCellText } from "@remar/shared/dist/components/Table/styles";
import { NotificationTypes, NotificationTypesLabels } from "@remar/shared/dist/constants";
import { EmptyState, TableWrapper, Wrapper } from "@remar/shared/dist/layouts";
import { BannerNotification, NotificationTypeId } from "@remar/shared/dist/models";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { getFullState as getFullAuthState } from "store/features/Auth/auth.slice";
import { fetchBanners, selectBannersFullState, updateBanner } from "store/features/Banners";
import { useAppSelector } from "store/hooks";

import EditBusinessBanners from "./EditBusinesssBanners";
import { Marker } from "./components";
import { mappedAudienceTypes, mappedNotificationTypes } from "./utils";

const BusinessBannersList = () => {
	const dispatch = useDispatch();
	const confirm = useConfirm();
	const [searchText, setSearchText] = useState("");
	const [typeFilter, setTypeFilter] = useState<string[]>([]);
	const [editBanner, setEditBanner] = useState<BannerNotification | null>(null);
	const { isLoading, banners, totalItems } = useAppSelector(selectBannersFullState);
	const {
		accessPerRoute: { canEdit }
	} = useAppSelector(getFullAuthState);

	useEffect(() => {
		dispatch(
			fetchBanners({
				page: 1,
				perPage: 10,
				filters: {
					notificationTypeId: [NotificationTypeId.SystemNotification]
				}
			})
		);
	}, [dispatch]);

	const handleUpdateBannerStatus = (id: number, status: boolean) => {
		confirm({
			title: "Business Banner",
			description: `Are you sure you want to ${status ? "Deactivate" : "Activate"} Banner?`,
			cancellationButtonProps: { variant: "contained", color: "secondary" },
			confirmationButtonProps: { variant: "contained" },
			confirmationText: `${status ? "Deactivate" : "Activate"}`
		}).then(() => {
			dispatch(
				updateBanner({
					id,
					data: { isActive: !status },
					cb: () => {
						dispatch(
							fetchBanners({
								page: 1,
								perPage: 10,
								filters: {
									notificationTypeId: [NotificationTypeId.SystemNotification]
								}
							})
						);
					}
				})
			);
		});
	};

	const tableColumns: Array<IColumn<BannerNotification>> = useMemo(
		() => [
			{
				align: "left",
				label: <ColumnHeader>Name</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { body } }) => <StyledCellText>{body}</StyledCellText>,
				dataKey: "name"
			},
			{
				align: "left",
				label: <ColumnHeader>Status</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { isActive } }) => (
					<StyledCellText>
						<Marker status={isActive ? "active" : "deactivated"} /> {isActive ? "Active" : "Deactivated"}
					</StyledCellText>
				),
				dataKey: "status"
			},
			{
				align: "left",
				label: <ColumnHeader>Type</ColumnHeader>,
				width: 200,
				Cell: ({
					rowData: {
						notificationType: { id }
					}
				}) => <StyledCellText>{mappedNotificationTypes[id]}</StyledCellText>,
				filter: {
					type: FilterTypes.MultipleCheckbox,
					data: {
						allFilters: {
							label: "All Question Types",
							checked: false
						},
						checkboxFilters: [
							{ id: NotificationTypes.System, label: NotificationTypesLabels.System, checked: false },
							{ id: NotificationTypes.Custom, label: NotificationTypesLabels.Custom, checked: false }
						].map(type =>
							typeFilter.includes("" + type.id)
								? {
										...type,
										checked: true
								  }
								: type
						)
					},
					width: "250px",
					onChange: value => {
						const typeId = value.allFilters.checked
							? value.checkboxFilters.map(({ id }) => "" + id)
							: value.checkboxFilters.filter(item => item.checked).map(({ id }) => "" + id);
						setTypeFilter(typeId);
					}
				} as unknown as IColumnFilter,
				dataKey: "type"
			},
			{
				align: "left",
				label: <ColumnHeader>Trigger</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { title } }) => <StyledCellText>{title}</StyledCellText>,
				dataKey: "trigger"
			},
			{
				align: "left",
				label: <ColumnHeader>Coupon</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { coupon } }) => <StyledCellText>{coupon?.name}</StyledCellText>,
				dataKey: "coupon"
			},
			{
				align: "left",
				label: <ColumnHeader>Audience</ColumnHeader>,
				width: 200,
				Cell: ({
					rowData: {
						notificationAudienceType: { id }
					}
				}) => <StyledCellText>{mappedAudienceTypes[id]}</StyledCellText>,
				dataKey: "audience"
			},
			{
				alignment: "left",
				width: 90,
				Cell: ({ rowData }) => (
					<ActionMenu
						customMenuItems={[
							{
								label: "Edit",
								onClick: () => {
									setEditBanner(rowData);
								},
								disabled: !canEdit,
								visible: true
							},
							{
								label: `${rowData.isActive ? "Deactivate" : "Activate"}`,
								onClick: () => handleUpdateBannerStatus(rowData.id, rowData.isActive),
								disabled: !canEdit,
								visible: true
							}
						]}
					/>
				),
				dataKey: "menu"
			}
		],
		[typeFilter, canEdit]
	);

	const handleSearchBarChange = (searchText: string) => {
		return dispatch(
			fetchBanners({
				searchKeyword: searchText,
				filters: {
					notificationTypeId: [NotificationTypeId.SystemNotification]
				}
			})
		);
	};

	return (
		<Wrapper heading="Business Banners">
			<TableWrapper
				tableTitle="Business Banners"
				data={banners}
				emptyState={<EmptyState description="No banners added" />}
				isLoading={isLoading}
				onChangePage={() => {}}
				page={1}
				perPage={10}
				tableColumns={tableColumns}
				totalItems={totalItems}
				searchText={searchText}
				setSearchText={setSearchText}
				handleSearchBarChange={handleSearchBarChange}
			/>
			{editBanner && (
				<EditBusinessBanners
					setEditBanner={setEditBanner}
					editBanner={editBanner}
					onClose={() => setEditBanner(null)}
				/>
			)}
		</Wrapper>
	);
};

export default BusinessBannersList;
