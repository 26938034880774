import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { QuestionType } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class QuestionTypesService extends BaseApiService<
	QuestionType,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const questionTypesService = new QuestionTypesService(URLS.QUESTION_TYPES);
