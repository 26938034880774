import React, { useMemo } from "react";

import { Box, Card, InputAdornment, Typography } from "@material-ui/core";
import { CancelRounded, CheckCircle, HelpRounded, Loop, PlayArrow, VisibilityRounded } from "@material-ui/icons";
import RemoveIcon from "@material-ui/icons/Remove";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { LessonVideoSegmentTypes, QuestionTypes } from "@remar/shared/dist/constants";
import { useDispatch, useSelector } from "react-redux";

import {
	getFullState,
	openVITPreview,
	setSelectedVideoSegment,
	setStateValue,
	validateForm
} from "store/features/CreateLesson/createLesson.slice";
import { InteractiveLessonFormFinalSegmentInputs } from "store/features/CreateLesson/models";
import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { useVideoActionsStyles } from "./videoActions.style";

const IconStyle = color => ({ color: color, width: "20px", height: "20px" });
const actionsConfig = [
	{
		fillColor: "#b5faad",
		Icon: (className, color) => <PlayArrow className={className} style={IconStyle(color)} />,
		label: "Start",
		type: LessonVideoSegmentTypes.Start
	},
	{
		fillColor: "#586179",
		Icon: (className, color) => <HelpRounded className={className} style={IconStyle(color)} />,
		label: "Question",
		type: LessonVideoSegmentTypes.Question
	},
	{
		fillColor: "#586179",
		Icon: (className, color) => <Loop className={className} style={IconStyle(color)} />,
		label: "Loop",
		type: LessonVideoSegmentTypes.Loop
	},
	{
		fillColor: "#b5faad",
		Icon: (className, color) => <CheckCircle className={className} style={IconStyle(color)} />,
		label: "Correct Response",
		type: LessonVideoSegmentTypes.Correct
	},
	{
		fillColor: "#f6aab2",
		Icon: (className, color) => <CancelRounded className={className} style={IconStyle(color)} />,
		label: "Incorrect Response",
		type: LessonVideoSegmentTypes.Incorrect
	}
];

export default () => {
	const classes = useVideoActionsStyles();
	const dispatch = useDispatch();
	const { interactiveLessonFormGroup } = useSelector(getFullState);
	const { interactiveBlocks } = interactiveLessonFormGroup.inputs;
	const { selectedSegmentType, selectedInteractiveBlockIndex } = interactiveLessonFormGroup.rawData;
	const selectedInteractiveBlock = interactiveBlocks[selectedInteractiveBlockIndex] ?? interactiveBlocks[0];
	const currentSegmentForm =
		selectedInteractiveBlock &&
		(selectedInteractiveBlock[selectedSegmentType] as InteractiveLessonFormFinalSegmentInputs);
	const { endMin, endSec, startMin, startSec } = currentSegmentForm;
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const actions = useMemo(() => {
		return selectedInteractiveBlock.question.typeId.value === QuestionTypes.Neutral
			? actionsConfig.filter(
					({ type }) => type !== LessonVideoSegmentTypes.Correct && type !== LessonVideoSegmentTypes.Incorrect
			  )
			: actionsConfig;
	}, [selectedInteractiveBlock.question.typeId.value]);

	return (
		<Box mt="24px">
			<Card className={classes.card}>
				<Box display="flex" mx="16px" mt="8px">
					{actions.map(({ Icon, fillColor, label, type }) => {
						const isSelected = selectedSegmentType === type;
						return (
							<Box
								key={`createLesson-videoActions-${type}`}
								className={classes.actionContainer + (isSelected ? " selected" : "")}
								display="flex"
								alignItems="center"
								mr="16px"
								onClick={() => !isSelected && dispatch(setSelectedVideoSegment(type))}
							>
								{Icon(classes.actionIcon, fillColor)}
								<Typography className={classes.actionLabel}>{label}</Typography>
							</Box>
						);
					})}
					<Box
						className={classes.actionContainer}
						display="flex"
						alignItems="center"
						mr="16px"
						onClick={() => dispatch(openVITPreview({ validate: true }))}
					>
						<VisibilityRounded className={classes.actionIcon} style={IconStyle("#586179")} />
						<Typography className={classes.actionLabel}>Preview</Typography>
					</Box>
				</Box>
				<Box mx="16px" my="16px" display="flex" className={classes.toolsContainer}>
					{startMin && (
						<>
							<CustomInput
								width={"110px"}
								className={"tools-input"}
								theme={theme}
								InputProps={{
									endAdornment: <InputAdornment position="end">min</InputAdornment>
								}}
								options={{ ...defaultCustomInputOptions, inputData: startMin }}
							/>
							<Box ml={1} mr={1}>
								<Typography className={classes.fromToColon}>:</Typography>
							</Box>
							<CustomInput
								width={"110px"}
								className={"tools-input"}
								theme={theme}
								InputProps={{
									endAdornment: <InputAdornment position="end">sec</InputAdornment>
								}}
								options={{ ...defaultCustomInputOptions, inputData: startSec }}
							/>
						</>
					)}
					{endMin && (
						<>
							{startMin && (
								<Box ml={1} mr={1} mt={1}>
									<RemoveIcon fontSize="small" className={classes.removeIcon} />
								</Box>
							)}
							<CustomInput
								width={"110px"}
								className={"tools-input"}
								theme={theme}
								InputProps={{
									endAdornment: <InputAdornment position="end">min</InputAdornment>
								}}
								options={{ ...defaultCustomInputOptions, inputData: endMin }}
							/>
							<Box ml={1} mr={1}>
								<Typography className={classes.fromToColon}>:</Typography>
							</Box>
							<CustomInput
								width={"110px"}
								className={"tools-input"}
								theme={theme}
								InputProps={{
									endAdornment: <InputAdornment position="end">sec</InputAdornment>
								}}
								options={{ ...defaultCustomInputOptions, inputData: endSec }}
							/>
						</>
					)}
				</Box>
			</Card>
		</Box>
	);
};
