import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	CustomInputType,
	DefaultValueGetter,
	DefaultValueGettersObject
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import { FormStateModel } from "@remar/shared/dist/utils/form/form.model";
import {
	createForm,
	getPopulateInputsAction,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";

import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { RootState } from "store";
import { schoolsService } from "store/services";

import { v4 as uuid } from "uuid";

import { AddSchoolFormInputs, AddSchoolFormRawData } from "./models";

import { fetchSchools } from "../ManageSchools/manageSchools.slice";
import { emit } from "../notifications/notifications.slice";
export interface AddSchoolState {
	isLoading: boolean;
	errorMessage: string;
	addNewSchoolForm: FormStateModel<AddSchoolFormInputs, AddSchoolFormRawData, {}>;
}

const getSchoolForm = () => ({
	id: { type: CustomInputType.Number },
	name: {
		label: "Type school name",
		placeholder: "Type school name",
		type: CustomInputType.Text,
		defaultValue: "",
		validations: { maxLength: 5000, required: true }
	},
	acronym: {
		label: "Type your acronym here",
		placeholder: "Type your acronym here",
		type: CustomInputType.Text,
		defaultValue: "",
		validations: { maxLength: 1000, required: true }
	}
});

export const initialState: AddSchoolState = {
	isLoading: false,
	errorMessage: "",
	addNewSchoolForm: createForm<AddSchoolFormInputs, AddSchoolFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "addNewSchoolForm",
		inputs: {
			mergeRequestId: {
				selectOptions: [],
				label: "Select Existing School",
				placeholder: "Select Existing School",
				defaultValue: 0,
				type: CustomInputType.Chips,
				validations: { required: true, isArray: true }
				// type: CustomInputType.Select
			},
			...getSchoolForm(),
			schools: [{ ...getSchoolForm() }]
		}
	})
};

const defaultValueGetters: DefaultValueGettersObject = {
	uuidV4: (() => uuid()) as DefaultValueGetter
};
const utilsResetState = getResetState<AddSchoolState>(initialState);

export const save = createAsyncThunk(
	"addSchool/save",
	async ({ success }: { success: () => void }, { dispatch, getState }) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const data = (getState() as { addSchool: AddSchoolState }).addSchool.addNewSchoolForm.rawData;
			if (data) {
				console.log(data, "data");
				const { name = "", acronym = "", id = "" } = data;
				if (id) {
					await schoolsService.update({ filters: { id }, data: { name, acronym, approved: true } });
					dispatch(emit({ message: "School has been updated.", color: "success" }));
				} else {
					await await schoolsService.create({ name, acronym, approved: true });
					dispatch(emit({ message: "School has been added.", color: "success" }));
				}
			}
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
		} finally {
			await dispatch(setStateValue({ key: "addSchool", value: initialState.addNewSchoolForm }));
			dispatch(fetchSchools({}));
			dispatch(setStateValue({ key: "isLoading", value: false }));
			dispatch(setStateValue({ key: "addNewSchoolForm.rawData.name", value: "" }));
			dispatch(setStateValue({ key: "addNewSchoolForm.rawData.acronym", value: "" }));
			dispatch(setStateValue({ key: "addNewSchoolForm.rawData.id", value: "" }));

			success();
		}
	}
);

const utilsPopulateInputs = getPopulateInputsAction<AddSchoolState>({ defaultValueGetters });
export const addSchoolSlice = createSlice({
	name: "addNewSchoolForm",
	initialState,
	reducers: {
		populateInputs: utilsPopulateInputs,
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		resetState: utilsResetState
	}
});

export function getFullState(state: RootState): AddSchoolState {
	return state.addSchool;
}

export const { setLoading, failed, setStateValue, validateForm, populateInputs } = addSchoolSlice.actions;

export default addSchoolSlice.reducer;
