import React, { useState } from "react";

import { Box, InputAdornment, InputLabel, TextField, useTheme } from "@material-ui/core";
import { Today } from "@material-ui/icons";

import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { format } from "date-fns";

import { IExtendedTheme } from "../../../theme/default";
import { DatePicker, DatePickerProps } from "../../DatePicker";

type DatePickerComponentProps = DatePickerProps & {
	name: string;
	value: Record<string, ParsableDate>;
	onChange: (d: Date) => void;
};

const DatePickerComponent = ({ name, label, value, onChange }: DatePickerComponentProps) => {
	const theme = useTheme<IExtendedTheme>();
	const [isDateOpen, setIsDateOpen] = useState(false);

	return (
		<Box display={"flex"} flexDirection={"column"}>
			<Box mb={1}>
				<InputLabel shrink>{label}</InputLabel>
			</Box>
			<DatePicker
				onClose={() => setIsDateOpen(prevState => !prevState)}
				open={isDateOpen}
				name={name}
				autoOk
				format="MM/dd/yyyy"
				value={value[name]}
				minDate={name === "endDate" ? value.startDate : undefined}
				maxDate={name === "startDate" ? value.endDate : new Date()}
				disableToolbar
				orientation="portrait"
				openTo="date"
				onBlur={() => {}}
				onChange={onChange}
				TextFieldComponent={() => (
					<TextField
						value={format((value[name] as number) || new Date(), "MM/dd/yyyy")}
						disabled={false}
						placeholder="MM/DD/YYYY"
						color="primary"
						InputProps={{
							style: {
								cursor: "pointer",
								color: theme.palette.text.primary,
								backgroundColor: theme.palette.background.default,
								height: 50,
								borderRadius: 4,
								padding: 10,
								width: "100%",
								border: `1px dashed ${theme.palette.colors.basic[700]}`
							},
							disableUnderline: true,
							endAdornment: (
								<InputAdornment position="start">
									<Today />
								</InputAdornment>
							),
							onClick: () => setIsDateOpen(prevState => !prevState),
							readOnly: true
						}}
					/>
				)}
			/>
		</Box>
	);
};

export default DatePickerComponent;
