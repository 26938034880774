import React, { useState } from "react";

import { Box, Card, CircularProgress } from "@material-ui/core";
import { LessonTypes } from "@remar/shared/dist/constants";
import { useDispatch, useSelector } from "react-redux";
import { Subject } from "rxjs";

import {
	deleteVideo,
	getFullState,
	setStateValue,
	uploadVideo,
	validateForm
} from "store/features/CreateLesson/createLesson.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import { VideoUploader } from "../VideoUploader";

export const VideoBasics = () => {
	const { isIntro, isLoading, lesson, newlyUploadedMainVideo, newlyUploadedTrailerVideo, videoBasicsForm } =
		useSelector(getFullState);
	const { videoId: mainVideoIdInput, trailerId: trailerIdInput } = videoBasicsForm.inputs;
	const dispatch = useDispatch();
	const [mainVideoProgress, setMainVideoProgress] = useState<number>(0);
	const [trailerVideoProgress, setTrailerVideoProgress] = useState<number>(0);
	const [mainVideoUploadError, setMainVideoUploadError] = useState(false);
	const [trailerVideoUploadError, setTrailerVideoUploadError] = useState(false);

	const clearMainFileInputSubject = new Subject<void>();
	const clearTrailerFileInputSubject = new Subject<void>();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const handleUpload = async (file: Partial<File>, inputStatePath: string, maxFileSizeMb?: number): Promise<void> => {
		const methods = {
			clearFileInput: () => clearMainFileInputSubject.next(),
			progress: setMainVideoProgress,
			uploadError: setMainVideoUploadError
		};
		if (inputStatePath.match(/trailerId/)) {
			methods.clearFileInput = () => clearTrailerFileInputSubject.next();
			methods.progress = setTrailerVideoProgress;
			methods.uploadError = setTrailerVideoUploadError;
		}
		if (maxFileSizeMb && file.size) {
			const fileSizeInMb = file.size / (1024 * 1024);
			if (fileSizeInMb > maxFileSizeMb) {
				dispatch(
					_emit(
						`Please select a file up to ${maxFileSizeMb}MB in size (current file size: ${Math.floor(fileSizeInMb)}MB).`,
						"error"
					)
				);
				methods.clearFileInput();
				return;
			}
		}
		await dispatch(
			uploadVideo({
				file,
				inputStatePath,
				options: {
					onError: () => {
						methods.uploadError(true);
					},
					onProgress: ({ loaded, total }) => {
						methods.progress((loaded / total) * 100);
					}
				}
			})
		);
		methods.uploadError(false);
	};

	const handleDelete = (inputStatePath: string): void => {
		dispatch(deleteVideo(inputStatePath));
		dispatch(setStateValue({ key: `${inputStatePath}.readonly`, value: false }));
	};

	return (
		<Box>
			{isLoading ? (
				<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
					<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
				</Box>
			) : (
				<Box display="flex" flexDirection="row" justifyContent="center">
					{lesson?.typeId !== LessonTypes.TestOnly && (
						<Box display="flex" flex="1" mr={3}>
							<Card>
								<VideoUploader
									{...{
										defaultCustomInputOptions,
										handleDelete: handleDelete,
										handleUpload: handleUpload,
										inputData: mainVideoIdInput,
										uploadError: mainVideoUploadError,
										uploaderProgress: mainVideoProgress,
										uploaderProps: {
											boxProps: { width: "250px" },
											clearFileInputSubject: clearMainFileInputSubject
										},
										lastUploadedVideo: newlyUploadedMainVideo
									}}
								/>
							</Card>
						</Box>
					)}
					{!isIntro && (
						<Box display="flex" flex="1">
							<Card>
								<VideoUploader
									{...{
										defaultCustomInputOptions,
										handleDelete: handleDelete,
										handleUpload: handleUpload,
										inputData: trailerIdInput,
										maxFileSizeMb: 100,
										uploadError: trailerVideoUploadError,
										uploaderProgress: trailerVideoProgress,
										uploaderProps: {
											boxProps: { width: "250px" },
											clearFileInputSubject: clearTrailerFileInputSubject
										},
										lastUploadedVideo: newlyUploadedTrailerVideo
									}}
								/>
							</Card>
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
};
