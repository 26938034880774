import React, { useMemo, useState } from "react";

import { Box, Card, CircularProgress, IconButton, Modal, Avatar as MuiAvatar, Typography } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import { LessonVideoSegmentTypes } from "@remar/shared/dist/constants";
import { format, millisecondsToHours } from "date-fns";

import { useDispatch, useSelector } from "react-redux";

import {
	getFullState,
	removeInteractiveBlock,
	setSelectedInteractiveBlock,
	transformInputsToDuration
} from "store/features/CreateLesson/createLesson.slice";

import { DeleteModalContent } from "./DeleteModalContent";
import { styles, useInteractiveBlocksStyles } from "./interactiveBlocks.styles";

const InteractiveBlocks = () => {
	const classes = useInteractiveBlocksStyles();
	const dispatch = useDispatch();
	const [delModal, setDelModal] = useState(false);
	const [deletableInteractiveBlockIndex, setDeletableInteractiveBlockIndex] = useState<number | null>(null);
	const { interactiveLessonFormGroup, saveIsLoading } = useSelector(getFullState);
	const { interactiveBlocks: interactiveBlocksInputs } = interactiveLessonFormGroup.inputs;
	const { selectedInteractiveBlockIndex, interactiveBlocks } = interactiveLessonFormGroup.rawData;
	const nonDeletedInteractiveBlocksLength = useMemo(
		() => interactiveBlocks.filter(x => !x.deleted).length,
		[interactiveBlocks]
	);
	return (
		<Box>
			{interactiveBlocks.map((interactiveBlock, interactiveBlockIndex) => {
				if (interactiveBlock.deleted) {
					return null;
				}
				const { question } = interactiveBlock;
				const questionName = (question && question.text) || "New block";
				const start = transformInputsToDuration(
					interactiveBlock[LessonVideoSegmentTypes.Start].startMin || 0,
					interactiveBlock[LessonVideoSegmentTypes.Start].startSec || 0
				);
				const end = transformInputsToDuration(
					interactiveBlock[LessonVideoSegmentTypes.Question].endMin || 0,
					interactiveBlock[LessonVideoSegmentTypes.Question].endSec || 0
				);
				const questionDraftId = interactiveBlock.questionDraftId;
				const index = interactiveBlocksInputs.findIndex(x => x.questionDraftId.value === questionDraftId);
				const isSelected = index === selectedInteractiveBlockIndex;
				return (
					<Card
						className={classes.block + " " + (isSelected ? classes.blockSelected : "")}
						key={questionDraftId}
						onClick={() => {
							!isSelected &&
								dispatch(
									setSelectedInteractiveBlock({
										emitErrorOnCurrentFormValidation: true,
										index,
										stopOnValidationError: true
									})
								);
						}}
					>
						<MuiAvatar className={classes.avatar}>{interactiveBlockIndex + 1}</MuiAvatar>
						<Typography className={classes.placeholderText}>
							{questionName.length > 15 ? `${questionName.substring(0, 15)}...` : questionName}
						</Typography>
						<Typography className={classes.timeStamp + " " + classes.placeholderText}>
							{millisecondsToHours(start) ? `${millisecondsToHours(start)}:` : "" + format(start, "mm:ss")}
							{" - "}
							{millisecondsToHours(end) ? `${millisecondsToHours(end)}:` : "" + format(end, "mm:ss")}
						</Typography>
						{nonDeletedInteractiveBlocksLength > 1 && (
							<Box display="flex" alignItems="center" justifyContent="center" ml={0.9}>
								<IconButton
									size="small"
									style={{ color: "#ffffff" }}
									onClick={e => {
										const questionDraftId = interactiveBlock.questionDraftId;
										const index = interactiveBlocksInputs.findIndex(x => x.questionDraftId.value === questionDraftId);
										e.preventDefault();
										e.stopPropagation();
										setDeletableInteractiveBlockIndex(index);
										setDelModal(true);
									}}
								>
									<CancelRounded color="disabled" />
								</IconButton>
							</Box>
						)}
					</Card>
				);
			})}
			<Card
				className={saveIsLoading ? classes.addNewBlockCardDisabled : classes.addNewBlockCard}
				onClick={() => {
					!saveIsLoading &&
						dispatch(
							setSelectedInteractiveBlock({
								emitErrorOnCurrentFormValidation: true,
								index: interactiveBlocksInputs.length,
								stopOnValidationError: true
							})
						);
				}}
			>
				{saveIsLoading ? (
					<CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
				) : (
					"+ Add Interactive Block"
				)}
			</Card>

			<Modal
				style={styles.modal}
				disableEnforceFocus
				disableAutoFocus
				open={delModal}
				onClose={() => setDelModal(false)}
			>
				<DeleteModalContent
					onDelete={() => {
						dispatch(
							removeInteractiveBlock({
								interactiveBlockIndex: deletableInteractiveBlockIndex as number,
								sideEffect: () => {
									setDeletableInteractiveBlockIndex(null);
									setDelModal(false);
								}
							})
						);
					}}
					onClose={() => {
						setDeletableInteractiveBlockIndex(null);
						setDelModal(false);
					}}
				/>
			</Modal>
		</Box>
	);
};

export default InteractiveBlocks;
