import React, { useEffect, useMemo, useState } from "react";

import { Box, Breadcrumbs, Button, InputAdornment } from "@material-ui/core";
import { DeleteSharp, EditSharp, NavigateNext } from "@material-ui/icons";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import {
	ColumnHeader,
	StyledCellText,
	StyledCellWrapper,
	StyledNameChip
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { Tag } from "@remar/shared/dist/models";

import { useDispatch, useSelector } from "react-redux";

import { getFullState as getFullAuthState } from "store/features/Auth/auth.slice";
import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";
import {
	createSubject,
	deleteLesson as deleteLessonAction,
	deleteSubject as deleteSubjectAction,
	fetchSubjects,
	getFullState,
	initialState,
	populateInputs,
	setStateValue,
	validateForm
} from "store/features/Subjects/Subjects.slice";
import { SubjectRawData } from "store/features/Subjects/models";

import { _emit } from "store/features/notifications/notifications.slice";

import CheckPermissions from "core/CheckPermissions";
import { routes } from "core/constants";
import theme from "theme/default";

import AddEditLesson from "./AddEditLesson";

import {
	ActiveLink,
	ButtonsRow,
	CircularProgress,
	DeleteCancelButton,
	DeleteConfirmButton,
	DeleteConfirmContent,
	DeleteModalTitle,
	EmptyPanel,
	FieldLabel,
	FormModal,
	HeaderSplit,
	Link,
	ModalRow,
	SearchPanel,
	SectionHeader,
	StyledCellAddNewLessonText,
	CustomInput as StyledInput,
	SubjectContainer,
	SubjectIconContainer,
	useStyles
} from "./styles";

const ManageSubjects = () => {
	const [showModal, setShowModal] = useState(false);
	const classes = useStyles();
	const [showLessonModal, setShowLessonModal] = useState<{ view: boolean; tags: Tag[] | null }>({
		view: false,
		tags: []
	});
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteableObject, setDeleteableObject] = useState<{ id: number; type: string } | undefined>();
	const { courses } = useSelector(getFullCourseState);
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getFullAuthState);
	const dispatch = useDispatch();
	const { subjectForm, isLoading, lessonForm, subjects, perPage, page, totalItems } = useSelector(getFullState);

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const addNewLessonHandle = id => {
		setShowLessonModal({ view: true, tags: [] });
		dispatch(setStateValue({ key: "lessonForm.valid", value: false }));
		dispatch(
			populateInputs({
				inputsStatePath: "lessonForm.inputs",
				templatesStatePath: "lessonForm.templates",
				values: { subjectId: id }
			})
		);
	};

	const editSubjectHandle = data => {
		setShowModal(true);
		const courseIds = data?.allowedCourses;
		dispatch(setStateValue({ key: "subjectForm.inputs.courseIds.selectOptions", value: courses }));
		dispatch(
			populateInputs({
				inputsStatePath: "subjectForm.inputs",
				templatesStatePath: "subjectForm.templates",
				values: { ...data, courseIds }
			})
		);
	};

	const editLessonHandle = (name, id, subjectId, tags) => {
		setShowLessonModal({ view: true, tags: tags });
		dispatch(setStateValue({ key: "lessonForm.valid", value: false }));
		dispatch(
			populateInputs({
				inputsStatePath: "lessonForm.inputs",
				templatesStatePath: "lessonForm.templates",
				values: { name, id, subjectId }
			})
		);
	};

	const LessonExpandedWrapper = ({ name, id, subjectId, tags }) => {
		const [show, setShow] = useState(false);
		return (
			<Box
				className={classes.expandRowGap}
				display="flex"
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
			>
				<StyledCellText>{name}</StyledCellText>
				<Box display={"flex"} mr={2}>
					{tags.map(({ id, name }) => (
						<Box key={id} mr={1}>
							<StyledNameChip>{name}</StyledNameChip>
						</Box>
					))}
				</Box>
				<Box alignItems="center" display={show ? "flex" : "none"} className={classes.iconsGap}>
					<EditSharp
						onClick={() => editLessonHandle(name, id, subjectId, tags)}
						className={classes.iconClass}
						style={{ width: "12px", height: "12px", color: "#d3d6e0" }}
					/>
					<DeleteSharp
						onClick={() => handleDelete({ id, type: "lesson" })}
						className={classes.iconClass}
						style={{ width: "12px", height: "12px", color: "#d3d6e0" }}
					/>
				</Box>
			</Box>
		);
	};

	const tableColumns: Array<IColumn<SubjectRawData>> = useMemo(() => {
		const prevRows = (page - 1) * perPage;
		return [
			{
				alignment: "center",
				label: <ColumnHeader>#</ColumnHeader>,
				width: 50,
				Cell: ({ rowIndex }) => <StyledCellWrapper>{rowIndex + 1 + prevRows}</StyledCellWrapper>,
				ChildCell: ({ rowData: { name, id, subjectId, tags } }) => (
					<StyledCellWrapper>
						<Box display={"flex"} flexDirection={"column"}>
							<Box display={"flex"} alignItems={"center"}>
								<LessonExpandedWrapper name={name} id={id} subjectId={subjectId} tags={tags} />
							</Box>
						</Box>
					</StyledCellWrapper>
				),
				childCellColSpan: 2,
				dataKey: "#"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subject</ColumnHeader>,
				width: 400,
				Cell: ({ rowData: { name } }) => <StyledCellWrapper>{name}</StyledCellWrapper>,
				dataKey: "subject"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Adding Lesson</ColumnHeader>,
				width: 140,
				Cell: ({ rowData: { id } }) => (
					<StyledCellWrapper center>
						<StyledCellAddNewLessonText disabled={!canEdit} onClick={() => canEdit && addNewLessonHandle(id)}>
							+ Add New Lesson
						</StyledCellAddNewLessonText>
					</StyledCellWrapper>
				),
				ChildCell: () => <></>,
				dataKey: "add_lesson"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Lessons</ColumnHeader>,
				width: 140,
				Cell: ({ rowData: { lessons } }) => <StyledCellWrapper center>{lessons.length}</StyledCellWrapper>,
				ChildCell: () => <></>,
				dataKey: "lessons"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				ChildCell: () => <></>,
				Cell: ({ rowData }) => (
					<ActionMenu
						customMenuItems={[
							{
								label: "Edit",
								onClick: () => editSubjectHandle(rowData),
								visible: true,
								disabled: !canEdit
							},
							{
								label: "Delete",
								onClick: () => handleDelete({ id: rowData.id, type: "subject" }),
								visible: true,
								disabled: !canEdit
							}
						]}
					/>
				),
				dataKey: "menu"
			}
		];
	}, [canEdit, page, perPage]);

	useEffect(() => {
		dispatch(fetchSubjects({}));
		dispatch(fetchAllCourses());
	}, [dispatch]);

	useEffect(() => {
		if (courses?.length) {
			dispatch(setStateValue({ key: "subjectForm.inputs.courseIds.selectOptions", value: courses }));
		}
	}, [courses, dispatch]);

	const handleDelete = data => {
		setShowDeleteConfirm(true);
		setDeleteableObject(data);
	};

	return (
		<SubjectContainer>
			<HeaderSplit>
				<div>
					<SectionHeader>Manage Subjects</SectionHeader>
					<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
						<Link to={routes.questionbank.getPath()}>Question Bank</Link>
						<ActiveLink>Manage Subjects</ActiveLink>
					</Breadcrumbs>
				</div>
				<div>
					<CheckPermissions>
						<Button
							variant={"contained"}
							color="primary"
							onClick={() => {
								setShowModal(true);
								dispatch(setStateValue({ key: "subjectForm.valid", value: false }));
							}}
							disabled={isLoading}
						>
							Add New Subject
						</Button>
					</CheckPermissions>
				</div>
			</HeaderSplit>

			<SearchPanel>
				<div>Subjects</div>
			</SearchPanel>

			{isLoading ? (
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			) : !subjects.length ? (
				<EmptyPanel>There is no subjects yet</EmptyPanel>
			) : (
				<>
					<MaterialTable columns={tableColumns} data={subjects} height={800} />
					<TablePagination
						count={totalItems}
						page={page}
						onChange={(event, page) => dispatch(fetchSubjects({ page }))}
						rowsPerPage={perPage}
					/>
				</>
			)}

			{showLessonModal.view && (
				<AddEditLesson
					onClose={() => setShowLessonModal({ view: false, tags: [] })}
					defaultCustomInputOptions={defaultCustomInputOptions}
					lessonForm={lessonForm}
					tags={showLessonModal.tags}
				/>
			)}

			<SimpleModal
				theme={theme}
				title={subjectForm?.inputs?.id?.value ? "Edit Subject" : "Add New Subject"}
				open={showModal}
				headerClass={classes.noHeaderMargin}
				onClose={() => {
					dispatch(setStateValue({ key: "subjectForm", value: initialState.subjectForm }));
					setShowModal(false);
				}}
				text={""}
				modalWidth={600}
				extraContent={
					<>
						<FieldLabel>
							Subject Name
							<StyledInput
								width={150}
								mr={2}
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.name }}
							/>
						</FieldLabel>
						<ModalRow>
							<Box width={"50%"} mr={4}>
								<FieldLabel>
									Min % for CAT
									<StyledInput
										width={150}
										mr={2}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>
										}}
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.minimumPercentage }}
									/>
								</FieldLabel>
							</Box>
							<Box width={"50%"}>
								<FieldLabel>
									Max % for CAT
									<StyledInput
										mr={2}
										width={150}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>
										}}
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.maximumPercentage }}
									/>
								</FieldLabel>
							</Box>
						</ModalRow>
						<ModalRow>
							<Box width="40%" textAlign="right">
								Courses
							</Box>
							<Box width="60%" marginLeft={4}>
								<StyledInput
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.allCourses }}
								/>
							</Box>
						</ModalRow>
						{!subjectForm.inputs.allCourses?.value && (
							<ModalRow>
								<Box marginLeft="auto">
									<StyledInput
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.courseIds }}
									/>
								</Box>
							</ModalRow>
						)}
						<ModalRow>
							<StyledInput
								width={150}
								mr={2}
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.isAvailableForTrial }}
							/>
						</ModalRow>
						<FieldLabel>
							Description
							<StyledInput
								width={150}
								mr={2}
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.description }}
							/>
						</FieldLabel>
					</>
				}
				footer={
					<>
						<Button variant={"contained"} className={classes.cancelBtn} onClick={() => setShowModal(false)}>
							Cancel
						</Button>
						<Button
							color="primary"
							variant={"contained"}
							onClick={() => {
								if (subjectForm.valid) {
									dispatch(createSubject(() => setShowModal(false)));
								}
							}}
						>
							{subjectForm?.inputs?.id?.value ? "Save Changes" : "Add New Subject"}
						</Button>
					</>
				}
			/>

			<FormModal
				open={showDeleteConfirm}
				onClose={() => {
					setShowDeleteConfirm(false);
					setDeleteableObject(undefined);
				}}
				disableEnforceFocus
				disableAutoFocus
			>
				<DeleteConfirmContent>
					<DeleteModalTitle>Delete {deleteableObject?.type}</DeleteModalTitle>

					<SubjectIconContainer>
						<div>Are you sure you want to delete this {deleteableObject?.type}?</div>
					</SubjectIconContainer>
					<ButtonsRow>
						<DeleteCancelButton variant={"contained"} onClick={() => setShowDeleteConfirm(false)}>
							No, Cancel
						</DeleteCancelButton>
						<DeleteConfirmButton
							variant={"contained"}
							onClick={() => {
								setShowDeleteConfirm(false);
								if (deleteableObject) {
									deleteableObject.type === "subject" && dispatch(deleteSubjectAction(deleteableObject.id));
									deleteableObject.type === "lesson" && dispatch(deleteLessonAction(deleteableObject.id));
								}
							}}
						>
							Yes, Delete
						</DeleteConfirmButton>
					</ButtonsRow>
				</DeleteConfirmContent>
			</FormModal>
		</SubjectContainer>
	);
};

export default ManageSubjects;
