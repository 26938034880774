import React, { useEffect, useMemo } from "react";

import { Box, Radio, Typography } from "@material-ui/core";
import { ReactComponent as DeleteIconSvg } from "@remar/shared/dist/assets/icons/icon-trash-filled.svg";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";

import {
	FormControlLabelWrapper,
	InputStyles,
	MatrixGroupLabel,
	MatrixTableGroup,
	RadioGroupWrapper,
	CustomInput as StyledInput,
	useStyles
} from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

const maxAnswerOptslLength = 2;

export const MatrixSingleChoiceQuestion = ({
	createSingleMatrixQuestionAnswerOptions,
	reduceAnswerOptsLength,
	createQuestionGroup,
	getFullState,
	removeQuestionGroup,
	setStateValue,
	validateForm,
	statePath = "",
	uploadQuestionMaterial,
	removeQuestionAttachment,
	formName = "addNewQuestionForm",
	templatePath = "answerOptions"
}) => {
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const {
		text,
		groups,
		answerOptions,
		tableLabel,
		description,
		attachments,
		endOfQuestionSummary,
		mainImageKey,
		_expanded
	} = questionData;

	useEffect(() => {
		if (answerOptions.length === 0) {
			dispatch(
				createSingleMatrixQuestionAnswerOptions({
					formName,
					inputsPath: `${fullStatePath}.answerOptions`,
					templatePath
				})
			);
		}
		if (answerOptions.length > maxAnswerOptslLength) {
			dispatch(reduceAnswerOptsLength({ inputsPath: `${fullStatePath}.answerOptions`, length: 2 }));
		}
	}, [answerOptions.length]);

	useEffect(() => {
		if (!groups.length) {
			dispatch(createQuestionGroup({ start: true, statePath, formName }));
		}
	}, [groups.length]);

	const nonDeletedGroupsLength = useMemo(() => groups.filter(x => !x.deleted.value).length, [groups]);

	return (
		<CommonQuestionFields
			description={description}
			attachments={attachments}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			expanded={_expanded?.value}
			statePath={statePath}
			setStateValue={setStateValue}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
		>
			<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
			<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
			<Typography className={classes.addOptionHeader}>Table View</Typography>

			<Box display="flex" flexDirection="row" width="100%">
				<Box display="flex" flexDirection="row" width="calc(100% - 50px)">
					<MatrixGroupLabel valid={!tableLabel.error}>
						<StyledInput
							theme={theme}
							noMargin
							InputProps={{
								style: {
									...InputStyles
								}
							}}
							options={{ ...defaultCustomInputOptions, inputData: tableLabel }}
						/>
					</MatrixGroupLabel>

					{answerOptions.map(({ text }, answerOptionIndex) => (
						<MatrixTableGroup key={answerOptionIndex}>
							<StyledInput
								theme={theme}
								InputProps={{
									style: {
										...InputStyles
									}
								}}
								noMargin
								options={{
									...defaultCustomInputOptions,
									inputData: text
								}}
							/>
						</MatrixTableGroup>
					))}
				</Box>
			</Box>

			{groups.map(
				({ text, selectedAnswerOptions, deleted }, i) =>
					!deleted?.value && (
						<Box key={`gr_${i}`} display="flex" flexDirection="row" width="100%">
							<Box key={i} display="flex" flexDirection="row" width="calc(100% - 50px)">
								<MatrixGroupLabel>
									<StyledInput
										theme={theme}
										InputProps={{
											style: {
												...InputStyles,
												backgroundColor: "#2a2e37"
											}
										}}
										noMargin
										options={{ ...defaultCustomInputOptions, inputData: text }}
									/>
								</MatrixGroupLabel>
								<Box display="flex" flexDirection="row" flexGrow="1">
									<RadioGroupWrapper
										name="value"
										value={selectedAnswerOptions.value[0]}
										onChange={e => {
											dispatch(
												setStateValue({
													key: `${formName}.${fullStatePath}.groups.${i}.selectedAnswerOptions.value`,
													value: [e.target.value]
												})
											);
											dispatch(validateForm({ formStatePath: formName }));
										}}
									>
										{answerOptions.map(({ id }, _index) => (
											<FormControlLabelWrapper
												label={""}
												key={_index}
												value={id.value}
												control={<Radio color="primary" />}
											/>
										))}
									</RadioGroupWrapper>
								</Box>
							</Box>

							{nonDeletedGroupsLength > 2 && (
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									width="50px !important"
									className={classes.addOptionButtonContainer}
								>
									<DeleteIconSvg
										onClick={() => {
											dispatch(
												removeQuestionGroup({
													statePath,
													groupIndex: i
												})
											);
										}}
									/>
								</Box>
							)}
						</Box>
					)
			)}

			<Box display="flex" flexDirection="row">
				{nonDeletedGroupsLength < 6 && (
					<Box mt={2}>
						<AddButton
							onClick={() => {
								dispatch(
									createQuestionGroup({
										statePath,
										formName
									})
								);
							}}
							label={"Add Row"}
						/>
					</Box>
				)}
			</Box>
		</CommonQuestionFields>
	);
};
