import React, { ReactNode, useState } from "react";

import { Box, IconButton, LinearProgress } from "@material-ui/core";
import { CameraAlt, Close } from "@material-ui/icons";

import { useCustomInputStyles } from "@remar/shared/dist/components/CustomInput/customInput.styles";
import Uploader from "@remar/shared/dist/components/Uploader";

import { useDispatch } from "react-redux";
import { emit } from "store/features/notifications/notifications.slice";

import { Container, FlexContainer } from "modules/Components/Card/styles";

interface CardProps {
	// eslint-disable-next-line no-unused-vars
	handleImageUpdate?: (_file: File, handleProgres: ({ progress: number, uploading: boolean }) => void) => void;
	width: number;
	rightComponent: ReactNode;
	imageUrl?: string;
	progress?: number;
	uploading?: boolean;
}

const Card = ({ width, rightComponent, handleImageUpdate, imageUrl }: CardProps) => {
	const dispatch = useDispatch();

	const [progress, setProgress] = useState<number>(0);
	const [uploading, setUploading] = useState<boolean>(false);
	const [isImageFile, setIsImageFile] = useState<boolean>(!!imageUrl);

	const classes = useCustomInputStyles();

	const handleProgres = (option: { progress: number; uploading: boolean }) => {
		setProgress(option.progress);
		setUploading(option.uploading);
	};

	const handleUpload = img => {
		const reader = new FileReader();
		reader.readAsDataURL(img);
		reader.onload = e => {
			const image = new Image();
			image.onload = () => {
				handleImageUpdate!(img, handleProgres);
				// TODO: decide on the appropriate dimension and place the check
				// if (image.width > 500 || image.height > 720) {
				// 	dispatch(emit({ message: "The Image is too big please try using a picture of 500x720", color: "error" }));
				// } else {
				// 	handleImageUpdate(img);
				// }
			};
			image.onerror = () => {
				dispatch(
					emit({ message: "There seems to be an error while loading the picture please try again", color: "error" })
				);
			};
			image.src = (e.target?.result as string) || "";
		};
	};

	return (
		<Container width={width}>
			<FlexContainer>
				{typeof imageUrl === "string" && imageUrl.length && isImageFile ? (
					<Box width={240} height={246}>
						<Box className={classes.cancelContainer}>
							<IconButton
								size="small"
								className={classes.cancelThumbnail}
								onClick={() => {
									setIsImageFile(false);
								}}
							>
								<Close className={classes.cancelIcon} />
							</IconButton>
							<Box
								className={`${imageUrl ? classes.imgContainerBorder : ""}`}
								style={{
									display: "flex",
									width: "100%",
									height: "175px",
									justifyContent: "center",
									margin: "auto",
									position: "relative"
								}}
							>
								<img src={imageUrl} className={classes.image} />
							</Box>
						</Box>
					</Box>
				) : (
					<Uploader
						accept={".png"}
						boxProps={{ maxWidth: "246px" }}
						clearFileInputSubject={undefined}
						error={false}
						header="Upload Thumbnail"
						icon={<CameraAlt style={{ width: "50px", height: "50px", fill: "#787a82" }} />}
						loading={false}
						onAdd={e => {
							e[0] && handleUpload(e[0]);
						}}
						progress={100}
						title="Drag and Drop File Here or Browse to Choose a File, Recommended Size 1440x1000"
						dragFileError="File type is not allowed."
					/>
				)}
				<Box ml={3.5}>{rightComponent}</Box>
			</FlexContainer>
			{uploading && (
				<Box width="246px" mt="3px" justifyContent="space-between" display="flex" alignItems="center">
					<LinearProgress
						style={{ background: "#191919", width: "100%" }}
						color="primary"
						variant={progress ? "determinate" : "indeterminate"}
						value={progress}
					/>
					<Box color="#fff">{Math.floor(progress as number)}%</Box>
				</Box>
			)}
		</Container>
	);
};

export default Card;
