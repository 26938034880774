import { BaseApiService, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { CourseChapterSectionLesson } from "@remar/shared/dist/models";

import {
	CourseChapterSectionLessonCreateDto,
	CourseChapterSectionLessonDeleteDto,
	CourseChapterSectionLessonDeleteResponse,
	CourseChapterSectionLessonUpdateDto
} from "./dto";

import { URLS } from "../services.constants";

export class CourseChapterSectionLessonsService extends BaseApiService<
	CourseChapterSectionLesson,
	CourseChapterSectionLessonCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	CourseChapterSectionLessonUpdateDto,
	CourseChapterSectionLessonDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	delete(data: CourseChapterSectionLessonDeleteDto): Promise<CourseChapterSectionLessonDeleteResponse> {
		return BaseApiService.prototype.delete.call(this, data) as Promise<CourseChapterSectionLessonDeleteResponse>;
	}
}

export const courseChapterSectionLessonsService = new CourseChapterSectionLessonsService(
	URLS.COURSE_CHAPTER_SECTION_LESSONS
);
