import { useEffect, useState } from "react";

import { ADMIN_USER_ID } from "@remar/shared/dist/constants";

import { forEach } from "lodash";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getFullState } from "store/features/Auth/auth.slice";

import { DEFAULT_ACCESS, IAccess, PermissionType } from "./permissions";

const usePermissions = (): IAccess => {
	const location = useLocation();
	const { user, isLoggedIn, userAccess } = useSelector(getFullState);
	const [access, setAccess] = useState<IAccess>(DEFAULT_ACCESS);

	useEffect(() => {
		if (!user || !userAccess) {
			return setAccess(DEFAULT_ACCESS);
		}

		const { assignedUserTypes } = user;

		if (!assignedUserTypes || !assignedUserTypes?.length) {
			return setAccess(DEFAULT_ACCESS);
		}
		const assignedUserTypeIds = assignedUserTypes.map(type => type.id);
		const hasSuperAdminAccess = assignedUserTypeIds.some(id => id === ADMIN_USER_ID);

		if (hasSuperAdminAccess) {
			return setAccess({
				canEdit: true,
				canView: true,
				accessAreas: [],
				isAccessReady: true
			});
		}

		const accessAreas: PermissionType[] = [];

		forEach(assignedUserTypeIds, typeId => {
			if (!userAccess[typeId]) {
				// access id returned from Whoami does not exist in redux store
				return false;
			}
			forEach(userAccess[typeId].accessAreas, access => {
				const hasRegisteredAccessIndex = accessAreas.findIndex(area => area.path === access.path);
				if (hasRegisteredAccessIndex > -1) {
					const registeredAccess = accessAreas[hasRegisteredAccessIndex];
					const { canEdit, canView } = registeredAccess;
					accessAreas[hasRegisteredAccessIndex] = {
						...registeredAccess,
						canEdit: canEdit || access.canEdit,
						canView: canView || access.canView
					};
				} else {
					accessAreas.push(access);
				}
			});
		});

		const currentPathIndex = accessAreas.findIndex(area => location.pathname.includes(area.path));
		if (currentPathIndex === -1) {
			return setAccess(DEFAULT_ACCESS);
		}

		const { canEdit, canView } = accessAreas[currentPathIndex];

		setAccess({
			canEdit,
			canView,
			accessAreas,
			isAccessReady: accessAreas && accessAreas?.length > 0
		});
	}, [user, isLoggedIn, userAccess]);

	return access;
};

export default usePermissions;
