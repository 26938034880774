import React, { useEffect, useMemo, useState } from "react";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import Button from "@remar/shared/dist/components/Button";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { ColumnHeader, StyledCellText } from "@remar/shared/dist/components/Table/styles";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";

import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";
import { NotificationAudienceTypeIdEnum, NotificationTypeId } from "@remar/shared/dist/models";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import { getFullState as getFullAuthState } from "store/features/Auth/auth.slice";
import {
	clearConflict,
	deleteNotification,
	fetchNotifications,
	selectBannersFullState
} from "store/features/Banners/banners.slice";

import { useAppDispatch, useAppSelector } from "store/hooks";

import { CreateNotificationDto, NotificationDto } from "store/services/notifications/dto";

import { IconListSvg } from "assets/icons";

import CheckPermissions from "core/CheckPermissions";

import ConflictModal from "./ConflictModal/ConflictModal";
import CreateEditNotification from "./CreateNotification";
import { StatusMarker } from "./components";

const PER_PAGE = 10;
const convertType = (type: NotificationTypeId) => {
	switch (type) {
		case NotificationTypeId.AdminGeneratedBanner:
			return "Banner";
		case NotificationTypeId.AdminGeneratedPush:
			return "Push";
		case NotificationTypeId.AdminGeneratedAnnouncement:
			return "Announcement";
		default:
			return type;
	}
};

const convertAudience = (audience: NotificationAudienceTypeIdEnum) => {
	switch (audience) {
		case NotificationAudienceTypeIdEnum.AllUsers:
			return "All users";
		case NotificationAudienceTypeIdEnum.PaidUsers:
			return "Paid users";
		case NotificationAudienceTypeIdEnum.TrialUsers:
			return "Trial users";
		default:
			return audience;
	}
};

const ManageNotifications = () => {
	const dispatch = useAppDispatch();
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getFullAuthState);

	useEffect(() => {
		dispatch(
			fetchNotifications({
				page: 1,
				perPage: PER_PAGE,
				filters: {
					notificationTypeId: [
						NotificationTypeId.AdminGeneratedBanner,
						NotificationTypeId.AdminGeneratedPush,
						NotificationTypeId.AdminGeneratedAnnouncement
					]
				}
			})
		);
	}, [dispatch]);

	const tableColumns: Array<IColumn<NotificationDto>> = useMemo(
		() => [
			{
				align: "left",
				label: <ColumnHeader>Message</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { body } }) => <StyledCellText>{body}</StyledCellText>,
				dataKey: "message"
			},
			{
				align: "left",
				label: <ColumnHeader>Status</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { status } }) => (
					<StyledCellText>
						<StatusMarker status={status} /> {status}
					</StyledCellText>
				),
				dataKey: "status"
			},
			{
				align: "left",
				label: <ColumnHeader>Send Date & Time</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { sendAt, expiresAt } }) => (
					<StyledCellText>
						{format(new Date(sendAt), "LLL dd, yyyy - HH:mm")}
						{expiresAt && <br />}
						{expiresAt && format(new Date(expiresAt), "LLL dd, yyyy - HH:mm")}
					</StyledCellText>
				),
				dataKey: "date_time"
			},
			{
				align: "left",
				label: <ColumnHeader>Type</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { notificationTypeId } }) => (
					<StyledCellText>{convertType(notificationTypeId)}</StyledCellText>
				),
				dataKey: "type"
			},
			{
				align: "center",
				label: <ColumnHeader>Audience</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { notificationAudienceTypeId } }) => (
					<StyledCellText>{convertAudience(notificationAudienceTypeId)}</StyledCellText>
				),
				dataKey: "audience"
			},
			{
				align: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }) => (
					<ActionMenu
						customMenuItems={[
							{
								label: "Edit",
								onClick: () => {
									setNotification(rowData);
								},
								visible: true,
								disabled: !canEdit
							},
							{
								label: "Delete",
								onClick: () => setNotificationIdForDelete(rowData.id),
								visible: true,
								disabled: !canEdit
							}
						]}
					/>
				)
			}
		],
		[canEdit]
	);

	const {
		conflict,
		notificationPage: page,
		notificationPerPage: perPage,
		notificationTotalItems: totalItems,
		notificationItems: items,
		isLoading,
		deleteLoading
	} = useAppSelector(selectBannersFullState);

	const [notification, setNotification] = useState<CreateNotificationDto | null>(null);
	const [notificationIdForDelete, setNotificationIdForDelete] = useState<number | null>(null);
	const [searchText, setSearchText] = useState("");
	// const [page, setPage] = useState(1);
	const [deliveryMethod, setDeliveryMethod] = useState<NotificationTypeId | 0>(0);

	const handleSelectFilter = notificationTypeId => {
		setDeliveryMethod(notificationTypeId);
		dispatch(
			fetchNotifications({ page: 1, perPage, filters: { notificationTypeId: notificationTypeId || undefined } })
		);
	};

	const handleSearchBarChange = (searchKeyword: string) => {
		dispatch(
			fetchNotifications({
				page: 1,
				perPage,
				searchKeyword,
				filters: {
					notificationTypeId: [
						NotificationTypeId.AdminGeneratedBanner,
						NotificationTypeId.AdminGeneratedPush,
						NotificationTypeId.AdminGeneratedAnnouncement
					]
				}
			})
		);
	};

	return (
		<WithTableContentLayout
			heading="Notifications"
			isLoading={isLoading}
			actions={
				<CheckPermissions>
					<Button variant="filled" color="primary" onClick={() => setNotification({} as CreateNotificationDto)}>
						Create new Notification
					</Button>
				</CheckPermissions>
			}
			tableTitle="Notifications"
			searchPlaceholder="Type to filter by keywords"
			searchText={searchText}
			handleSearchBarChange={handleSearchBarChange}
			setSearchText={setSearchText}
			filterBlock={
				<>
					<IconListSvg width={25} height={25} style={{ marginRight: "5px", fill: "#fff" }} />
					<FormControl hiddenLabel size="small" variant="filled">
						<Select
							disableUnderline
							value={deliveryMethod}
							onChange={event => handleSelectFilter(event.target.value)}
							displayEmpty
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left"
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left"
								},
								getContentAnchorEl: null
							}}
						>
							<MenuItem value={0}>All Notifications</MenuItem>
							<MenuItem value={NotificationTypeId.AdminGeneratedBanner}>Banners</MenuItem>
							<MenuItem value={NotificationTypeId.AdminGeneratedPush}>Pushes</MenuItem>
							<MenuItem value={NotificationTypeId.AdminGeneratedAnnouncement}>Announcements</MenuItem>
						</Select>
					</FormControl>
				</>
			}
			emptyState={<EmptyState description="No notifications yet" />}
			onChangePage={page =>
				dispatch(
					fetchNotifications({
						page: page,
						perPage,
						searchText,
						filters: {
							notificationTypeId: [
								NotificationTypeId.AdminGeneratedBanner,
								NotificationTypeId.AdminGeneratedPush,
								NotificationTypeId.AdminGeneratedAnnouncement
							]
						}
					})
				)
			}
			totalItems={totalItems}
			totalEntities={totalItems}
			perPage={perPage}
			page={page}
			tableColumns={tableColumns}
			data={items}
		>
			<CreateEditNotification notification={notification} onClose={() => setNotification(null)} />
			<ConflictModal type={conflict} onClose={() => dispatch(clearConflict())} />
			<DeleteModal
				open={!!notificationIdForDelete}
				title={"Delete Notification"}
				message={"Are you sure you want to delete this notification?"}
				onClose={() => setNotificationIdForDelete(null)}
				onDelete={() => {
					dispatch(deleteNotification(notificationIdForDelete as number)).then(() => {
						setNotificationIdForDelete(null);
					});
				}}
				cancelBtnText={"No,Cancel"}
				deleteBtnText={"Yes, Delete"}
				deleteLoading={deleteLoading}
			/>
		</WithTableContentLayout>
	);
};

export default ManageNotifications;
