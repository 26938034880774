import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { Subject } from "@remar/shared/dist/models";

import { SubjectCreateDto, SubjectUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class SubjectService extends BaseApiService<
	Subject,
	SubjectCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	SubjectUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const subjectService = new SubjectService(URLS.SUBJECTS);
