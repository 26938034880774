import { makeStyles } from "@material-ui/core";

export const useInteractiveQuestionStyles = makeStyles(() => ({
	addOptionButtonContainer: {
		cursor: "pointer",
		"& button": {
			height: "20px",
			marginRight: "8px",
			minWidth: "20px",
			padding: "0px"
		}
	},
	addOptionHeader: {
		color: "#ffffff",
		fontSize: "12px",
		fontWeight: "bold",
		lineHeight: 1.33,
		opacity: 0.38
	},
	addOptionLabel: {
		color: "rgba(255, 255, 255, 0.6)",
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6
	},
	card: {
		padding: "24px"
	},
	questionTypeInput: {
		"& div[role=button]": {
			paddingTop: "14px",
			paddingBottom: "15px"
		}
	}
}));
