import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
	keepBtn: {
		backgroundColor: "transparent",
		color: "rgba(255, 255, 255, 0.6)",
		borderColor: "#6b6e75;",
		borderWidth: "1px",
		borderStyle: "solid",
		marginRight: "10px"
	},
	cancelBtn: {
		backgroundColor: "#46191e",
		color: "#ed5465"
	},
	button: {
		lineHeight: 1.25,
		fontSize: "16px",
		fontWeight: 900,
		padding: "13px 18px 15px"
	}
});
