import {
	Box,
	Button,
	Modal,
	CircularProgress as Progress,
	Typography,
	createStyles,
	makeStyles
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import { basic } from "@remar/shared/dist/colors";
import { CustomInput as Input } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const useStyles = makeStyles(() =>
	createStyles({
		iconClass: {
			cursor: "pointer"
		},
		textCenter: {
			textAlign: "center"
		},
		noHeaderMargin: {
			marginBottom: "0 !important"
		},
		noPadding: {
			padding: "0 !important"
		},
		expandRowGap: {
			gap: 25
		},
		iconsGap: {
			gap: 9
		},
		tableBgColor: {
			backgroundColor: "#22252d"
		},
		rowPadding: {
			paddingLeft: 0,
			paddingBottom: 0,
			paddingTop: 0
		},
		cancelBtn: {
			borderRadius: "4px !important",
			borderColor: "#94979f !important",
			backgroundColor: "transparent !important",
			color: "rgba(255, 255, 255, 0.6) !important",
			borderWidth: "1px !important",
			borderStyle: "solid !important",
			marginRight: "15px !important"
		}
	})
);

export const StyledCellText = styled(Typography)`
	word-break: break-all;
	align-self: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #ffffff;
	font-size: 13px;
`;

export const StyledTableCell = styled(TableCell)<{ open: boolean }>`
	border-bottom: none;
`;

export const StyledCellAddNewLessonText = styled(Typography)`
	font-size: 12px;
	font-family: var(--poppins);
	font-weight: bold;
	color: #4a99f9;
	height: 16px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	cursor: pointer;
	text-align: center;
	${({ disabled, theme }) =>
		disabled
			? css`
					color: ${theme.palette.colors.basic[400]};
					cursor: default;
			  `
			: ""}
`;

export const FieldLabel = styled.label`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: rgba(255, 255, 255, 0.6);

	margin: 0 0 24px 0;
`;

export const ModalRow = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 15px 0 24px;
	align-items: center;
`;

export const SectionHeader = styled.div`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #fff;
	margin: 0 0 13px;
`;

export const ButtonsRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: end;
	border-top: solid 1px #2a2e37;
	padding: 15px 2px 0;
`;

export const CustomInput = styled(Input)`
	width: 100%;
	margin: 5px 0 0;
`;

export const ColumnHeader = styled(Typography)`
	font-size: 12px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.6);
`;

export const ModalTitle = styled.h1`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: 26px;
	font-weight: bold;
	line-height: 1.23;
	color: #fff;
	padding: 0;
	margin: 0 0 38px 0;
	text-align: left;
`;

export const DeleteModalTitle = styled.h3`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #fff;
	padding: 0;
	margin: 0;
	text-align: left;
	text-transform: capitalize;
`;

export const DeleteConfirmContent = styled.div`
	width: 480px;
	position: relative;
	background: ${basic[900]};
	padding: 16px 8px;
	overflow: hidden;
`;

export const DeleteCancelButton = styled(Button)`
	background: transparent;
	border-radius: 4px;
	border: solid 1px #94979f;
	color: #94979f;
	margin-right: 16px;
`;
export const DeleteConfirmButton = styled(Button)`
	background: #46191e;
	color: #ed5465;
	font-family: var(--poppins);
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;
	width: 144px;
	box-sizing: border-box;
`;

export const SubjectIconContainer = styled.div`
	height: 75px;
	filter: grayscale(1);
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	font-size: 15px;
	color: rgba(255, 255, 255, 0.6);
	padding: 16px;
	margin: 16px 0;
`;
export const FormModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const SubjectModalContent = styled.div`
	width: 600px;
	background: #22252d;
	padding: 26px;
	display: flex;
	flex-flow: column;
`;

export const SubjectModalButtonContainer = styled(Box)`
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
`;

export const SubjectContainer = styled.div`
	margin-right: 44px;
`;

export const HeaderSplit = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 27px 0 0 0;
`;

export const Link = styled(RouterLink)`
	font-family: var(--openSans);
	font-size: 14px;
	line-height: 1.23;
	color: #fff !important;
	opacity: 0.38;
`;

export const ActiveLink = styled(Link)`
	opacity: 1;
`;

export const SearchPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 14px;
	background: #22252d;
	color: #fff;
	margin: 28px 0 21px;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;

	> div:first-child {
		flex-grow: 1;
	}
`;

export const CircularProgress = styled(Progress)`
	margin-left: 50%;
	transform: translate(-50%);
`;

export const EmptyPanel = styled.div`
	height: 360px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background: #22252d;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #fff;
`;
