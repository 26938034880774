import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { Question } from "@remar/shared/dist/models";

import { AddQuestionToCommunityDto, QuestionCreateDto, QuestionUpdateDto, QuestionsCountResponseDto } from "./dto";

import { URLS } from "../services.constants";

export class QuestionsService extends BaseApiService<
	Question,
	QuestionCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	QuestionUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getQuestionsCount(): Promise<QuestionsCountResponseDto> {
		return request(`${this.baseUrl}/question-count`, "GET") as Promise<QuestionsCountResponseDto>;
	}

	addQuestionToCommunity(data: AddQuestionToCommunityDto): Promise<Question> {
		return request(
			`${this.baseUrl}/add-to-community`,
			"POST",
			data as unknown as Record<string, unknown>
		) as Promise<Question>;
	}
}

export const questionsService = new QuestionsService(URLS.QUESTIONS);
