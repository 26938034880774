import { Button, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	.breadcrumb-container {
		margin-top: 6px;
		div {
			font-size: 13px;
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}
		div:last-child {
			color: ${({ theme }) => theme.palette.colors.basic[100]};
		}
	}
`;

export const HeaderContainer = styled.div`
	margin: 24px 32px 0 24px;
	display: flex;
`;

export const Heading = styled(Typography)`
	opacity: 0.87;
	font-size: 18px;
	font-weight: bold;
	font-family: var(--poppins);
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
`;

export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
`;

export const ModuleContainer = styled(Grid)`
	padding: 24px 0 0 0;
`;

export const ModuleWrapper = styled(Grid)`
	.report-card {
		padding: 1.2rem;
		background-color: ${({ theme }) => theme.palette.colors.basic[800]};
		border-radius: 0.2rem;
		.durations {
			width: 100%;
			margin-right: 0.5rem;
			div:first-child {
				background-color: ${({ theme }) => theme.palette.colors.basic[750]};
				border-radius: 0.2rem;
			}
		}
		.downloadBtn {
			width: 100%;
			margin-left: 0.5rem;
		}
		.filter-container {
			display: flex;
			gap: 16px;
			align-items: center;

			.filter-selector {
				display: flex;
				flex-direction: column;
			}

			svg {
				color: hsl(224, 16%, 41%);
			}
		}
	}
`;

export const TitleText = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.text.disabled};
`;

export const DownloadButton = styled(Button)`
	background-color: ${({ theme, disabled }) =>
		disabled ? theme.palette.colors.basic[750] : theme.palette.colors.primary[500]};
	color: ${({ theme, disabled }) => theme.palette.colors.basic[disabled ? 750 : 100]};
	&:hover {
		background-color: ${({ theme }) => theme.palette.colors.primary[500]};
	}
`;
