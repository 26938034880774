import { Typography, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useCreateLessonStyles = makeStyles(() => ({
	durationInputsContainer: {
		"& input": {
			paddingTop: "10px",
			paddingBottom: "11px"
		}
	},
	fileUploaded: {
		height: "50px",
		margin: "10px 0 8px",
		padding: "9px 13px 9px 10px",
		borderRadius: "4px",
		border: "solid 1px #222b45",
		"&>.container": {
			display: "flex",
			justifyContent: "space-between",
			marginTop: "-2px"
		}
	},
	spacer: {
		height: "1px",
		margin: "16px 0",
		border: "solid 1px #22252d"
	},
	closeIcon: {
		cursor: "pointer"
	}
}));

export const MediaUploadContainer = styled.div`
	margin: 24px 0px;
	padding: 16px 18px;
	border-radius: 4px;
	background-color: #191c24;
`;

export const MediaUploadHeading = styled.div`
	margin-bottom: 10px;
	opacity: 0.38;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: #fff;
`;

export const FileUploadedText = styled.div`
	margin-top: 2px;
	opacity: 0.87;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: #fff;
	white-space: nowrap;
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const FileUploadedSize = styled.div`
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #8f9bb3;
	margin-top: 4px;
`;

export const BreadcrumbText = styled(Typography)`
	font-size: 0.8125rem;
	color: #4d5058;
`;
