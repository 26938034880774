import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "store";

import { activityLogsService } from "../../services/activityLogs";
import { IActivityLogList } from "../../services/activityLogs/dto";

interface ActivityLogsState {
	logs: IActivityLogList | null;
	isLoadingLogs: boolean;
}

const initialState: ActivityLogsState = {
	logs: null,
	isLoadingLogs: false
};
const SLICE_NAME = "activityLogs";

export const getActivityLogs = createAsyncThunk(
	`${SLICE_NAME}/getActivityLogs`,
	async ({ userId, page, perPage }: { userId: string; page?: number; perPage: number }, { rejectWithValue }) => {
		return await activityLogsService
			.find({
				filters: { userId },
				include: ["userActivityLogType"],
				page: page,
				perPage: perPage,
				orderBy: { createdAt: "DESC" }
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const activityLogsSlice = createSlice({
	name: "activityLogs",
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(getActivityLogs.pending.type, state => {
				state.isLoadingLogs = true;
			})
			.addCase(getActivityLogs.fulfilled.type, (state, action: PayloadAction<IActivityLogList>) => {
				state.logs = action.payload;
				state.isLoadingLogs = false;
			})
			.addCase(getActivityLogs.rejected.type, state => {
				state.isLoadingLogs = false;
			})
});

export const getFullState = (state: RootState): ActivityLogsState => state.activityLogs;
export default activityLogsSlice.reducer;
