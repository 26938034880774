import { Typography, createStyles, makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import styled from "styled-components";

import { IExtendedTheme } from "../../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		iconClass: {
			cursor: "pointer"
		},
		textCenter: {
			textAlign: "center"
		},
		noHeaderMargin: {
			marginBottom: "0 !important"
		},
		noPadding: {
			padding: "0 !important"
		},
		expandRowGap: {
			gap: 25
		},
		iconsGap: {
			gap: 9
		},
		tableBgColor: {
			backgroundColor: theme.palette.colors.basic[900]
		},
		rowPadding: {
			paddingLeft: 0,
			paddingBottom: 0,
			paddingTop: 0
		},
		cancelBtn: {
			borderRadius: "4px !important",
			borderColor: "hsl(224,5%,60%) !important",
			backgroundColor: "transparent !important",
			color: "hsla(0,0%,100%,0.6) !important",
			borderWidth: "1px !important",
			borderStyle: "solid !important",
			marginRight: "15px !important"
		}
	})
);

export const FieldLabel = styled.label`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};

	margin: 0 0 24px 0;
`;

export const AddUpdateText = styled(Typography)`
	margin-top: 0;
	margin-left: ${({ $isLoading }) => ($isLoading ? "0.5rem" : "0")};
	color: ${({ theme, $disabled }) => ($disabled ? theme.palette.text.disabled : theme.palette.common.white)};
`;
