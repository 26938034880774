import React, { useEffect, useState } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const DndProv: React.FC<{ id: string }> = ({ children, id }) => {
	const [context, setContext] = useState<HTMLElement | null>(null);

	useEffect(() => {
		setContext(document.getElementById(id));
	}, [id]);

	return (
		<DndProvider backend={HTML5Backend} options={{ rootElement: context }}>
			{children}
		</DndProvider>
	);
};

export default DndProv;
