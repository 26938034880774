import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";

import { CouponCreateDto, CouponEditDto } from "./dto/coupon.create.dto";

import { Coupon, ICouponProducts, ICouponTypes } from "../../features/Coupons/models";

import { URLS } from "../services.constants";

export class CouponService extends BaseApiService<
	Coupon,
	CouponCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Omit<CouponEditDto, "id">>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getCouponTypes(): Promise<ICouponTypes> {
		return request("/coupon-types", "GET") as Promise<ICouponTypes>;
	}

	getCouponProducts(): Promise<ICouponProducts> {
		return request("/coupon-products", "GET") as Promise<ICouponProducts>;
	}

	createCoupon(data: CouponCreateDto): Promise<unknown> {
		return request(
			`${this.baseUrl}`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}
}

export const couponService = new CouponService(URLS.COUPONS);
