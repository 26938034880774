import React from "react";

import { Grid } from "@material-ui/core";

import { EmptyStateWrapper, HeaderWrapper, InfoWrapper } from "./styles";

interface EmptyStateProps {
	header: string;
	icon: React.ReactNode;
	description: string;
	button: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = ({ header, icon, description, button }) => (
	<EmptyStateWrapper>
		<Grid xs={12}>
			<HeaderWrapper>{header}</HeaderWrapper>
		</Grid>
		<Grid xs={12}>
			<InfoWrapper>
				<div className={"item"}> {icon}</div>
				<div className={"item"}> {description}</div>
				<div className={"item"}> {button}</div>
			</InfoWrapper>
		</Grid>
	</EmptyStateWrapper>
);
export default EmptyState;
