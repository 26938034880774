import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Breadcrumbs, Button, CircularProgress } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { ReactComponent as DeleteIconSvg } from "@remar/shared/dist/assets/icons/icon-trash-filled.svg";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";
import {
	ColumnHeader,
	Container,
	InvoiceDownloadIcon,
	StyledCellMultipleText,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle,
	THeaderWrapper
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { Lesson } from "@remar/shared/dist/models";
import { formatAdminDate } from "@remar/shared/dist/utils/myAccountUtils";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils/helpers";

import { useDispatch, useSelector } from "react-redux";

import { Link as RouterLink, useParams } from "react-router-dom";

import { getFullState as getAuthFullState } from "store/features/Auth/auth.slice";
import {
	deleteFileVaultFile,
	fetchFolders,
	fetchSubFolderFiles,
	fetchSubFolders,
	getFullState,
	resetModalForm,
	setSelectedFolder,
	setSelectedSubFolder
} from "store/features/FileVault/FileVault.slice";

import CheckPermissions from "core/CheckPermissions";
import { routes } from "core/constants";
import AttachmentDeleteModal from "modules/Components/AttachmentDeleteModal";

import { FileUploadModal } from "./FileUploadModal";

import { ActiveLink, HeaderSplit, Link, SectionHeader, SubFolderFilesTableSpacer } from "../styles";

const FileVaultSubFoldersFiles = () => {
	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState("");

	const { folderId, subFolderId } = useParams<{ folderId: string; subFolderId: string }>();
	const {
		isLoading,
		isDeleteLoading,
		subFolders,
		subFolderFiles: { files, page, perPage, totalItems },
		content,
		selectedFolder,
		selectedSubFolder
	} = useSelector(getFullState);
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getAuthFullState);
	const [modal, setModal] = useState(false);
	const [attachment, setAttachment] = useState<Record<string, unknown>>({});
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

	useEffect(() => {
		content.length === 0 && dispatch(fetchFolders(""));
		subFolders.length === 0 && dispatch(fetchSubFolders({ parentId: +folderId, searchText: "" }));
	}, []);

	useEffect(() => {
		if (folderId && content.length !== 0) {
			dispatch(setSelectedFolder({ folderId: +folderId }));
		}
		if (subFolderId && subFolders.length !== 0) {
			dispatch(setSelectedSubFolder({ folderId: +subFolderId }));
		}
	}, [subFolders, content, subFolderId, folderId, dispatch]);

	const fetchFiles = ({ page = 1, searchText = null }: { page?: number; searchText?: null | string }) => {
		if (selectedFolder && selectedSubFolder && selectedSubFolder.id == +subFolderId) {
			if (selectedSubFolder.includesLessonContent) {
				dispatch(
					fetchSubFolderFiles({
						folderId: selectedSubFolder.id,
						courseId: selectedFolder.courses[0].id as number,
						searchText: typeof searchText === "string" ? (searchText as string) : (inputValue as string),
						page
					})
				);
			} else {
				dispatch(
					fetchSubFolderFiles({
						folderId: selectedSubFolder.id,
						searchText: typeof searchText === "string" ? (searchText as string) : (inputValue as string),
						page
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchFiles({});
	}, [selectedSubFolder, selectedFolder, subFolderId]);

	const handleSearchBarChange = useCallback(
		searchText => {
			fetchFiles({ searchText });
		},
		[selectedSubFolder, selectedFolder]
	);

	const handleDelete = (id: number, folderId: number): void => {
		dispatch(
			deleteFileVaultFile({
				id,
				folderId,
				sideEffect: () => {
					setShowDeleteModal(false);
					fetchFiles({});
				}
			})
		);
	};

	const onCloseModal = () => {
		if (!isLoading) {
			setModal(false);
			dispatch(resetModalForm());
		}
	};

	const tableColumns = useMemo(
		() => [
			...(selectedSubFolder && selectedSubFolder.isNameVisible
				? [
						{
							alignment: "left",
							label: <ColumnHeader>Lesson Name</ColumnHeader>,
							Cell: ({ rowData: { lessons } }: { rowData: { lessons: Lesson[] } }) => {
								const lessonNames = lessons.length > 0 ? lessons.map(lesson => lesson.name).join(", ") : "N/A";
								return (
									<Box>
										<StyledCellMultipleText title={lessonNames}>{lessonNames}</StyledCellMultipleText>
									</Box>
								);
							},
							dataKey: "name"
						}
				  ]
				: []),
			{
				alignment: "left",
				label: <ColumnHeader>Date</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => {
					return (
						<StyledCellWrapper>
							<StyledCellText>{formatAdminDate(createdAt)}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "date"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Files</ColumnHeader>,
				Cell: ({ rowData: { name } }: { rowData: { name: string } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "files"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Download Files</ColumnHeader>,
				Cell: ({ rowData: { fileUrl, fileName } }: { rowData: { fileUrl: string; fileName: string } }) => (
					<StyledCellWrapper>
						<InvoiceDownloadIcon>
							<DownloadIconSvg
								onClick={() => {
									downloadURI(fileUrl, fileName);
								}}
							/>
						</InvoiceDownloadIcon>
					</StyledCellWrapper>
				),
				dataKey: "download"
			},
			{
				Cell: ({ rowData }) => {
					const showDelete = !!rowData.folders.find(f => f.id === selectedSubFolder!.id);
					return (
						<StyledCellWrapper>
							{showDelete && canEdit ? (
								<RouterLink
									to="#"
									onClick={() => {
										setAttachment(rowData);
										setShowDeleteModal(true);
									}}
								>
									<DeleteIconSvg />
								</RouterLink>
							) : null}
						</StyledCellWrapper>
					);
				},
				dataKey: "delete"
			}
		],
		[selectedSubFolder, files]
	);

	return (
		<>
			<Container>
				<HeaderSplit>
					<SubFolderFilesTableSpacer>
						<SectionHeader>FileVault</SectionHeader>
						<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
							<Link to="/">Dashboard</Link>
							<Link to={routes.fileVault.getPath()}>File Vault</Link>
							{selectedFolder && (
								<Link to={`${routes.fileVault.getPath()}/${selectedFolder.id}`}>{selectedFolder.name}</Link>
							)}
							{selectedSubFolder && selectedFolder && (
								<ActiveLink
									to={`${routes.fileVault.getPath()}/${selectedFolder.id}/subFolders/${selectedSubFolder.id}/files`}
								>
									{selectedSubFolder.name}
								</ActiveLink>
							)}
						</Breadcrumbs>
					</SubFolderFilesTableSpacer>
					<Box mr={6}>
						<CheckPermissions>
							<Button
								variant={"contained"}
								color={"primary"}
								onClick={() => {
									return setModal(true);
								}}
							>
								Upload Files
							</Button>
						</CheckPermissions>
					</Box>
					{selectedSubFolder && (
						<FileUploadModal
							modal={modal}
							onCloseModal={onCloseModal}
							sideEffect={() => fetchFiles({ page })}
							folderId={selectedSubFolder.id}
						/>
					)}
				</HeaderSplit>
				<Box mr={4}>
					<THeaderWrapper>
						<THeaderTitle>File Vault</THeaderTitle>
						<SearchBarComponent
							inputValue={inputValue}
							setInputValue={setInputValue}
							onChange={handleSearchBarChange}
							isDebounced={true}
							debounceTime={1000}
							placeHolder={"Type to filter by keywords"}
						/>
					</THeaderWrapper>
					{isLoading ? (
						<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
							<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
						</Box>
					) : (
						<>
							<MaterialTable
								columns={tableColumns as Array<IColumn<unknown>>} // todo: refactor - setup correct type
								data={files || []}
								height={800}
							/>
							<TablePagination
								count={totalItems}
								page={page}
								onChange={(_, page) => fetchFiles({ page })}
								rowsPerPage={perPage}
							/>
						</>
					)}
				</Box>
			</Container>
			<AttachmentDeleteModal
				isDeleteLoading={isDeleteLoading}
				text={`Are you sure you want to delete <span>${attachment?.name}</span> ?`}
				modalOpen={showDeleteModal}
				setModalOpen={setShowDeleteModal}
				onConfirm={() => handleDelete(attachment!.id as number, selectedSubFolder!.id)}
			/>
		</>
	);
};

export default FileVaultSubFoldersFiles;
