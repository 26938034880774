import React from "react";

import { Box, Button, TextField, Typography } from "@material-ui/core";

import { TransparentButton } from "@remar/shared/dist/ui/Buttons/styles";

import { StyledCheckbox } from "./styles";

const EditFields = (
	name,
	updateData,
	resetEditData,
	createNewSection,
	disabledSaveBtn,
	isAvailableForTrial = false
) => (
	<>
		<Box display="flex" alignItems="center">
			<Box width={120}>
				<Typography>Section Title</Typography>
			</Box>
			<Box width={350}>
				<TextField
					fullWidth
					hiddenLabel
					InputProps={{ style: { color: "#fff" }, disableUnderline: true }}
					inputProps={{ maxLength: 140 }}
					color="primary"
					onChange={(event: React.ChangeEvent<{ value: unknown }>) => updateData(event.target.value, "name")}
					variant="filled"
					size="small"
					placeholder="Enter Section Title"
					value={name}
				/>
			</Box>
		</Box>
		<Box display="flex" alignItems="center" justifyContent="center" mt={2}>
			<Box>
				<StyledCheckbox
					color="primary"
					checked={isAvailableForTrial}
					onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
						updateData(event.target.checked, "isAvailableForTrial")
					}
				/>
			</Box>
			<Box width={200}>
				<Typography>Include in Free Trial</Typography>
			</Box>
		</Box>
		<Box mt={20} ml={38}>
			<TransparentButton variant="text" onClick={resetEditData}>
				Cancel
			</TransparentButton>
			<Button
				variant={"contained"}
				color={"primary"}
				disabled={disabledSaveBtn || name.trim().length < 2}
				onClick={createNewSection}
			>
				Save
			</Button>
		</Box>
	</>
);

export default EditFields;
