/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";

import { Box, CircularProgress, MenuItem, Select } from "@material-ui/core";

import { ReactComponent as CrossIconSvg } from "@remar/shared/dist/assets/icons/icon-close-red.svg";
import { ReactComponent as DoneIconSvg } from "@remar/shared/dist/assets/icons/icon-message-check-green.svg";
import HeaderContainer from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";

import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";

import { Question } from "@remar/shared/dist/models";
import { useDispatch, useSelector } from "react-redux";
import { fetchCATAvailability, getFullState } from "store/features/CATAvailability/catAvailability.slice";
import { fetchAllCourses, getFullState as getCoursesFullState } from "store/features/Course/course.slice";

import { Container, TFormControl, THeaderWrapper } from "./styles";

const tableColumns = [
	{
		alignment: "left",
		label: <ColumnHeader>Requirement</ColumnHeader>,
		width: 900,
		Cell: ({ rowData: { requirement } }) => (
			<StyledCellWrapper>
				<StyledCellText>{requirement}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "requirement"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Current Count</ColumnHeader>,
		Cell: ({ rowData: { currentCount } }) => (
			<StyledCellWrapper>
				<StyledCellText>{currentCount}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "id"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Desired Count</ColumnHeader>,
		Cell: ({ rowData: { desiredCount } }) => (
			<StyledCellWrapper>
				<StyledCellText>{desiredCount}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "id"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Fulfilled</ColumnHeader>,
		Cell: ({ rowData: { fulfilled } }) => (
			<StyledCellWrapper>
				<StyledCellText>
					<Box display="flex" alignItems="center">
						{fulfilled ? <DoneIconSvg /> : <CrossIconSvg />} {fulfilled ? "Yes" : "No"}
					</Box>
				</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "id"
	}
];

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "CAT Availability", key: 1 }
];

const CATAvailability = () => {
	const dispatch = useDispatch();
	const { isLoading, requirements, isCATAvailable, isCATEnabled } = useSelector(getFullState);
	const { courses } = useSelector(getCoursesFullState);
	const [currentCourseId, setCurrentCourseId] = useState(0);

	useEffect(() => {
		courses?.length && setCurrentCourseId(courses![0]["id"]);
	}, [courses?.length]);

	useEffect(() => {
		!courses?.length && dispatch(fetchAllCourses());
	}, []);

	useEffect(() => {
		currentCourseId && dispatch(fetchCATAvailability({ courseId: +currentCourseId }));
	}, [currentCourseId, dispatch]);

	const handleSelect = id => {
		setCurrentCourseId(id);
	};

	return (
		<Container>
			<HeaderContainer heading={"CAT Availability"} breadcrumb={breadcrumb}></HeaderContainer>

			<Box pr={3}>
				<Box>
					<Box>
						<THeaderWrapper>
							{" "}
							<Box ml={2} display="flex">
								<TFormControl hiddenLabel size="small" variant="filled">
									<Select
										disableUnderline
										value={currentCourseId}
										onChange={event => handleSelect(event.target.value)}
										displayEmpty
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left"
											},
											getContentAnchorEl: null
										}}
									>
										<MenuItem value="">All courses</MenuItem>
										{courses?.map(({ id, name }) => (
											<MenuItem key={id} value={id}>
												{name}
											</MenuItem>
										))}
									</Select>
								</TFormControl>
								{/* <div></div> */}
								<Box display="flex" alignItems={"center"}>
									<Box>CAT Enabled: {isCATEnabled ? "Yes" : "No"}</Box>
									<Box ml={2}>CAT Available: {isCATAvailable ? "Yes" : "No"}</Box>
								</Box>
							</Box>
						</THeaderWrapper>
						{isLoading ? (
							<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
								<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
							</Box>
						) : (
							<>
								<MaterialTable
									columns={tableColumns as IColumn<Question | unknown>[]} // todo: refactor - setup correct type
									data={requirements ?? []}
									height={700}
								/>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</Container>
	);
};

export default CATAvailability;
