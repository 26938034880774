import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";

import { CATAvailability } from "@remar/shared/dist/models";

import { GetCATAvailabilityDto } from "./dto";

import { URLS } from "../services.constants";

export class UserCustomTestsService extends BaseApiService<
	CATAvailability,
	GetCATAvailabilityDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getCATAvailability(data: GetCATAvailabilityDto): Promise<CATAvailability> {
		return request(
			`${this.baseUrl}/cat-availibility`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<CATAvailability>;
	}
}

export const userCustomTestsService = new UserCustomTestsService(URLS.USER_CUSTOM_TESTS);
