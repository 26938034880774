import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto,
	FindResponse
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import {
	IDateFilters,
	ILoginReportFilter,
	ILoginReportResponse,
	ISocketNotifications,
	IStudentAccountInfo,
	IStudentPaymentHistory,
	IUserCourseCompletionDetailForAdmin,
	IUserReport,
	InviteData,
	User,
	UserSubscrptnType
} from "@remar/shared/dist/models";

import { IUserCustomTestResponse, IUserCustomTestsResponse } from "@remar/shared/dist/services/userCustomTests/dto";

import { format } from "date-fns";

import {
	AdminDto,
	ICancellationReasonSummary,
	InviteAdminPayload,
	StudentCountByCourseResult,
	UserLoginDto,
	UserLoginResponseDto
} from "./dto";

import { URLS } from "../services.constants";
import { StudentsAddCATDto } from "../students";

export class UsersService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	login(data: UserLoginDto): Promise<UserLoginResponseDto> {
		return request(`${this.baseUrl}/login`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<UserLoginResponseDto>;
	}

	logout(): Promise<void> {
		return request(`${this.baseUrl}/logout`, "POST", {}, { credentials: "include" }, false, true) as Promise<void>;
	}

	whoami(): Promise<{ user: User }> {
		return request(`${this.baseUrl}/whoami`, "GET") as Promise<{ user: User }>;
	}

	getAccount(id: string): Promise<{ user: IStudentAccountInfo }> {
		return request(`${this.baseUrl}/${id}/account`, "GET") as Promise<{ user: IStudentAccountInfo }>;
	}

	getSubscription(id: string): Promise<{ user: UserSubscrptnType }> {
		return request(`${this.baseUrl}/${id}/subscription`, "GET") as Promise<{ user: UserSubscrptnType }>;
	}

	getPaymentHistory(id: string, query: { page?: number }): Promise<IStudentPaymentHistory> {
		return request(
			`${this.baseUrl}/${id}/payment-history${this.serializeQuery(query as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<IStudentPaymentHistory>;
	}

	getStudentReport(userId: string, data: IDateFilters): Promise<IUserReport> {
		return request(
			`${this.baseUrl}/${userId}/report${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<IUserReport>;
	}

	getStudentLoginReport(userId: string, data: ILoginReportFilter): Promise<ILoginReportResponse> {
		return request(
			`${this.baseUrl}/${userId}/login-chart${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<ILoginReportResponse>;
	}

	getStudentTests(userId: string, data: BaseFindDto): Promise<IUserCustomTestsResponse> {
		return request(
			`${this.baseUrl}/${userId}/custom-tests${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<IUserCustomTestsResponse>;
	}

	getStudentTest(userId: string, testId: number): Promise<IUserCustomTestResponse> {
		return request(`${this.baseUrl}/${userId}/custom-tests/${testId}`, "GET") as Promise<IUserCustomTestResponse>;
	}

	getStudentCourseCompletion(userId: number, courseId: number): Promise<IUserCourseCompletionDetailForAdmin> {
		return request(
			`${this.baseUrl}/${userId}/course-details/${courseId}`,
			"GET"
		) as Promise<IUserCourseCompletionDetailForAdmin>;
	}

	getStudentCATQuota(userId: number, courseId: number): Promise<StudentsAddCATDto> {
		return request(
			`${this.baseUrl}/get-cat-quota-count-user/${userId}/${courseId}`,
			"GET"
		) as Promise<StudentsAddCATDto>;
	}

	addStudentCATQuota(userId: number, quantity: number): Promise<void> {
		return request(`${this.baseUrl}/add-cat-quota-to-user`, "POST", { userId, quantity }) as Promise<void>;
	}

	getTotalStudentsByCourse(data: Record<string, unknown>): Promise<Array<StudentCountByCourseResult>> {
		return request(
			`${this.baseUrl}/get-total-students-by-course${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<Array<StudentCountByCourseResult>>;
	}

	resetPassword(userId: number): Promise<{ url: string }> {
		return request(`${this.baseUrl}/${userId}/one-time-login-link`, "POST") as Promise<{ url: string }>;
	}

	resetCourseProgress(userId: number): Promise<unknown> {
		return request(`${this.baseUrl}/${userId}/reset-user-progress`, "POST") as Promise<unknown>;
	}

	getCancellationReasonSummary(startDate: Date, endDate: Date): Promise<ICancellationReasonSummary> {
		return request(
			`${this.baseUrl}/cancellation-reason-summary${this.serializeQuery(
				{ startDate: format(startDate, "yyyy-MM-dd"), endDate: format(endDate, "yyyy-MM-dd") },
				{ isTopLevel: true }
			)}`,
			"GET"
		) as Promise<ICancellationReasonSummary>;
	}

	verifyInvite(data: { accountClaimCode: string }): Promise<InviteData> {
		return request(
			`${this.baseUrl}/admin-invite-verification`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<InviteData>;
	}
	acceptInvite(data: {
		accountClaimCode: string;
		firstName: string;
		lastName: string;
		password: string;
	}): Promise<UserLoginResponseDto> {
		return request(
			`${this.baseUrl}/accept-admin-invite`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserLoginResponseDto>;
	}

	getUserNotifications(data: { filters: Record<string, unknown> }): Promise<ISocketNotifications> {
		return request(
			`${this.baseUrl}/notifications${this.serializeQuery(data as Record<string, unknown>, { isTopLevel: true })}`,
			"GET"
		) as Promise<ISocketNotifications>;
	}

	markNotificationsAsRead(): Promise<void> {
		return request(`${this.baseUrl}/notifications-read`, "POST") as Promise<void>;
	}

	inviteAdmin(data: InviteAdminPayload): Promise<void> {
		return request(`${this.baseUrl}/invite-admin`, "POST", data) as Promise<void>;
	}

	updateInvitation(data: BaseUpdateDto<InviteAdminPayload>): Promise<void> {
		return request(`${this.baseUrl}/invite-admin`, "PATCH", data) as Promise<void>;
	}

	getAdmins(data: BaseFindDto): Promise<FindResponse<AdminDto>> {
		return request(
			`${this.baseUrl}/admins${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<FindResponse<AdminDto>>;
	}

	cancelInvitation(id: number): Promise<void> {
		return request(`${this.baseUrl}/cancel-invitation`, "POST", { id }) as Promise<void>;
	}
}

export const usersService = new UsersService(URLS.USERS);
