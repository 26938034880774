import { Box, Button, Modal, CircularProgress as Progress, TextField } from "@material-ui/core";
import Icon from "@material-ui/core/SvgIcon";
import { basic } from "@remar/shared/dist/colors";
import { CustomInput as Input } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const CheckboxContainer = styled(Box)`
	padding: 10px;
	background: #2a2e37;
	display: flex;
	flex-flow: row nowrap;
`;

export const CheckboxLabel = styled.div`
	flex-grow: 1;
	display: flex;
	flex-flow: column;
	opacity: 0.83;
	font-family: var(--openSans);
	font-size: 13px;
	line-height: 1.54;
	flex-basis: 100%;
	color: #fff;
	justify-content: center;

	> span {
		font-size: 13px;
		line-height: 1.23;
		color: rgba(255, 255, 255, 0.6);
	}
`;

export const RoundedIcon = styled(Icon)`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: #172e4b;
	padding: 13px;
	margin-right: 5px;
	flex-grow: 0;

	* {
		filter: grayscale(1);
	}
`;

export const FieldLabel = styled.label`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: rgba(255, 255, 255, 0.6);
	margin: 10px 0 0;
`;

export const InputHolder = styled.div`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: 600;
	line-height: 1.85;
	color: rgba(255, 255, 255, 0.6);
	margin: 10px 0 0;
`;

export const FoldersContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: start;
	margin: 0 -10px;
`;

export const FolderTitle = styled(RouterLink)`
	flex-grow: 1;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Folder = styled.div`
	width: 302px;
	padding: 25px 10px 25px 25px;
	font-family: var(--openSans);
	font-size: 15px;
	line-height: 1.33;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`;

export const FolderVisibilityContainer = styled.div`
	width: 302px;
	padding: 25px 10px 10px 25px;
	font-family: var(--openSans);
	font-size: 15px;
	line-height: 1.33;
`;

export const FolderVisibility = styled.div`
	opacity: 0.9;
`;

export const FolderSeparator = styled.div`
	width: 303px;
	height: 1px;
	position: absolute;
	opacity: 0.15;
	border: solid 1px #979797;
`;

export const FolderVisibilitySubText = styled.div`
	margin: 6px 0 0 0;
	opacity: 0.33;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: normal;
`;

export const SectionHeader = styled.div`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #fff;
	margin: 0 0 13px;
`;

export const ButtonsRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: end;
	border-top: solid 1px #2a2e37;
	margin: 47px -26px -10px;
	padding: 15px 26px 0;
`;

export const CustomInput = styled(Input)`
	width: 100%;
	margin: 5px 0 0;
`;

export const StyledCheckbox = styled(Input)`
	flex-basis: 0;
	display: flex;
	align-items: center;

	label {
		margin-right: 0;
	}
`;
export const ModalTitle = styled.h1`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: 26px;
	font-weight: bold;
	line-height: 1.23;
	color: #fff;
	padding: 0;
	margin: 0;
	text-align: left;
`;

export const DeleteConfirmContent = styled.div`
	width: 480px;
	position: relative;
	background: ${basic[900]};
	padding: 16px 8px;
	overflow: hidden;
`;

export const DeleteCancelButton = styled(Button)`
	background: transparent;
	border-radius: 4px;
	border: solid 1px #94979f;
	color: #94979f;
	margin-right: 16px;
`;
export const DeleteConfirmButton = styled(Button)<{ subFolder: boolean }>`
	background: #46191e;
	color: #ed5465;
	font-family: var(--poppins);
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;
	width: ${({ subFolder }) => (subFolder ? "200px" : "160px")};
	box-sizing: border-box;
`;

export const FolderIconContainer = styled.div`
	height: 128px;
	filter: grayscale(1);
	display: flex;
	flex-flow: column;
	align-items: center;

	justify-content: center;
`;
export const FormModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const FolderModalContent = styled.div`
	width: 600px;
	background: #22252d;
	padding: 26px;
	display: flex;
	flex-flow: column;
`;

export const SvgIcon = styled(Icon)`
	width: 70px;
	height: 70px;
`;

export const VaultContainer = styled.div`
	margin-right: 44px;
`;

export const HeaderSplit = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 27px 0 0 0;
`;

export const Link = styled(RouterLink)`
	font-family: var(--openSans);
	font-size: 14px;
	line-height: 1.23;
	color: #fff !important;
	opacity: 0.38;
`;

export const ActiveLink = styled(Link)`
	opacity: 1;
`;

export const SearchPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 14px;
	background: #22252d;
	color: #fff;
	margin: 28px 0 21px;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.6;

	> div:first-child {
		flex-grow: 1;
	}

	.search-icon {
		margin-left: 0;
		padding-left: 10px;
		background-color: ${({ theme }) => theme.palette.colors.basic[1000]};
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
`;

export const Search = styled(TextField)`
	background-color: ${({ theme }) => theme.palette.colors.basic[1000]};
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	width: 450px;
	flex-grow: 1;
	color: #fff;
`;

export const CircularProgress = styled(Progress)`
	margin-left: 50%;
	tranform: translate(-50%);
`;

export const EmptyPanel = styled.div`
	height: 360px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background: #22252d;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #fff;
`;

export const SubFolderInputLabel = styled.div`
	margin: 20px 0 5px;
	color: rgba(255, 255, 255, 0.6);
`;

export const InvoiceDownloadIcon = styled.div`
	> svg {
		height: 100% !important;
		cursor: pointer;
	}
`;

export const SubFolderFilesTableSpacer = styled.div`
	margin-bottom: 15px;
`;

export const TransparentBgContainer = styled.div`
	> div {
		background: transparent;
	}
`;

export const CreatePersonalStorageFolderButton = styled(Button)`
	border-radius: 4px;
	background-color: #22252d;
	margin-right: 12px;
	color: rgba(255, 255, 255, 0.6);
`;
