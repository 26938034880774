import React from "react";

import { Card, FormControlLabel, TextField } from "@material-ui/core";

import DialogModal from "@remar/shared/dist/modals/DialogModal/DialogModal";
import { AccountStatusEnum } from "@remar/shared/dist/models";
import { useFormik } from "formik";

import isEmpty from "lodash/isEmpty";

import { inviteNewAdmin } from "store/features/ManageAdmins/manageAdmins.slice";
import { useAppDispatch } from "store/hooks";

import { AdminDto, InviteAdminPayload, UserTypeEnum } from "store/services";

import * as Yup from "yup";

import { SelectInput, SwitchInput } from "modules/Components/FormikFields";
import { InputField } from "modules/Components/InputField";

const defaultValues: Partial<InviteAdminPayload> = {
	email: "",
	adminRoleId: undefined,
	inActive: undefined
};

const validationSchema = Yup.object({
	email: Yup.string().email("Invalid email").required("Required"),
	adminRoleId: Yup.number().required("Required")
});

type InviteAdminProps = {
	entity: AdminDto | null;
	onClose: () => void;
};
const InviteAdmin = ({ entity, onClose }: InviteAdminProps) => {
	const dispatch = useAppDispatch();

	const {
		values: { email, adminRoleId, inActive },
		isValid,
		handleChange,
		handleBlur,
		touched,
		errors,
		resetForm,
		handleSubmit
	} = useFormik({
		initialValues: entity?.id
			? {
					email: entity.email,
					adminRoleId: entity.assignedUserTypes![0].id,
					inActive: entity.accountStatus.id === AccountStatusEnum.Inactive
			  }
			: defaultValues,
		validationSchema,
		onSubmit: v => {
			dispatch(inviteNewAdmin({ ...v, id: entity.id })).then(() => handleClose());
		}
	});

	const { id } = entity;

	const handleClose = () => {
		resetForm();
		onClose();
	};

	return (
		<DialogModal
			title={`${id ? "Edit" : "Invite New"} Admin`} // Edit
			open={!!entity}
			onClose={handleClose}
			cancelBtnText="Cancel"
			successBtnText={id ? "Save" : "Invite"}
			successBtnDisabled={!isValid || isEmpty(touched)}
			onSuccess={() => handleSubmit()}
			onCancel={handleClose}
		>
			<Card
				component="form"
				onSubmit={e => handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>)}
				style={{ boxShadow: "none" }}
			>
				<InputField
					title="Email"
					inputComponent={
						<TextField
							fullWidth
							hiddenLabel
							InputProps={{ style: { color: "primary" }, disableUnderline: true }}
							inputProps={{ maxLength: 140 }}
							color="primary"
							id="email"
							name="email"
							onChange={handleChange}
							onBlur={handleBlur}
							variant="filled"
							size="small"
							error={!!errors.email && touched.email}
							helperText={!!errors.email && touched.email && errors.email}
							placeholder="Email"
							value={email}
						/>
					}
				/>
				<InputField
					title="Role"
					inputComponent={
						<SelectInput
							id="adminRoleId"
							name="adminRoleId"
							fullWidth
							placeholder="Select Role"
							disableUnderline
							value={adminRoleId}
							onChange={handleChange}
							displayEmpty
							options={[
								{ label: "Support", value: UserTypeEnum.CustomerSupport },
								{ label: "Manager", value: UserTypeEnum.ContentManager }
							]}
						/>
					}
				/>
				<InputField
					title="Role"
					inputComponent={
						<FormControlLabel
							control={
								<SwitchInput
									checked={inActive}
									value={inActive}
									onChange={handleChange}
									id="inActive"
									name="inActive"
								/>
							}
							label={"InActive"}
						/>
					}
				/>
			</Card>
		</DialogModal>
	);
};

export default InviteAdmin;
