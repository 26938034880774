import React, { FC, useState } from "react";

import { ReactComponent as CloseIconSvg } from "@remar/shared/dist/assets/icons/icon-close-circle.svg";
import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded/FilesUploaded";

import Uploader from "@remar/shared/dist/components/Uploader";
import { LessonAttachments } from "store/features/CreateLesson/models";

import { emit } from "store/features/notifications/notifications.slice";

import { ReactComponent as UploadIconSvg } from "assets/icons/icon-upload-media.svg";

import AttachmentDeleteModal from "modules/Components/AttachmentDeleteModal";

import { MediaUploadContainer, MediaUploadHeading, useCreateLessonStyles } from "../../createLesson.styles";

function handleDeleteSignature(id: number | undefined, inputStatePath: string, sideEffect): void {
	console.log(id, inputStatePath, sideEffect);
}

function handleUploadSignature(file: Partial<File>, inputStatePath: string): void {
	console.log(file, inputStatePath);
}

interface Props {
	handleDelete?: typeof handleDeleteSignature;
	handleUpload: typeof handleUploadSignature;
	statePath: string;
	lessonAttachments: LessonAttachments;
	uploadError?: boolean;
	uploaderProgress?: number;
	uploaderProps?: { [propName: string]: unknown };
}

export const AttachmentUploader: FC<Props> = ({
	handleDelete,
	handleUpload,
	statePath,
	lessonAttachments,
	uploadError,
	uploaderProgress,
	uploaderProps = {}
}) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [attachment, setAttachment] = useState<Record<string, unknown>>({});
	const classes = useCreateLessonStyles();
	const { attachments, isLoading } = lessonAttachments;

	const sideEffect = () => {
		setShowDeleteModal(false);
	};

	return (
		<>
			<MediaUploadContainer width="24%">
				<MediaUploadHeading>UPLOAD MATERIAL</MediaUploadHeading>
				<Uploader
					{...uploaderProps}
					loading={isLoading}
					error={uploadError}
					icon={<UploadIconSvg />}
					header={"Upload Media"}
					progress={uploaderProgress}
					onAdd={files => handleUpload(files[0], statePath)}
					emit={emit}
				/>
				<div className={classes.spacer}></div>
				<FilesUploaded
					attachments={attachments}
					IconSvg={CloseIconSvg}
					onClick={attachment => {
						setShowDeleteModal(true);
						setAttachment(attachment);
					}}
				/>
			</MediaUploadContainer>
			<AttachmentDeleteModal
				isDeleteLoading={lessonAttachments.isDeleteLoading}
				text={`Are you sure you want to delete <span>${attachment?.name}</span> ?`}
				modalOpen={showDeleteModal}
				setModalOpen={setShowDeleteModal}
				onConfirm={() => handleDelete && handleDelete(attachment?.id as number, statePath, sideEffect)}
			/>
		</>
	);
};
