import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH, QuestionTypes } from "@remar/shared/dist/constants";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import CommonQuestionFields from "./CommonQuestionFields";
import { useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";
import AnswerOption from "../components/AnswerOption";
import AnswerOptionDropZone from "../components/AnswerOptionDropZone";

export const GroupingQuestion = ({
	createQuestionAnswerOptionItem,
	getFullState,
	initGroupingQuestion,
	moveGroupQuestionAnswerOption,
	setStateValue,
	validateForm,
	statePath = "",
	removeQuestionAttachment,
	uploadQuestionMaterial,
	formName = "testOnlyQuestionsForm"
}) => {
	const dispatch = useDispatch();
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const lesson = get(useSelector(getFullState), "lesson");
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const {
		groups,
		description,
		text,
		_expanded,
		id,
		endOfQuestionSummary,
		mainImageKey,
		attachments = []
	} = questionData;
	const expanded = _expanded.value;

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(
				initGroupingQuestion({
					formPath: formName,
					questionPath: `${formName}.${fullStatePath}`,
					templatePath: "questions.groups"
				})
			);
		}
	}, [groups.length]);

	return (
		<CommonQuestionFields
			statePath={statePath}
			lessonId={lesson?.id}
			expanded={expanded}
			endOfQuestionSummary={endOfQuestionSummary}
			mainImageKey={mainImageKey}
			attachments={attachments}
			questionId={id.value}
			description={description}
			setStateValue={setStateValue}
			validateForm={validateForm}
			removeQuestionAttachment={removeQuestionAttachment}
			uploadQuestionMaterial={uploadQuestionMaterial}
			mediaAfterChildren
			hideDescription
		>
			{expanded && (
				<>
					<Typography className={classes.addOptionHeader}>SENTENCE</Typography>
					<CustomInput style={{ flex: 1 }} theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
				</>
			)}

			<Typography className={classes.addOptionHeader}>ANSWER OPTIONS</Typography>
			<DndProvider backend={HTML5Backend}>
				<Box display="flex" flexDirection="row" width="calc(100% - 51px)" mt="1" style={{ gap: "24px" }}>
					{groups.map((answerGroupInputs, answerGroupIndex) => {
						const { text, answerOptions } = answerGroupInputs;
						let answerOptionCount = 0;
						return (
							<div
								style={{ border: "solid 1px #4d5058", flex: "1", padding: "11px 10px 10px" }}
								key={`createLesson-qestionItem-answerGroup-${answerGroupIndex}`}
							>
								<CustomInput theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
								<AnswerOptionDropZone
									canAlwaysDrop={true}
									index={0}
									onDrop={data => {
										const { oldDropZoneIndex, order } = data;
										if (
											answerGroupIndex === oldDropZoneIndex ||
											answerOptions.filter(x => !x.deleted.value).length < MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH
										) {
											dispatch(
												moveGroupQuestionAnswerOption({
													templatePath: "questions.groups.answerOptions",
													formPath: formName,
													groupsPath: `${formName}.${fullStatePath}.groups`,
													newGroupIndex: answerGroupIndex!,
													oldGroupIndex: oldDropZoneIndex!,
													oldOptionIndex: order,
													newOptionIndex: 0
												})
											);
										}
									}}
								>
									<Box style={{ minHeight: 10 }} />
								</AnswerOptionDropZone>
								{answerOptions.map((answerOptionInputs, answerOptionIndex) => {
									const { deleted, text } = answerOptionInputs;
									if (deleted.value) {
										return null;
									}
									answerOptionCount++;
									return (
										<AnswerOptionDropZone
											canAlwaysDrop={true}
											dropZoneIndex={answerGroupIndex}
											key={`createLesson-qestionItem-answerGroup-${answerGroupIndex}-answerOption-${answerOptionIndex}`}
											index={answerGroupIndex}
											onDrop={data => {
												const { oldDropZoneIndex, newDropZoneIndex, order } = data;
												if (
													oldDropZoneIndex === newDropZoneIndex ||
													answerOptions.filter(x => !x.deleted.value).length < MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH
												) {
													dispatch(
														moveGroupQuestionAnswerOption({
															templatePath: "questions.groups.answerOptions",
															formPath: formName,
															groupsPath: `${formName}.${fullStatePath}.groups`,
															newGroupIndex: answerGroupIndex!,
															oldGroupIndex: oldDropZoneIndex!,
															oldOptionIndex: order,
															newOptionIndex: answerOptionIndex + 1
														})
													);
												}
											}}
										>
											<AnswerOption
												formName={formName}
												statePath={statePath}
												answerOptionIndex={answerOptionIndex}
												defaultCustomInputOptions={defaultCustomInputOptions}
												dropZoneIndex={answerGroupIndex}
												key={`createLesson-qestionItem-answerGroup-${answerGroupIndex}-answerOption-${answerOptionIndex}`}
												questionPath={`groups.${answerGroupIndex}`}
												typeId={QuestionTypes.Grouping}
												text={text}
											/>
										</AnswerOptionDropZone>
									);
								})}
								<Box mt={2}>
									<AddButton
										isDisabled={answerOptionCount >= MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH}
										onClick={() => {
											if (answerOptionCount < MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH) {
												dispatch(
													createQuestionAnswerOptionItem({
														formName,
														inputsPath: `${fullStatePath}.groups.${answerGroupIndex}.answerOptions`,
														parentPath: fullStatePath,
														templatePath: "questions.groups.answerOptions"
													})
												);
											}
										}}
										label={"Add option"}
									/>
								</Box>
							</div>
						);
					})}
				</Box>
			</DndProvider>
		</CommonQuestionFields>
	);
};
