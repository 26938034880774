import { BaseApiService, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { Package } from "@remar/shared/dist/models";

import {
	LocationPackageCreateDto,
	PackageDeleteDto,
	PackagePublishUpdateDto,
	UpdateAssignLocationToPackageDto,
	UpdateInviteLocationToPackageDto
} from "./dto";

import { URLS } from "../services.constants";

export class PackagesService extends BaseApiService<
	Package,
	LocationPackageCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	PackagePublishUpdateDto,
	PackageDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
	publishOrUnpublishPackage(data: PackagePublishUpdateDto): Promise<unknown> {
		return request(
			`${this.baseUrl}`,
			"PATCH",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<unknown>;
	}

	assignLocationToPackage(data: UpdateAssignLocationToPackageDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/assign`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{}
		) as Promise<unknown>;
	}
	inviteLocationToPackage(data: UpdateInviteLocationToPackageDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/invite`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{}
		) as Promise<unknown>;
	}
	cancelInviteLocationToPackage(data: UpdateInviteLocationToPackageDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/cancel-invite`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{}
		) as Promise<unknown>;
	}
}

export const packagesService = new PackagesService(URLS.LOCATION_PACKAGE);
