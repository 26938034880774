import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { subjectCategoriesService } from "@remar/shared/dist/services/subjectCategories";
import { SubjectCategories } from "@remar/shared/dist/services/subjectCategories/modal";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { AppThunk, RootState } from "store";

import { emit } from "../notifications/notifications.slice";

export interface SubjectCategoriesState {
	isLoading: boolean;
	subjectCategories: SubjectCategories;
	errorMessage: string;
}

const initialState: SubjectCategoriesState = {
	isLoading: false,
	subjectCategories: {
		page: 1,
		perPage: 0,
		items: [],
		totalItems: 0,
		totalPages: 0,
		more: false
	},
	errorMessage: ""
};

export const subjectCategoriesSlice = createSlice({
	name: "subjectCategories",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setSubjectCategories: (state, action: PayloadAction<SubjectCategories>) => {
			state.subjectCategories = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue
	}
});

export const fetchAllSubjectCategories =
	(locationId: number): AppThunk =>
	dispatch => {
		dispatch(setLoading(true));
		subjectCategoriesService
			.find({
				findAll: true,
				orderBy: { createdAt: "DESC" },
				filters: { locationId }
			})
			.then(r => {
				dispatch(setSubjectCategories(r as unknown as SubjectCategories));
			})
			.catch(err => {
				dispatch(emit({ message: err.message, color: "error" }));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export function getFullState(state: RootState): SubjectCategoriesState {
	return state.subjectCategories;
}

export const { setLoading, failed, setStateValue, setSubjectCategories } = subjectCategoriesSlice.actions;

export default subjectCategoriesSlice.reducer;
