import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useTestOnlyPreviewStyles = makeStyles(() => ({
	buttonsRow: {
		textAlign: "right",
		padding: "72px 16px 16px",
		background: "#eceff4",
		width: "100%"
	},
	lessonPreviewTitle: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		opacity: " 0.87",
		fontFamily: "var(--openSans)",
		fontSize: "18px",
		fontWeight: "bold",
		letterSpacing: "normal",
		color: "#fff",
		backgroundColor: "#264fae",
		paddingLeft: "20px",
		paddingRight: "20px"
	},
	previewContainer: {
		background: "#eceff4",
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "5%"
	},
	previewContentContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "81vh",
		overflowY: "scroll"
	},
	previousQuestionButton: {
		marginRight: "10px"
	},
	questionAnswerOption: {
		width: "100%",
		padding: "0 16px",
		display: "flex",
		alignItems: "center"
	},
	questionContent: {
		width: "100%",
		flexGrow: 1,
		padding: "16px 16px 0 16px",
		color: "#4d5058",
		display: "flex",
		flexFlow: "column",
		fontFamily: "var(--openSans)",
		fontWeight: 600,
		fontSize: "15px",
		lineHeight: 1.6
	},
	questionRationale: {
		fontFamily: "var(--openSans)",
		paddingTop: "14px",
		paddingLeft: "20px",
		paddingRight: "20px",
		paddingBottom: "25px",
		marginTop: "20px",
		backgroundColor: "#cbcfd8",
		borderRadius: "5px"
	},
	questionRationaleImage: {
		borderRadius: "5px",
		height: "150px"
	},
	questionText: {
		fontFamily: "var(--openSans)",
		fontSize: "13px",
		fontWeight: 600,
		lineHeight: 1.85,
		color: "#4d5058",
		padding: "0 14px 14px"
	},
	questionTitle: {
		padding: "14px 14px 8px",
		margin: 0,
		flex: 0,
		fontFamily: "var(--openSans)",
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6,
		color: "#4d5058"
	}
}));

export const GroupingContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
`;

export const GroupingItem = styled.div`
	display: flex;
	width: 40%;
	box-sizing: border-box;
	border: 8px solid #d8d8d8;
	margin-right: 10%;
	background: #fff;
`;

export const SequenceAnswerOptionContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	width: 50%;
	margin: 8px 0;
`;

export const SequenceIndex = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: 600;
	color: #4d5058;
	background: #fff;
	margin-right: 8px;
`;

export const SequenceText = styled.div`
	height: 48px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: var(--openSans);
	display: flex;
	flex-grow: 1;
	padding-left: 16px;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	color: #4d5058;
	background: #fff;
`;

export const SequenceDrag = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: 34px;
	font-weight: 600;
	color: #4d5058;
	background: #fff;
	cursor: grabbing;
`;
