import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { School } from "@remar/shared/dist/models";

import { SchoolMergeDataDto } from "./dto";

import { URLS } from "../services.constants";

export class SchoolsService extends BaseApiService<
	School,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	merge(data: SchoolMergeDataDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/merge`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const schoolsService = new SchoolsService(URLS.SCHOOLS);
