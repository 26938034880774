import { CourseChapter, CourseChapterSection } from "@remar/shared/dist/models";

import { DraggableSrcInterface, LocationOptions, LockChapterDuration, LockChapterPeriods } from "./types";

interface ReorderSectionLessonArg {
	startSrc: DraggableSrcInterface;
	endSrc: DraggableSrcInterface;
	sections: CourseChapterSection[];
}

export const computeReorder = (
	info: ReorderSectionLessonArg
): { newOrder: CourseChapterSection[]; selectedLesson: CourseChapterSection } => {
	const { startSrc, sections, endSrc } = info;

	const _section = [...sections];
	let selectedLesson;
	const newOrder = _section.map(el => {
		if (el.id === Number(startSrc.droppableId)) {
			const sortedLessons = el.sectionLessons!.concat().sort((a, b) => a.order - b.order);
			const [removed] = sortedLessons.splice(startSrc.index, 1);
			selectedLesson = removed;
			sortedLessons.splice(endSrc.index, 0, removed);
			const updatedOrderLessons = sortedLessons.map((lesson, index) => {
				return { ...lesson, order: index };
			});
			return { ...el, sectionLessons: updatedOrderLessons };
		} else {
			return el;
		}
	});
	return { newOrder, selectedLesson };
};

export const reorderChapters = (
	chapters: CourseChapter[],
	swapTo: { index: number },
	swapFrom: { index: number }
): { reorderedChapters: CourseChapter[]; selectedChapterId: number } => {
	const reorderedChapters = [...chapters];
	const selectedChapterId = reorderedChapters[swapFrom.index]?.id;
	const [removed] = reorderedChapters.splice(swapFrom.index, 1);
	const newOrder = swapTo.index > swapFrom.index ? chapters[swapTo.index].order + 1 : chapters[swapTo.index].order;
	reorderedChapters.splice(swapTo.index, 0, { ...removed, order: newOrder });
	const updatedOrderChapters = reorderedChapters.map((chapter, index) => {
		return { ...chapter, order: index };
	});
	return { reorderedChapters: updatedOrderChapters, selectedChapterId: selectedChapterId as number };
};

export const targetLocationOptions = [
	{ label: "All Locations", value: LocationOptions.all },
	{ label: "Selected Locations", value: LocationOptions.selectedLocation }
];

export const lockChapterDurationOptions = [
	{ label: "Lifetime", value: LockChapterDuration.LIFETIME },
	{ label: "Limited", value: LockChapterDuration.LIMITED }
];

export const lockChapterPeriodOptions = [
	{
		label: "1 week",
		value: LockChapterPeriods.ONE_WEEK
	},
	{
		label: "2 weeks",
		value: LockChapterPeriods.TWO_WEEKS
	},
	{
		label: "3 weeks",
		value: LockChapterPeriods.THREE_WEEKS
	},
	{
		label: "4 weeks",
		value: LockChapterPeriods.FOUR_WEEKS
	}
];
