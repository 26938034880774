import { createStyles, makeStyles } from "@material-ui/core";

import { ReactComponent as IconCalender } from "@remar/shared/dist/assets/icons/icon-calender-filled.svg";
import { ReactComponent as IconClock } from "@remar/shared/dist/assets/icons/icon-clock.svg";
import { ReactComponent as IconCheck } from "@remar/shared/dist/assets/icons/message_check.svg";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import styled from "styled-components";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		select: {
			color: theme.palette.colors.basic[100],
			background: theme.palette.colors.basic[800],
			padding: "10px",
			width: "100%",
			fontSize: "14px",
			fontWeight: "bold",
			lineHeight: 1.33,
			"& > div": {
				background: theme.palette.colors.basic[800]
			},
			"& > div:focus": {
				background: theme.palette.colors.basic[800]
			}
		}
	})
);

export const StyledCalenderIcon = styled(IconCalender)`
	g {
		path {
			fill: ${props => props.theme.palette.colors.basic[600]};
		}
	}
`;
export const StyledTimeIcon = styled(IconClock)`
	g {
		path:last-child {
			fill: ${props => props.theme.palette.colors.basic[600]};
		}
	}
`;

export const StyledCheckIcon = styled(IconCheck)`
	g {
		path {
			fill: ${props => props.theme.palette.colors.primary[300]};
		}
	}
`;
