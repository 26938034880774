import React from "react";

import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import styled from "styled-components";

import basic from "../../../colors/basic";

import primary from "../../../colors/primary";
import { IExtendedTheme } from "../../../theme/default";

const useStyles = makeStyles((theme: IExtendedTheme) => ({
	addOptionButtonContainer: {
		cursor: "pointer",
		width: "fit-content",
		margin: "10px 0",
		"& button": {
			height: "20px",
			marginRight: "8px",
			minWidth: "20px",
			padding: "0px"
		}
	},
	addOptionLabel: {
		color: alpha(theme.palette.text.primary, 0.6),
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: 1.6
	}
}));
export const StyledBoxContainer = styled(Box)<{ $isDisabled: boolean }>`
	display: flex;
	align-items: center;
	${({ $isDisabled }) => ($isDisabled ? "cursor: not-allowed" : "")}
`;

const ButtonComponent = ({
	onClick,
	isDisabled = false,
	label
}: {
	onClick: () => void;
	isDisabled?: boolean;
	label: string;
}) => {
	const classes = useStyles();
	return (
		<StyledBoxContainer className={classes.addOptionButtonContainer} onClick={onClick} $isDisabled={isDisabled}>
			<Button style={{ background: isDisabled ? basic[800] : primary[600], borderRadius: "50%" }} disabled={isDisabled}>
				<Add style={{ color: basic[100], fontSize: "18px" }} />
			</Button>
			<Typography className={classes.addOptionLabel}>{label}</Typography>
		</StyledBoxContainer>
	);
};

export default ButtonComponent;
