import React from "react";

import { IconButton, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import DialogModal from "@remar/shared/dist/modals/DialogModal/DialogModal";

import { addSeats } from "store/features/ManageLocations/manageLocations.slice";
import { useAppDispatch } from "store/hooks";

import { AddSeatsCount, AddSeatsCounterBox, AddSeatsWrapper, LabelWrapper } from "./styles";

type AddSeatsProps = {
	locationId: number | null;
	handleClose: () => void;
};
/**
 * A component for adding seats to a location.
 *
 * @param {Object} AddSeatsProps - The props for the AddSeats component.
 * @param {string} AddSeatsProps.locationId - The ID of the location to add seats to.
 * @param {function} AddSeatsProps.handleClose - The function to handle closing the dialog.
 * @returns {JSX.Element} The rendered AddSeats component.
 */
const AddSeats = ({ locationId, handleClose }: AddSeatsProps) => {
	const dispatch = useAppDispatch();
	const [seatCount, setSeatCount] = React.useState(1);

	const handleAdd = () => {
		dispatch(addSeats({ locationId: locationId as number, numberOfSeats: seatCount, sideEffect: handleClose })).then(
			() => {
				setSeatCount(1);
			}
		);
	};

	const decrementSeatCount = () => setSeatCount(s => (s > 0 ? s - 1 : s));
	const incrementSeatCount = () => setSeatCount(s => s + 1);

	return (
		<DialogModal
			open={!!locationId}
			title="Add Seats"
			cancelBtnText="Cancel"
			successBtnText="Add"
			onClose={handleClose}
			onSuccess={handleAdd}
		>
			<AddSeatsWrapper>
				<LabelWrapper>
					<Typography className={"label sm"}>Add Additional Seats</Typography>
				</LabelWrapper>
				<AddSeatsCounterBox>
					<IconButton size="small" onClick={decrementSeatCount}>
						<Remove className={"addRemoveIcon"} />
					</IconButton>
					<AddSeatsCount>{seatCount}</AddSeatsCount>
					<IconButton size="small" onClick={incrementSeatCount}>
						<Add className={"addRemoveIcon"} />
					</IconButton>
				</AddSeatsCounterBox>
			</AddSeatsWrapper>
		</DialogModal>
	);
};

export default AddSeats;
