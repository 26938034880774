import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Question } from "@remar/shared/dist/models";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { RootState } from "store";
import { userCustomTestsService } from "store/services";
export interface CATAvailabilityState {
	isLoading: boolean;
	isCATAvailable: boolean;
	isCATEnabled: boolean;
	requirements: Question[];
	errorMessage: string;
}

const initialState: CATAvailabilityState = {
	isLoading: false,
	requirements: [],
	isCATAvailable: false,
	isCATEnabled: false,
	errorMessage: ""
};

export const catAvailabilitySlice = createSlice({
	name: "catAvailability",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue
	}
});

export const fetchCATAvailability = createAsyncThunk(
	"catAvailability/fetchCATAvailability",
	async (options: { courseId: number }, { dispatch, getState }) => {
		try {
			const { isLoading } = (
				getState() as {
					catAvailability: CATAvailabilityState;
				}
			).catAvailability;
			if (!isLoading) {
				dispatch(setLoading(true));
			}
			const { courseId } = options;
			const { requirements, isCATAvailable, isCATEnabled } = await userCustomTestsService.getCATAvailability({
				courseId
			});
			dispatch(setStateValue({ key: "requirements", value: requirements }));
			dispatch(setStateValue({ key: "isCATAvailable", value: isCATAvailable }));
			dispatch(setStateValue({ key: "isCATEnabled", value: isCATEnabled }));
			dispatch(setLoading(false));
		} catch (e) {
			dispatch(setLoading(false));
			return { error: e };
		}
	}
);

export function getFullState(state: RootState): CATAvailabilityState {
	return state.catAvailability;
}

export const { setLoading, failed, setStateValue } = catAvailabilitySlice.actions;

export default catAvailabilitySlice.reducer;
