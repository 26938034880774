import React, { useEffect, useRef, useState } from "react";

import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import { Box, ButtonGroup, Card, TextField } from "@material-ui/core";
import { Assessment, FileCopy, Loop, Mic, MicOff, Wifi, WifiOff } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";
import { Wrapper } from "@remar/shared/dist/layouts";
import { emit } from "store/features/notifications/notifications.slice";
import { useAppDispatch } from "store/hooks";
import { v4 as uuid } from "uuid";

import { GLOBAL_CONSTANTS } from "core/constants";

import { StyledIconButton } from "./styles";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "New Stream", key: 1 }
];

const StartStream = () => {
	const dispatch = useAppDispatch();

	const adaptorRef = useRef<typeof WebRTCAdaptor>(null);

	const [streamId, setStreamId] = useState<string>(uuid());
	const [isOnline, setIsOnline] = useState(false);
	const [isMuted, setIsMuted] = useState(false);

	useEffect(() => {
		adaptorRef.current = new WebRTCAdaptor({
			websocket_url: `${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_URL}/websocket`,
			mediaConstraints: {
				video: true,
				audio: true
			},
			peerconnection_config: {
				iceServers: JSON.parse(GLOBAL_CONSTANTS.ICE_SERVERS)
			},
			sdp_constraints: {
				OfferToReceiveAudio: false,
				OfferToReceiveVideo: false
			},
			localVideoId: "id-of-video-element",
			bandwidth: 900, // default is 900 kbps, string can be 'unlimited'
			dataChannelEnabled: false, // enable or disable data channel
			callback: (info, obj) => {
				if (info === "publish_started") {
					dispatch(emit({ message: `Stream ID: ${streamId} has been started!`, type: "success" }));
				}

				if (info === "updated_stats") {
					console.log(obj);
					console.log(`Stream ID: ${obj.streamId}, Viewers: ${obj.webRTCViewerCount}`);
				}

				if (info === "number_of_viewers") {
					console.log(`Stream ID: ${obj.streamId}, Viewers: ${obj.viewerCount}`);
				}
			},
			callbackError: (error, message) => {
				console.error(`WebRtcError: ${message}}`, error);
			} // check error callbacks bellow
		});
	}, [dispatch, streamId]);

	return (
		<Wrapper heading="Create Stream" breadcrumb={breadcrumb}>
			<Card>
				<video id="id-of-video-element" autoPlay muted width="100%" />
				<Box mt={2} display="flex" justifyContent="center" gridGap={16} alignItems="center">
					<StyledIconButton
						label={isMuted ? "unmute" : "mute"}
						muted={isMuted}
						backgroundColor={isMuted ? "danger" : "success"}
						onClick={() => {
							setIsMuted(m => !m);
							isMuted ? adaptorRef.current.unmuteLocalMic() : adaptorRef.current.muteLocalMic();
						}}
					>
						{isMuted ? <Mic /> : <MicOff />}
					</StyledIconButton>
					<TextField
						variant="outlined"
						fullWidth
						value={streamId}
						disabled={isOnline}
						onChange={e => setStreamId(e.target.value)}
						InputProps={{
							endAdornment: (
								<Box display="flex" gridGap={8} ml={2}>
									<FileCopy
										style={{ cursor: "pointer" }}
										onClick={async () => {
											await navigator.clipboard.writeText(streamId);
											dispatch(
												emit({
													message: `Stream id: ${streamId} copied to clipboard`,
													type: "success"
												})
											);
										}}
									/>
									<Loop
										style={{ cursor: isOnline ? "not-allowed" : "pointer" }}
										onClick={() => !isOnline && setStreamId(uuid())}
									/>
								</Box>
							)
						}}
					/>
					<ButtonGroup fullWidth>
						<Button
							variant="filled"
							color={isOnline ? "danger" : "success"}
							startIcon={isOnline ? <WifiOff /> : <Wifi />}
							size="large"
							onClick={() => {
								setIsOnline(o => !o);
								isOnline ? adaptorRef.current?.stop(streamId) : adaptorRef.current?.publish(streamId);
							}}
						>
							{isOnline ? "Stop Stream" : "Go Live"}
						</Button>
						<Button
							variant="filled"
							color="primary"
							size="large"
							startIcon={<Assessment />}
							onClick={async () => {
								adaptorRef.current.getStats(streamId);
								console.log(adaptorRef.current.getStreamInfo(streamId));
							}}
						>
							Get Stats
						</Button>
					</ButtonGroup>
				</Box>
			</Card>
		</Wrapper>
	);
};

export default StartStream;
