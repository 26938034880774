import React, { FC } from "react";

import { Box, Typography } from "@material-ui/core";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { CustomInputModel } from "@remar/shared/dist/components/CustomInput/customInput.model";
import Uploader from "@remar/shared/dist/components/Uploader";
import { ERROR_FIELD_COLOR } from "@remar/shared/dist/constants";
import { LessonVideo } from "@remar/shared/dist/models";

import { setStateValue, validateForm } from "store/features/CreateLesson/createLesson.slice";

import { emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

function handleDeleteSignature(inputStatePath: string): void {
	console.log(inputStatePath);
}

function handleUploadSignature(file: Partial<File>, inputStatePath: string, maxFileSizeMb?: number): void {
	console.log(file, inputStatePath, maxFileSizeMb);
}

interface Props {
	boxProps?: { [propName: string]: unknown };
	defaultCustomInputOptions: {
		_emit: unknown;
		setStateValue: typeof setStateValue;
		validateForm: typeof validateForm;
	};
	handleDelete: typeof handleDeleteSignature;
	handleUpload: typeof handleUploadSignature;
	inputData: CustomInputModel<number>;
	maxFileSizeMb?: number;
	uploadError?: boolean;
	uploaderProgress?: number;
	uploaderProps?: { [propName: string]: unknown };
	lastUploadedVideo?: LessonVideo;
}

export const VideoUploader: FC<Props> = ({
	boxProps = {},
	defaultCustomInputOptions,
	handleDelete,
	handleUpload,
	inputData,
	maxFileSizeMb,
	uploadError,
	uploaderProgress,
	uploaderProps = {},
	lastUploadedVideo
}) => {
	const { error, isLoading, label, readonly, statePath } = inputData;
	let videoOptionLabel = "";
	if (lastUploadedVideo) {
		videoOptionLabel = lastUploadedVideo.name;
	}
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			{...boxProps}
			border={error || uploadError ? 1 : 0}
			borderColor={error || uploadError ? ERROR_FIELD_COLOR : ""}
		>
			<Box mb={2}>
				<Typography>{label}</Typography>
			</Box>
			<Uploader
				{...uploaderProps}
				error={uploadError}
				confirmTitle={`Are you sure you want to delete uploaded video "${videoOptionLabel}"?`}
				disabled={readonly || isLoading}
				loading={isLoading}
				progress={uploaderProgress}
				accept={"video/*"}
				onAdd={files => handleUpload(files[0], statePath, maxFileSizeMb)}
				onRemove={() => {
					handleDelete(statePath);
				}}
				emit={emit}
			/>
			<Box mt={4} mb={4}>
				<Typography>Or Choose From Media Library</Typography>
			</Box>
			<CustomInput width={380} theme={theme} options={{ ...defaultCustomInputOptions, inputData }}></CustomInput>
		</Box>
	);
};
