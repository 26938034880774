import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	CustomInputType,
	DefaultValueGetter,
	DefaultValueGettersObject
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import { FormStateModel } from "@remar/shared/dist/utils/form/form.model";
import {
	createForm,
	getPopulateInputsAction,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";

import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { RootState } from "store";
import { SchoolMergeDataDto, schoolsService } from "store/services";

import { v4 as uuid } from "uuid";

import { MergeSchoolFormInputs, MergeSchoolFormRawData } from "./models";

import { fetchSchools } from "../ManageSchools/manageSchools.slice";
import { emit } from "../notifications/notifications.slice";
export interface MergeSchoolState {
	isLoading: boolean;
	errorMessage: string;
	mergeNewSchoolForm: FormStateModel<MergeSchoolFormInputs, MergeSchoolFormRawData, {}>;
}

const getSchoolForm = () => ({
	id: { type: CustomInputType.Number },
	mergeId: { type: CustomInputType.Number }
});

export const initialState: MergeSchoolState = {
	isLoading: false,
	errorMessage: "",
	mergeNewSchoolForm: createForm<MergeSchoolFormInputs, MergeSchoolFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "mergeNewSchoolForm",
		inputs: {
			...getSchoolForm(),
			schools: {
				label: "Existing School Name",
				placeholder: "Existing School Name",
				selectOptions: [],
				type: CustomInputType.Select,
				validations: {
					isArray: true
				}
			}
		}
	})
};

const defaultValueGetters: DefaultValueGettersObject = {
	uuidV4: (() => uuid()) as DefaultValueGetter
};
const utilsResetState = getResetState<MergeSchoolState>(initialState);

export const save = createAsyncThunk(
	"addSchool/merge",
	async ({ success }: { success: () => void }, { dispatch, getState }) => {
		await dispatch(setMergeStateValue({ key: "isLoading", value: true }));
		try {
			const data = (getState() as { mergeSchool: MergeSchoolState }).mergeSchool.mergeNewSchoolForm.rawData;
			if (data) {
				const { mergeId = [], id = 0 } = data;
				if (id) {
					const payload = {
						id,
						mergeIds: [mergeId]
					} as unknown as SchoolMergeDataDto;

					await schoolsService.merge(payload);
					dispatch(emit({ message: "School has been merged.", color: "success" }));
				}
			}
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
		} finally {
			await dispatch(setMergeStateValue({ key: "mergeSchool", value: initialState.mergeNewSchoolForm }));
			dispatch(fetchSchools({}));
			dispatch(setMergeStateValue({ key: "isLoading", value: false }));
			dispatch(setMergeStateValue({ key: "mergeNewSchoolForm.rawData.mergeId", value: "" }));
			dispatch(setMergeStateValue({ key: "mergeNewSchoolForm.rawData.id", value: "" }));

			success();
		}
	}
);

const utilsPopulateInputs = getPopulateInputsAction<MergeSchoolState>({ defaultValueGetters });
export const mergeSchoolSlice = createSlice({
	name: "mergeNewSchoolForm",
	initialState,
	reducers: {
		populateInputs: utilsPopulateInputs,
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setMergeStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		resetState: utilsResetState
	}
});

export function getFullState(state: RootState): MergeSchoolState {
	return state.mergeSchool;
}

export const { setLoading, failed, setMergeStateValue, validateForm, populateInputs } = mergeSchoolSlice.actions;

export default mergeSchoolSlice.reducer;
