import {
	ContentAccessTypes,
	ContentAccessTypesLabels,
	NotificationTypes,
	NotificationTypesLabels
} from "@remar/shared/dist/constants";

export const mappedNotificationTypes = {
	[NotificationTypes.System]: NotificationTypesLabels.System,
	[NotificationTypes.Custom]: NotificationTypesLabels.Custom
};

export const mappedAudienceTypes = {
	[ContentAccessTypes.Paid]: ContentAccessTypesLabels.Paid,
	[ContentAccessTypes.Trial]: ContentAccessTypesLabels.Trial,
	[ContentAccessTypes.Mixed]: ContentAccessTypesLabels.Mixed
};
