import { alpha, createStyles, makeStyles } from "@material-ui/core/styles";

import { IExtendedTheme } from "../../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column"
			}
		},
		subscriptionCard: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			gap: "16px",
			[theme.breakpoints.down("sm")]: {
				width: "95vw"
			}.width,

			"& div[role='progressbar']": {
				height: "10px"
			}
		},
		subscriptionInfoContainer: {},
		cancelSubscriptionContainer: {
			width: "100%",
			padding: 2,
			borderTop: "solid 1px rgb(137, 143, 158)"
		},
		subscriptionTitle: {
			fontSize: 15,
			fontWeight: 600,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.6,
			marginBottom: 20
		},
		cancelSubscriptionBtn: {
			fontSize: 12,
			fontWeight: "bold",
			margin: "auto",
			display: "block",
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.33,
			color: alpha(theme.palette.colors.basic[100], 0.6),
			marginTop: 23
		},
		resumeSubscriptionBtn: {
			fontFamily: "var(--poppins)",
			fontSize: 14,
			fontWeight: "bold",
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.primary.main,
			margin: "auto",
			display: "block"
		},
		manageSubscriptionBtn: {
			fontFamily: "var(--poppins)",
			fontSize: 14,
			fontWeight: "bold",
			color: "hsla(0,0%,100%, 0.6)", // todo: add to colorset
			backgroundColor: theme.palette.primary.main,
			[theme.breakpoints.down("xs")]: {
				flex: 3
			}
		},
		editProfileBtn: {
			fontFamily: "var(--poppins)",
			fontSize: 14,
			fontWeight: "bold",
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.primary.main,
			[theme.breakpoints.down("xs")]: {
				flex: 2
			}
		},
		card: {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			background: theme.palette.background.default,
			flex: 5,
			minHeight: 630,
			[theme.breakpoints.down("xs")]: {
				minHeight: "100%"
			}
		},
		keepBtn: {
			backgroundColor: "transparent",
			color: theme.palette.text.primary,
			borderColor: "hsl(222,4%,44%)", // todo: add to colorset
			borderWidth: "1px",
			borderStyle: "solid",
			marginRight: "10px"
		},
		cancelBtn: {
			backgroundColor: theme.palette.colors.danger[900],
			color: theme.palette.colors.danger[500]
		},
		button: {
			lineHeight: 1.25,
			fontSize: `"${theme.typography.pxToRem(16)}"`,
			fontWeight: 900,
			padding: "13px 18px 15px"
		},
		filledFormTitle: {
			fontSize: theme.typography.pxToRem(26),
			fontWeight: 700,
			fontFamily: "var(--poppins)"
		},
		infoContainer: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			marginLeft: 0
		},
		infoText: {
			fontSize: 15,
			fontWeight: 600,
			marginLeft: 18,
			marginTop: 0,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.6,
			[theme.breakpoints.down("xs")]: {
				width: "70vw"
			}
		},
		infoName: {
			fontSize: 12,
			fontWeight: "normal",
			marginLeft: 18,
			marginTop: 0,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87,
			lineHeight: 1.33,
			color: alpha(theme.palette.text.primary, 0.6)
		},
		simpleBtn: {
			padding: "4px 6px",
			border: "solid 1px rgb(137, 143, 158)",
			marginLeft: "auto"
		}
	})
);
