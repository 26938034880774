import React from "react";

import { Checkbox } from "@material-ui/core";

import { QuizQuestionAnswerOption, StyledText } from "./style";

const MultipleChoiceQuestionPreview = ({
	question: {
		data: { answerOptions },
		answerOptions: _answerOptions
	}
}) =>
	(_answerOptions || answerOptions)?.map(({ id, text }) => (
		<QuizQuestionAnswerOption key={id}>
			<Checkbox color="default" />
			<StyledText>{text}</StyledText>
		</QuizQuestionAnswerOption>
	));
export default MultipleChoiceQuestionPreview;
