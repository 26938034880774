import React from "react";

import { Box, FormControl, Input, MenuItem, Select, Typography } from "@material-ui/core";

import { StyledCheckIcon, useStyles } from "./styles";
const MultiSelectInput = ({ options, value: val, disabled, ...fieldProps }) => {
	const classes = useStyles();
	return (
		<FormControl hiddenLabel fullWidth={true} variant="filled">
			<Select
				className={classes.select}
				fullWidth={true}
				multiple
				disableUnderline
				displayEmpty
				disabled={disabled}
				{...fieldProps}
				value={val}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left"
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left"
					},
					getContentAnchorEl: null
				}}
				input={<Input />}
				renderValue={selected =>
					(selected as string[]).length === 0 ? (
						<Box width={"100%"}>Select course(s)</Box>
					) : (
						<Box width={"100%"}>{(selected as string[]).length} Selected courses</Box>
					)
				}
			>
				{options.map(({ label, value }) => (
					<MenuItem key={value} value={value}>
						<Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
							<Typography>{label}</Typography>
							{val.includes(value) ? <StyledCheckIcon /> : ""}
						</Box>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default MultiSelectInput;
