import React from "react";

import { Box, Typography } from "@material-ui/core";

import { Avatar } from "@remar/shared/dist/components/Avatar";
import { capitalizeFirstLetter } from "@remar/shared/dist/utils/capitaliseFirstLetter";
import { formatDate, formatTime } from "@remar/shared/dist/utils/myAccountUtils";

import {
	IActivityLog,
	UserActivityLogTypeAdminEnum,
	UserActivityLogTypeLocationEnum,
	UserActivityLogTypeStudentEnum
} from "store/services/activityLogs/dto";

import { ActivityWrapper, BoldTypography, CurrentText, PreviousText, StyledArrowForward } from "../styles";
const getAction = log => {
	const {
		userActivityLogType: { id },
		data
	} = log;
	switch (id) {
		case UserActivityLogTypeStudentEnum.Login: {
			return "Logged In with Email";
		}
		case UserActivityLogTypeStudentEnum.LoginFacebook: {
			return "Logged In with FaceBook account";
		}
		case UserActivityLogTypeStudentEnum.LoginGoogle: {
			return "Logged In with Google account";
		}
		case UserActivityLogTypeStudentEnum.SessionShare: {
			return "Logged In with Token";
		}
		case UserActivityLogTypeStudentEnum.Logout: {
			return "Logged Out";
		}
		case UserActivityLogTypeStudentEnum.LessonRetake: {
			return "Retake Lesson";
		}
		case UserActivityLogTypeStudentEnum.QuestionBankRetake: {
			return "Retake QuestionBank test";
		}
		case UserActivityLogTypeAdminEnum.SubscriptionPaused: {
			return (
				<Box display={"flex"}>
					<Typography style={{ marginRight: "5px" }}>Paused Subscription to </Typography>{" "}
					<BoldTypography>{data.actedOn}</BoldTypography>
				</Box>
			);
		}
		case UserActivityLogTypeAdminEnum.AssignSubscription: {
			return (
				<Box display={"flex"}>
					<Typography style={{ marginRight: "5px" }}>Changed </Typography>{" "}
					<BoldTypography> Account Subscription</BoldTypography>
				</Box>
			);
		}
		case UserActivityLogTypeStudentEnum.ChangeSubscriptionDate:
		case UserActivityLogTypeAdminEnum.ChangeSubscriptionDate: {
			return "Changed Subscription date";
		}
		case UserActivityLogTypeStudentEnum.AddCATQuota:
		case UserActivityLogTypeAdminEnum.AddCATQuota:
		case UserActivityLogTypeLocationEnum.AddCATQuota: {
			return "Added CAT Quota";
		}
		case UserActivityLogTypeLocationEnum.IACanceledInvite: {
			return "Cancelled Invite";
		}
		case UserActivityLogTypeLocationEnum.institutionAdminCanceledSubscription: {
			return "Cancelled Subscription";
		}
		case UserActivityLogTypeLocationEnum.InstitutionAdminRevokedSubscription: {
			return "Revoked subscription";
		}
		case UserActivityLogTypeAdminEnum.ChangeEmail: {
			return "Updated Email";
		}
		case UserActivityLogTypeAdminEnum.SubscriptionResumed: {
			return "Resumed Subscription";
		}
		case UserActivityLogTypeAdminEnum.ResetUserProgress: {
			return "Rest user progress";
		}
		case UserActivityLogTypeAdminEnum.AddDaysToSubscription: {
			return "Added days to Subscription";
		}
		default:
			return "";
	}
};
const DisplayChange = ({ previous, current }) => (
	<Box display={"flex"} alignItems={"center"}>
		<PreviousText>{previous}</PreviousText>
		<Box display={"flex"} alignItems={"center"} mx={1}>
			<StyledArrowForward />
		</Box>
		<CurrentText>{current}</CurrentText>
	</Box>
);
const getDataChange = log => {
	const {
		userActivityLogType: { id },
		data
	} = log;
	switch (id) {
		case UserActivityLogTypeAdminEnum.AddDaysToSubscription: {
			return `Added ${data.change} day`;
		}
		case UserActivityLogTypeStudentEnum.AddCATQuota:
		case UserActivityLogTypeAdminEnum.AddCATQuota:
		case UserActivityLogTypeLocationEnum.AddCATQuota: {
			return `Added ${data.change}`;
		}
		case UserActivityLogTypeStudentEnum.ChangeSubscriptionDate:
		case UserActivityLogTypeAdminEnum.ChangeSubscriptionDate: {
			return <DisplayChange current={formatDate(data.current)} previous={formatDate(data.previous)} />;
		}
		case UserActivityLogTypeAdminEnum.SubscriptionResumed: {
			return <DisplayChange current={formatDate(data.current)} previous={formatDate(data.previous)} />;
		}
		case UserActivityLogTypeAdminEnum.ChangeEmail: {
			return <DisplayChange current={data.current} previous={data.previous} />;
		}
		case UserActivityLogTypeAdminEnum.AssignSubscription: {
			return <DisplayChange current={data.current} previous={data.previous} />;
		}
		default:
			return "";
	}
};
const Activity = ({ log }: { log: IActivityLog }) => {
	const {
		createdAt,
		userActivityLogType: { actor },
		data
	} = log;

	return (
		<ActivityWrapper>
			<Box display={"flex"} alignItems={"center"}>
				<Box mr={2}>
					<Avatar profileImageUrl={undefined} fullName={actor} variant="rounded" style={{ color: "#1998d5" }} />
				</Box>
				<Box display={"flex"} alignItems={"center"}>
					<Box display={"flex"} alignItems={"center"}>
						<Typography className={"activity-role"}>{capitalizeFirstLetter(actor)}</Typography> {getAction(log)}.
					</Box>
					<Box mx={1}>
						<Typography className={"activity-date"}>{`${formatDate(createdAt as string)} at ${formatTime(
							createdAt as string
						)}`}</Typography>
					</Box>
				</Box>
			</Box>
			{data && (
				<Box ml={7}>
					<Typography>{getDataChange(log)}</Typography>
				</Box>
			)}
		</ActivityWrapper>
	);
};

export default Activity;
