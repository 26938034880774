import { Box } from "@material-ui/core";
import styled from "styled-components";

export const SubscriptionStatusIcon = styled(Box)`
	height: 7px;
	width: 7px;
	border-radius: 50%;
	background: ${props => props.theme.palette.colors[props.color][500]};
	margin: 0 4px;
`;

export const SubscriptionStatusWrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	.status {
		font-size: 13px;
	}
	.subText {
		font-size: 12px;
		color: ${props => props.theme.palette.text.secondary};
		margin-left: 15px;
	}
`;
