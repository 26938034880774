import React, { useEffect, useState } from "react";

import { Box, Card, IconButton, MenuItem, Modal, Select, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Button from "../../components/Button";
import { FormControlContainer, LabelText, useStyles } from "../../styles";

type CloneQuestionModalProps = {
	isOpen: boolean;
	subjects: Array<{ id: number; name: string }>;
	onClose: () => void;
	isCloning?: boolean;
	isCloningButtonDisabled?: boolean;
	title?: string;
	labelText?: string;
	onClone: (subjectId: string) => void;
	disableEnforceFocus?: boolean;
	disableAutoFocus?: boolean;
};

const CloneQuestionModal = ({
	isOpen,
	subjects,
	onClose,
	title = "",
	onClone,
	isCloning = false,
	isCloningButtonDisabled = false,
	disableEnforceFocus = false,
	disableAutoFocus = false,
	labelText = ""
}: CloneQuestionModalProps) => {
	const classes = useStyles();
	const [subjectId, setSubjectId] = useState("");

	useEffect(() => {
		!isOpen && setSubjectId("");
	}, [isOpen]);

	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus={disableEnforceFocus}
			disableAutoFocus={disableAutoFocus}
			open={isOpen}
			onClose={onClose}
		>
			<Box width={550}>
				<Card className={classes.card}>
					<Box display="flex" alignItems="flex-start" justifyContent="space-between" mb="40px">
						<Typography className={classes.title}>{title}</Typography>
						<IconButton className={classes.closeIcon} onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<Box>
						<LabelText>{labelText || "Pick a subject where you’d like to add question"}</LabelText>
						<FormControlContainer hiddenLabel>
							<Select
								classes={{ filled: classes.selectFilled }}
								disableUnderline
								variant="filled"
								value={subjectId}
								onChange={event => setSubjectId(event.target.value as string)}
								displayEmpty
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
							>
								<MenuItem value="">Subject name example</MenuItem>
								{subjects.map(({ id, name }) => (
									<MenuItem key={id.toString()} value={id.toString()}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControlContainer>
					</Box>
					<Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.modalFooter}>
						<Box mr={1}>
							<Button variant={"outlined"} color={"tertiary"} onClick={onClose}>
								Cancel
							</Button>
						</Box>
						<Button
							loading={isCloning}
							variant={"filled"}
							color={"primary"}
							disabled={!subjectId || isCloningButtonDisabled}
							onClick={() => onClone(subjectId)}
						>
							Add
						</Button>
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default CloneQuestionModal;
