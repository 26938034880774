import { NotificationTypeId } from "@remar/shared/dist/models";
import { CreateNotificationDto } from "store/services/notifications/dto";
import * as Yup from "yup";

export interface IFormValues
	extends Omit<CreateNotificationDto, "sendAt" | "expiresAt" | "data" | "notificationAudienceTypeId"> {
	sendAtDate: Date;
	notificationAudienceTypeId: string;
	addDatePeriod?: boolean;
	expiresAtDate?: Date;
	buttonAction?: "apply-coupon" | "dismiss" | "open-link";
	buttonUrl?: string;
	buttonSuccessText?: string;
	buttonCancelText?: string;
}

export const validationSchema = Yup.object({
	notificationTypeId: Yup.mixed()
		.oneOf([
			NotificationTypeId.AdminGeneratedPush,
			NotificationTypeId.AdminGeneratedBanner,
			NotificationTypeId.AdminGeneratedAnnouncement
		])
		.required("You have to select notification type"),
	notificationAudienceTypeId: Yup.string().required("You have to select target audience"),
	sendAtDate: Yup.date().min(new Date(), "Start date should be in future").required("Start Date required"),
	addDatePeriod: Yup.boolean().optional(),
	isDismissible: Yup.boolean().optional(),
	expiresAtDate: Yup.date()
		.when("isDismissible", {
			is: false,
			then: s =>
				s
					.default(() => new Date(Date.now() + 10 * 60 * 1000))
					.required("End date required")
					.min(Yup.ref("sendAtDate"), "End date should be after start date"),
			otherwise: s => s.default(undefined).optional().min(Yup.ref("sendAtDate"), "End date should be after start date")
		})
		.when("addDatePeriod", {
			is: true,
			then: s => s.required("End date required").min(Yup.ref("sendAtDate"), "End date should be after start date"),
			otherwise: s => s.default(undefined).optional().min(Yup.ref("sendAtDate"), "End date should be after start date")
		})
		.when("countDown", {
			is: true,
			then: s => s.required("End date required").min(Yup.ref("sendAtDate"), "End date should be after start date"),
			otherwise: s => s.default(undefined).optional()
		}),
	countDown: Yup.boolean().optional(),
	body: Yup.string().required("Body is required").min(20, "Body message should be at least 20 characters long"),
	mobileBody: Yup.string()
		.required("Mobile body is required")
		.min(15, "Mobile body should be at least 15 characters long")
		.max(255, "Mobile body should be max 255 characters"),
	title: Yup.string()
		.when("notificationTypeId", {
			is: 1,
			then: s => s.required("Title is required").min(5, "Title should be at least 5 characters long"),
			otherwise: s => s.optional()
		})
		.min(5),
	mobileTitle: Yup.string().when("notificationTypeId", {
		is: 1,
		then: s =>
			s
				.optional()
				.min(5, "Mobile title should be at least 5 characters")
				.max(25, "Mobile title should be max 25 characters"),
		otherwise: s => s.optional()
	}),
	couponId: Yup.number().when("buttonAction", {
		is: "apply-coupon",
		then: s => s.required("You have to select coupon"),
		otherwise: s => s.optional()
	}),
	buttonAction: Yup.mixed()
		.oneOf(["apply-coupon", "dismiss", "open-link"])
		.required("You have to select Button action type"),
	buttonSuccessText: Yup.string()
		.min(2, "Button sucess text should be at least 2 characters")
		.max(25, "Button action text should be max 25 characters")
		.required("You have to enter Button success text"),
	buttonCancelText: Yup.string().when("notificationTypeId", {
		is: 1,
		then: s => s.required("You have to enter Button cancel text"),
		otherwise: s => s.optional()
	}),
	buttonUrl: Yup.string().when("buttonAction", {
		is: "open-link",
		then: s => s.url("Button url should be valid").required("You have to enter Button URL"),
		otherwise: s => s.optional()
	})
});
