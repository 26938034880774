import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { ManageSubject } from "@remar/shared/dist/models/manageSubjects.model";

import { URLS } from "../services.constants";

class CommunityPoolService extends BaseApiService<
	ManageSubject,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const communityPoolService = new CommunityPoolService(URLS.SUBJECT_CATEGORIES);
