import React from "react";

import { Box, Card, Grid, Typography } from "@material-ui/core";

import { fetchGiftStripePrice, getFullGiftState, setError } from "store/features/Gifts";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { SelectInput, TextField } from "modules/Components/FormikFields";

type AboutFormProps = {
	name: string;
	setName: (n: string) => void;
	planId: string;
	setPlanId: (p: string) => void;
	validity: number;
	setValidity: (v: number) => void;
};
const AboutForm = ({ name, setName, planId, setPlanId, validity, setValidity }: AboutFormProps) => {
	const dispatch = useAppDispatch();
	const { fetchPriceError } = useAppSelector(getFullGiftState);
	return (
		<Card component="form">
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<Box>
						<Typography>Name Promotion Card</Typography>
						<TextField
							name="name"
							fullWidth
							type="text"
							value={name}
							onChange={e => setName(e.target.value)}
							placeholder="Free Sign Up Gift Card"
							InputProps={{
								disableUnderline: true,
								style: {
									height: "48px"
								}
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<Typography>Validity (No. of days after buying)</Typography>
						<SelectInput
							placeholder={"Set validity"}
							defaultValue={"60"}
							onChange={e => setValidity(parseInt(e.target.value))}
							options={[
								{ label: "30", value: "30" },
								{ label: "60", value: "60" },
								{ label: "90", value: "90" },
								{ label: "180", value: "180" }
							]}
							value={validity.toString()}
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box>
						<Typography>Price (Stripe Link)</Typography>
						<TextField
							name="planId"
							fullWidth
							type="text"
							value={planId}
							onChange={e => setPlanId(e.target.value)}
							placeholder="Stripe link"
							onBlur={e => {
								const v = e.target.value;
								if (v) {
									dispatch(fetchGiftStripePrice(e.target.value));
								} else {
									dispatch(setError("Please enter a valid Stripe link"));
								}
							}}
							helperText={fetchPriceError}
							helperTextType="error"
							InputProps={{
								disableUnderline: true,
								style: {
									height: "48px"
								}
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</Card>
	);
};

export default AboutForm;
