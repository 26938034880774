import React from "react";

const copyPasteOnlyInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
	const allowedKeys = ["c", "v", "x"];
	const allow = (e.metaKey || e.ctrlKey) && allowedKeys.includes(e.key);
	if (!allow) {
		e.preventDefault();
	}
};
export default copyPasteOnlyInput;
