import React, { useMemo } from "react";

import { Box, Button, Card, Typography, useTheme } from "@material-ui/core";
import { CalendarTodaySharp } from "@material-ui/icons";

import { isBefore, isToday } from "date-fns";

import ProgressStatus from "./ProgressStatus";
import { useStyles } from "./styles";

import SubscriptionSvg from "../../assets/icons/Subscription.svg";
import BadgeDollarSvg from "../../assets/icons/badge-dollar.svg";

import { IExtendedTheme } from "../../theme/default";

import { addDaysSuffix } from "../../utils/addDaysSuffix";
import { formatAdminDate, getRemainingDays } from "../../utils/myAccountUtils";

enum Location {
	ADMIN = "ADMIN",
	INSTITUTION = "INSTITUTION"
}
interface IStudentSubscriptionCard {
	location?: string;
	isCancelled?: boolean;
	subscriptionName: string;
	expiresOn: Date;
	subscriptionStartDate: Date | null;
	billingDate: Date;
	price: string;
	quizzesProgress?: number;
	coursesProgress: number;
	onChangeSub?: () => void;
	changeSubStartDate?: () => void;
	handleAddDays?: () => void;
	handlePauseSub?: () => void;
	handleResumeSub?: () => void;
	isPaused?: boolean;
	hasStarted?: boolean;
	viewAddDays?: boolean;
	isReadOnly?: boolean;
}
const StudentSubscriptionCard = ({
	isCancelled = false,
	subscriptionName,
	expiresOn,
	subscriptionStartDate,
	billingDate,
	price,
	quizzesProgress = 0,
	coursesProgress = 0,
	location = Location.INSTITUTION,
	onChangeSub,
	handleAddDays,
	viewAddDays = false,
	changeSubStartDate,
	handlePauseSub,
	handleResumeSub,
	isPaused,
	hasStarted,
	isReadOnly = false
}: IStudentSubscriptionCard) => {
	const theme = useTheme<IExtendedTheme>();
	const classes = useStyles();
	const isAdminView = location === Location.ADMIN;
	const subscriptionEndDate = useMemo(
		() =>
			getRemainingDays(new Date(expiresOn)) > 1
				? `${getRemainingDays(new Date(expiresOn))} Days`
				: (expiresOn && "1 Day") || "-",
		[expiresOn]
	);
	const subscriptionResumeDate = useMemo(() => addDaysSuffix(subscriptionStartDate as Date), [subscriptionStartDate]);
	const nextBillingDate = useMemo(() => (billingDate ? formatAdminDate(billingDate) : undefined), [billingDate]);
	const hasSubscriptionStarted = subscriptionStartDate && isBefore(subscriptionStartDate, new Date());

	return (
		<Box className={classes.subscriptionInfoContainer}>
			<Card className={classes.subscriptionCard}>
				<Typography className={classes.subscriptionTitle}>Student&apos;s Subscription</Typography>
				{!isAdminView && (
					<Box mb={1} width={"100%"}>
						<ProgressStatus title="Test Completion" progress={quizzesProgress} />
					</Box>
				)}
				<Box width={"100%"}>
					<ProgressStatus title="Course Completion" progress={coursesProgress} />
				</Box>
				<Box className={classes.infoContainer}>
					<img src={SubscriptionSvg} width={30} height={30} alt="Subscription" />
					<Box>
						<Typography className={classes.infoText}>{subscriptionName || "-"}</Typography>
						<Typography className={classes.infoName}>Subscription</Typography>
					</Box>
					{isAdminView && (
						<Button className={classes.simpleBtn} onClick={onChangeSub} disabled={isReadOnly}>
							Change
						</Button>
					)}
				</Box>

				{subscriptionStartDate && !isToday(subscriptionStartDate) && !hasSubscriptionStarted && (
					<Box className={classes.infoContainer}>
						<CalendarTodaySharp style={{ width: "30px", height: "30px", fill: theme.palette.colors.basic[1300] }} />
						<Box>
							<Typography className={classes.infoText}>
								{isPaused ? subscriptionResumeDate : formatAdminDate(subscriptionStartDate)}
							</Typography>
							<Typography className={classes.infoName}>
								{isPaused ? "Subscription Resumes In" : "Subscription Starts on"}
							</Typography>
						</Box>
						{isAdminView &&
							(isPaused ? (
								<Button className={classes.simpleBtn} onClick={handleResumeSub} disabled={isReadOnly}>
									Resume
								</Button>
							) : (
								<Button className={classes.simpleBtn} onClick={changeSubStartDate} disabled={isReadOnly}>
									Change
								</Button>
							))}
					</Box>
				)}

				<Box className={classes.infoContainer}>
					<CalendarTodaySharp style={{ width: "30px", height: "30px", fill: theme.palette.colors.basic[1300] }} />
					<Box>
						<Typography className={classes.infoText}>{subscriptionEndDate}</Typography>
						<Typography className={classes.infoName}>Subscription Expires In</Typography>
					</Box>
					{isAdminView && (hasSubscriptionStarted || hasStarted) && !isPaused && (
						<Button className={classes.simpleBtn} onClick={handlePauseSub} disabled={isReadOnly}>
							Pause
						</Button>
					)}
				</Box>
				<Box className={classes.infoContainer}>
					<img src={BadgeDollarSvg} width={30} height={30} alt="Dollar icon" />
					<Box>
						{!isCancelled ? (
							<Typography className={classes.infoText}>
								{nextBillingDate || "-"} | ${price || "-"}
							</Typography>
						) : (
							<Typography className={classes.infoText}>- | -</Typography>
						)}
						<Typography className={classes.infoName}>Next Billing Date</Typography>
					</Box>
				</Box>
				{viewAddDays && (
					<Box mt={2} width={"100%"}>
						<Button className={classes.simpleBtn} fullWidth onClick={handleAddDays} disabled={isReadOnly}>
							Add Days
						</Button>
					</Box>
				)}
			</Card>
		</Box>
	);
};

export default StudentSubscriptionCard;
