import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Tab, Tabs, createStyles, makeStyles } from "@material-ui/core";
import { Wrapper } from "@remar/shared/dist/layouts";
import { useStyles as useSharedStyles } from "@remar/shared/dist/styles";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { pick } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getFullState, getStudentAccount } from "store/features/Students/students.slice";
import { useAppSelector } from "store/hooks";

import { routes } from "core/constants";

import EmailLogs from "./EmailLogs";
import StudentActivity from "./StudentActivity";

const useStyles = makeStyles((t: IExtendedTheme) =>
	createStyles({
		indicator: {
			display: "none"
		},
		tab: {
			fontFamily: "var(--poppins)",
			color: t.palette.colors.basic[600],
			background: t.palette.colors.basic[1000],
			fontWeight: 700,
			fontSize: 12,
			textTransform: "none"
		},
		tabSelected: {
			color: t.palette.colors.info[600],
			background: t.palette.colors.info[900],

			"&:first-child": {
				borderRadius: "3px 0 0 3px"
			},
			"&:last-child": {
				borderRadius: "0 3px 3px 0"
			}
		}
	})
);

enum TabValues {
	ACTIVITY = "Activity",
	EMAILS = "Email Logs"
}

const AccountActivity = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const sharedClasses = useSharedStyles();
	const classes = useStyles();

	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		if (id) {
			dispatch(getStudentAccount({ id }));
		}
	}, [dispatch, id]);

	const { studentAccountInfo } = useAppSelector(getFullState);

	const [selectedTab, setSelectedTab] = useState<TabValues>(TabValues.ACTIVITY);

	const { firstName, lastName } = useMemo(() => {
		if (!studentAccountInfo) {
			return {};
		}

		return pick(studentAccountInfo, ["firstName", "lastName", "email"]);
	}, [studentAccountInfo]);

	const breadcrumb = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Manage Students", key: 1, link: routes.manageStudents.getPath() },
			{
				title: `${history.location.state.firstName} ${history.location.state.lastName}`,
				key: 2,
				link: `${routes.manageStudents.getPath()}/${id}`
			},
			{ title: "Account Activity", key: 3 }
		],
		[history.location.state.firstName, history.location.state.lastName, id]
	);

	return (
		<Wrapper
			heading={`${firstName} ${lastName}`}
			breadcrumb={breadcrumb}
			actions={
				<Box display="flex" gridGap={16}>
					<Box>
						<Button
							className={sharedClasses.cancelBtn}
							onClick={() => history.push(`${routes.manageStudents.getPath()}/${userId}`)}
						>
							Back
						</Button>
					</Box>
				</Box>
			}
		>
			<Tabs
				classes={{ indicator: classes.indicator }}
				style={{ marginBottom: 16 }}
				value={selectedTab}
				onChange={(_, v) => setSelectedTab(v)}
			>
				{Object.values(TabValues).map(v => (
					<Tab classes={{ root: classes.tab, selected: classes.tabSelected }} key={v} label={v} value={v} />
				))}
			</Tabs>
			{selectedTab === TabValues.ACTIVITY ? <StudentActivity /> : <EmailLogs />}
		</Wrapper>
	);
};

export default AccountActivity;
