import shuffle from "lodash/shuffle";
import {
	Group,
	TestOnlyQuestionsFormAnswerOptionRawData,
	TestOnlyQuestionsFormQuestionItemRawData
} from "store/features/CreateLesson/models/testOnlyQuestionsForm";

export const getQuestionGroups = (question: TestOnlyQuestionsFormQuestionItemRawData): Group[] => {
	const list: TestOnlyQuestionsFormAnswerOptionRawData[] = [];
	question.groups.forEach(item => {
		item.answerOptions.forEach(answerOption => {
			list.push(answerOption as TestOnlyQuestionsFormAnswerOptionRawData);
		});
	});

	const shuffledList = shuffle(list);
	const half = Math.ceil(shuffledList?.length / 2);
	const firstHalf = shuffledList.splice(0, half);
	const secondHalf = shuffledList.splice(-half);
	const questionGroups = question.groups;
	return questionGroups?.map((x, i) => {
		if (i) {
			return { ...x, answerOptions: shuffle(secondHalf) };
		} else return { ...x, answerOptions: shuffle(firstHalf) };
	});
};
