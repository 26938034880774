import React, { useEffect, useMemo, useState } from "react";

import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import Button from "@remar/shared/dist/components/Button";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import StatusComponent from "@remar/shared/dist/components/StatusComponent";
import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";

import DeleteModal from "@remar/shared/dist/modals/DeleteModal";
import { AccountStatus, AccountStatusEnum, UserType } from "@remar/shared/dist/models";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";
import {
	cancelInvitation,
	changeAdminStatus,
	deleteAdmin,
	getAdmins,
	getManageAdminsFullState
} from "store/features/ManageAdmins/manageAdmins.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { AdminDto, UserTypeEnum } from "store/services";

import InviteAdmin from "./InviteAdmin";

import { StyledListIcon } from "../ManageLocations/styles";

type Colors = "basic" | "success" | "warning" | "danger";
type AccountStatusComponentProps = { status: AccountStatus };
const AccountStatusComponent = ({ status }: AccountStatusComponentProps) => {
	const { id } = status;

	const { color, text } = useMemo((): { color: Colors; text: string } => {
		switch (id) {
			case AccountStatusEnum.Active:
				return { color: "success", text: "Active" };
			case AccountStatusEnum.Inactive:
				return { color: "danger", text: "InActive" };
			case AccountStatusEnum.SignupPending:
				return { color: "warning", text: "Pending SignUp" };
			default:
				return { color: "basic", text: "Unknown" };
		}
	}, [id]);

	return <StatusComponent color={color} text={text} />;
};

type RoleBadgeProps = { role: UserType };
const RoleBadge = ({ role }: RoleBadgeProps) => {
	const { bgColor, color } = useMemo(() => {
		switch (role.id) {
			case UserTypeEnum.CustomerSupport:
				return { bgColor: "hsla(154, 96%, 11%, 0.5)", color: "hsl(46, 66%, 52%)" };
			case UserTypeEnum.ContentManager:
				return { bgColor: "hsla(200, 75%, 21%, 0.5)", color: "hsl(210, 94%, 72%)" };
			default:
				return { bgColor: "", color: "" };
		}
	}, [role]);

	return (
		<Box
			display="flex"
			alignItems="center"
			bgcolor={bgColor}
			style={{ color: color, borderRadius: 15, fontWeight: 600, fontSize: 13 }}
			px={2}
			py={0.75}
		>
			{role.name}
		</Box>
	);
};

const breadcrumbs = [
	{
		title: "Dashboard",
		key: 0,
		link: "/"
	},
	{
		title: "Manage Admins",
		key: 1
	}
];

type avaliableActions = "cancel" | "changeStatus" | "delete";

const getActionTitle = (action: avaliableActions) => {
	switch (action) {
		case "cancel":
			return "Cancel Invitation";
		case "changeStatus":
			return "Change Status";
		case "delete":
			return "Remove Admin";
	}
};

const getActionDescription = (action: avaliableActions) => {
	switch (action) {
		case "cancel":
			return "Are you sure you want to cancel this invitation?";
		case "changeStatus":
			return "Are you sure you want to change this admin's account status?";
		case "delete":
			return "Are you sure you want to remove this Admin?";
	}
};

const getActionButtonText = (action: avaliableActions) => {
	switch (action) {
		case "cancel":
			return "Cancel";
		case "changeStatus":
			return "Change Status";
		case "delete":
			return "Delete";
	}
};

type FilterType = {
	"assignedUserTypes.id"?: UserTypeEnum;
	accountStatusId?: AccountStatusEnum;
};

const ManageAdmins = () => {
	const dispatch = useAppDispatch();

	const [showInviteModal, setShowInviteModal] = React.useState<AdminDto | null>(null);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [filters, setFilters] = useState<FilterType>({
		"assignedUserTypes.id": undefined,
		accountStatusId: undefined
	});
	const [showConfirmActionModal, setShowConfirmActionModal] = useState<{
		action: "delete" | "cancel" | "changeStatus";
		id: number;
		status?: AccountStatusEnum;
	} | null>(null);

	const { admins, isLoading, page, perPage, totalItems } = useAppSelector(getManageAdminsFullState);

	useEffect(() => {
		dispatch(getAdmins({ page: 1, searchKeyword, filters }));
	}, [dispatch, filters, searchKeyword]);

	const tableColumns: Array<IColumn<AdminDto>> = useMemo(
		() => [
			{
				alignment: "left",
				width: 400,
				label: <ColumnHeader>Admins</ColumnHeader>,
				Cell: ({ rowData }) => {
					const { firstName, lastName, email } = rowData;
					const fullName = [firstName, lastName].join(" ");
					return (
						<StyledCellWrapper>
							<Avatar variant="rounded" fullName={fullName} />
							<Box>
								<StyledCellText margin="5px 0 0 0">{fullName}</StyledCellText>
								<StyledCellText margin="0 0 5px 0">{email}</StyledCellText>
							</Box>
						</StyledCellWrapper>
					);
				},
				dataKey: "name"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Role</ColumnHeader>,
				Cell: ({ rowData: { assignedUserTypes } }) => (
					<StyledCellWrapper>
						{assignedUserTypes?.map(r => (
							<RoleBadge key={r.id} role={r} />
						))}
					</StyledCellWrapper>
				),
				dataKey: "role"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Invited On</ColumnHeader>,
				Cell: ({ rowData }) => (
					<StyledCellWrapper>
						<StyledCellText>{formatDate(rowData.createdAt)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "invitedOn"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Onboarded On</ColumnHeader>,
				Cell: ({ rowData }) => (
					<StyledCellWrapper>
						<StyledCellText>{formatDate(rowData.onboardedAt)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "onboardedOn"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Status</ColumnHeader>,
				Cell: ({ rowData }) => (
					<StyledCellWrapper>
						<AccountStatusComponent status={rowData.accountStatus} />
					</StyledCellWrapper>
				),
				dataKey: "accountStatus"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }) => {
					return (
						<ActionMenu
							customMenuItems={[
								{
									label: "Edit",
									onClick: () => setShowInviteModal(rowData),
									visible: true,
									disabled: !!rowData.onboardedAt
								},
								{
									label: "Cancel Invitation",
									onClick: () => setShowConfirmActionModal({ action: "cancel", id: rowData.id }),
									visible: !rowData.onboardedAt,
									disabled: false
								},
								{
									label: rowData.accountStatus.id === AccountStatusEnum.Active ? "InActive" : "Activate",
									onClick: () =>
										setShowConfirmActionModal({
											action: "changeStatus",
											id: rowData.id,
											status:
												rowData.accountStatus.id === AccountStatusEnum.Active
													? AccountStatusEnum.Inactive
													: AccountStatusEnum.Active
										}),
									visible: !!rowData.onboardedAt,
									disabled: false
								},
								{
									label: "Delete",
									onClick: () => setShowConfirmActionModal({ action: "delete", id: rowData.id }),
									visible: true,
									disabled: false
								}
							]}
						/>
					);
				}
			}
		],
		[]
	);

	const doAction = (action: avaliableActions, id: number, status?: AccountStatusEnum) => {
		switch (action) {
			case "cancel":
				return dispatch(cancelInvitation(id));
			case "changeStatus":
				return dispatch(changeAdminStatus({ id, accountStatus: status }));
			case "delete":
				return dispatch(deleteAdmin(id));
		}
	};

	return (
		<WithTableContentLayout
			heading="Manage Admins"
			actions={
				<Button color="primary" variant="filled" onClick={() => setShowInviteModal({} as AdminDto)}>
					Invite New Admin
				</Button>
			}
			filterBlock={
				<>
					<Box mx={1} display="flex" alignItems="center">
						<Box mr={1}>
							<StyledListIcon />
						</Box>
						<Box mr={2}>
							<FormControl hiddenLabel size="small" variant="filled">
								<Select
									disableUnderline
									value={filters["assignedUserTypes.id"]}
									onChange={e => setFilters(f => ({ ...f, "assignedUserTypes.id": e.target.value }))}
									displayEmpty
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left"
										},
										getContentAnchorEl: null
									}}
								>
									<MenuItem value={undefined}>All Roles</MenuItem>
									<MenuItem value={UserTypeEnum.CustomerSupport}>Support</MenuItem>
									<MenuItem value={UserTypeEnum.ContentManager}>Manager</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</Box>
					<Box mx={1} display="flex" alignItems="center">
						<Box mr={1}>
							<StyledListIcon />
						</Box>
						<Box mr={2}>
							<FormControl hiddenLabel size="small" variant="filled">
								<Select
									disableUnderline
									value={filters.accountStatusId}
									onChange={e => setFilters(f => ({ ...f, accountStatusId: e.target.value }))}
									displayEmpty
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left"
										},
										getContentAnchorEl: null
									}}
								>
									<MenuItem value={undefined}>All Statuses</MenuItem>
									<MenuItem value={AccountStatusEnum.Active}>Active</MenuItem>
									{/*<MenuItem value={AccountStatusEnum.Inactive}>InActive</MenuItem>*/}
									<MenuItem value={AccountStatusEnum.SignupPending}>Pending SignUp</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</Box>
				</>
			}
			searchText={searchKeyword}
			setSearchText={setSearchKeyword}
			tableColumns={tableColumns}
			breadcrumb={breadcrumbs}
			tableTitle="Admins"
			data={admins || ([] as AdminDto[])}
			emptyState={
				<EmptyState
					description="No Admins found"
					buttonTitle="Invite New Admin"
					onButtonClick={() => setShowInviteModal({} as AdminDto)}
				/>
			}
			isLoading={isLoading}
			onChangePage={page => dispatch(getAdmins({ page, searchKeyword, filters }))}
			page={page}
			perPage={perPage}
			totalItems={totalItems}
			totalEntities={admins?.length || 0}
		>
			{!!showInviteModal && <InviteAdmin entity={showInviteModal} onClose={() => setShowInviteModal(null)} />}
			<DeleteModal
				open={!!showConfirmActionModal}
				title={getActionTitle(showConfirmActionModal?.action)}
				message={getActionDescription(showConfirmActionModal?.action)}
				cancelBtnText="No"
				deleteBtnText={`Yes, ${getActionButtonText(showConfirmActionModal?.action)}`}
				onClose={() => setShowConfirmActionModal(null)}
				onDelete={() =>
					doAction(showConfirmActionModal!.action, showConfirmActionModal!.id, showConfirmActionModal!.status)
				}
			/>
		</WithTableContentLayout>
	);
};

export default ManageAdmins;
