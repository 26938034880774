import { SubjectCategoriesCreateDto, SubjectCategoriesDeleteDto, SubjectCategoriesUpdateDto } from "./dto";
import { SubjectCategory } from "./modal";

import { BaseApiService, BaseFindDto, BaseFindOneDto } from "../../api/baseApiService";

import { GLOBAL_CONSTANTS } from "../../constants";

export class SubjectCategoriesService extends BaseApiService<
	SubjectCategory,
	SubjectCategoriesCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	SubjectCategoriesUpdateDto,
	SubjectCategoriesDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const subjectCategoriesService = new SubjectCategoriesService(GLOBAL_CONSTANTS.SUBJECT_CATEGORIES);
