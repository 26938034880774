import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BaseFindDto } from "@remar/shared/dist/api/baseApiService";
import { AccountStatusEnum } from "@remar/shared/dist/models";
import { RootState } from "store";
import { AdminDto, InviteAdminPayload, usersService } from "store/services";

import { emit } from "../notifications/notifications.slice";

interface ManageAdminsSlice {
	admins: AdminDto[] | null;
	page: number;
	perPage: number;
	totalItems: number;
	isLoading: boolean;
	error: string | null;
}

const initialState: ManageAdminsSlice = {
	admins: null,
	page: 1,
	perPage: 10,
	totalItems: 0,
	isLoading: false,
	error: null
};

export const getAdmins = createAsyncThunk(
	"manageAdmins/getAdmins",
	async (data: BaseFindDto, { rejectWithValue, dispatch }) => {
		return usersService
			.getAdmins({
				...data,
				include: ["accountStatus"]
			})
			.catch(error => {
				dispatch(emit({ message: "Failed to get admins", color: "error" }));
				return rejectWithValue(error);
			});
	}
);

export const inviteNewAdmin = createAsyncThunk(
	"manageAdmins/inviteNewAdmin",
	async (payload: InviteAdminPayload, { dispatch, rejectWithValue }) => {
		if (payload.id) {
			const { id, ...data } = payload;
			return usersService
				.updateInvitation({ filters: { id }, data })
				.then(() => {
					dispatch(emit({ message: "Admin invitation has been changed successfully", color: "success" }));
					dispatch(getAdmins({ page: 1 }));
				})
				.catch(e => {
					dispatch(emit({ message: "Failed to change admin invitation", color: "error" }));
					return rejectWithValue(e);
				});
		}

		return usersService
			.inviteAdmin(payload)
			.then(() => {
				dispatch(emit({ message: "Admin has been invited successfully", color: "success" }));
				dispatch(getAdmins({ page: 1 }));
			})
			.catch(e => {
				dispatch(emit({ message: "Failed to invite admin", color: "error" }));
				return rejectWithValue(e);
			});
	}
);

export const cancelInvitation = createAsyncThunk(
	"manageAdmins/cancelInvitation",
	async (id: number, { dispatch, rejectWithValue }) => {
		return usersService
			.cancelInvitation(id)
			.then(() => {
				dispatch(emit({ message: "Admin invitation has been cancelled successfully", color: "success" }));
				dispatch(getAdmins({ page: 1 }));
			})
			.catch(e => {
				dispatch(emit({ message: "Failed to cancel admin invitation", color: "error" }));
				return rejectWithValue(e);
			});
	}
);

export const changeAdminStatus = createAsyncThunk(
	"manageAdmins/changeAdminStatus",
	async ({ id, accountStatus }: { id: number; accountStatus: AccountStatusEnum }, { dispatch, rejectWithValue }) => {
		return usersService
			.update({ filters: { id }, data: { accountStatus } })
			.then(() => {
				dispatch(emit({ message: "Admin status has been changed successfully", color: "success" }));
				dispatch(getAdmins({ page: 1 }));
			})
			.catch(e => {
				dispatch(emit({ message: "Failed to change admin status", color: "error" }));
				return rejectWithValue(e);
			});
	}
);

export const deleteAdmin = createAsyncThunk(
	"manageAdmins/deleteAdmin",
	async (id: number, { dispatch, rejectWithValue }) => {
		return usersService
			.delete({ filters: { id } })
			.then(() => {
				dispatch(emit({ message: "Admin has been deleted successfully", color: "success" }));
				dispatch(getAdmins({ page: 1 }));
			})
			.catch(e => {
				dispatch(emit({ message: "Failed to delete admin", color: "error" }));
				return rejectWithValue(e);
			});
	}
);

const manageAdminsSlice = createSlice({
	name: "manageAdmins",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAdmins.pending, state => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getAdmins.fulfilled, (state, { payload: { items, page, perPage, totalItems } }) => {
				state.isLoading = false;
				state.admins = items;
				state.perPage = perPage;
				state.page = page;
				state.totalItems = totalItems || 0;
			})
			.addCase(getAdmins.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(inviteNewAdmin.pending, state => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(inviteNewAdmin.fulfilled, state => {
				state.isLoading = false;
			})
			.addCase(inviteNewAdmin.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(deleteAdmin.pending, state => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(deleteAdmin.fulfilled, state => {
				state.isLoading = false;
			})
			.addCase(deleteAdmin.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	}
});

export const {} = manageAdminsSlice.actions;

export const getManageAdminsFullState = (state: RootState): ManageAdminsSlice => state.manageAdmins;

export default manageAdminsSlice.reducer;
