import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { Box, SvgIcon } from "@material-ui/core";

import isEmpty from "lodash/isEmpty";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { HTML5Backend } from "react-dnd-html5-backend";

import { DragOptionContainer, DragOptionContainerText, Gap, GroupContainer } from "./style";

import { useDraggedScroll } from "../../../utils/useDraggedScroll";

interface Option {
	id: string;
	text: string;
}

interface SelectedAnswer {
	option: Option;
	groupId?: string;
}

interface Props {
	type: string;
	option: Option;
	sideEffect?: () => void;
	IconEquals?;
}

const DragOption: FC<Props> = ({ option, type, sideEffect, IconEquals }) => {
	const [, drag] = useDrag({
		item: { option, ...(sideEffect && { sideEffect: sideEffect }) },
		type,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	});
	return (
		<DragOptionContainer id={option?.id} ref={drag}>
			<DragOptionContainerText>{option?.text}</DragOptionContainerText>
			<SvgIcon style={{ cursor: "move" }} fontSize="large">
				<IconEquals />
			</SvgIcon>
		</DragOptionContainer>
	);
};

const AnswerDropZone = ({ onDropped, groupId, userAnswer, IconEquals }) => {
	const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswer>();

	const ref = useRef({
		selectedAnswer
	});

	useEffect(() => {
		if (!isEmpty(selectedAnswer)) {
			if (selectedAnswer?.option.id !== userAnswer?.option.id) {
				onDropped({ item: selectedAnswer, prevItem: ref.current.selectedAnswer });
			}
			ref.current.selectedAnswer = selectedAnswer;
		}
	}, [selectedAnswer, onDropped]);

	useEffect(() => {
		if (userAnswer && userAnswer.option.id !== selectedAnswer?.option.id) {
			setSelectedAnswer({
				groupId: userAnswer.option.groupId,
				option: { id: userAnswer.option.id, text: userAnswer.option.text }
			});
		}
	}, [userAnswer]);

	const [, drop] = useDrop({
		accept: "group",
		drop: item => {
			setSelectedAnswer({ ...(item as unknown as SelectedAnswer), groupId });
		},
		collect: monitor => ({
			isOver: monitor.isOver()
		})
	});

	const cleanData = () => {
		ref.current.selectedAnswer = undefined;
		setSelectedAnswer(undefined);
	};

	return (
		<div ref={drop}>
			<Gap className={selectedAnswer ? "filled" : ""}>
				{selectedAnswer && (
					<DragOption type="standalone" sideEffect={cleanData} option={selectedAnswer.option} IconEquals={IconEquals} />
				)}
			</Gap>
		</div>
	);
};

const GroupDropZone = ({ answerOptions, onDropped, IconEquals }) => {
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: "standalone",
		drop: item => {
			onDropped({ option: (item as unknown as SelectedAnswer).option });
			(item as unknown as { sideEffect: () => void })?.sideEffect();
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const overStyles = {
		opacity: 0.7
	};

	return (
		<div ref={drop} style={isOver && canDrop ? overStyles : undefined}>
			{answerOptions.map((dragOption, i) => (
				<Box mt={2} key={i}>
					<DragOption type="group" option={dragOption} IconEquals={IconEquals} />
				</Box>
			))}
		</div>
	);
};

const DragAndDropQuestionPreview = ({ question, IconEquals }) => {
	const [userAnswers, setUserAnswers] = useState<{ groupId: string; text?: string; id: string }[]>([]);

	const { answerOptions, groups } = question.data;
	const [userAnswerToRemove, setUserAnswerToRemove] = useState<{ option: Option }>();

	useDraggedScroll();

	useEffect(() => {
		if (!isEmpty(userAnswerToRemove)) {
			setUserAnswers(ctx => {
				const { option } = userAnswerToRemove as SelectedAnswer;
				const arr = [...ctx];
				const index = arr.findIndex(({ id }) => id === option.id);
				arr.splice(index, 1);

				return [...arr];
			});
		}
	}, [userAnswerToRemove]);

	const onDroppedHandle = ({ item, prevItem }) => {
		const {
			groupId,
			option: { id }
		} = item;
		const _item: { groupId: string; id: string } = { groupId, id };

		if (item.option.id === prevItem?.option.id) {
			return;
		}
		const existedAnswerOpts = [...userAnswers.filter(({ groupId }) => groupId !== item.groupId)];
		const answers = prevItem
			? existedAnswerOpts.filter(x => !(x.id === prevItem.option.id && x.groupId === prevItem.groupId))
			: existedAnswerOpts;
		setUserAnswers([...answers, _item]);
	};

	const filteredAnswerOptions = useMemo(
		() => answerOptions.filter(item => !userAnswers.some(({ id }) => id === item.id)),
		[userAnswers, answerOptions]
	);

	return (
		<DndProvider backend={HTML5Backend}>
			<Box mt={2} ml={2}>
				<Box display="flex" flexWrap="wrap" bgcolor="white">
					{groups?.map(({ id: groupId, text, selectedAnswerOptions }) => {
						const answer = userAnswers.find(answer => answer.groupId === groupId);

						if (answer) {
							answer.text = answerOptions.find(({ id }) => id === answer.id)?.text;
						}

						return (
							<GroupContainer key={groupId}>
								{text}
								{selectedAnswerOptions && selectedAnswerOptions.length !== 0 && (
									<AnswerDropZone
										onDropped={onDroppedHandle}
										groupId={groupId}
										userAnswer={answer ? { option: answer } : undefined}
										IconEquals={IconEquals}
									/>
								)}
							</GroupContainer>
						);
					})}
				</Box>
			</Box>
			<Box ml={2}>
				<h4>Word Choices</h4>
				<GroupDropZone
					onDropped={setUserAnswerToRemove}
					answerOptions={filteredAnswerOptions}
					IconEquals={IconEquals}
				/>
			</Box>
		</DndProvider>
	);
};

export default DragAndDropQuestionPreview;
