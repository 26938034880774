import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, CircularProgress, Modal } from "@material-ui/core";
import { Add, Delete, MergeType } from "@material-ui/icons";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";

import { Container, StyledCellWrapper, THeaderWrapper } from "@remar/shared/dist/components/Table/styles";

import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { School } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import { useDispatch, useSelector } from "react-redux";

import { getFullState as getFullAuthState } from "store/features/Auth/auth.slice";
import {
	deleteMergeRequest,
	fetchSchoolRequests,
	getRequestFullState
} from "store/features/ManageSchools/manageSchoolRequests.slice";
import {
	deleteSchool,
	fetchAllSchools,
	fetchSchools,
	getFullState
} from "store/features/ManageSchools/manageSchools.slice";

import CheckPermissions from "core/CheckPermissions";

import { AddSchoolModel } from "./AddSchool/AddSchool";
import { DeleteSchool } from "./ConfirmationDialogue/DeleteModalContent";

import {
	AddButton,
	ButtonContainer,
	ColumnHeader,
	ContainerTableSection,
	DeleteButton,
	Header,
	MergeButton,
	RedCircle,
	RequestContainer,
	RequestItemContainer,
	RequestListTitle,
	RequestMessage,
	RequestTitle,
	THeaderTitle,
	TableContent
} from "./styles";

import { HeaderContainer, StyledCellText } from "../ManageStudents/styles";

export interface EditSchoolType {
	name: string;
	acronym: string;
	id: number;
}

const ManageSchools = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isLoading, perPage, page, schools, totalItems, allSchools } = useSelector(getFullState);
	const { schoolRequests, isRequestLoading } = useSelector(getRequestFullState);
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getFullAuthState);

	const [searchText, setSearchText] = useState("");
	const [addSchool, setAddSchool] = useState(false);
	const [editSchoolPayload, setEditSchoolPayload] = useState<EditSchoolType>();
	const [schoolDeleteId, setSchoolDeleteId] = useState(0);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [requestList, setRequestList] = useState<unknown>(undefined);
	const [isMergeAction, setIsMergeAction] = useState<boolean>(false);
	const [isMergeRequest, setIsMergeRequest] = useState<boolean>(false);

	useEffect(() => {
		dispatch(fetchSchools({}));
		dispatch(fetchAllSchools());
		dispatch(fetchSchoolRequests({}));
	}, [dispatch]);

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(fetchSchools({ searchText, page: 1 }));
		},
		[dispatch]
	);

	const editSchool = useCallback((editSchoolDetails: EditSchoolType) => {
		setEditSchoolPayload(editSchoolDetails);
		setAddSchool(true);
	}, []);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>School Name</ColumnHeader>,
				width: 500,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "school"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Acronym</ColumnHeader>,
				Cell: ({ rowData: { acronym } }) => (
					<StyledCellWrapper>
						<StyledCellText>{acronym || "N/A"}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "school"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData: { id, name, acronym } }) => {
					return (
						<ActionMenu
							customMenuItems={[
								{
									label: "Delete",
									onClick: () => onDeleteClick(id),
									visible: true,
									disabled: !canEdit
								},
								{
									label: "Edit",
									onClick: () => editSchool({ name, acronym, id }),
									visible: true,
									disabled: !canEdit
								}
							]}
						/>
					);
				},
				dataKey: "menu"
			}
		],
		[canEdit]
	);

	const onDeleteClick = id => {
		setSchoolDeleteId(id);
		setDeleteModal(true);
	};

	useEffect(() => {
		generateRequest();
	}, [schoolRequests]);

	const generateRequest = useCallback(() => {
		const items: unknown[] = [];
		schoolRequests.map(request => {
			const item = (
				<RequestItemContainer key={request.id}>
					<RequestListTitle>{request.name}</RequestListTitle>
					<ButtonContainer>
						<CheckPermissions>
							<AddButton
								onClick={() => {
									editSchool({ name: request.name, acronym: "", id: request.id });
								}}
							>
								<Add /> Add
							</AddButton>
						</CheckPermissions>
						<CheckPermissions>
							<MergeButton
								onClick={() => {
									editSchool({ name: request.name, acronym: "", id: request.id });
									setIsMergeAction(true);
									setIsMergeRequest(true);
								}}
							>
								<MergeType />
								Merge
							</MergeButton>
						</CheckPermissions>
						<CheckPermissions>
							<DeleteButton
								onClick={() => {
									onDeleteClick(request.id);
									setIsMergeAction(true);
								}}
							>
								<Delete /> Delete
							</DeleteButton>
						</CheckPermissions>
					</ButtonContainer>
				</RequestItemContainer>
			);
			items.push(item);
		});
		setRequestList(items);
	}, [schoolRequests]);

	return (
		<Container>
			<HeaderContainer>
				<Header>Manage Schools</Header>
				<Box display={"flex"} flexDirection={"row"} ml={"auto"} mr={5}>
					<CheckPermissions>
						<Button variant={"contained"} color={"primary"} onClick={() => setAddSchool(true)}>
							Add New School
						</Button>
					</CheckPermissions>
				</Box>
			</HeaderContainer>

			<ContainerTableSection>
				<TableContent mr={4} mt={2}>
					<THeaderWrapper>
						<THeaderTitle>Schools</THeaderTitle>
						<SearchBarComponent
							inputValue={searchText}
							setInputValue={setSearchText}
							onChange={handleSearchBarChange}
							isDebounced={true}
							debounceTime={1000}
							placeHolder={"Type to filter by keywords"}
						/>
					</THeaderWrapper>
					{isLoading ? (
						<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
							<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
						</Box>
					) : (
						<>
							<MaterialTable columns={tableColumns as IColumn<School>[]} data={schools || []} height={800} />
							<TablePagination
								count={totalItems}
								page={page}
								onChange={(event, page) => dispatch(fetchSchools({ page, searchText }))}
								rowsPerPage={perPage}
							/>
						</>
					)}

					{/* Add Edit Model for Schools  */}
					<Modal
						disableEnforceFocus
						disableAutoFocus
						className={classes.modal}
						open={addSchool}
						onClose={() => {
							setAddSchool(false);
							setIsMergeAction(false);
							setIsMergeRequest(false);
							setEditSchoolPayload(undefined);
						}}
					>
						<AddSchoolModel
							title={
								isMergeRequest
									? "Merge Request"
									: isMergeAction
									? "Approve School Request"
									: editSchoolPayload
									? "Edit Schools"
									: "Add School"
							}
							editSchoolPayload={editSchoolPayload}
							onClose={() => {
								setAddSchool(false);
								setEditSchoolPayload(undefined);
								setIsMergeAction(false);
								setIsMergeRequest(false);
							}}
							setAddSchool={() => setAddSchool(false)}
							setEditSchoolPayload={() => setEditSchoolPayload(undefined)}
							isMergeAction={isMergeAction}
							setIsMergeAction={() => setIsMergeAction(false)}
							schools={allSchools}
							setIsMergeRequest={() => setIsMergeRequest(false)}
							isMergeRequest={isMergeRequest}
						/>
					</Modal>

					{/* Delete School Model */}
					<Modal
						className={classes.modal}
						disableEnforceFocus
						disableAutoFocus
						open={deleteModal}
						onClose={() => setDeleteModal(false)}
					>
						<DeleteSchool
							onDelete={() => {
								isMergeAction ? dispatch(deleteMergeRequest(schoolDeleteId)) : dispatch(deleteSchool(schoolDeleteId));
								setDeleteModal(false);
							}}
							title="Delete School"
							message="Are you sure you want to delete this school ?"
							onClose={() => setDeleteModal(false)}
						/>
					</Modal>
				</TableContent>
				<RequestContainer>
					<Box mr={4} mt={2}>
						<THeaderWrapper>
							<Box>
								<RequestMessage>
									<RedCircle>{schoolRequests.length}</RedCircle>
									{schoolRequests.length > 0 ? " School Requests Waiting for Approval" : "No request available."}
								</RequestMessage>
							</Box>
						</THeaderWrapper>
						<RequestTitle>Schools</RequestTitle>
						{isRequestLoading ? (
							<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
								<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
							</Box>
						) : (
							requestList
						)}
					</Box>
				</RequestContainer>
			</ContainerTableSection>
		</Container>
	);
};

export default ManageSchools;
