import React from "react";

import { Grid, Typography } from "@material-ui/core";

export const InputField = ({ title, inputComponent }) => (
	<Grid container spacing={4}>
		<Grid item xs={4}>
			{title && <Typography>{title}</Typography>}
		</Grid>
		<Grid item xs={8}>
			{inputComponent}
		</Grid>
	</Grid>
);

export default InputField;
