import React, { useEffect, useMemo, useState } from "react";

import { useTheme } from "@material-ui/core";
import StudentReportComponent, { MonthChange } from "@remar/shared/dist/components/StudentReportComponent";
import { YYYY_MM_DD } from "@remar/shared/dist/constants";
import { IStudentReport } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { addDays, format, getDaysInMonth, subMonths } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router-dom";

import { getFullState, getStudentLoginReport, getStudentReport } from "store/features/Students/students.slice";

import { AverageIconSvg, TimeIconSvg, TotalIconSvg } from "assets/icons";
import { routes } from "core/constants";

const StudentReport = () => {
	const history = useHistory();
	const { id: userId } = useParams<{ id: number }>();
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useDispatch();
	const [dateRange, setDateRange] = useState({
		startDate: subMonths(new Date(), 1),
		endDate: new Date()
	});
	const [loginChartDate, setLoginChartDate] = useState({
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear()
	});

	const { studentReport, loadingStudentReports, loadingStudentLoginReports, loginReports } = useSelector(getFullState);

	const {
		location: {
			state: { studentInfo }
		}
	} = history;

	const {
		coursesProgress,
		quizzesProgress,
		loginCount,
		timeSpentTrainingInMinutes,
		locationPackageTypeId,
		testPassingPercentage
	} = useMemo(
		() =>
			studentReport
				? {
						...studentReport
				  }
				: ({} as IStudentReport),
		[studentReport]
	);

	const { minutes, hours } = useMemo(() => {
		if (!timeSpentTrainingInMinutes) {
			return { minutes: 0, hours: 0 };
		}
		const minutes = Math.round(timeSpentTrainingInMinutes % 60);
		const hours = Math.floor(timeSpentTrainingInMinutes / 60);
		return { minutes, hours };
	}, [timeSpentTrainingInMinutes]);

	const StatisticsBlocks = useMemo(
		() => [
			{
				title: "Sign ins",
				text: "" + loginCount,
				Icon: TotalIconSvg,
				customBgColor: "hsl(216, 41%, 13%)"
			},
			{
				title: "Passing Percentage",
				text: `${testPassingPercentage ?? 0}%`,
				Icon: AverageIconSvg,
				customBgColor: "hsl(307, 32%, 15%)"
			},
			{
				title: "Time Spent Training",
				text: `${hours}h ${minutes}m`,
				Icon: TimeIconSvg,
				customBgColor: "hsl(154, 90%, 8%)"
			}
		],
		[loginCount, testPassingPercentage, hours, minutes]
	);

	useEffect(() => {
		dispatch(
			getStudentReport({
				userId,
				filters: {
					startDate: format(dateRange.startDate, YYYY_MM_DD),
					endDate: format(addDays(dateRange.endDate, 1), YYYY_MM_DD)
				}
			})
		);
	}, [dispatch, userId, dateRange]);

	useEffect(() => {
		const { month, year } = loginChartDate;
		dispatch(getStudentLoginReport({ userId, filters: { month, year } }));
	}, [dispatch, userId, loginChartDate]);

	const monthName = useMemo(() => {
		const { month, year } = loginChartDate;
		return format(new Date(year, month - 1, 1), "MMM");
	}, [loginChartDate]);

	const labels = useMemo(() => {
		const { month, year } = loginChartDate;
		const daysInMonth = getDaysInMonth(new Date(year, month - 1));

		return [...Array(daysInMonth)].map((_, index) => `${monthName} ${index + 1}`);
	}, [loginChartDate, monthName]);

	const data = useMemo(
		() => ({
			labels,
			datasets: [
				{
					data: loginReports,
					backgroundColor: theme.palette.colors.primary[1000],
					barThickness: 15
				}
			]
		}),
		[theme, loginReports]
	);

	const handleMonthChange = type => {
		if (MonthChange.DECREMENT === type) {
			setLoginChartDate(prevState => {
				let newMonth = prevState.month - 1;
				newMonth = newMonth < 1 ? 12 : newMonth;
				const newYear = newMonth === 12 ? prevState.year - 1 : prevState.year;
				return {
					month: newMonth,
					year: newYear
				};
			});
		} else {
			setLoginChartDate(prevState => {
				let newMonth = prevState.month + 1;
				newMonth = newMonth > 12 ? 1 : newMonth;
				const newYear = newMonth === 1 ? prevState.year + 1 : prevState.year;
				return {
					month: newMonth,
					year: newYear
				};
			});
		}
	};

	const breadcrumbs = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Manage Students", key: 1, link: routes.manageStudents.getPath() },
			{
				title: `${studentInfo.firstName} ${studentInfo.lastName}`,
				key: 2,
				link: `${routes.manageStudents.getPath()}/${userId}`
			},
			{ title: "Report", key: 3 }
		],
		[studentInfo.firstName, studentInfo.lastName, userId]
	);

	return (
		<StudentReportComponent
			StatisticsBlocks={StatisticsBlocks}
			loadingStudentReports={loadingStudentReports}
			loadingStudentLoginReports={loadingStudentLoginReports}
			data={data}
			studentInfo={studentInfo}
			dateRange={dateRange}
			setDateRange={setDateRange}
			loginChartDate={loginChartDate}
			quizzesProgress={quizzesProgress}
			coursesProgress={coursesProgress}
			monthName={monthName}
			handleMonthChange={handleMonthChange}
			locationPackageTypeId={locationPackageTypeId}
			breadcrumbs={breadcrumbs}
		/>
	);
};

export default StudentReport;
