import React, { useEffect, useState } from "react";

import { Box, Card, CircularProgress, Typography, createStyles, makeStyles } from "@material-ui/core";
import { CloudUploadRounded } from "@material-ui/icons";
import { ReactComponent as CloseIconSvg } from "@remar/shared/dist/assets/icons/icon-close-circle.svg";
import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded/FilesUploaded";
import Uploader from "@remar/shared/dist/components/Uploader";
import { LessonTypes } from "@remar/shared/dist/constants";
import { File as LessonAttachment } from "@remar/shared/dist/models";
import { useDispatch, useSelector } from "react-redux";
import { Subject } from "rxjs";
import {
	deleteLessonMaterial,
	getFullState,
	uploadLessonMaterial
} from "store/features/CreateLesson/createLesson.slice";

import { emit } from "store/features/notifications/notifications.slice";

import AttachmentDeleteModal from "modules/Components/AttachmentDeleteModal";

import { VideoBasics } from "../components/VideoBasics";

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			marginBottom: 25
		},
		fileName: {
			fontSize: 12
		},
		fileSize: {
			fontSize: 10,
			color: "#8f9bb3"
		}
	})
);

export const LessonMaterials = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { isLoading, lesson, lessonAttachments } = useSelector(getFullState);
	const [mainVideoProgress, setMainVideoProgress] = useState<number>(0);
	const [attachementLoading, setAttachmentLoading] = useState<boolean>(false);
	const [mainVideoUploadError, setMainVideoUploadError] = useState(false);
	const clearFileInputSubject = new Subject<void>();
	const [attachmentsView, setAttachmentsView] = useState<LessonAttachment[]>([]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [attachment, setAttachment] = useState<Record<string, unknown>>({});

	useEffect(() => {
		if (lesson?.attachments) {
			setAttachmentsView(lesson.attachments);
		}
	}, [lesson]);

	const sideEffect = () => {
		setShowDeleteModal(false);
	};

	const handleUpload = async (file: Partial<File>, statePath: string): Promise<void> => {
		const methods = {
			clearFileInput: () => clearFileInputSubject.next(),
			progress: setMainVideoProgress,
			uploadError: setMainVideoUploadError
		};
		setAttachmentLoading(true);
		dispatch(
			uploadLessonMaterial({
				file,
				lessonId: lesson?.id,
				statePath,
				options: {
					onError: () => {
						setAttachmentLoading(false);
						methods.uploadError(true);
					},
					onProgress: ({ loaded, total }) => {
						methods.progress((loaded / total) * 100);
					},
					onUploaded: () => {
						methods.clearFileInput();
						setAttachmentLoading(false);
					}
				}
			})
		);
		methods.uploadError(false);
	};

	const handleDelete = (id: number | undefined, sideEffect): void => {
		dispatch(deleteLessonMaterial({ id, sideEffect }));
	};

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={450} m={20}>
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	return (
		<>
			<Box pt={3} pr={4}>
				<Box>
					<VideoBasics />
				</Box>
				{/* TODO: Remove this check to show lesson materials accordingly */}
				{lesson?.typeId !== LessonTypes.Interactive && (
					<Box mt={3}>
						<Card>
							<Box display="flex" flexDirection="row" justifyContent="center">
								<Box width="45%">
									<Typography className={classes.title}>Lesson Materials</Typography>
									<Uploader
										accept={"application/pdf,.pptx,.jpg"}
										boxProps={{ maxWidth: "569px" }}
										clearFileInputSubject={clearFileInputSubject}
										error={mainVideoUploadError}
										header="Upload Media"
										icon={<CloudUploadRounded name="cloud-upload" style={{ width: "50px", height: "50px" }} />}
										loading={attachementLoading}
										onAdd={files => handleUpload(files[0], "lessonAttachments")}
										progress={mainVideoProgress}
										title="Drag and Drop File Here or Browse to Choose a File"
										dragFileError="File type is not allowed."
										emit={emit}
									/>
								</Box>
								<Box width="45%" ml="5%">
									{attachmentsView.length && (
										<Box width={400}>
											<Typography className={classes.title}>Files Uploaded</Typography>
											<FilesUploaded
												showTitle={false}
												attachments={attachmentsView}
												IconSvg={CloseIconSvg}
												onClick={attachment => {
													setShowDeleteModal(true);
													setAttachment(attachment);
												}}
											/>
										</Box>
									)}
								</Box>
							</Box>
						</Card>
					</Box>
				)}
			</Box>
			<AttachmentDeleteModal
				isDeleteLoading={lessonAttachments.isDeleteLoading}
				text={`Are you sure you want to delete <span>${attachment?.name}</span> ?`}
				modalOpen={showDeleteModal}
				setModalOpen={setShowDeleteModal}
				onConfirm={() => handleDelete(attachment?.id as number, sideEffect)}
			/>
		</>
	);
};
