import React from "react";

import StatusComponent from "@remar/shared/dist/components/StatusComponent";
import { UserSubscription } from "@remar/shared/dist/models";
import { differenceInCalendarDays } from "date-fns";

const SubscriptionStatus = ({ subscriptions }: { subscriptions: UserSubscription[] }) => {
	const currentSubs = (subscriptions: UserSubscription[]): UserSubscription => {
		const currentSubscription = (subscriptions || []).find(s => !s.hasExpired);
		return currentSubscription || ({} as UserSubscription);
	};
	const currentSubscription = currentSubs(subscriptions);
	const subscriptionType = currentSubscription.type;
	let daysLeft = -1;
	const expiration = currentSubscription.expiresOn;
	if (expiration) {
		daysLeft = differenceInCalendarDays(new Date(expiration), new Date());
	}
	if (currentSubscription.isCancelled) {
		return (
			<StatusComponent
				color={"warning"}
				text={"PENDING CANCELLATION"}
				subText={daysLeft > -1 ? `${daysLeft} active days left` : ""}
			/>
		);
	}

	if (!subscriptionType) {
		return <StatusComponent color={"info"} text={"No Subscription"} />;
	}

	if (subscriptionType.isRecurring) {
		return (
			<StatusComponent
				color={"success"}
				text={"RECURRING"}
				subText={daysLeft > -1 ? `${daysLeft} days till renewal` : ""}
			/>
		);
	}

	if (subscriptionType.isTrial) {
		const isCancelledRecurring = subscriptions.find(s => s.isCancelled && s.type!.isRecurring && s.hasExpired);
		const isCancelledInitial = subscriptions.find(
			s => s.isCancelled && !s.type?.isRecurring && !s.type?.isTrial && s.hasExpired
		);

		if (isCancelledRecurring) {
			return <StatusComponent color={"danger"} text={"CANCELLED RECURRING"} subText={"Trial"} />;
		}

		if (isCancelledInitial) {
			return <StatusComponent color={"danger"} text={"CANCELLED INITIAL"} subText={"Trial"} />;
		}
		return <StatusComponent color={"primary"} text={"TRIAL"} />;
	}

	return (
		<StatusComponent
			color={"success"}
			text={"INITIAL"}
			subText={daysLeft > -1 ? `${daysLeft} days till renewal` : ""}
		/>
	);
};

export default SubscriptionStatus;
