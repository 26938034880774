import { Card, Input as MuiInput, Typography } from "@material-ui/core";

import styled from "styled-components";

export const ChangeEmailHeading = styled(Typography)`
	line-height: 1.85;
	letter-spacing: normal;
	font-weight: bold;
	color: ${props => props.theme.palette.colors.basic[400]};
`;

export const EmailInput = styled(MuiInput)`
	input {
		color: ${props => props.theme.palette.text.primary};
		padding: 12px;
		border-radius: 5px;
		background-color: ${props => props.theme.palette.inputField.background};
	}
	fieldset {
		border-color: ${props => props.theme.palette.colors.basic[900]} !important;
	}
	padding-top: 10px;
	width: 100%;
	&:before {
		display: none;
	}
	&:after {
		display: none;
	}
`;

export const StyledCard = styled(Card)`
	width: 550px;
	height: 464px;
`;
