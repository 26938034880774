import React from "react";

import { TextField as MuiTextField, makeStyles } from "@material-ui/core";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

const TextField = props => <MuiTextField {...props} variant="filled" hiddenLabel />;

const useStyles = makeStyles((theme: IExtendedTheme) => ({
	input: {
		"& input[type=number]": {
			"-moz-appearance": "textfield"
		},
		"& input[type=number]::-webkit-outer-spin-button": {
			"-webkit-appearance": "none",
			margin: 0
		},
		"& input[type=number]::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			margin: 0
		},
		"& input[type=number]:disabled": {
			background: theme.palette.colors.basic[800]
		}
	}
}));

const InputNumber = ({ disabled, ...rest }) => {
	const classes = useStyles();

	return <TextField className={classes.input} type={"number"} disabled={disabled} {...rest} />;
};

export default InputNumber;
