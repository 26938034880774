import { makeStyles } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

export const useVideoActionsStyles = makeStyles((theme: IExtendedTheme) => ({
	actionLabel: {
		fontFamily: "var(--poppins)",
		fontSize: "14px",
		fontWeight: "bold",
		lineGeight: 1.14,
		color: "rgba(255, 255, 255, 0.6)",
		marginRight: "16px",
		marginTop: "2px"
	},
	actionIcon: {
		marginTop: "-3px",
		marginRight: "8px"
	},
	actionContainer: {
		padding: "8px",
		cursor: "pointer",
		marginRight: 5,
		"&.selected": {
			background: "#2a2e37",
			borderRadius: "4px",
			"& .MuiTypography-root": {
				color: "white"
			}
		},
		"&.disabled": {
			cursor: "none",
			pointerEvents: "none"
		}
	},
	card: {
		padding: "8px"
	},
	fromToColon: {
		fontSize: "1.5rem"
	},
	removeIcon: {
		fill: "#fff",
		marginTop: "4px"
	},
	saveTimeBtn: {
		backgroundColor: "#172e4b",
		marginTop: "-2px",
		"& .MuiButton-label": {
			fontWeight: "bold",
			color: theme.palette.colors.info[500]
		}
	},
	toolsContainer: {
		"& input": {
			paddingTop: "10px",
			paddingBottom: "11px"
		}
	}
}));
