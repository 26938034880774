import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FindResponse } from "@remar/shared/dist/api/baseApiService";
import { Tag } from "@remar/shared/src/models";

import { uniqBy } from "lodash";

import { RootState } from "../../index";
import { tagsService } from "../../services/tags";

export interface TagsStateType {
	tags: Tag[];
	page: number;
	perPage: number;
	totalItems: number;
	isLoading: boolean;
	isCreatingEditingDeleting: boolean;
}

const initialState: TagsStateType = {
	tags: [],
	page: 1,
	perPage: 10,
	totalItems: 0,
	isLoading: false,
	isCreatingEditingDeleting: false
};

export const fetchTags = createAsyncThunk(
	"tags/fetchTags",
	async (
		{
			page = 1,
			perPage = 10,
			searchKeyword
		}: { page?: number; perPage?: number; searchKeyword?: string; infinite?: boolean },
		{ rejectWithValue }
	) => {
		return await tagsService
			.find({
				page,
				perPage,
				searchKeyword,
				orderBy: { createdAt: "DESC" },
				include: ["subjectLessons.subject", "sectionLessons.lesson"]
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const createTag = createAsyncThunk(
	"tags/createTag",
	async (
		{
			name,
			sectionLessonIds,
			subjectLessonIds,
			cb
		}: { name: string; sectionLessonIds: number[]; subjectLessonIds: number[]; cb: () => void },
		{ rejectWithValue }
	) => {
		try {
			const res = await tagsService.create({ name, sectionLessonIds, subjectLessonIds });
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);
export const updateTag = createAsyncThunk(
	"tags/updateTag",
	async (
		{
			id,
			name,
			sectionLessonIds,
			subjectLessonIds,
			cb
		}: { id: number; name: string; sectionLessonIds: number[]; subjectLessonIds: number[]; cb: () => void },
		{ rejectWithValue }
	) => {
		try {
			const res = await tagsService.update({
				filters: { id },
				data: { name, sectionLessonIds, subjectLessonIds }
			});
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);
export const deleteTag = createAsyncThunk(
	"tags/deleteTag",
	async ({ id, cb }: { id: string; cb: () => void }, { rejectWithValue }) => {
		try {
			const res = await tagsService.delete({
				filters: { id }
			});
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const tagsSlice = createSlice({
	name: "tags",
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(createTag.pending.type, state => {
				state.isCreatingEditingDeleting = true;
			})
			.addCase(createTag.fulfilled.type, state => {
				state.isCreatingEditingDeleting = false;
			})
			.addCase(createTag.rejected.type, state => {
				state.isCreatingEditingDeleting = false;
			})
			.addCase(updateTag.pending.type, state => {
				state.isCreatingEditingDeleting = true;
			})
			.addCase(updateTag.fulfilled.type, state => {
				state.isCreatingEditingDeleting = false;
			})
			.addCase(updateTag.rejected.type, state => {
				state.isCreatingEditingDeleting = false;
			})
			.addCase(deleteTag.pending.type, state => {
				state.isCreatingEditingDeleting = true;
			})
			.addCase(deleteTag.fulfilled.type, state => {
				state.isCreatingEditingDeleting = false;
			})
			.addCase(deleteTag.rejected.type, state => {
				state.isCreatingEditingDeleting = false;
			})
			.addCase(fetchTags.pending.type, state => {
				state.isLoading = true;
			})
			.addCase(fetchTags.fulfilled.type, (state, action: PayloadAction<FindResponse<Tag>>) => {
				const { meta, payload } = action;
				state.isLoading = false;
				const mappedTags = tags => [
					...tags.map(tag => ({
						...tag,
						hasChildren: true,
						childItems: [{ subjectLessons: tag.subjectLessons, sectionLessons: tag.sectionLessons }]
					}))
				];
				state.tags = meta.arg.infinite
					? (uniqBy([...state.tags, ...mappedTags(payload.items)] as [], "id") as [])
					: [...mappedTags(action.payload.items)];
				state.page = action.payload.page;
				state.perPage = action.payload.perPage;
				state.totalItems = action.payload.totalItems || 0;
			})
			.addCase(fetchTags.rejected.type, state => {
				state.isLoading = false;
			})
});

export const getFullTagsState = (state: RootState): TagsStateType => state.tags;

export default tagsSlice.reducer;
