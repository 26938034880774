import React, { useMemo, useState } from "react";

import { Box, Card, CircularProgress, FormControl, Typography } from "@material-ui/core";
import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { useDispatch, useSelector } from "react-redux";

import {
	filterChaptersAndSections,
	filterSections,
	getFullState,
	setStateValue,
	validateForm
} from "store/features/CreateLesson/createLesson.slice";
import { fetchTags, getFullTagsState } from "store/features/Tags/Tags.slice";
import { _emit } from "store/features/notifications/notifications.slice";
import { genericService } from "store/services";

import theme from "theme/default";

import CreateItemDialog from "./CreateItemDialog";

// TODO: bottom action not working
const LessonDetails = () => {
	const dispatch = useDispatch();
	const { isLoading, lessonDetailsForm } = useSelector(getFullState);
	const { tags, page } = useSelector(getFullTagsState);
	const { inputs: lessonDetailsFormInputs } = lessonDetailsForm;
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const [createItemOpen, setCreateItemOpen] = useState(false);
	const [createItemType, setCreateItemType] = useState<null | "course" | "section" | "chapter">(null);

	const tagOptions = useMemo(() => tags.map(({ name, id }) => ({ name, id })), [tags]);
	const selectedTags = useMemo(
		() =>
			lessonDetailsFormInputs
				? [...tagOptions].filter(tag => lessonDetailsFormInputs?.tagIds?.value?.some(tagId => tagId === tag.id))
				: [],
		[lessonDetailsFormInputs, tagOptions]
	);

	const searchTags = text => {
		dispatch(fetchTags({ searchKeyword: text, page: 1 }));
	};

	const loadMoreTags = () => {
		dispatch(fetchTags({ page: page + 1, infinite: true }));
	};

	return isLoading ? (
		<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
			<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
		</Box>
	) : (
		<Box pt={3} pr={4}>
			<Box>
				<Box mt={2}>
					<Card>
						<Typography>Lesson Location</Typography>

						<Box px={10}>
							<Box mt={3}>
								<Box display="flex" alignItems="center">
									<Box width={150}>
										<Typography>Select Course</Typography>
									</Box>
									<CustomInput
										width={400}
										theme={theme}
										inputProps={{
											onClose: value => dispatch(filterChaptersAndSections(value as number[]))
										}}
										options={{ ...defaultCustomInputOptions, inputData: lessonDetailsFormInputs.courseIds }}
									/>
								</Box>
							</Box>

							<Box mt={3}>
								<Box display="flex" alignItems="center">
									<Box width={150}>
										<Typography>Select Chapter</Typography>
									</Box>
									<CustomInput
										width={400}
										theme={theme}
										inputProps={{
											chipsBottomAction: () => {
												setCreateItemOpen(true);
												setCreateItemType("chapter");
											},
											onClose: value => dispatch(filterSections(value as number[]))
										}}
										options={{
											...defaultCustomInputOptions,
											inputData: { ...lessonDetailsFormInputs.chapterIds, chipsBottomLabel: "Create new chapter" }
										}}
									/>
								</Box>
							</Box>

							<Box mt={3}>
								<Box display="flex" alignItems="center">
									<Box width={150}>
										<Typography>Select Section</Typography>
									</Box>
									<CustomInput
										width={400}
										theme={theme}
										inputProps={{
											chipsBottomAction: () => {
												setCreateItemOpen(true);
												setCreateItemType("section");
											}
										}}
										options={{
											...defaultCustomInputOptions,
											inputData: { ...lessonDetailsFormInputs.sectionIds, chipsBottomLabel: "Create new section" }
										}}
									/>
								</Box>
							</Box>

							<Box mt={3}>
								<Box display="flex" alignItems="center">
									<Box width={150}>
										<Typography>Select Tags</Typography>
									</Box>
									<Box width={400}>
										<AutocompleteFilter
											options={tagOptions}
											value={selectedTags}
											setValue={val => {
												dispatch(
													setStateValue({
														key: "lessonDetailsForm.inputs.tagIds.value",
														value: val.map(t => t.id)
													})
												);
											}}
											onTextInputChange={searchTags}
											filterName="Tags"
											loadMoreResults={loadMoreTags}
											showChips={true}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Card>
				</Box>

				<Box mt={2}>
					<Card>
						<Typography>Lesson Details</Typography>

						<Box px={10} display="flex" justifyContent="space-between" alignItems="flex-start">
							<Box>
								<Box mt={3}>
									<Box display="flex" alignItems="center">
										<Box width={150}>
											<Typography>Lesson Name</Typography>
										</Box>
										<CustomInput
											width={400}
											theme={theme}
											options={{ ...defaultCustomInputOptions, inputData: lessonDetailsFormInputs.name }}
										/>
									</Box>
								</Box>
								<Box mt={3}>
									<Box display="flex" alignItems="flex-start">
										<Box width={150}>
											<Typography>Description</Typography>
										</Box>
										<CustomInput
											width={400}
											theme={theme}
											options={{ ...defaultCustomInputOptions, inputData: lessonDetailsFormInputs.description }}
										/>
									</Box>
								</Box>

								{/* <Box mt={3}>
									<Box display="flex" alignItems="center">
										<Box width={150}>
											<Typography>Lesson Rank</Typography>
										</Box>
										<CustomInput
											width={150}
											theme={theme}
											options={{ ...defaultCustomInputOptions, inputData: lessonDetailsFormInputs.rankId }}
										/>
									</Box>
								</Box> */}

								<Box mt={3}>
									<FormControl component="fieldset">
										<Box display="flex" alignItems="center">
											<Box width={150}>
												<Typography>Lesson Type</Typography>
											</Box>
											<CustomInput
												width={400}
												theme={theme}
												options={{ ...defaultCustomInputOptions, inputData: lessonDetailsFormInputs.typeId }}
											/>
										</Box>
									</FormControl>
								</Box>
							</Box>

							<CustomInput
								width={400}
								ml={4}
								mt={3}
								theme={theme}
								inputProps={{
									fileUploaderDescription: (
										<>
											Drag and Drop File Here or Browse to Choose a File <br />
											<br />
											Recommended Size 720x500
										</>
									)
								}}
								options={{
									...defaultCustomInputOptions,
									genericApiService: genericService,
									inputData: lessonDetailsFormInputs.mainImageKey
								}}
							/>
						</Box>
					</Card>
				</Box>
			</Box>

			<CreateItemDialog
				open={createItemOpen}
				onClose={() => {
					setCreateItemOpen(false);
					setCreateItemType(null);
				}}
				type={createItemType}
			/>
		</Box>
	);
};

export default LessonDetails;
