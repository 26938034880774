import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { CourseChapterSection } from "@remar/shared/dist/models";

import { CourseChapterSectionCreateDto, CourseChapterSectionUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class CourseChapterSectionsService extends BaseApiService<
	CourseChapterSection,
	CourseChapterSectionCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	CourseChapterSectionUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const courseChapterSectionsService = new CourseChapterSectionsService(URLS.COURSE_CHAPTER_SECTIONS);
