import React, { useState } from "react";

import { Box, Collapse, Typography } from "@material-ui/core";
import { CloudUploadRounded } from "@material-ui/icons";
import { ReactComponent as CloseIconSvg } from "@remar/shared/dist/assets/icons/icon-close-circle.svg";
import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded/FilesUploaded";
import Uploader from "@remar/shared/dist/components/Uploader";
import { useDispatch } from "react-redux";
import { Subject } from "rxjs";
import { _emit, emit } from "store/features/notifications/notifications.slice";
import { genericService } from "store/services";

import theme from "theme/default";

import { CustomInput as StyledInput, useStyles } from "./styles";

import { Header } from "../../ManageStudents/styles";

export default ({
	removeQuestionAttachment,
	setStateValue,
	uploadQuestionMaterial,
	validateForm,
	attachments,
	description,
	children,
	endOfQuestionSummary,
	mainImageKey,
	statePath = "",
	lessonId = 0,
	questionId = "",
	expanded = true,
	mediaAfterChildren = false,
	hideDescription = false
}) => {
	const dispatch = useDispatch();
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const [error, setError] = useState(false);
	const [uploadingProgress, setUploadingProgress] = useState(0);
	const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);

	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";

	const handleUpload = async (file: Partial<File>): Promise<void> => {
		const methods = {
			clearFileInput: () => clearFileInputSubject.next(),
			progress: setUploadingProgress,
			uploadError: setError
		};
		setIsAttachmentLoading(true);
		dispatch(
			uploadQuestionMaterial({
				file,
				fullStatePath,
				...(lessonId && { lessonId }),
				...(questionId && { questionId }),
				options: {
					onError: () => {
						setIsAttachmentLoading(false);
						methods.uploadError(true);
					},
					onProgress: ({ loaded, total }) => {
						methods.progress((loaded / total) * 100);
					},
					onUploaded: () => {
						setIsAttachmentLoading(false);
						methods.clearFileInput();
					}
				}
			})
		);
		methods.uploadError(false);
	};
	const clearFileInputSubject = new Subject<void>();
	const classes = useStyles();

	const UploadMediaContent = () => {
		return (
			<Box display="flex">
				<Box mt={3} flex="0 0 50%">
					<Typography className={classes.addOptionHeader}>UPLOAD MATERIALS</Typography>
					<Box display="flex" mt={3}>
						<Uploader
							accept=".pdf,.jpeg,.jpe,.jpg,.ppt,.pptx"
							clearFileInputSubject={clearFileInputSubject}
							error={error}
							header="Upload Media"
							icon={<CloudUploadRounded name="cloud-upload" style={{ width: "50px", height: "50px" }} />}
							loading={isAttachmentLoading}
							onAdd={files => handleUpload(files[0])}
							progress={uploadingProgress}
							dragFileError="File Type is not allowed."
							title="Drag and Drop File Here or Browse to Choose a File. PPT, JPEG, PDF types are allowed."
							emit={emit}
						/>
					</Box>
				</Box>
				<Box width="45%" ml="5%" mt={3}>
					{attachments?.length > 0 && (
						<Box>
							<Typography className={classes.addOptionHeader}>FILES UPLOADED</Typography>
							<FilesUploaded
								showTitle={false}
								attachments={attachments.map(attachment => ({
									fileName: attachment.fileName.value,
									name: attachment.name.value,
									fileSizeInBytes: attachment.fileSizeInBytes.value,
									fileType: attachment.fileType.value,
									lessonId: 0,
									id: 0
								}))}
								IconSvg={CloseIconSvg}
								onClick={attachment =>
									dispatch(
										removeQuestionAttachment({
											fullStatePath,
											index: attachments.findIndex(({ fileName }) => fileName.value === attachment.fileName)
										})
									)
								}
							/>
						</Box>
					)}
				</Box>
			</Box>
		);
	};

	return (
		<Collapse in={expanded} unmountOnExit>
			{!hideDescription && (
				<>
					{" "}
					<Header>Question Description</Header>
					<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: description }} />{" "}
				</>
			)}

			{!mediaAfterChildren && attachments && <UploadMediaContent />}
			{children}
			{mediaAfterChildren && attachments && <UploadMediaContent />}
			<div className={classes.rationalContainer}>
				<Typography className={classes.rationalText}>Rationale</Typography>
				<StyledInput
					mr={2}
					theme={theme}
					options={{
						...defaultCustomInputOptions,
						inputData: endOfQuestionSummary
					}}
				/>
				<Box display="flex" mt={3}>
					<StyledInput
						width={246}
						height={246}
						theme={theme}
						inputProps={{
							fileUploaderDescription: (
								<>
									Drag and Drop File Here or Browse to Choose a File <br />
									<br />
									Recommended Size 720x500
								</>
							)
						}}
						options={{
							...defaultCustomInputOptions,
							genericApiService: genericService,
							inputData: mainImageKey
						}}
					/>
				</Box>
			</div>
		</Collapse>
	);
};
