import React, { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import { Computer, Person } from "@material-ui/icons";

import { subMonths } from "date-fns";

import { DatePickerComponent } from "./components";

import { ModuleContainer, ModuleWrapper, TitleText, TotalText } from "./styles";

import { Wrapper } from "../../layouts/TableContentLayout";
import { Course, User } from "../../models";
import { getFullName } from "../../utils/auth";
import Button from "../Button";
import { HeaderWrapperProps } from "../HeaderContainer/HeaderContainer";

export type PayloadItems = {
	studentId?: number;
	startDate: Date;
	endDate: Date;
	courseId?: number;
};

type ReportsProps = HeaderWrapperProps & {
	onUpdate: (d: PayloadItems) => void;
};

const Reports: React.FC<ReportsProps> = ({ heading, breadcrumb, actions, children, onUpdate }) => {
	const [student] = useState<User>();
	const [course] = useState<Course>();
	const [dateRange, setDateRange] = useState({
		startDate: subMonths(new Date(), 1),
		endDate: new Date()
	});

	useEffect(() => {
		onUpdate({
			studentId: student?.id,
			startDate: dateRange.startDate,
			endDate: dateRange.endDate,
			courseId: course?.id
		});
	}, [dateRange, student, course]);

	return (
		<Wrapper heading={heading} breadcrumb={breadcrumb} actions={actions}>
			<ModuleContainer container spacing={2}>
				<ModuleWrapper item xs={12}>
					<Box className="report-card" gridGap={16} justifyContent="space-between">
						<Box className="filter-container" gridGap={16}>
							<Person />
							<Box className="filter-selector">
								<TotalText>{student ? getFullName(student) : "All"}</TotalText>
								<TitleText className="filter-title">Students</TitleText>
							</Box>
							<Button variant="outlined" color="secondary" disabled>
								Change
							</Button>
						</Box>
						<Box className="filter-container">
							<Box display={"flex"} alignItems={"center"} justifyContent="center" flexWrap="wrap" gridGap={8}>
								<Box>
									<DatePickerComponent
										name={"startDate"}
										label={"Start Date"}
										value={dateRange}
										onChange={d =>
											setDateRange(p => ({
												...p,
												startDate: d
											}))
										}
									/>
								</Box>
								<Box>
									<DatePickerComponent
										name={"endDate"}
										label={"End Date"}
										value={dateRange}
										onChange={d =>
											setDateRange(p => ({
												...p,
												endDate: d
											}))
										}
									/>
								</Box>
							</Box>
						</Box>
						<Box className="filter-container">
							<Computer />
							<Box className="filter-selector">
								<TotalText className="filter-selected">{course ? course.name : "All"}</TotalText>
								<TitleText className="filter-title">Selected Course</TitleText>
							</Box>
							<Button variant="outlined" color="secondary" disabled>
								Change
							</Button>
						</Box>
					</Box>
				</ModuleWrapper>
				{children}
			</ModuleContainer>
		</Wrapper>
	);
};

export default Reports;
