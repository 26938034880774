import React, { useState } from "react";

import { Box, IconButton, TextField, Typography, useTheme } from "@material-ui/core";

import { ReactComponent as DeleteIconSvg } from "@remar/shared/dist/assets/icons/icon-trash-filled.svg";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";

import TimeInput from "../TimeInput";

const Cue = ({ index, start, end, text, error, handleCueUpdate, handleTextUpdate, addCue, removeCue }) => {
	const [showCueControls, setShowCueControls] = useState(false);
	const theme = useTheme<IExtendedTheme>();
	return (
		<Box onMouseEnter={() => setShowCueControls(true)} onMouseLeave={() => setShowCueControls(false)}>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				height={"45px"}
				style={{ border: error ? `1px solid ${theme.palette.colors.danger[400]}` : "" }}
			>
				<Box>
					<TimeInput seconds={start} index={index} handleUpdate={handleCueUpdate} type={"start"} />
				</Box>
				<Box mx={1}>
					<Typography>-</Typography>
				</Box>
				<Box>
					<TimeInput seconds={end} index={index} handleUpdate={handleCueUpdate} type={"end"} />
				</Box>
				{showCueControls && (
					<IconButton style={{ marginLeft: 5 }} onClick={() => removeCue(index)}>
						<DeleteIconSvg width={20} />
					</IconButton>
				)}
			</Box>
			<Box mt={1}>
				<TextField
					hiddenLabel
					InputProps={{
						style: { color: "#fff" },
						disableUnderline: true
					}}
					color="primary"
					disabled={false}
					onChange={(event: React.ChangeEvent<{ value: string }>) => {
						if (event.target.value) {
							handleTextUpdate({ index, text: event.target.value });
						}
					}}
					variant="filled"
					fullWidth
					value={text}
				/>
			</Box>
			<Box height={30} display={"flex"} justifyContent={"flex-end"} mt={0.5} mb={1}>
				{showCueControls && <AddButton onClick={() => addCue(index)} label={"Add Cue"} />}
			</Box>
		</Box>
	);
};
export default Cue;
