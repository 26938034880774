import React, { useEffect, useMemo, useState } from "react";

import { Box, FormControl, IconButton, MenuItem, Select } from "@material-ui/core";

import { Visibility } from "@material-ui/icons";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import StatusComponent from "@remar/shared/dist/components/StatusComponent";
import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import { EmptyState, TableWrapper } from "@remar/shared/dist/layouts";

import { getSanitizedText } from "@remar/shared/dist/utils/textUtils";
import { format } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFullState, getStudentEmailLogs } from "store/features/Students/students.slice";

import { DeliveryStatus, EmailLogItem } from "store/services/emailLogService";

import EmailPreview from "./EmailPreview";

import { StyledListIcon } from "../../ManageLocations/styles";

const LongText: React.FC<{ maxLength?: number }> = ({ children, maxLength = 50 }) => {
	const sanitizedText = getSanitizedText(children as string);
	const isLongText = sanitizedText.length > maxLength;
	return <StyledCellText>{isLongText ? `${sanitizedText.substring(0, maxLength)}...` : sanitizedText}</StyledCellText>;
};

const getColor = (status: DeliveryStatus) => {
	switch (status) {
		case DeliveryStatus.DELIVERED:
			return "success";
		case DeliveryStatus.FAILED:
			return "danger";
		default:
			return "basic";
	}
};

const EmailLogs = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const userId = parseInt(id, 10);

	const {
		studentEmailLogs: { isLoading, page, perPage, totalItems, items }
	} = useSelector(getFullState);

	const [searchKeyword, setSearchKeyword] = useState("");
	const [selectedStatus, setSelectedStatus] = useState<DeliveryStatus | "">("");

	useEffect(() => {
		if (userId) {
			dispatch(getStudentEmailLogs({ userId, page, searchKeyword, selectedStatus }));
		}
	}, [dispatch, page, searchKeyword, selectedStatus, userId]);

	const [openEmailPreview, setOpenEmailPreview] = useState<EmailLogItem | null>(null);

	const tableColumns: Array<IColumn<EmailLogItem>> = useMemo(
		() => [
			{
				align: "left",
				width: 400,
				dataKey: "sentBy",
				label: <ColumnHeader>Sent by</ColumnHeader>,
				Cell: ({
					rowData: {
						compiledData: { from }
					}
				}) => {
					return (
						<StyledCellWrapper>
							<Avatar variant="rounded" fullName={"admin"} style={{ cursor: "pointer" }} onClick={() => {}} />
							<Box>
								<StyledCellText margin="5px 0 0 0">admin</StyledCellText>
								<StyledCellText margin="0 0 5px 0">{from}</StyledCellText>
							</Box>
						</StyledCellWrapper>
					);
				}
			},
			{
				align: "left",
				width: 800,
				dataKey: "subject",
				label: <ColumnHeader>Subject</ColumnHeader>,
				Cell: ({
					rowData: {
						compiledData: { subject }
					}
				}) => <LongText maxLength={100}>{subject}</LongText>
			},
			{
				align: "left",
				width: 400,
				dataKey: "status",
				label: <ColumnHeader>Status</ColumnHeader>,
				Cell: ({ rowData: { delivered } }) => {
					const status = delivered ? DeliveryStatus.DELIVERED : DeliveryStatus.FAILED;
					return <StatusComponent color={getColor(status)} text={status} />;
				}
			},
			{
				align: "left",
				width: 400,
				dataKey: "createdAt",
				label: <ColumnHeader>Date sent</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => (
					<StyledCellText>{format(Date.parse(createdAt as string), "MMMM d, yyyy 'at' h:mm a 'EST'")}</StyledCellText>
				)
			},
			{
				align: "right",
				width: 400,
				dataKey: "actions",
				label: <></>,
				Cell: ({ rowData }) => (
					<Box display="flex" gridGap={16}>
						<IconButton size="small" onClick={() => setOpenEmailPreview(rowData)}>
							<Visibility color="disabled" />
						</IconButton>
					</Box>
				)
			}
		],
		[]
	);

	const handleChangeFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedStatus(event.target.value as DeliveryStatus);
	};

	return (
		<>
			<TableWrapper
				tableTitle="Email Logs"
				data={items}
				filterBlock={
					<>
						<Box mr={1}>
							<StyledListIcon />
						</Box>
						<Box mr={2}>
							<FormControl hiddenLabel size="small" variant="filled">
								<Select
									disableUnderline
									value={selectedStatus}
									onChange={handleChangeFilter}
									displayEmpty
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left"
										},
										getContentAnchorEl: null
									}}
								>
									<MenuItem value={""}>All Statuses</MenuItem>
									{Object.keys(DeliveryStatus).map(s => (
										<MenuItem key={s} value={DeliveryStatus[s]}>
											<StatusComponent color={getColor(DeliveryStatus[s])} text={DeliveryStatus[s]} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</>
				}
				searchText={searchKeyword}
				setSearchText={setSearchKeyword}
				isLoading={isLoading}
				emptyState={<EmptyState description="No emails sent yet" />}
				onChangePage={p => dispatch(getStudentEmailLogs({ userId, page: p, searchKeyword, selectedStatus }))}
				page={page}
				perPage={perPage}
				tableColumns={tableColumns}
				totalItems={totalItems}
			/>
			<EmailPreview email={openEmailPreview} onClose={() => setOpenEmailPreview(null)} />
		</>
	);
};

export default EmailLogs;
