import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";

import { Module, ModulesResponseDto, ReportCreateDto, ReportUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class ReportService extends BaseApiService<
	Module,
	ReportCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	ReportUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getModules(): Promise<ModulesResponseDto> {
		return request(`${this.baseUrl}/get-modules`, "GET") as Promise<ModulesResponseDto>;
	}

	getDuration(): Promise<ModulesResponseDto> {
		return request(`${this.baseUrl}/get-duration`, "GET") as Promise<ModulesResponseDto>;
	}

	generateReport(report: number, period: number): Promise<string> {
		return request(
			`${this.baseUrl}/generate-report?period=${period}&report=${report}`,
			"GET",
			undefined,
			undefined,
			false,
			false,
			{ Accept: "application/csv", "Content-Type": "application/json" }
		) as Promise<string>;
	}
}

export const reportService = new ReportService(URLS.REPORTS);
