import React, { useEffect, useState } from "react";

import { Box, Card, Chip, Divider, Typography, useTheme } from "@material-ui/core";

import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import TextFieldChipInput from "@remar/shared/dist/components/TextFieldChipInput";
import { ILocation } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { validateEmail } from "@remar/shared/dist/utils/serviceUtils";
import { differenceWith, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { fetchLocations, selectManageLocationsFullState } from "store/features/ManageLocations/manageLocations.slice";
import {
	assignLocationToCustomPackage,
	cancelInviteLocationToCustomPackage,
	inviteLocationToCustomPackage
} from "store/features/ManagePackage/managePackage.slice";

import { InviteInstituteWrapper, SelectInstitutionWrapper, StyledCloseIcon } from "./styles";

import { AddBookWrapper, AddIcon } from "../../../CourseAddEdit/courseAddEdit.styles";

const LOCATION_PER_PAGE = 20;
const LocationDetails = ({ locationPackageId, initialLocations, initialInvites }) => {
	const [addInstitute, setAddInstitute] = useState(false);
	const [selectedLocations, setSelectedLocations] = useState<ILocation[]>(initialLocations || []);
	const [selectedInvites, setSelectedInvites] = useState<string[]>(initialInvites || []);
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useDispatch();
	const { locations: allLocations, page: locationPage } = useSelector(selectManageLocationsFullState);

	useEffect(() => {
		if (!allLocations.length) {
			dispatch(fetchLocations({ perPage: LOCATION_PER_PAGE, page: locationPage, searchKeyword: "", filters: {} }));
		}
	}, [allLocations, dispatch]);

	const handAddInstitutes = email => {
		const isValid = validateEmail(email);
		if (!isValid) {
			return;
		}
		setSelectedInvites(prevState => [...prevState, email]);
		dispatch(
			inviteLocationToCustomPackage({
				email,
				locationPackageId,
				failCb: () => {
					setSelectedInvites(prevState => [...prevState].filter(item => item !== email));
				}
			})
		);
	};

	const handleDeleteChip = chip => {
		setSelectedInvites(prevState => [...prevState].filter(item => item !== chip));
		dispatch(cancelInviteLocationToCustomPackage({ email: chip, locationPackageId }));
	};
	const searchLocations = text => {
		dispatch(fetchLocations({ perPage: 10, page: 1, searchKeyword: text, filters: {} }));
	};
	const loadMoreLocations = () => {
		dispatch(
			fetchLocations({
				perPage: 10,
				page: locationPage + 1,
				searchKeyword: "",
				filters: {},
				infinite: true
			})
		);
	};

	return (
		<Card style={{ paddingBottom: 0 }}>
			<Box display={"flex"}>
				<Box mr={3}>
					<Typography>Location</Typography>
				</Box>
				<Box display={"flex"} flexDirection={"column"}>
					<SelectInstitutionWrapper>
						<AutocompleteFilter
							options={allLocations}
							value={selectedLocations || []}
							setValue={val => {
								const newLoc = differenceWith(val, selectedLocations, isEqual);
								if (newLoc.length) {
									setSelectedLocations(val);
									dispatch(
										assignLocationToCustomPackage({
											locationId: newLoc[0].id,
											locationPackageId,
											type: "assign",
											failCb: () => {
												setSelectedLocations(prevState => [...prevState].filter(l => l.id !== newLoc[0].id));
											}
										})
									);
								}
							}}
							onTextInputChange={searchLocations}
							filterName="locations"
							loadMoreResults={loadMoreLocations}
						/>
					</SelectInstitutionWrapper>
					<Box mb={1} display={"flex"} style={{ flexFlow: "row wrap" }}>
						{selectedLocations.map(c => (
							<Box key={c.id} m={"3px"}>
								<Chip
									label={c.name}
									deleteIcon={<StyledCloseIcon />}
									style={{ background: theme.palette.colors.basic[200], color: theme.palette.colors.basic[1000] }}
									onDelete={() => {
										const tempLocations = [...selectedLocations];
										const updatedLocations = [...selectedLocations].filter(l => l.id != c.id);
										setSelectedLocations(updatedLocations);
										dispatch(
											assignLocationToCustomPackage({
												locationId: c.id,
												locationPackageId,
												type: "unAssign",
												failCb: () => {
													setSelectedLocations(tempLocations);
												}
											})
										);
									}}
								/>
							</Box>
						))}
					</Box>
				</Box>
			</Box>

			<Divider />

			<Divider />
			{!addInstitute && !selectedInvites.length ? (
				<AddBookWrapper onClick={() => setAddInstitute(true)}>
					<AddIcon />
					<Typography>Add Institute</Typography>
				</AddBookWrapper>
			) : (
				<Box>
					<Divider />
					<Box py={3}>
						<Typography>Invite New Institute</Typography>
					</Box>
					<Divider />
					<InviteInstituteWrapper py={3}>
						<Box className={"label"}>
							<Typography>Email of Institutes</Typography>
						</Box>
						<TextFieldChipInput
							chips={selectedInvites}
							handleAddChip={handAddInstitutes}
							handleDeleteChip={handleDeleteChip}
						/>
					</InviteInstituteWrapper>
				</Box>
			)}
		</Card>
	);
};

export default LocationDetails;
