import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import CancelRounded from "@material-ui/icons/CancelRounded";
import { QuestionTypes } from "@remar/shared/dist/constants";
import IconRoundButton from "@remar/shared/dist/ui/Buttons/IconRoundButton";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { updateOptionOrder } from "store/features/CreateLesson/createLesson.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { ClozeDropDownQuestion } from "./ClozeDropDownQuestion";
import { DragAndDropQuestion } from "./DragAndDropQuestion";
import { DropDownTableQuestion } from "./DropDownTableQuestion";
import { GroupingQuestion } from "./GroupingQuestion";
import { HotspotHighlightQuestion } from "./HotspotHighlight";
import { HotspotHighlightTableQuestion } from "./HotspotHighlightTableQuestion";
import { MatrixMultipleChoiceQuestion } from "./MatrixMultipleChoiceQuestion";
import { MatrixSingleChoiceQuestion } from "./MatrixSingleChoiceQuestion";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import { MultipleResponseGroupQuestion } from "./MultipleResponseGroupQuestion";
import SequencingQuestion from "./SequencingQuestion";
import { SingleChoiceQuestion } from "./SingleChoiceQuestion";
import { Page, CustomInput as StyledInput, CustomTabTitle as StyledTabTitleInput, TabText, useStyles } from "./styles";

import { useTestOnlyQuestionStyles } from "../../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

export const isFormValid = addNewQuestionForm => {
	return (
		["courseIds", "typeId", "subjectId", "lessonSubjectId", "difficultyLevelId", "description"]
			.map(key => get(addNewQuestionForm, `inputs.${key}.error`))
			.findIndex(value => value !== null) === -1 &&
		!addNewQuestionForm.inputs.tabs.find(
			({ text, deleted, title }) => !deleted?.value && (text?.value === "" || title?.value === "")
		) &&
		!addNewQuestionForm.inputs.questions.find(({ typeId }) => !typeId?.value)
	);
};

export const CaseStudyQuestion = ({
	addOneMoreQuestionAnswerOption,
	clearArrayOfQuestionAnswerOption,
	createGroupAnswerOptionItem,
	createQuestionAnswerOptionItem,
	createQuestionGap,
	createQuestionGroup,
	createSingleMatrixQuestionAnswerOptions,
	createTab,
	duplicatePreviousTabs,
	getFullState,
	initCaseStudyQuestion,
	reduceAnswerOptsLength,
	removeGap,
	removeGapOption,
	removeQuestionAnswerOptionItem,
	removeQuestionAttachment,
	removeQuestionData,
	removeQuestionGroup,
	removeTab,
	setArrayOfQuestionAnswerOption,
	setStateValue,
	updateAnswerOptsFlag,
	updateCorrectFlag,
	updateMatrixQuestionGroup,
	updateValidationCriteria,
	uploadQuestionMaterial,
	validateForm,
	formName = "addNewQuestionForm",
	templatePath = "answerOptions",
	statePath = "",
	initGroupingQuestion,
	moveGroupQuestionAnswerOption
}) => {
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const dispatch = useDispatch();
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const { questions = [], description } = questionData;
	const [activeTab, setActiveTab] = useState(0);
	const [addButtonDisable, setAddButtonDisable] = useState(false);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [editableTab, setEditableTab] = useState<number | undefined>();
	const inputRef = useRef<HTMLInputElement>(null);
	const question = questions[questionIndex];
	const tabs = useMemo(() => question?.tabs ?? [], [question]);
	const notDeletedTabs = tabs.filter(({ deleted }) => !deleted?.value);

	const questionStatePath = statePath ? `${statePath}.questions.${questionIndex}` : `questions.${questionIndex}`;
	const questionInputPath = `inputs.${questionStatePath}`;
	const questionsInputBasePath = statePath ? `inputs.${statePath}.questions` : "inputs.questions";
	const setQuestionAndTabIndex = (index: number) => {
		setQuestionIndex(index);
		setActiveTab(0);
	};

	useEffect(() => {
		if (typeof editableTab == "number" && inputRef.current) {
			inputRef.current.focus();
		}
	}, [editableTab, inputRef]);
	useEffect(() => {
		if (questions.length === 0) {
			dispatch(initCaseStudyQuestion({ formName, statePath: fullStatePath }));
		}
	}, [questions.length]);

	useEffect(() => {
		if (tabs.length === 0 && question) {
			dispatch(createTab({ formName, statePath: questionInputPath }));
			dispatch(validateForm({ formStatePath: formName, markInputsAsDirty: true }));
		}
	}, [tabs.length, question]);
	useEffect(() => {
		if (tabs.filter(({ deleted }) => !deleted?.value).length === 3) {
			setAddButtonDisable(true);
		} else {
			setAddButtonDisable(false);
		}
	}, [tabs]);

	const getQuestionBody = (typeId: QuestionTypes) => {
		switch (typeId) {
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return (
					<MultipleChoiceQuestion
						formName={formName}
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						templatePath={templatePath}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.MultipleResponseGroup:
				return (
					<MultipleResponseGroupQuestion
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeGapOption={removeGapOption}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						formName={formName}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return (
					<DragAndDropQuestion
						addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						formName={formName}
						templatePath={templatePath}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return (
					<ClozeDropDownQuestion
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeGapOption={removeGapOption}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						updateCorrectFlag={updateCorrectFlag}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						formName={formName}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.MatrixSingleChoice:
				return (
					<MatrixSingleChoiceQuestion
						createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
						reduceAnswerOptsLength={reduceAnswerOptsLength}
						createQuestionGroup={createQuestionGroup}
						getFullState={getFullState}
						removeQuestionGroup={removeQuestionGroup}
						setStateValue={setStateValue}
						validateForm={validateForm}
						statePath={questionStatePath}
						uploadQuestionMaterial={uploadQuestionMaterial}
						removeQuestionAttachment={removeQuestionAttachment}
						formName={formName}
						templatePath={templatePath}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.MatrixMultipleChoice:
				return (
					<MatrixMultipleChoiceQuestion
						addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
						createQuestionGroup={createQuestionGroup}
						createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
						getFullState={getFullState}
						reduceAnswerOptsLength={reduceAnswerOptsLength}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						removeQuestionGroup={removeQuestionGroup}
						setStateValue={setStateValue}
						updateMatrixQuestionGroup={updateMatrixQuestionGroup}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						formName={formName}
						templatePath={templatePath}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.DropDownTable:
				return (
					<DropDownTableQuestion
						createGroupAnswerOptionItem={createGroupAnswerOptionItem}
						createQuestionGroup={createQuestionGroup}
						getFullState={getFullState}
						removeGap={removeGap}
						removeGapOption={removeGapOption}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						updateCorrectFlag={updateCorrectFlag}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						formName={formName}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.SingleChoice:
				return (
					<SingleChoiceQuestion
						initSingleChoiceQuestion={null}
						templatePath={templatePath}
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						updateAnswerOptsFlag={updateAnswerOptsFlag}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						formName={formName}
						key={`${fullStatePath}.questions.${questionIndex}`}
					/>
				);
			case QuestionTypes.HotspotHighlight:
				return (
					<HotspotHighlightQuestion
						templatePath={templatePath}
						clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
						getFullState={getFullState}
						removeQuestionAttachment={removeQuestionAttachment}
						setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
						setStateValue={setStateValue}
						updateValidationCriteria={updateValidationCriteria}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						key={`${fullStatePath}.questions.${questionIndex}`}
						formName={formName}
					/>
				);
			case QuestionTypes.HighlightTable:
				return (
					<HotspotHighlightTableQuestion
						templatePath={templatePath}
						clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
						createQuestionGap={createQuestionGap}
						getFullState={getFullState}
						removeGap={removeGap}
						removeQuestionAttachment={removeQuestionAttachment}
						setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						key={`${fullStatePath}.questions.${questionIndex}`}
						formName={formName}
					/>
				);
			case QuestionTypes.Grouping:
				return (
					<GroupingQuestion
						moveGroupQuestionAnswerOption={moveGroupQuestionAnswerOption}
						initGroupingQuestion={initGroupingQuestion}
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						statePath={questionStatePath}
						formName={formName}
					/>
				);
			case QuestionTypes.Sequencing:
				return (
					<SequencingQuestion
						createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
						getFullState={getFullState}
						removeQuestionAttachment={removeQuestionAttachment}
						setStateValue={setStateValue}
						uploadQuestionMaterial={uploadQuestionMaterial}
						validateForm={validateForm}
						updateOptionOrder={updateOptionOrder}
						statePath={questionStatePath}
						formName={formName}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<Box display="flex">
			<Box width="50%">
				<Typography className={classes.addOptionHeader}>MAIN QUESTION DESCRIPTION</Typography>

				<StyledInput mr={2} theme={theme} options={{ ...defaultCustomInputOptions, inputData: description }} />

				<Box display="flex" alignItems="center" mt={3} flexWrap="wrap">
					{tabs.map(
						({ id, title, deleted }, tabIndex) =>
							!deleted?.value && (
								<Box width="25%" key={id.value} pr={2} display="flex" alignItems="center">
									{tabIndex === editableTab ? (
										<StyledTabTitleInput
											mr={2}
											inputProps={{ onBlur: () => setEditableTab(undefined), inputRef }}
											theme={theme}
											options={{ ...defaultCustomInputOptions, inputData: title }}
										/>
									) : (
										<Box
											title="Double click for edit title"
											className={classes.tab + (tabIndex === activeTab ? ` ${classes.activeTab}` : "")}
											onClick={() => {
												setActiveTab(tabIndex);
												setEditableTab(undefined);
											}}
											onDoubleClick={() => setEditableTab(tabIndex)}
										>
											<TabText>{title?.value}</TabText>
											{notDeletedTabs.length > 1 && (
												<IconButton
													size="small"
													onClick={() => {
														dispatch(
															removeTab({
																formName,
																statePath: questionInputPath,
																tabIndex
															})
														);
														if (tabIndex === activeTab) {
															setActiveTab(tabs.findIndex(({ deleted }) => !deleted?.value));
														}
													}}
												>
													<CancelRounded color="disabled" />
												</IconButton>
											)}
										</Box>
									)}
								</Box>
							)
					)}
					<IconRoundButton
						color={"primary"}
						variant={"contained"}
						disabled={addButtonDisable}
						Icon={<Add style={{ width: "15px", height: "15px" }} fill={"#fff"} />}
						onClick={() => {
							dispatch(createTab({ formName, statePath: questionInputPath }));
						}}
					/>
				</Box>

				{tabs.length > 0 && (
					<Box mt={3}>
						<StyledInput
							mr={2}
							theme={theme}
							options={{ ...defaultCustomInputOptions, inputData: tabs[activeTab].text }}
						/>
						{questionIndex > 0 && (
							<Button
								variant="contained"
								color="primary"
								className={classes.duplicateTabsButton}
								onClick={() => {
									setActiveTab(0);
									dispatch(duplicatePreviousTabs({ formName, questionsInputBasePath, index: questionIndex }));
								}}
							>
								Duplicate Tab(s) From Previous Question
							</Button>
						)}
					</Box>
				)}
			</Box>
			{question && (
				<Box width="50%" pl={2}>
					<Typography className={classes.addOptionHeader}>QUESTION {questionIndex + 1}/6</Typography>
					<Typography className={classes.addOptionHeader}>Question type</Typography>
					<StyledInput
						inputProps={{
							onChange: () => {
								dispatch(
									removeQuestionData({
										statePath: `${formName}.${fullStatePath}.questions.${questionIndex}`
									})
								);
							}
						}}
						mr={2}
						theme={theme}
						options={{ ...defaultCustomInputOptions, inputData: question.typeId }}
					/>
					{getQuestionBody(question.typeId.value)}
					<Box display="flex" alignContent="flex-end" mt={3}>
						<Page onClick={() => questionIndex > 0 && setQuestionAndTabIndex(questionIndex - 1)}>&lt;</Page>
						{questions.map((_, page) => (
							<Page onClick={() => setQuestionAndTabIndex(page)} key={page} active={page === questionIndex}>
								{page + 1}
							</Page>
						))}
						<Page onClick={() => questionIndex < questions.length - 1 && setQuestionAndTabIndex(questionIndex + 1)}>
							&gt;
						</Page>
					</Box>
				</Box>
			)}
		</Box>
	);
};
