import React from "react";

import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const RadioInput = ({ options, ...fieldProps }: { options: Record<string, string>[] }) => (
	<FormControl>
		<RadioGroup
			style={{ display: "flex", flexFlow: "row nowrap" }}
			aria-labelledby="radio-buttons-group-label"
			{...fieldProps}
		>
			{options.map(({ value, label }) => (
				<FormControlLabel
					key={label}
					value={value}
					control={<Radio disabled={fieldProps.disabled} color="primary" />}
					label={label}
				/>
			))}
		</RadioGroup>
	</FormControl>
);

export default RadioInput;
