import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Grid } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { Wrapper } from "@remar/shared/dist/layouts";
import { Book, Course, UserSubscriptionType } from "@remar/shared/dist/models";
import { useHistory, useParams } from "react-router-dom";

import { getFullState as getFullCourseState } from "store/features/Course/course.slice";
import { createGift, fetchGifts, findGift, getFullGiftState } from "store/features/Gifts";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { routes } from "core/constants";
import TriangleBreadcrumbs from "modules/Components/TriangleBreadcrumbs";

import AboutForm from "./AboutForm";
import GiftSummary from "./GiftSummary";
import SelectBooks from "./SelectBooks";
import SelectCourse from "./SelectCourse";
import SelectPackage from "./SelectPackage";

import { ITEMS_PER_PAGE } from "../constants";

enum Steps {
	About,
	Course,
	Package,
	Books
}

const AddEditGift = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		if (id) {
			dispatch(findGift(id));
		}
	}, [dispatch, id]);

	const [step, setStep] = useState<Steps>(Steps.About);
	const [name, setName] = useState<string>("");
	const [validity, setValidity] = useState<number>(0);
	const [planId, setPlanId] = useState<string>("");
	const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
	const [selectedPackage, setSelectedPackage] = useState<UserSubscriptionType | null>(null);
	const [selectedBooks, setSelectedBooks] = useState<Book[]>();

	const { isCreatingUpdatingDeletingGift, fetchPriceError } = useAppSelector(getFullGiftState);
	const { isLoading: loadingCourses } = useAppSelector(getFullCourseState);

	const breadcrumb = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Gifts", key: 1, link: routes.gifts.getPath() },
			{ title: `${id ? "Edit" : "Add New"} Gift Card`, key: 2 }
		],
		[id]
	);

	const validate = useCallback(() => {
		if (step === Steps.About) {
			return name && validity && planId && !fetchPriceError;
		}
		if (step === Steps.Course) {
			return !!selectedCourse;
		}
		if (step === Steps.Package) {
			return !!selectedPackage;
		}
		if (step === Steps.Books) {
			return !!selectedBooks?.length;
		}
		return false;
	}, [name, planId, selectedBooks?.length, selectedCourse, selectedPackage, step, validity]);

	const customSteps: Array<{ label: string; onClick: () => void }> = [
		{ label: "About", onClick: () => setStep(Steps.About) },
		{ label: "Course", onClick: () => setStep(Steps.Course) },
		{ label: "Package", onClick: selectedCourse ? () => setStep(Steps.Package) : () => {} },
		{ label: "Books", onClick: selectedPackage ? () => setStep(Steps.Books) : () => {} }
	];

	const handleSubmit = () => {
		dispatch(
			createGift({
				options: {
					name,
					validForNumberOfDays: validity,
					planId,
					userSubscriptionTypeId: selectedPackage?.id,
					bookIds: selectedBooks.map(b => ({ value: b.id, deleted: false }))
				},
				cb: () => {
					dispatch(fetchGifts({ include: ["books", "userSubscriptionType"], page: 1, perPage: ITEMS_PER_PAGE }));
					history.push(routes.gifts.getPath());
				}
			})
		);
	};

	return (
		<Wrapper
			heading={`${id ? "Add New" : "Edit"} Gift Card`}
			breadcrumb={breadcrumb}
			actions={
				<Button
					variant="filled"
					color="primary"
					loading={isCreatingUpdatingDeletingGift || loadingCourses}
					disabled={!validate()}
					onClick={() => {
						if (step === 3) {
							handleSubmit();
						} else {
							setStep(s => s + 1);
						}
					}}
				>
					{step < 3 ? "Next" : "Publish Gift Card"}
				</Button>
			}
		>
			<Box mt={1} mb={2}>
				<TriangleBreadcrumbs breadcrumb={customSteps} activeIndex={step} />
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={9}>
					{step === Steps.About && (
						<AboutForm
							name={name}
							setName={setName}
							validity={validity}
							setValidity={setValidity}
							planId={planId}
							setPlanId={setPlanId}
						/>
					)}
					{step === Steps.Course && (
						<SelectCourse selectedCourseId={selectedCourse?.id} onCourseSelect={c => setSelectedCourse(c)} />
					)}
					{step === Steps.Package && (
						<SelectPackage
							selectedPackageId={selectedPackage?.id}
							selectedCourse={selectedCourse}
							onSelectPackage={p => setSelectedPackage(p)}
						/>
					)}
					{step === Steps.Books && (
						<SelectBooks
							selectedCourseId={selectedCourse?.id}
							selectedBooks={selectedBooks}
							onBookSelect={b => setSelectedBooks(b)}
						/>
					)}
				</Grid>
				<Grid item xs={3}>
					<GiftSummary
						name={name}
						validity={validity}
						selectedCourse={selectedCourse}
						selectedBooks={selectedBooks}
						selectedPackage={selectedPackage}
					/>
				</Grid>
			</Grid>
		</Wrapper>
	);
};

export default AddEditGift;
