import React from "react";

import { Button, ButtonGroup, useTheme } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
const BtnGroup = ({
	options,
	onClick,
	value: val
}: {
	options: Record<string, string | number>[];
	onClick: (val: string) => void;
	value: string;
}) => {
	const theme = useTheme<IExtendedTheme>();
	return (
		<ButtonGroup color="primary" aria-label="outlined primary button group">
			{options.map(({ value, label }) => (
				<Button
					variant={"contained"}
					style={{
						background: value === val ? theme.palette.primary.main : theme.palette.background.paper
					}}
					key={label}
					onClick={() => onClick(value)}
				>
					{label}
				</Button>
			))}
		</ButtonGroup>
	);
};

export default BtnGroup;
