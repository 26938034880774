import { Box, Checkbox, Typography } from "@material-ui/core";

import styled from "styled-components";

export const StyledCheckbox = styled(Checkbox)`
	margin-right: 0;
	margin-top: -2px;
`;

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	margin-bottom: 24px;

	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: 13px;
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}

		div:last-child {
			color: #ffffff;
		}
	}
`;

export const ListContainer = styled.div`
	padding: 32px 16px;
	margin-left: auto;
	margin-right: 32px;
	width: 650px;
	background-color: ${({ theme }) => theme.palette.colors.basic[800]};
`;

export const CardContainer = styled(Box)`
	margin-right: 24px;
	margin-bottom: 24px;
`;

export const HeaderContainer = styled.div`
	margin-bottom: 24px;
	display: flex;
	margin-top: 24px;
`;

export const CourseHeading = styled(Typography)`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 8px;
`;

export const Header = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffffff;
`;

export const HeaderActions = styled.div`
	margin-left: auto;
	margin-right: 36px;
	flex-shrink: 0;
`;

export const ButtonContainer = styled.div`
	margin-top: 32px;
	margin-left: -5px;
`;

export const ListItems = styled.div`
	user-select: none;
	opacity: 0.87;
	font-size: 15px;
	display: flex;
	align-items: center;
	padding: 12px 0;
	line-height: 1.33;
	color: #ffffff;

	.status {
		margin-right: 24px;
		color: ${({ theme }) => theme.palette.colors.success[600]};
	}
	.cta {
		display: flex;
		margin-left: auto;

		button {
			margin-top: -6px;
		}
	}
`;
