import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	timeInput: {
		"& input[type=number]": {
			"-moz-appearance": "textfield"
		},
		"& input[type=number]::-webkit-outer-spin-button": {
			"-webkit-appearance": "none",
			margin: 0
		},
		"& input[type=number]::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			margin: 0
		}
	},
	adornedStyle: {
		padding: 0
	}
}));
