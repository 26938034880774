import React, { useState } from "react";

import { Box, Button, Card, Container, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "../../../ManageStudents/styles";
import AssignCourseModal from "../AssignCourseModal";
import CancelSubscriptionModal from "../CancelSubscriptionModal";

const ManageSubscription = ({
	onClose,
	allowedCourses,
	notAllowedCourses,
	fullName,
	subscriptionId,
	subscription,
	disabled,
	userId = 0,
	fetchAccount
}) => {
	const classes = useStyles({ disabled });
	const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
	const [showAssignCourseModal, setShowAssignCourseModal] = useState(false);
	const [subscriptionTypeId, setSubscriptionTypeId] = useState(undefined);
	const [text, setText] = useState("");
	const [charges, setCharges] = useState(false);
	const [amount, setAmount] = useState(0);

	return (
		<>
			<Container maxWidth="md">
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
					<Card className={classes.card}>
						<Box>
							<Box display="flex" alignItems="center" justifyContent="space-between">
								<Typography className={classes.title} variant="h6">
									Manage Subscription
								</Typography>
								<IconButton onClick={onClose}>
									<CloseIcon color="disabled" />
								</IconButton>
							</Box>
							<Typography className={classes.smallHeading} variant="h6">
								Currently Enrolled In
							</Typography>
							<Box display="flex">
								<Box className={classes.course}>
									<Box className={classes.text}>{allowedCourses?.[0]?.name}</Box>
									<Box className={classes.secondaryText} mt={2}>
										<span onClick={() => !disabled && setShowCancelSubscriptionModal(true)}>
											{disabled ? "Free Trial" : "Cancel Subscription"}
										</span>
									</Box>
								</Box>
							</Box>
							<Typography className={classes.smallHeading} variant="h6">
								Not Enrolled In
							</Typography>
							<Box display="flex" whiteSpace="nowrap" className={classes.notAllowedCoursesScroll}>
								{notAllowedCourses?.map(course => (
									<Box key={course.id} className={classes.course}>
										<Box className={classes.text}>{course?.name}</Box>
										<Box className={classes.secondaryTextForAssignCourse} mt={2}>
											<span
												onClick={() => {
													setText(
														`Are you sure you want to <span>assign ${course?.name}</span> for Student: ${fullName}?`
													);
													const subId = course.allowedForUST.find(
														item =>
															item.isRecurring ===
																(subscription && subscription.type ? subscription.type.isRecurring : false) &&
															item.isActive &&
															!item.isTrial &&
															!item.isAppleSubscription
													).id;
													setSubscriptionTypeId(subId);
													setAmount(0);
													setCharges(false);
													setShowAssignCourseModal(true);
												}}
											>
												Assign Course
											</span>
										</Box>
									</Box>
								))}
							</Box>
							<Box display="flex" justifyContent="flex-end" mt={3}>
								<Button variant="contained" color="primary" className={classes.save} onClick={() => onClose()}>
									Close
								</Button>
							</Box>
						</Box>
					</Card>
				</Box>
			</Container>
			<CancelSubscriptionModal
				text={`Are you sure you want to <span>unassign ${allowedCourses?.[0]?.name}</span> for Student: ${fullName}?`}
				modalOpen={showCancelSubscriptionModal}
				setModalOpen={setShowCancelSubscriptionModal}
				subscriptionId={subscriptionId}
			/>
			<AssignCourseModal
				text={text}
				modalOpen={showAssignCourseModal}
				setModalOpen={setShowAssignCourseModal}
				subscriptionTypeId={subscriptionTypeId}
				amount={amount}
				setAmount={setAmount}
				setCharges={setCharges}
				charges={charges}
				userId={userId}
				fetchAccount={fetchAccount}
			/>
		</>
	);
};

export default ManageSubscription;
