import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { LessonVideo } from "@remar/shared/dist/models";

import { LessonVideoCreateDto } from "./dto";

import { URLS } from "../services.constants";

export class LessonVideosService extends BaseApiService<
	LessonVideo,
	LessonVideoCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getVideo(videoId: number): Promise<unknown> {
		return request(`${this.baseUrl}/item/${videoId}`, "GET") as Promise<unknown>;
	}

	loadSubtitles(url: string): Promise<string> {
		return fetch(url)
			.then(r => r.text())
			.then(text => text);
	}

	validate(url: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.addEventListener("load", () => {
				if (xhr.status === 200) {
					resolve();
				} else {
					reject(xhr.response);
				}
			});
			xhr.addEventListener("error", err => {
				reject(err);
			});
			xhr.send();
		});
	}
}

export const lessonVideosService = new LessonVideosService(URLS.LESSON_VIDEOS);
