import React from "react";

import { Typography } from "@material-ui/core";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";

import { NotificationTypeId } from "@remar/shared/dist/models";

import { getContentByType } from "./conflictContent";

type ConflictModalProps = {
	type: NotificationTypeId | null;
	onClose: () => void;
};

const ConflictModal = ({ onClose, type }: ConflictModalProps) => {
	const handleReplace = () => {
		// TODO: Implement
		onClose();
	};

	const { title, message, description, cancelText, submitText } = getContentByType(type);

	return (
		<DeleteModal
			open={!!type}
			title={title}
			message={message}
			cancelBtnText={cancelText ?? "Go Back to Edit"}
			deleteBtnText={submitText ?? "Continue & Replace"}
			onClose={onClose}
			onDelete={handleReplace}
		>
			<Typography color="textSecondary">{description}</Typography>
		</DeleteModal>
	);
};

export default ConflictModal;
