import * as Yup from "yup";

export const validationSchema = Yup.object({
	name: Yup.string().required(),
	description: Yup.string().required(),
	courseIds: Yup.array()
		.of(
			Yup.object().shape({
				id: Yup.number(),
				name: Yup.string()
			})
		)
		.required()
		.min(1),
	planId: Yup.string().required(),
	productSKU: Yup.string().required(),
	imageUrl: Yup.string().required(),
	isAvailableForTrial: Yup.boolean().required()
});
