import React, { useState } from "react";

import { Box, InputAdornment, InputLabel, TextField, useTheme } from "@material-ui/core";

import { alpha, createStyles, makeStyles } from "@material-ui/core/styles";
import { Today as TodayIcon } from "@material-ui/icons";
import { format } from "date-fns";
import styled from "styled-components";

import TrainingCenterTiny from "../../assets/icons/icon-trainin-center-tiny.svg";

import { IExtendedTheme } from "../../theme/default";
import { DatePicker } from "../DatePicker";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		noCancel: {
			backgroundColor: "transparent",
			color: alpha(theme.palette.colors.basic[100], 0.6),
			borderColor: "hsl(222,4%,44%)",
			borderWidth: "1px",
			borderStyle: "solid",
			marginRight: "10px"
		}
	})
);

export const StudentBlock = styled(Box)`
	display: flex;
	align-items: center;

	svg {
		fill: ${props => props.theme.palette.colors.basic[1300]};
		font-size: 40px;
	}
`;

export const TitleWrapper = styled(Box)`
	margin: 0 10px;
	p {
		font-family: var(--openSans);
	}
	p:first-child {
		font-size: 15px;
	}
	p:last-child {
		font-size: 12px;
		color: ${props => props.theme.palette.text.secondary};
	}
`;

export const FilterBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

export const StyledCourseIcon = styled(() => <img alt="training center" src={TrainingCenterTiny} />)`
	width: 35px;
	height: 35px;
	g {
		g {
			path {
				fill: ${props => props.theme.palette.colors.basic[1300]};
			}
		}
	}
`;
export const CourseBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

export const DatePickerComponent = ({ name, label, value, onChange }) => {
	const theme = useTheme<IExtendedTheme>();
	const [isDateOpen, setIsDateOpen] = useState(false);

	return (
		<Box display={"flex"} flexDirection={"column"}>
			<Box mb={1}>
				<InputLabel shrink>{label}</InputLabel>
			</Box>
			<DatePicker
				onClose={() => setIsDateOpen(prevState => !prevState)}
				open={isDateOpen}
				name={name}
				autoOk
				format="MM/dd/yyyy"
				value={value[name]}
				minDate={name === "endDate" ? value.startDate : undefined}
				maxDate={name === "startDate" ? value.endDate : new Date()}
				disableToolbar
				orientation="portrait"
				openTo="date"
				onBlur={() => {}}
				onChange={onChange}
				TextFieldComponent={() => (
					<TextField
						value={format(value[name] || new Date(), "MM/dd/yyyy")}
						disabled={false}
						placeholder="MM/DD/YYYY"
						color="primary"
						InputProps={{
							style: {
								cursor: "pointer",
								color: theme.palette.text.primary,
								backgroundColor: theme.palette.background.default,
								height: 50,
								borderRadius: 4,
								padding: 10,
								width: "100%",
								border: `1px dashed ${theme.palette.colors.basic[700]}`
							},
							disableUnderline: true,
							endAdornment: (
								<InputAdornment position="start">
									<TodayIcon />
								</InputAdornment>
							),
							onClick: () => setIsDateOpen(prevState => !prevState),
							readOnly: true
						}}
					/>
				)}
			/>
		</Box>
	);
};
