import { makeStyles } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
	preview: {
		cursor: "pointer"
	},
	viewMore: {
		color: "hsl(213, 94%, 63%)",
		cursor: "pointer",

		"&:hover": {
			color: lighten("hsl(213, 94%, 63%)", 0.1)
		}
	}
}));
