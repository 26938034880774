import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { Course } from "@remar/shared/dist/models";

import { CourseCreateDto, CourseUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class CoursesService extends BaseApiService<
	Course,
	CourseCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	CourseUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const coursesService = new CoursesService(URLS.COURSES);
