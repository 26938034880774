import React, { FC, ReactNode } from "react";

import { useDrop } from "react-dnd";

const AnswerOptionDropZone: FC<{
	canAlwaysDrop?: boolean;
	children?: ReactNode | ReactNode[];
	onDrop?: (_options: { index: number; id: number }) => unknown;
	index: number;
}> = ({ children, onDrop }) => {
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: "box",
		drop: item =>
			onDrop &&
			onDrop({
				index: (item as unknown as Record<string, unknown>).index as number,
				id: (item as unknown as Record<string, unknown>).id as number
			}),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: true
		}),
		canDrop: () => true
	});
	const overStyles = {
		border: "2px dashed #000",
		height: 48,
		margin: "8px 8px 0 0",
		width: "50%"
	};
	return (
		<div ref={drop}>
			{children}
			<div style={isOver && canDrop ? overStyles : undefined} />
		</div>
	);
};

export default AnswerOptionDropZone;
