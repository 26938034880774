import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BaseListModel, IBaseState } from "@remar/shared/dist/models";
import { IVideoModel } from "@remar/shared/dist/models/video.model";
import { mapUniqueIdsToList } from "@remar/shared/dist/utils/mapUniqueIdsToList";

import { parse } from "node-webvtt";

import { emit } from "store/features/notifications/notifications.slice";

import { RootState } from "../../index";
import { lessonVideosService } from "../../services";

interface IVideo {
	name: string;
	videoUrl: string;
	subtitleFileName: string;
	subtitleUrl: string;
}
export interface ISubtitleData {
	valid: boolean;
	cues: ICue[];
	page?: number;
}
interface LessonVideosState extends IBaseState {
	videos: IVideos | null;
	isDeletingVideo: boolean;
	isUpdatingVideo: boolean;
	isLoadingSubtitles: boolean;
	subtitleData: ISubtitleData;
	video: IVideo | null;
}

interface IVideos extends BaseListModel {
	items: IVideoModel[];
}
const initialState: LessonVideosState = {
	videos: null,
	isLoading: false,
	isDeletingVideo: false,
	isUpdatingVideo: false,
	isLoadingSubtitles: false,
	subtitleData: { valid: true, cues: [] },
	error: "",
	video: null
};
export interface ICue {
	id: string;
	start: number;
	end: number;
	text: string;
	identifier: string;
	styles: string;
	error: string | null;
}
export const cueInitialVal: ICue = {
	id: "0",
	start: 0,
	end: 0,
	text: "",
	identifier: "",
	styles: "",
	error: null
};

export const getVideos = createAsyncThunk(
	"LessonVideos/getVideos",
	async (
		{ page, perPage, searchKeyword }: { page: number; perPage: number; searchKeyword: string },
		{ rejectWithValue }
	) => {
		return await lessonVideosService
			.find({ page, perPage, searchKeyword, orderBy: { createdAt: "DESC" } })
			.catch(e => rejectWithValue(e.message));
	}
);

export const getVideo = createAsyncThunk(
	"LessonVideos/getVideo",
	async ({ videoId }: { videoId: number; disableLoading?: boolean }, { rejectWithValue }) => {
		return await lessonVideosService.getVideo(videoId).catch(e => rejectWithValue(e.message));
	}
);

export const loadSubtitles = createAsyncThunk(
	"LessonVideos/loadSubtitles",
	async ({ url, cb }: { url: string; cb: (data: ISubtitleData) => void }, { rejectWithValue, dispatch }) => {
		const text = await lessonVideosService.loadSubtitles(url).catch(e => rejectWithValue(e.message));
		let parsed: ISubtitleData = {} as ISubtitleData;
		try {
			parsed = parse(text, { strict: false });
			parsed.cues = mapUniqueIdsToList(parsed.cues);
			cb(parsed);
			return parsed;
		} catch (e) {
			dispatch(
				emit({
					message: "Failed to load subtitles",
					color: "error"
				})
			);
			parsed = { valid: true, cues: [{ ...cueInitialVal }] };
			cb(parsed);
			return rejectWithValue(e.message);
		}
	}
);

export const updateVideo = createAsyncThunk(
	"LessonVideos/updateVideo",
	async (
		{ videoId, data, cb }: { videoId: number; data: { name: string; subtitleFileName: string }; cb: () => void },
		{ rejectWithValue, dispatch }
	) => {
		const res = lessonVideosService.update({ filters: { id: videoId }, data }).catch(e => rejectWithValue(e.message));
		dispatch(emit({ message: "Video has been successfully updated", color: "success" }));
		cb();
		return res;
	}
);

export const deleteVideo = createAsyncThunk(
	"LessonVideos/deleteVideo",
	async ({ videoId, cb }: { videoId: number; cb: () => void }, { rejectWithValue, dispatch }) => {
		const res = await lessonVideosService.delete({ filters: { id: videoId } }).catch(e => rejectWithValue(e.message));
		dispatch(emit({ message: "Video has been successfully deleted", color: "success" }));
		cb();
		return res;
	}
);

export const lessonVideosSlice = createSlice({
	name: "LessonVideos",
	initialState,
	reducers: {
		clearVideoState: state => {
			state.video = null;
		}
	},
	extraReducers: builder =>
		builder
			.addCase(getVideos.pending.type, state => {
				state.isLoading = true;
			})
			.addCase(getVideos.fulfilled.type, (state, { payload }) => {
				state.videos = payload;
				state.isLoading = false;
			})
			.addCase(getVideos.rejected.type, state => {
				state.isLoading = false;
			})
			.addCase(getVideo.pending.type, (state, action) => {
				const { disableLoading = false } = action.meta.arg;
				state.isLoading = !disableLoading;
			})
			.addCase(getVideo.fulfilled.type, (state, { payload }) => {
				state.video = payload;
				state.isLoading = false;
			})
			.addCase(getVideo.rejected.type, state => {
				state.isLoading = false;
			})
			.addCase(deleteVideo.pending.type, state => {
				state.isDeletingVideo = true;
			})
			.addCase(deleteVideo.fulfilled.type, state => {
				state.isDeletingVideo = false;
			})
			.addCase(deleteVideo.rejected.type, state => {
				state.isDeletingVideo = false;
			})
			.addCase(updateVideo.pending.type, state => {
				state.isUpdatingVideo = true;
			})
			.addCase(updateVideo.fulfilled.type, state => {
				state.isUpdatingVideo = false;
			})
			.addCase(updateVideo.rejected.type, state => {
				state.isUpdatingVideo = false;
			})
			.addCase(loadSubtitles.pending.type, state => {
				state.isLoadingSubtitles = true;
			})
			.addCase(loadSubtitles.fulfilled.type, (state, action: PayloadAction<ISubtitleData>) => {
				state.subtitleData = action.payload;
				state.isLoadingSubtitles = false;
			})
			.addCase(loadSubtitles.rejected.type, state => {
				state.isLoadingSubtitles = false;
			})
});

export const { clearVideoState } = lessonVideosSlice.actions;
export const getFullLessonVideosState = (state: RootState): LessonVideosState => state.lessonVideos;
export default lessonVideosSlice.reducer;
