import React from "react";

import { Button } from "@material-ui/core";

import { Course as CourseDiv, CourseHeader, CourseText, SelectedIcon } from "./styles";

type CourseItemCardProps = {
	item: { name: string };
	typeName: "Course" | "Package";
	selected: boolean;
	disabled: boolean;
	onSelect: () => void;
};
/**
 * CourseItemCard is a functional component in React that represents a card
 * displaying course information, along with a selectable button.
 *
 * @typedef {Object} CourseItemCardProps
 * @property {Object} item - The course item object.
 * @property {string} item.name - The name of the course.
 * @property {boolean} selected - Indicates if the course is currently selected.
 * @property {boolean} disabled - Indicates if the selection button should be disabled.
 * @property {Function} onSelect - Callback function to be called when the selection button is clicked.
 * @property {string} typeName - A string indicating the type of item being selected.
 * @property {React.ReactNode} children - The content to be displayed within the course card.
 *
 * @param {CourseItemCardProps} props - The properties passed to the component.
 * @returns {JSX.Element} The JSX representing the course item card.
 *
 * @example
 * // Example usage of CourseItemCard component
 * import React, { useState } from 'react';
 * import CourseItemCard from './CourseItemCard';
 *
 * const CourseList = () => {
 *	 const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
 *	 const courses = [
 *		 { name: 'Course A', typeName: 'Course' },
 *		 { name: 'Package B', typeName: 'Package' }
 *	 ];
 *
 *	 const handleSelect = (courseName: string) => {
 *		 setSelectedCourse(courseName);
 *	 };
 *
 *	 return (
 *		 <div>
 *			 {courses.map(course => (
 *				 <CourseItemCard
 *					 key={course.name}
 *					 item={course}
 *					 typeName={course.typeName}
 *					 selected={selectedCourse === course.name}
 *					 disabled={selectedCourse !== null && selectedCourse !== course.name}
 *					 onSelect={() => handleSelect(course.name)}
 *				 >
 *					 <p>This is some content for the {course.name} card.</p>
 *				 </CourseItemCard>
 *			 ))}
 *		 </div>
 *	 );
 * };
 **/
const CourseItemCard: React.FC<CourseItemCardProps> = ({
	item: { name },
	selected,
	disabled,
	onSelect,
	typeName,
	children
}) => (
	<CourseDiv $current={selected}>
		<CourseHeader current={selected}>{name}</CourseHeader>
		{selected && <SelectedIcon />}
		<CourseText>{children}</CourseText>
		<Button variant="contained" color="primary" disabled={disabled} size="large" onClick={onSelect}>
			Select {typeName}
		</Button>
	</CourseDiv>
);

export default CourseItemCard;
