import React, { useEffect } from "react";

import { Box } from "@material-ui/core";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Book } from "@remar/shared/dist/models";
import { useDispatch, useSelector } from "react-redux";
import { fetchBooks, getFullBookState } from "store/features/Books";

import BookItem, { BookItemActionTypes } from "../BookItem";
import { ITEMS_PER_PAGE } from "../constants";

type SelectBooksProps = {
	selectedCourseId: number;
	selectedBooks: Book[];
	onBookSelect: (b: Book[]) => void;
};
const SelectBooks = ({ selectedCourseId, selectedBooks, onBookSelect }: SelectBooksProps) => {
	const dispatch = useDispatch();
	const { books, isFetchingBooks } = useSelector(getFullBookState);

	useEffect(() => {
		if (!books) {
			dispatch(
				fetchBooks({
					// filters: { "course.id": selectedCourseId },
					include: ["courses"],
					page: 1,
					perPage: ITEMS_PER_PAGE
				})
			);
		}
	}, [books, dispatch, selectedCourseId]);

	return (
		<Box>
			{isFetchingBooks ? (
				<ContentLoader height={700} />
			) : (
				<Box display={"flex"} flexDirection="row" flexWrap="wrap">
					{books?.items?.map(book => {
						const isSelected = selectedBooks?.some(b => b.id === book.id) || false;
						return (
							<BookItem
								key={book.id}
								{...book}
								onAction={() => {
									onBookSelect(
										isSelected ? selectedBooks?.filter(b => b.id !== book.id) : [...(selectedBooks || []), book]
									);
								}}
								actionType={isSelected ? BookItemActionTypes.DELETE : BookItemActionTypes.ADD}
							/>
						);
					})}
				</Box>
			)}
		</Box>
	);
};

export default SelectBooks;
