import { ReactComponent as IconEyeSlash } from "@remar/shared/dist/assets/icons/icon-eye-slash.svg";
import { ReactComponent as IconEye } from "@remar/shared/dist/assets/icons/icon-eye.svg";

import styled from "styled-components";

export const StyledVisibilityOutlined = styled(IconEye)`
	g {
		path:last-child {
			fill: ${props => props.theme.palette.colors.basic[200]};
		}
	}
`;
export const StyledVisibilityOffOutlined = styled(IconEyeSlash)`
	g {
		path:last-child {
			fill: ${props => props.theme.palette.colors.basic[200]};
		}
	}
`;

export const Terms = styled.span`
	font-size: 13px;
	font-family: var(--openSans);
	font-weight: 600;
	a {
		margin-left: 5px;
		color: ${({ theme }) => theme.palette.colors.primary[500]};
	}
`;
