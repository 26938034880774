import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
	Box,
	Card,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Modal,
	Paper,
	Typography,
	useTheme
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@remar/shared/dist/components/Button";
import { ButtonActionEnum, NotificationTypeId, NotificationsModel } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { isBefore } from "date-fns";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";

import { fetchBanners, selectBannersFullState, updateBanner } from "store/features/Banners";
import { getCouponTypes, getCoupons } from "store/features/Coupons/coupons.slice";
import { Coupon } from "store/features/Coupons/models";
import { useAppSelector } from "store/hooks";

import styled from "styled-components";

import { editBannerSchema } from "./schema";

import CreateEditCoupons, { FormValuesType } from "../../../App/View/Container/Coupons/CreateEditCoupons";
import { FormikField, SelectInput, TextField } from "../../FormikFields";
import { mappedAudienceTypes } from "../utils";

export const Label = styled(Typography)`
	font-size: 13px;
	color: ${props => props.theme.palette.colors.basic[400]};
`;

const actions = [
	{ label: "Apply coupon to payment", value: ButtonActionEnum.ApplyCoupon },
	{ label: "Dismiss", value: ButtonActionEnum.Dismiss },
	{ label: "Open Link", value: ButtonActionEnum.OpenLink }
];

const getButtonActionLabel = (buttonAction: ButtonActionEnum) => {
	switch (buttonAction) {
		case ButtonActionEnum.ApplyCoupon:
			return "Linked Coupon";
		case ButtonActionEnum.OpenLink:
			return "Url";
	}
};

const GridItem = ({ label, component, size }) => (
	<Grid item xs={size}>
		<Box mt={1} mb={1}>
			<Label>{label}</Label>
		</Box>
		{component}
	</Grid>
);

const EditBusinessBanners = ({ setEditBanner, onClose, editBanner: bannerData }) => {
	const classes = useStyles();
	const [selectedCoupon, setSelectedCoupon] = useState<Coupon>(bannerData.coupon);
	const [showCreateCoupon, setShowCreateCoupon] = useState(false);
	const { Coupons, couponsLoading } = useSelector((state: RootState) => state.coupons);
	const { isEditingBanner } = useAppSelector(selectBannersFullState);
	const dispatch = useDispatch();
	const theme = useTheme<IExtendedTheme>();

	useEffect(() => {
		dispatch(getCoupons({}));
		dispatch(getCouponTypes());
	}, [dispatch]);

	const initialValues = useMemo(() => {
		const {
			title: trigger,
			body: messageText,
			notificationAudienceType: { id },
			data,
			isDismissible,
			countDown,
			duration,
			coupon
		} = bannerData;
		const primaryButton = data?.primaryButton || {};
		const { text: buttonSuccessText, action: buttonAction, url: buttonUrl } = primaryButton;
		const { id: couponId } = coupon || bannerData.couponId || {};
		const { days = 0, hours = 0, minutes = 0 } = duration || {};
		return {
			trigger,
			audience: mappedAudienceTypes[id],
			dayDuration: days,
			hourDuration: hours,
			minDuration: minutes,
			countDown,
			isDismissible,
			messageText,
			couponId,
			buttonAction,
			buttonSuccessText,
			buttonUrl
		};
	}, [bannerData]);

	useEffect(() => {
		if (Coupons && !selectedCoupon && bannerData.couponId) {
			const coupon = Coupons.items.find(({ id }) => id === bannerData.couponId);
			coupon && setSelectedCoupon(coupon);
		}
	}, [Coupons, bannerData.couponId, selectedCoupon]);

	const handleSubmit = values => {
		const {
			messageText: body,
			dayDuration: days,
			hourDuration: hours,
			minDuration: minutes,
			isDismissible,
			couponId,
			countDown,
			buttonAction,
			buttonSuccessText,
			buttonUrl
		} = values;

		const data: NotificationsModel = {
			couponId,
			isDismissible,
			countDown,
			body,
			duration: { days, hours, minutes },
			data: {
				primaryButton: {
					text: buttonSuccessText,
					action: buttonAction,
					url: buttonUrl
				}
			}
		};

		dispatch(
			updateBanner({
				id: bannerData.id,
				data,
				cb: () => {
					dispatch(
						fetchBanners({
							page: 1,
							perPage: 10,
							filters: {
								notificationTypeId: [NotificationTypeId.SystemNotification]
							}
						})
					);
					onClose();
				}
			})
		);
	};

	const debouncedSearch = useCallback(
		debounce((searchText: string) => {
			dispatch(getCoupons({ page: 1, searchText }));
		}, 500),
		[]
	);
	const handleClose = _ => {
		if (_.relatedTarget?.id === "create_coupon") {
			setShowCreateCoupon(true);
		}
	};

	return (
		<>
			<Modal
				className={classes.modal}
				disableEnforceFocus
				disableAutoFocus
				open={true}
				onClose={() => setEditBanner(null)}
			>
				<Box width={600}>
					<Card>
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<Typography variant="h6">{"Edit Business Banner"}</Typography>
							<IconButton onClick={onClose}>
								<CloseIcon color="disabled" />
							</IconButton>
						</Box>
						<Grid container>
							<Formik
								initialValues={initialValues}
								validationSchema={editBannerSchema}
								onSubmit={values => handleSubmit(values)}
							>
								{({ values, isValid, dirty, setFieldValue, handleChange, handleBlur }) => {
									const valid =
										isValid && dirty && (values.buttonAction === ButtonActionEnum.ApplyCoupon ? selectedCoupon : true);
									return (
										<Form style={{ width: "inherit" }}>
											<Grid container spacing={2}>
												<GridItem
													label={"Trigger"}
													component={<FormikField name={"trigger"} type={"text"} disabled />}
													size={6}
												/>
												<GridItem
													label={"Audience Users"}
													component={<FormikField name={"audience"} type={"text"} disabled />}
													size={6}
												/>
												<GridItem
													label={"Showing Duration"}
													component={
														<Box display={"flex"} flexDirection={"column"}>
															<Box display={"flex"} justifyContent={"space-between"}>
																<Box mr={2}>
																	<FormikField
																		name={"dayDuration"}
																		type={"inputNumber"}
																		label={"Day Duration"}
																		endAdornment={
																			<InputAdornment position="start">
																				<p>d</p>
																			</InputAdornment>
																		}
																	/>
																</Box>
																<Box mr={2}>
																	<FormikField
																		name={"hourDuration"}
																		type={"inputNumber"}
																		label={"Hour Duration"}
																		// value={values.hourDuration}
																		endAdornment={
																			<InputAdornment position="start">
																				<p>h</p>
																			</InputAdornment>
																		}
																	/>
																</Box>
																<FormikField
																	name={"minDuration"}
																	type={"inputNumber"}
																	label={"Min Duration"}
																	// value={values.minDuration}
																	endAdornment={
																		<InputAdornment position="start">
																			<p>min</p>
																		</InputAdornment>
																	}
																/>
															</Box>
															<FormikField
																name={"countDown"}
																type={"checkbox"}
																label={"Add Countdown"}
																checked={values.countDown}
															/>
														</Box>
													}
													size={6}
												/>
												<GridItem
													label={"Business Banner Settings"}
													component={
														<FormikField
															name={"isDismissible"}
															type={"checkbox"}
															label={"Dismissible"}
															checked={values.isDismissible}
														/>
													}
													size={6}
												/>
												<Grid item xs={6} style={{ display: "flex", flexDirection: "column", gap: 16 }}>
													<Box mt={1} mb={-1}>
														<Label>Button Action</Label>
													</Box>
													<SelectInput
														name="buttonAction"
														options={actions}
														placeholder="Select Action"
														value={values.buttonAction}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
												</Grid>
												<Grid
													item
													xs={6}
													style={{ display: "flex", flexDirection: "column", gap: 16, justifyContent: "flex-end" }}
												>
													{values.buttonAction && values.buttonAction !== ButtonActionEnum.Dismiss && (
														<>
															<Box mt={1} mb={-1}>
																<Label>{getButtonActionLabel(values.buttonAction)}</Label>
															</Box>
															{values.buttonAction === ButtonActionEnum.ApplyCoupon && (
																<Autocomplete
																	value={selectedCoupon}
																	// classes={{ paper: classes.paper }}
																	onChange={(_, newValue) => {
																		setSelectedCoupon(newValue);
																		setFieldValue("couponId", newValue.id);
																	}}
																	onInputChange={(_, newInputValue) => debouncedSearch(newInputValue)}
																	filterOptions={options => {
																		const currentDate = new Date();
																		return options.filter(({ expiresOn, totalUsed, quantity }) => {
																			const isExpired = isBefore(new Date(expiresOn), currentDate);
																			const hasLimit = !!quantity ? totalUsed! < quantity : true;
																			return !isExpired && hasLimit;
																		});
																	}}
																	disableClearable
																	clearOnBlur
																	handleHomeEndKeys
																	loading={couponsLoading}
																	id="school"
																	options={Coupons?.items || []}
																	getOptionLabel={option => option.name}
																	renderOption={option => option.name}
																	onClose={handleClose}
																	PaperComponent={({ children, ...rest }) => (
																		<Paper elevation={8} {...rest}>
																			{children}
																			<Box m={1}>
																				<Button fullWidth variant={"filled"} color={"primary"} id={"create_coupon"}>
																					Create Coupon
																				</Button>
																			</Box>
																		</Paper>
																	)}
																	renderInput={params => (
																		<TextField
																			{...params}
																			InputProps={{
																				...params.InputProps,
																				style: {
																					padding: "4px 8px",
																					color: theme.palette.text.primary,
																					background: "#2a2e37",
																					borderRadius: "5px",
																					height: "50px"
																				},
																				disableUnderline: true
																			}}
																		/>
																	)}
																/>
															)}
															{values.buttonAction === ButtonActionEnum.OpenLink && (
																<TextField
																	fullWidth
																	placeholder="Enter Url"
																	name="buttonUrl"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.buttonUrl}
																/>
															)}
														</>
													)}
												</Grid>
												<Grid
													item
													xs={12}
													style={{ display: "flex", flexDirection: "column", gap: 16, justifyContent: "flex-end" }}
												>
													<Box>
														<Box mt={1} mb={1}>
															<Label>Button Text</Label>
														</Box>
														<TextField
															fullWidth
															name="buttonSuccessText"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.buttonSuccessText}
														/>
													</Box>
												</Grid>
											</Grid>
											<Box mt={2}>
												<Divider />
											</Box>
											<Box mt={2} display={"flex"} justifyContent={"flex-end"}>
												<Button
													className={classes.cancelBtn}
													disabled={false}
													style={{ marginRight: "10px" }}
													onClick={onClose}
												>
													Cancel
												</Button>
												<Button
													variant={"filled"}
													color={"primary"}
													type={"submit"}
													disabled={!valid || isEditingBanner}
													loading={isEditingBanner}
												>
													Save Changes
												</Button>
											</Box>
										</Form>
									);
								}}
							</Formik>
						</Grid>
					</Card>
				</Box>
			</Modal>
			{showCreateCoupon && (
				<CreateEditCoupons initObj={{} as FormValuesType} onClose={() => setShowCreateCoupon(false)} />
			)}
		</>
	);
};

export default EditBusinessBanners;
