import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";

import { SubjectLesson } from "@remar/shared/dist/models";

import { SubjectLessonCreateDto, SubjectLessonUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class SubjectLessonService extends BaseApiService<
	SubjectLesson,
	SubjectLessonCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	SubjectLessonUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const subjectLessonService = new SubjectLessonService(URLS.SUBJECT_LESSONS);
