import React, { useState } from "react";

import { Box, IconButton, TextField as MuiTextField, makeStyles } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import styled from "styled-components";

const TextField = props => <MuiTextField {...props} variant="filled" hiddenLabel />;

const StyledButton = styled(IconButton)`
	background: ${props => props.theme.palette.colors.basic[800]};
	width: 20px;
	border-radius: 5px 0 0 5px;
	padding: 16px;
	&:last-child {
		border-radius: 0 5px 5px 0;
	}
`;
const useStyles = makeStyles((theme: IExtendedTheme) => ({
	input: {
		"& input[type=number]": {
			"-moz-appearance": "textfield"
		},
		"& input[type=number]::-webkit-outer-spin-button": {
			"-webkit-appearance": "none",
			margin: 0
		},
		"& input[type=number]::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			margin: 0
		},
		"& input[type=number]:disabled": {
			background: theme.palette.colors.basic[800]
		}
	}
}));

const InputNumberWithControls = ({ value, onChange, disabled, ...rest }) => {
	const classes = useStyles();
	const [number, setNumber] = useState(value || 1);
	const MIN = rest.inputProps.min || 0;
	const MAX = rest.inputProps.max || Number.MAX_VALUE;
	return (
		<Box>
			<StyledButton
				size="small"
				disabled={disabled}
				onClick={() => {
					setNumber(p => {
						const val = p > MIN ? p - 1 : MIN;
						onChange(val);
						return val;
					});
				}}
			>
				<Remove />
			</StyledButton>
			<TextField
				className={classes.input}
				type={"number"}
				disabled={disabled}
				value={number}
				onChange={e => {
					const val = parseInt(e.target.value) || MIN;
					setNumber(val);
					onChange(val);
				}}
				{...rest}
			/>
			<StyledButton
				size="small"
				disabled={disabled}
				onClick={() =>
					setNumber(p => {
						const val = p < MAX ? p + 1 : MAX;
						onChange(val);
						return val;
					})
				}
			>
				<Add />
			</StyledButton>
		</Box>
	);
};

export default InputNumberWithControls;
