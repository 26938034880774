import React, { memo } from "react";

import { TopNav as SharedTopNav } from "@remar/shared/dist/layouts/BaseLayout";
import { useDispatch, useSelector } from "react-redux";

import { getFullState, logout } from "store/features/Auth/auth.slice";

import BellNotifications from "./BellNotifications";

const TopNav = () => {
	const dispatch = useDispatch();

	const { user } = useSelector(getFullState);

	return (
		<SharedTopNav
			userInfo={user}
			onLogOut={async () => dispatch(logout())}
			topMenuItems={
				<>
					{/*	<MessageIcon>
						<Icon name="message-linear" height={19} width={22} fill="#2a2e37" />
						</MessageIcon>
					*/}
					<BellNotifications />
				</>
			}
		/>
	);
};

export default memo(TopNav);
