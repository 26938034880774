import React, { useEffect } from "react";

import { Box } from "@material-ui/core";
import { CourseItemCard } from "@remar/shared/dist/components/CoursePackageItemCard";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Course } from "@remar/shared/dist/models";
import { useDispatch } from "react-redux";
import { useSelector } from "store";
import { fetchAllCourses, getFullState } from "store/features/Course/course.slice";

type SelectCourseProps = {
	selectedCourseId: number | undefined;
	onCourseSelect: (course: Course) => void;
};
const SelectCourse = ({ selectedCourseId, onCourseSelect }: SelectCourseProps) => {
	const dispatch = useDispatch();

	const { courses, isLoading } = useSelector(getFullState);

	useEffect(() => {
		if (!courses?.length) {
			dispatch(fetchAllCourses());
		}
	}, [courses?.length, dispatch]);

	return (
		<Box display="flex" flexWrap="wrap" flexDirection="row" gridGap={20}>
			{isLoading && <ContentLoader />}
			{courses?.map(c => (
				<CourseItemCard
					key={`course-${c.id}`}
					item={c}
					typeName="Course"
					selected={selectedCourseId === c.id}
					disabled={selectedCourseId === c.id}
					onSelect={() => onCourseSelect(c)}
				>
					{c.data?.additionalInformation}
				</CourseItemCard>
			))}
		</Box>
	);
};

export default SelectCourse;
