import { CourseChapterSectionUpdateDto } from "store/services";

export const intialEditData: CourseChapterSectionUpdateDto = {
	data: {
		name: "",
		mainImageUrl: "",
		totalTimeLengthInMinutes: 0,
		calculateTimeLengthAutomatically: true,
		isAvailableForTrial: false
	},
	filters: {
		id: 0
	}
};
