export enum CreateLessonSteps {
	LessonDetails = "Lesson Details", // eslint-disable-line no-unused-vars
	LessonMaterials = "Lesson Materials", // eslint-disable-line no-unused-vars
	LessonVIT = "Lesson VIT", // eslint-disable-line no-unused-vars
	LessonTest = "Lesson Test" // eslint-disable-line no-unused-vars
}

export const INTERACTIVE_LESSON_TYPE = "Interactive";
export const STEPS_ORDER_MAP = [
	CreateLessonSteps.LessonDetails,
	CreateLessonSteps.LessonMaterials,
	CreateLessonSteps.LessonVIT,
	CreateLessonSteps.LessonTest
];
export const VIDEO_LESSON_TYPE = "Video Only";
