import { Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import styled from "styled-components";

export const InviteInstituteWrapper = styled(Box)`
	display: flex;

	.label {
		margin-right: 20px;
		width: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const SelectInstitutionWrapper = styled(Box)`
	margin-bottom: 10px;
	button {
		width: 300px;
		background-color: ${({ theme }) => theme.palette.colors.basic[800]};
	}
`;

export const StyledCloseIcon = styled(CloseIcon)`
	fill: ${({ theme }) => theme.palette.colors.basic[200]};
	width: 20px;
	background:${({ theme }) => theme.palette.colors.basic[800]};
	border-radius: 50%;
	padding: 3px;
}
	
`;
