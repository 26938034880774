import React, { useState } from "react";

import { Box, BoxProps, ClickAwayListener, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";

import { useStyles } from "./styles";

import FilterIcon from "../../../assets/icons/Icon_filter.svg";
import FilterIconActive from "../../../assets/icons/Icon_filter_active.svg";
import ChevronDownSvg from "../../../assets/icons/icon-chevron-down.svg";
import ChevronUpSvg from "../../../assets/icons/icon-chevron-up.svg";

type DropFilterProps = BoxProps & {
	options: { label: string; value: string }[];
	value: string;
	onChange: (value: string) => void;
};
const DropFilter = ({ options, value, onChange, ...rest }: DropFilterProps) => {
	const classes = useStyles();
	const [viewFilterWrapper, setViewFilterWrapper] = useState(false);

	const handleClickAway = () => {
		setViewFilterWrapper(false);
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Box style={{ position: "relative" }} {...rest}>
				<Box className={classes.filterContainer} onClick={() => setViewFilterWrapper(s => !s)}>
					<img
						src={viewFilterWrapper ? FilterIconActive : FilterIcon}
						alt="filter icon"
						className={classes.columnFilterIcon}
					/>
					<Typography className={classes.filterTitleText}>Filters</Typography>
					<img
						src={viewFilterWrapper ? ChevronUpSvg : ChevronDownSvg}
						alt="filter icon"
						className={classes.columnFilterIcon}
					/>
				</Box>
				{viewFilterWrapper && (
					<Box className={classes.filbersBlock}>
						<RadioGroup
							row
							value={`${value}`}
							style={{ color: "primary" }}
							onChange={e => {
								onChange(e.target.value);
								setViewFilterWrapper(false);
							}}
						>
							{options.map(item => (
								<FormControlLabel
									key={`${item.value}`}
									value={`${item.value}`}
									control={<Radio color="primary" />}
									label={<Typography className={classes.labelText}>{item.label}</Typography>}
								/>
							))}
						</RadioGroup>
					</Box>
				)}
			</Box>
		</ClickAwayListener>
	);
};

export default DropFilter;
