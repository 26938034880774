import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseFindDto } from "@remar/shared/dist/api/baseApiService";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import { BookList, CreateBook, UpdateBook } from "@remar/shared/dist/models";
import { validateFormAction as utilsValidateFormAction } from "@remar/shared/dist/utils/form/form.utils";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { RootState } from "../../index";
import { booksService, userSubscriptionTypesService } from "../../services";

interface BooksState {
	books: BookList | null;
	bookCover: { imageUrl: string; value: string } | null;
	isCreatingUpdatingDeletingBook: boolean;
	isFetchingBooks: boolean;
	isFetchingPrice: boolean;
	isFetchingShipmentData: boolean;
	shipmentData: {
		name: string;
	} | null;
	bookPrice: number;
}

const initialState: BooksState = {
	books: null,
	bookCover: null,
	isCreatingUpdatingDeletingBook: false,
	isFetchingBooks: false,
	isFetchingShipmentData: false,
	shipmentData: null,
	isFetchingPrice: false,
	bookPrice: 0
};

export const bookSlice = createSlice({
	name: "books",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		resetBookCover: state => {
			state.bookCover = null;
		},
		resetShipmentData: state => {
			state.shipmentData = null;
		}
	},
	extraReducers: builder =>
		builder
			.addCase(createBook.pending.type, state => {
				state.isCreatingUpdatingDeletingBook = true;
			})
			.addCase(createBook.fulfilled.type, state => {
				state.isCreatingUpdatingDeletingBook = false;
				state.bookCover = null;
			})
			.addCase(createBook.rejected.type, state => {
				state.isCreatingUpdatingDeletingBook = false;
			})
			.addCase(updateBook.pending.type, state => {
				state.isCreatingUpdatingDeletingBook = true;
			})
			.addCase(updateBook.fulfilled.type, state => {
				state.isCreatingUpdatingDeletingBook = false;
				state.bookCover = null;
			})
			.addCase(updateBook.rejected.type, state => {
				state.isCreatingUpdatingDeletingBook = false;
			})
			.addCase(deleteBook.pending.type, state => {
				state.isCreatingUpdatingDeletingBook = true;
			})
			.addCase(deleteBook.fulfilled.type, state => {
				state.isCreatingUpdatingDeletingBook = false;
			})
			.addCase(deleteBook.rejected.type, state => {
				state.isCreatingUpdatingDeletingBook = false;
			})
			.addCase(fetchBooks.pending.type, state => {
				state.isFetchingBooks = true;
			})
			.addCase(fetchBooks.fulfilled.type, (state, action: PayloadAction<BookList>) => {
				state.isFetchingBooks = false;
				state.books = action.payload;
			})
			.addCase(fetchBooks.rejected.type, state => {
				state.isFetchingBooks = false;
			})
			.addCase(fetchBookStripePrice.pending.type, state => {
				state.isFetchingPrice = true;
			})
			.addCase(fetchBookStripePrice.fulfilled.type, (state, action: PayloadAction<{ price: number }>) => {
				state.isFetchingPrice = false;
				state.bookPrice = action.payload.price;
			})
			.addCase(fetchBookStripePrice.rejected.type, state => {
				state.isFetchingPrice = false;
			})
			.addCase(fetchShipStationData.pending.type, state => {
				state.isFetchingShipmentData = true;
			})
			.addCase(fetchShipStationData.fulfilled.type, (state, action: PayloadAction<{ name: string }>) => {
				state.isFetchingShipmentData = false;
				state.shipmentData = action.payload;
			})
			.addCase(fetchShipStationData.rejected.type, state => {
				state.isFetchingShipmentData = false;
			})
});

export const fetchBookStripePrice = createAsyncThunk(
	"books/fetchBookStripePrice",
	async (planId: string, { rejectWithValue }) =>
		await userSubscriptionTypesService
			.getPaymentProviderPriceData(ExternalIntegrationIds.Stripe, planId)
			.catch(e => rejectWithValue(e.message))
);

export const fetchShipStationData = createAsyncThunk(
	"books/fetchShipStationData",
	async (productSKU: string, { rejectWithValue }) =>
		await userSubscriptionTypesService
			.getStockKeepingProductData(ExternalIntegrationIds.PBS, productSKU)
			.catch(e => rejectWithValue(e.message))
);

export const fetchBooks = createAsyncThunk("books/fetchBooks", async (data: BaseFindDto, { rejectWithValue }) => {
	return await booksService.find({ ...data, orderBy: { createdAt: "DESC" } }).catch(e => rejectWithValue(e.message));
});

export const createBook = createAsyncThunk(
	"books/createBook",
	async ({ options, cb }: { options: CreateBook; cb: () => void }, { rejectWithValue }) => {
		try {
			const res = await booksService.create(options as unknown as Record<string, unknown>);
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const updateBook = createAsyncThunk(
	"books/updateBook",
	async ({ options, cb }: { options: UpdateBook; cb: () => void }, { rejectWithValue }) => {
		const { id, ...restOptions } = options;
		try {
			const res = await booksService.update({ filters: { id }, data: { ...restOptions } });
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const deleteBook = createAsyncThunk(
	"books/deleteBook",
	async ({ id, cb }: { id: number; cb: () => void }, { rejectWithValue }) => {
		try {
			const res = await booksService.delete({ filters: { id } });
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const getFullBookState = (state: RootState): BooksState => state.books;
export const { setStateValue, validateForm, resetBookCover, resetShipmentData } = bookSlice.actions;
export default bookSlice.reducer;
