import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";

import { ILocation } from "@remar/shared/dist/models";

import { ChartData, ChartReportParams, GetSummaryReportParams, ReportSummaryDto, TestChartData } from "./dto";

import { URLS } from "../services.constants";

export class LocationsService extends BaseApiService<
	ILocation,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getSummaryReport(data: GetSummaryReportParams): Promise<ReportSummaryDto> {
		return request(
			`${this.baseUrl}/report?courseId=${data.courseId}&startDate=${data.startDate}&endDate=${data.endDate}`,
			"GET"
		) as Promise<ReportSummaryDto>;
	}

	getLoginChartData(data: ChartReportParams): Promise<ChartData> {
		return request(`${this.baseUrl}/login-chart?month=${data.month}&year=${data.year}`, "GET") as Promise<ChartData>;
	}

	getTrainingChartData(data: ChartReportParams): Promise<ChartData> {
		return request(`${this.baseUrl}/training-chart?month=${data.month}&year=${data.year}`, "GET") as Promise<ChartData>;
	}

	getTestChartData(data: ChartReportParams): Promise<TestChartData> {
		return request(`${this.baseUrl}/test-chart?month=${data.month}&year=${data.year}`, "GET") as Promise<TestChartData>;
	}

	/**
	 * Adds seats to a specified location.
	 *
	 * @param {number} locationId - The ID of the location where seats are to be added.
	 * @param {number} quantity - The number of seats to be added.
	 * @return {Promise<unknown>} - A promise that resolves when the seats have been added.
	 */
	addSeats(locationId: number, quantity: number): Promise<unknown> {
		return request(`${this.baseUrl}/assign-seat`, "POST", { locationId, quantity });
	}
}

export const locationsService = new LocationsService(URLS.LOCATIONS);
