import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseFindDto } from "@remar/shared/dist/api/baseApiService";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import { Gift } from "@remar/shared/dist/models";
import { validateFormAction as utilsValidateFormAction } from "@remar/shared/dist/utils/form/form.utils";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { RootState } from "store";

import { CreateGift, GiftList, UpdateGift, giftsService, userSubscriptionTypesService } from "store/services";

import { ITEMS_PER_PAGE } from "modules/App/View/Container/Shop/constants";

interface GiftState {
	gifts: GiftList | null;
	selectedGift: Gift | null;
	isCreatingUpdatingDeletingGift: boolean;
	isFetchingGifts: boolean;
	isFetchingPrice: boolean;
	giftPrice: number;
	fetchPriceError: string | null;
}

const initialState: GiftState = {
	gifts: null,
	selectedGift: null,
	isCreatingUpdatingDeletingGift: false,
	isFetchingGifts: false,
	isFetchingPrice: false,
	giftPrice: 0,
	fetchPriceError: null
};

export const giftSlice = createSlice({
	name: "gifts",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		setError: (state, action: PayloadAction<string>) => {
			state.fetchPriceError = action.payload;
		}
	},
	extraReducers: builder =>
		builder
			.addCase(createGift.pending.type, state => {
				state.isCreatingUpdatingDeletingGift = true;
			})
			.addCase(createGift.fulfilled.type, state => {
				state.isCreatingUpdatingDeletingGift = false;
			})
			.addCase(createGift.rejected.type, state => {
				state.isCreatingUpdatingDeletingGift = false;
			})

			.addCase(updateGift.pending.type, state => {
				state.isCreatingUpdatingDeletingGift = true;
			})
			.addCase(updateGift.fulfilled.type, state => {
				state.isCreatingUpdatingDeletingGift = false;
			})
			.addCase(updateGift.rejected.type, state => {
				state.isCreatingUpdatingDeletingGift = false;
			})

			.addCase(deleteGift.pending.type, state => {
				state.isCreatingUpdatingDeletingGift = true;
			})
			.addCase(deleteGift.fulfilled.type, state => {
				state.isCreatingUpdatingDeletingGift = false;
			})
			.addCase(deleteGift.rejected.type, state => {
				state.isCreatingUpdatingDeletingGift = false;
			})

			.addCase(fetchGifts.pending.type, state => {
				state.isFetchingGifts = true;
			})
			.addCase(fetchGifts.fulfilled.type, (state, action: PayloadAction<GiftList>) => {
				state.isFetchingGifts = false;
				state.gifts = action.payload;
			})
			.addCase(fetchGifts.rejected.type, state => {
				state.isFetchingGifts = false;
			})

			.addCase(fetchGiftStripePrice.pending.type, state => {
				state.isFetchingPrice = true;
				state.fetchPriceError = null;
			})
			.addCase(fetchGiftStripePrice.fulfilled.type, (state, action: PayloadAction<{ price: number }>) => {
				state.isFetchingPrice = false;
				state.giftPrice = action.payload.price;
			})
			.addCase(fetchGiftStripePrice.rejected.type, (state, action: PayloadAction<string>) => {
				state.isFetchingPrice = false;
				state.fetchPriceError = action.payload;
			})

			.addCase(findGift.pending.type, state => {
				state.selectedGift = null;
			})
			.addCase(findGift.fulfilled.type, (state, action: PayloadAction<Gift>) => {
				state.selectedGift = action.payload;
			})
			.addCase(findGift.rejected.type, state => {
				state.selectedGift = null;
			})

			.addCase(setActive.pending.type, state => {
				state.isFetchingGifts = true;
			})
			.addCase(setActive.fulfilled.type, state => {
				state.isFetchingGifts = false;
			})
			.addCase(setActive.rejected.type, state => {
				state.isFetchingGifts = false;
			})
});

export const fetchGiftStripePrice = createAsyncThunk(
	"gifts/fetchGiftStripePrice",
	async (planId: string, { rejectWithValue }) =>
		await userSubscriptionTypesService
			.getPaymentProviderPriceData(ExternalIntegrationIds.Stripe, planId)
			.catch(e => rejectWithValue(e.message))
);

export const fetchGifts = createAsyncThunk("gifts/fetchGifts", async (data: BaseFindDto, { rejectWithValue }) => {
	return await giftsService.find({ ...data }).catch(e => rejectWithValue(e.message));
});

export const findGift = createAsyncThunk("gifts/findGift", async (id: number, { rejectWithValue }) => {
	return await giftsService.find({ filters: { id } }).catch(e => rejectWithValue(e.message));
});

export const createGift = createAsyncThunk(
	"gifts/createGift",
	async ({ options, cb }: { options: CreateGift; cb: () => void }, { rejectWithValue }) => {
		try {
			const res = await giftsService.create(options as unknown as Record<string, unknown>);
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const updateGift = createAsyncThunk(
	"gifts/updateGift",
	async ({ options, cb }: { options: UpdateGift; cb: () => void }, { rejectWithValue }) => {
		const { id, ...restOptions } = options;
		try {
			const res = await giftsService.update({ filters: { id }, data: { ...restOptions } });
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const setActive = createAsyncThunk(
	"gifts/setActive",
	async ({ id, isActive }: Pick<UpdateGift, "id" | "isActive">, { rejectWithValue, dispatch }) => {
		await giftsService.update({ filters: { id }, data: { isActive } }).catch(e => rejectWithValue(e.message));
		dispatch(fetchGifts({ include: ["books", "userSubscriptionType"], page: 1, perPage: ITEMS_PER_PAGE }));
	}
);

export const deleteGift = createAsyncThunk(
	"gifts/deleteGift",
	async ({ id, cb }: { id: number; cb: () => void }, { rejectWithValue }) => {
		try {
			const res = await giftsService.delete({ filters: { id } });
			cb && cb();
			return res;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const getFullGiftState = (state: RootState): GiftState => state.gifts;
export const { setStateValue, validateForm, setError } = giftSlice.actions;
export default giftSlice.reducer;
