import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import HeaderContainer from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";

import { ContentAccessTypes } from "@remar/shared/dist/models";

import { StyledCheckbox, TransparentButton } from "@remar/shared/dist/ui/Buttons/styles";
import convertMinsToHrsMins from "@remar/shared/dist/utils/convertMinsToHrsMins";
import { validatePositiveNumber } from "@remar/shared/dist/utils/serviceUtils/validators";
import { uploadFile } from "@remar/shared/dist/utils/stateUtils";
import { cloneDeep } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getFullState as getAuthFullState } from "store/features/Auth/auth.slice";
import {
	createChapter,
	editChapter,
	fetchAppleProductInfo,
	fetchCourse,
	fetchStripePrice,
	getFullState,
	setStateValue
} from "store/features/Course/course.slice";
import { fetchLocations, selectManageLocationsFullState } from "store/features/ManageLocations/manageLocations.slice";
import { _emit, emit } from "store/features/notifications/notifications.slice";

import { CourseChapterCreateDto, CourseChapterUpdateDto, genericService } from "store/services";

import CheckPermissions from "core/CheckPermissions";
import { routes } from "core/constants";

import Card from "modules/Components/Card";
import EditCard from "modules/Components/EditCard";
import EmptyViewCard from "modules/Components/EmptyViewCard";

import { getAccessOptions } from "utils/getAccessOptions";

import EditFields from "./EditFields";

import { AddNewCategory, ButtonContainer, CardContainer, Container } from "./styles";

import { ListItems } from "../Chapters/styles";
import { ChapterViewInterface, LocationOptions } from "../types";
import { reorderChapters, targetLocationOptions } from "../utils";

const WEEK_DAYS = 7;
const Content = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<{ courseId: string }>();
	const { activeCourse, editChapterData, addChapterData, isLoading, uploadingImage } = useSelector(getFullState);
	const { locations, page: locationPage } = useSelector(selectManageLocationsFullState);
	const {
		accessPerRoute: { canEdit }
	} = useSelector(getAuthFullState);
	const chapters = activeCourse ? activeCourse.chapters! : [];
	const courseId = Number(params.courseId);
	const defaultAddChapterFields: CourseChapterCreateDto = {
		order: 0,
		courseId,
		name: "",
		totalTimeLengthInMinutes: 0,
		calculateTimeLengthAutomatically: false,
		progressTrackingEnabled: false,
		mainImageKey: "",
		contentAccessTypeId: ContentAccessTypes.Paid,
		targetLocation: LocationOptions.all,
		lockedChapterData: {
			lifetimeAccess: true
		}
	};
	const [editIndex, setEditIndex] = useState<number | null>(null);
	const [showAddNewChapter, setShowAddNewChapter] = useState<boolean>(false);
	const [chapterLength, setChapterLength] = useState({ hours: "", mins: "" });
	const [addChapterLength, setAddChapterLength] = useState({ hours: "", mins: "" });
	const [disableAddChapter, setDisableAddChapter] = useState(false);

	const [chapterView, setChapterView] = useState<ChapterViewInterface[]>([]);

	useEffect(() => {
		dispatch(fetchCourse(courseId));
		if (!editChapterData) {
			dispatch(
				setStateValue({ key: "editChapterData", value: { data: { ...defaultAddChapterFields }, filters: { id: 0 } } })
			);
		}
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchLocations({ perPage: 10, page: 1, searchKeyword: "", filters: {} }));
	}, [dispatch]);

	useEffect(() => {
		if (chapters.length !== 0) {
			populateData(chapters);
		}
	}, [chapters]);

	const resetLocations = () => {
		searchLocations("");
	};
	const handleSave = () => {
		const {
			name,
			totalTimeLengthInMinutes,
			calculateTimeLengthAutomatically,
			progressTrackingEnabled,
			hours,
			mins,
			excludedFromCourseRequirement,
			contentAccessTypeId,
			targetLocation,
			selectedLocations,
			lockedChapter,
			lockedChapterData
		} = editChapterData!.data;

		const numberOfDays = lockedChapterData?.lifetimeAccess ? 0 : (lockedChapterData?.period || 0) * WEEK_DAYS;
		const payload = {
			data: {
				name,
				totalTimeLengthInMinutes,
				calculateTimeLengthAutomatically,
				progressTrackingEnabled,
				excludedFromCourseRequirement,
				contentAccessTypeId,
				allLocations: targetLocation === LocationOptions.all,
				...(targetLocation === LocationOptions.selectedLocation && {
					locationIds: selectedLocations.map(l => ({ value: l.id }))
				}),
				lockedChapter,
				...(lockedChapter && {
					data: {
						...lockedChapterData,
						numberOfDays
					}
				}),
				...(editChapterData!.data.mainImageKey && { mainImageKey: editChapterData!.data.mainImageKey })
			},
			filters: editChapterData!.filters
		};
		payload.data.data && delete payload.data.data.period;
		if (!calculateTimeLengthAutomatically) {
			payload.data.totalTimeLengthInMinutes = Number(hours) * 60 + Number(mins);
		}
		dispatch(editChapter(payload));
		setEditIndex(null);
		dispatch(setStateValue({ key: "editChapterData", value: null }));
		resetLocations();
		resetEditData();
		setDisableAddChapter(false);
	};

	const handleEdit = (i: number) => {
		const {
			id,
			name,
			totalTimeLengthInMinutes,
			calculateTimeLengthAutomatically,
			progressTrackingEnabled,
			mainImageUrl,
			mainImageKey,
			excludedFromCourseRequirement,
			contentAccessTypeId,
			allowedLocations,
			lockedChapter,
			data: lockedChapterData
		} = chapters![i]!;
		const minHrs = convertMinsToHrsMins(totalTimeLengthInMinutes).split("h");
		const hours = Number(minHrs[0]);
		const mins = Number(minHrs[1].split("min")[0]);
		const payload = {
			data: {
				name,
				mainImageUrl,
				mainImageKey,
				totalTimeLengthInMinutes,
				hours,
				mins,
				calculateTimeLengthAutomatically,
				progressTrackingEnabled,
				excludedFromCourseRequirement,
				contentAccessTypeId,
				targetLocation: !!allowedLocations?.length ? LocationOptions.selectedLocation : LocationOptions.all,
				selectedLocations: allowedLocations || [],
				lockedChapter,
				lockedChapterData: {
					...lockedChapterData,
					lifetimeAccess: lockedChapterData.lifetimeAccess ?? true,
					period: lockedChapterData.numberOfDays ? lockedChapterData.numberOfDays / WEEK_DAYS : undefined
				}
			},
			filters: { id }
		} as unknown as CourseChapterUpdateDto;
		setEditIndex(i);
		dispatch(setStateValue({ key: "editChapterData", value: payload }));
		if (lockedChapterData.planId) {
			dispatch(fetchStripePrice(lockedChapterData.planId));
		}

		if (lockedChapterData.applePlanId) {
			dispatch(fetchAppleProductInfo(lockedChapterData.applePlanId));
		}
	};

	const handleCancel = () => {
		setEditIndex(null);
		dispatch(setStateValue({ key: "editChapterData", value: null }));
		populateData(chapters);
		resetEditData();
		resetLocations();
		setDisableAddChapter(false);
	};

	const updateEditChapterLength = (val: string, key) => {
		if (updateChapterLength(val, key)) {
			updateEditData(val, key);
		}
	};

	const updateEditData = (val: string | boolean | number, key) => {
		const _editData = cloneDeep(editChapterData || ({} as CourseChapterUpdateDto));
		_editData.data[key] = val;
		dispatch(setStateValue({ key: "editChapterData", value: _editData }));
	};

	const populateData = chapters => {
		if (chapters.length) {
			const data: ChapterViewInterface[] = (chapters || []).map((el, i) => {
				const selectedAccess = accessId => {
					switch (accessId) {
						case ContentAccessTypes.Paid: {
							return "Paid Only";
						}
						case ContentAccessTypes.Mixed: {
							return "Paid & Trial";
						}
						default: {
							return "Trial Only";
						}
					}
				};
				const {
					name,
					totalSections,
					totalTimeLengthInMinutes,
					id,
					totalLessons,
					order,
					isActive,
					excludedFromCourseRequirement,
					contentAccessTypeId,
					allowedLocations,
					allLocations
				} = el;
				return {
					id: id,
					order: order,
					title: {
						key: "Chapter Title",
						subtitle: isActive ? "Published" : "Unpublished",
						value: name
					},
					content: [
						{ key: "Sections", value: totalSections },
						{ key: "Total Lessons", value: totalLessons },
						{ key: "Chapter Length", value: convertMinsToHrsMins(totalTimeLengthInMinutes) },
						{ key: "Access", value: selectedAccess(contentAccessTypeId) },
						{ key: "Location", value: `${!allLocations ? allowedLocations?.length + " Selected" : "All"}` }
					],
					imgSrc: el.mainImageUrl,
					bottomComponent: (
						<ButtonContainer>
							<CheckPermissions>
								<TransparentButton
									variant="text"
									onClick={() => {
										handleEdit(i);
										setDisableAddChapter(true);
									}}
								>
									Edit Chapter
								</TransparentButton>
							</CheckPermissions>
							<Button
								variant={"contained"}
								color={"primary"}
								onClick={() => history.push(`${routes.course.getPath()}/${courseId}/chapters/${id}`)}
							>
								View Chapter
							</Button>
						</ButtonContainer>
					),
					bottomLeftComponent: (
						<Box mt={2}>
							<CheckPermissions>
								<StyledCheckbox
									checked={excludedFromCourseRequirement}
									onChange={(event: React.ChangeEvent<{ checked: unknown }>) => {
										updateEditData(!!event.target.checked, "excludedFromCourseRequirement");
										const { id } = chapters![i]!;
										const payload = {
											data: {
												excludedFromCourseRequirement: !!event.target.checked
											},
											filters: { id }
										};
										dispatch(editChapter(payload, false));
									}}
								/>
							</CheckPermissions>
							<span>Exclude from course requirement </span>
						</Box>
					)
				};
			});
			setChapterView(data);
		} else {
			setChapterView([]);
		}
	};

	const addNewChapter = () => {
		dispatch(
			setStateValue({ key: "addChapterData", value: { data: { ...defaultAddChapterFields }, filters: { id: 0 } } })
		);
		setShowAddNewChapter(true);
	};

	const breadcrumb = [
		{ title: "Course", key: 0, link: routes.course.getPath() },
		{ title: !!activeCourse ? activeCourse.name : "", key: 1 }
	];

	const createNewChapter = () => {
		const {
			order,
			name: chapterName,
			totalTimeLengthInMinutes,
			calculateTimeLengthAutomatically,
			progressTrackingEnabled,
			excludedFromCourseRequirement,
			contentAccessTypeId,
			targetLocation,
			selectedLocations,
			lockedChapter,
			lockedChapterData
		} = addChapterData!.data;
		if (chapters.find(chapter => chapter.name.toLowerCase() === addChapterName!.toLowerCase())) {
			dispatch(
				emit({
					message: "Chapter Name already exists",
					color: "error"
				})
			);
			return;
		}
		const numberOfDays = lockedChapterData!.lifetimeAccess ? 0 : (lockedChapterData!.period || 0) * WEEK_DAYS;
		const payload = {
			order,
			courseId,
			name: chapterName!.trim(),
			totalTimeLengthInMinutes,
			calculateTimeLengthAutomatically,
			progressTrackingEnabled,
			excludedFromCourseRequirement,
			contentAccessTypeId,
			allLocations: targetLocation === LocationOptions.all,
			...(targetLocation === LocationOptions.selectedLocation && {
				locationIds: selectedLocations.map(l => ({ value: l.id }))
			}),
			lockedChapter,
			...(lockedChapter && {
				data: {
					...lockedChapterData,
					numberOfDays
				}
			}),
			...(addChapterData!.data.mainImageKey && { mainImageKey: addChapterData!.data.mainImageKey })
		};
		if (payload.calculateTimeLengthAutomatically) {
			payload.totalTimeLengthInMinutes = 0;
		} else {
			payload.totalTimeLengthInMinutes = Number(addChapterLength.hours) * 60 + Number(addChapterLength.mins);
		}
		payload.data && delete payload.data.period;
		dispatch(createChapter(payload as CourseChapterCreateDto));
		resetAddChapterData();
	};

	const resetEditData = () => {
		setShowAddNewChapter(false);
		dispatch(
			setStateValue({ key: "editChapterData", value: { data: { ...defaultAddChapterFields }, filters: { id: 0 } } })
		);
		setChapterLength({ hours: "", mins: "" });
	};

	const resetAddChapterData = () => {
		setShowAddNewChapter(false);
		dispatch(
			setStateValue({ key: "addChapterData", value: { data: { ...defaultAddChapterFields }, filters: { id: 0 } } })
		);
		resetLocations();
		setAddChapterLength({ hours: "", mins: "" });
	};

	const updateChapterLength = (val: string, key) => {
		if (validatePositiveNumber(val)) {
			const _chapterLength = { ...chapterLength };
			_chapterLength[key] = val;
			setChapterLength(_chapterLength);
			return true;
		}
		return false;
	};

	const updateAddChapterLength = (val: string, key) => {
		if (validatePositiveNumber(val)) {
			const _chapterLength = { ...addChapterLength };
			_chapterLength[key] = val;
			setAddChapterLength(_chapterLength);
			return true;
		}
		return false;
	};

	const updateAddChapterData = (val: unknown, key) => {
		dispatch(setStateValue({ key: `addChapterData.data.${key}`, value: val }));
	};

	const {
		name: addChapterName,
		calculateTimeLengthAutomatically: addChapterCalculateTimeLengthAutomatically,
		progressTrackingEnabled: addChapterProgressTrackingEnabled,
		contentAccessTypeId: addChapterAccessTypeId,
		targetLocation,
		selectedLocations,
		lockedChapter,
		lockedChapterData
	} = addChapterData ? addChapterData.data : ({} as CourseChapterCreateDto);

	const disabledAddChapterSaveBtn =
		(!addChapterCalculateTimeLengthAutomatically
			? !(addChapterLength.hours && addChapterLength.mins && addChapterName)
			: !addChapterName) ||
		(lockedChapter && !lockedChapterData?.lifetimeAccess && !lockedChapterData?.period);

	const onDragEnd = async result => {
		if (!result.destination) {
			return;
		}
		const { reorderedChapters, selectedChapterId } = reorderChapters(chapters, result.destination, result.source);
		if (selectedChapterId) {
			//Mock order change to reflect on UI without lag
			dispatch(setStateValue({ key: "activeCourse.chapters", value: reorderedChapters }));
			await dispatch(
				editChapter(
					{
						data: {
							order: result.destination.index
						},
						filters: {
							id: selectedChapterId
						}
					},
					false,
					false
				)
			);
		}
	};

	const searchLocations = text => {
		dispatch(fetchLocations({ perPage: 10, page: 1, searchKeyword: text, filters: {} }));
	};
	const loadMoreLocations = () => {
		dispatch(
			fetchLocations({
				perPage: 10,
				page: locationPage + 1,
				searchKeyword: "",
				filters: {},
				infinite: true
			})
		);
	};
	const chapterCard = (el, i) => {
		if (i === editIndex) {
			const {
				name,
				calculateTimeLengthAutomatically,
				hours,
				mins,
				progressTrackingEnabled,
				excludedFromCourseRequirement,
				contentAccessTypeId,
				targetLocation,
				selectedLocations,
				lockedChapter,
				lockedChapterData
			} = editChapterData!.data;
			const editChapterLength = { hours, mins };
			const disabledSaveBtn =
				(!calculateTimeLengthAutomatically
					? !(editChapterLength.hours !== "" && editChapterLength.mins !== "" && name)
					: !name) ||
				uploadingImage ||
				(lockedChapter && !lockedChapterData?.lifetimeAccess && !lockedChapterData?.period);
			return (
				<Box mb={4}>
					<EditCard
						rightComponent={EditFields(
							updateEditData,
							name,
							calculateTimeLengthAutomatically,
							progressTrackingEnabled,
							updateEditChapterLength,
							chapterLength,
							disabledSaveBtn,
							handleCancel,
							handleSave,
							editChapterLength,
							excludedFromCourseRequirement,
							contentAccessTypeId,
							getAccessOptions(),
							targetLocationOptions,
							locations,
							selectedLocations,
							searchLocations,
							loadMoreLocations,
							lockedChapter,
							lockedChapterData,
							targetLocation,
							1
						)}
						imageUrl={activeCourse!.chapters![i].mainImageUrl!}
						width={850}
						handleImageUpdate={(file, handleProgres) => {
							dispatch(
								uploadFile(
									{ file },
									{
										_emit,
										genericApiService: genericService,
										imageUrlStatePath: `activeCourse.chapters.${i}.mainImageUrl`,
										loaderStatePath: "uploadingImage",
										s3KeyStatePath: "editChapterData.data.mainImageKey",
										setStateValue,
										onProgress: ({ loaded, total }) => {
											handleProgres && handleProgres({ progress: (loaded / total) * 100, uploading: true });
										},
										uploadCb: () => {
											handleProgres && handleProgres({ progress: 0, uploading: false });
										}
									}
								)
							);
						}}
					/>
				</Box>
			);
		} else {
			return (
				<CardContainer key={el.id}>
					<Card
						width={850}
						title={el.title}
						content={el.content}
						imgSrc={el.imgSrc}
						imgRounded={true}
						rightComponent={el.bottomComponent}
						bottomLeftComponent={el.bottomLeftComponent}
					/>
				</CardContainer>
			);
		}
	};

	return (
		<Container>
			<HeaderContainer
				heading={!!activeCourse ? activeCourse.name : ""}
				breadcrumb={breadcrumb}
				actions={
					<CheckPermissions>
						<AddNewCategory
							disabled={disableAddChapter}
							variant={"contained"}
							color={"primary"}
							onClick={addNewChapter}
						>
							Add New Chapter
						</AddNewCategory>
					</CheckPermissions>
				}
			/>
			{showAddNewChapter && (
				<Box px={3} py={1}>
					<EditCard
						rightComponent={EditFields(
							updateAddChapterData,
							addChapterName,
							addChapterCalculateTimeLengthAutomatically,
							addChapterProgressTrackingEnabled,
							updateAddChapterLength,
							addChapterLength,
							disabledAddChapterSaveBtn,
							resetAddChapterData,
							createNewChapter,
							undefined,
							undefined,
							addChapterAccessTypeId,
							getAccessOptions(),
							targetLocationOptions,
							locations,
							selectedLocations,
							searchLocations,
							loadMoreLocations,
							lockedChapter,
							lockedChapterData,
							targetLocation,
							1
						)}
						width={850}
						handleImageUpdate={(file, handleProgres) => {
							dispatch(
								uploadFile(
									{ file },
									{
										_emit,
										onProgress: ({ loaded, total }) => {
											handleProgres && handleProgres({ progress: (loaded / total) * 100, uploading: true });
										},
										uploadCb: () => {
											handleProgres && handleProgres({ progress: 0, uploading: false });
										},
										genericApiService: genericService,
										imageUrlStatePath: "addChapterData.data.mainImageUrl",
										loaderStatePath: "uploadingImage",
										s3KeyStatePath: "addChapterData.data.mainImageKey",
										setStateValue
									}
								)
							);
						}}
					/>
				</Box>
			)}
			{isLoading ? (
				<Box display="flex" alignItems="center" justifyContent="center" height={450}>
					<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
				</Box>
			) : chapterView.length > 0 ? (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={`${courseId}`}>
						{provided => (
							<Box px={3} py={1} {...provided.droppableProps} ref={provided.innerRef}>
								{chapterView.map((item, index) => (
									<Draggable key={item.id} draggableId={`${item.id}`} index={index} isDragDisabled={!canEdit}>
										{provided => (
											<ListItems ref={provided.innerRef} {...provided.draggableProps}>
												<Box flexDirection="column">
													{index !== editIndex && (
														<Box
															display="flex"
															justifyContent="center"
															style={{ backgroundColor: "#2a2e37" }}
															{...provided.dragHandleProps}
															mr={3}
														>
															<SvgIcon fontSize="large" color="disabled">
																<DragHandleIcon />
															</SvgIcon>
														</Box>
													)}
													{chapterCard(item, index)}
												</Box>
											</ListItems>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				!showAddNewChapter && (
					<CardContainer>
						<EmptyViewCard title="There are no Chapters yet" />
					</CardContainer>
				)
			)}
		</Container>
	);
};

export default Content;
