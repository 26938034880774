import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";

import activityLogsReducer from "./features/ActivityLogs/ActivityLogs.slice";
import addQuestionReducer from "./features/AddQuestion/addQuestion.slice";
import addschoolReducer from "./features/AddSchools/addSchools.slice";
import authReducer from "./features/Auth/auth.slice";
import bannersReducer from "./features/Banners/banners.slice";
import BooksSlice from "./features/Books/books.slice";
import catAvailabilityReducer from "./features/CATAvailability/catAvailability.slice";
import couponReducer from "./features/Coupons/coupons.slice";
import courseReducer from "./features/Course/course.slice";
import courseAddEditReducer from "./features/CourseAddEdit/courseAddEdit.slice";
import createLessonReducer from "./features/CreateLesson/createLesson.slice";
import fileVaultFolderReducer from "./features/FileVault/FileVault.slice";
import giftState from "./features/Gifts/gifts.slice";
import lessonsReducer from "./features/Lesson/lesson.slice";
import lessonVideoReducer from "./features/LessonVideos/LessonVideos.slice";
import manageAdminsReducer from "./features/ManageAdmins/manageAdmins.slice";
import CommunityPoolManageSubjectsReducer from "./features/ManageCommunityPool/ManageCommunityPool.slice";
import manageLocationReducer from "./features/ManageLocations/manageLocations.slice";
import managePackageReducer from "./features/ManagePackage/managePackage.slice";
import manageSchoolRequestsReducer from "./features/ManageSchools/manageSchoolRequests.slice";
import manageSchoolsReducer from "./features/ManageSchools/manageSchools.slice";
import mergeSchoolReducer from "./features/MergeSchool/mergeSchools.slice";
import questionBankReducer from "./features/QuestionBank/questionBank.slice";
import reportsReducer from "./features/Reports/Reports.slice";
import studentsReducer from "./features/Students/students.slice";
import subjectCategoriesReducer from "./features/SubjectCategories/subjectCategories.slice";
import subjectsReducer from "./features/Subjects/Subjects.slice";
import tagsReducer from "./features/Tags/Tags.slice";
import counterReducer from "./features/counter/counter.slice";
import notificationsReducer from "./features/notifications/notifications.slice";

export const store = configureStore({
	reducer: {
		activityLogs: activityLogsReducer,
		addQuestion: addQuestionReducer,
		addSchool: addschoolReducer,
		auth: authReducer,
		banners: bannersReducer,
		books: BooksSlice,
		catAvailability: catAvailabilityReducer,
		counter: counterReducer,
		coupons: couponReducer,
		course: courseReducer,
		courseAddEdit: courseAddEditReducer,
		createLesson: createLessonReducer,
		fileVaultFolder: fileVaultFolderReducer,
		gifts: giftState,
		lesson: lessonsReducer,
		lessonVideos: lessonVideoReducer,
		manageAdmins: manageAdminsReducer,
		manageCommunityPool: CommunityPoolManageSubjectsReducer,
		manageLocations: manageLocationReducer,
		managePackage: managePackageReducer,
		mergeSchool: mergeSchoolReducer,
		notifications: notificationsReducer,
		questionBank: questionBankReducer,
		reports: reportsReducer,
		schoolRequests: manageSchoolRequestsReducer,
		schools: manageSchoolsReducer,
		students: studentsReducer,
		subjectCategories: subjectCategoriesReducer,
		subjects: subjectsReducer,
		tags: tagsReducer
	}
});

export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
