import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { IActivityLog } from "./dto";

import { URLS } from "../services.constants";

export class ActivityLogsService extends BaseApiService<
	IActivityLog,
	IActivityLog,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {}

export const activityLogsService = new ActivityLogsService(URLS.ACTIVITY_LOGS);
