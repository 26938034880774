import { Box, Typography } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

import styled from "styled-components";

export const ActivityWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	.activity-role {
		font-weight: bold;
		margin-right: 5px;
	}
	.activity-date {
		font-size: 14px;
		color: ${({ theme }) => theme.palette.colors.basic[400]};
	}
`;

export const PreviousText = styled(Typography)`
	font-family: var(--openSans);
	font-size: 14px;
	color: ${({ theme }) => theme.palette.colors.basic[400]};
`;
export const CurrentText = styled(Typography)`
	font-family: var(--openSans);
	font-size: 14px;
`;

export const StyledArrowForward = styled(ArrowForward)`
	width: 20px;
	color: ${({ theme }) => theme.palette.colors.basic[400]};
`;

export const BoldTypography = styled(Typography)`
	font-weight: bold;
`;
