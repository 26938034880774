import { makeStyles } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

export const useInteractiveBlocksStyles = makeStyles((theme: IExtendedTheme) => ({
	addNewBlockCard: {
		color: theme.palette.colors.info[500],
		textAlign: "center",
		fontFamily: "var(--poppins)",
		fontSize: "14px",
		fontWeight: "bold",
		cursor: "pointer",
		lineHeight: 1.14
	},
	addNewBlockCardDisabled: {
		color: theme.palette.colors.info[500],
		textAlign: "center",
		cursor: "default"
	},
	avatar: {
		width: "32px",
		height: "32px",
		opacity: 0.87,
		fontFamily: "var(--poppins)",
		fontSize: "18px",
		fontWeight: "bold",
		lineHeight: 1.33,
		color: "#ffffff",
		backgroundColor: "#45454e"
	},
	block: {
		display: "flex",
		marginBottom: "16px",
		color: "rgb(255, 255, 255)",
		cursor: "pointer"
	},
	blockSelected: {
		border: `1px solid ${theme.palette.colors.info[500]}`,
		cursor: "default"
	},
	placeholderText: {
		opacity: 0.87,
		fontSize: "15px",
		lineHeight: 1.33,
		marginLeft: "12px",
		marginTop: "7px"
	},
	timeStamp: {
		marginLeft: "auto"
	}
}));

export const styles = {
	deleteIcon: {
		background: "transparent"
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	card: {
		borderRadius: "5px"
	},
	title: {
		fontSize: "25px",
		fontWeight: 900
	},
	closeIcon: {
		marginTop: "-15px",
		marginRight: "-15px"
	},
	subTitle: {
		color: "rgba(255, 255, 255, 0.6)",
		marginBottom: "25px"
	},
	modalFooter: {
		paddingTop: "25px",
		borderTop: "1px solid rgb(42, 46, 55)"
	},
	cancelBtn: {
		fontSize: "16px",
		backgroundColor: "transparent",
		color: "rgba(255, 255, 255, 0.6)",
		borderColor: "#6b6e75;",
		borderWidth: "1px",
		borderStyle: "solid",
		marginRight: "10px"
	},
	deleteBtn: {
		fontSize: "16px",
		backgroundColor: "#46191e",
		color: "#ed5465"
	}
};
