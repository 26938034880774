export const COURSE_ADD_EDIT_API_URLS = {
	BASE: "/courses",
	FIND_ONE: "/courses/item",
	GET_PAYMENT_PROVIDER_PRICE_DATA: "/user-subscription-types/payment-provider-price-data",
	GET_STOCK_KEEPING_PRODUCT_DATA: "/user-subscription-types/stock-keeping-product-data",
	UPLOAD: "/upload"
};

export const STATE_BASE_NAME = "courses-add-edit";

export const STRIPE_CURRENCY_SYMBOL_MAP = {
	usd: "$"
};

export const PAYMENT_CONSTANTS = {
	INITIAL: "initial",
	APPLE_INITIAL: "appleInitial",
	ADDITIONAL_INITIAL: "additionalInitial",
	ADDITIONAL_RECURRING: "additionalRecurring",
	ONE_TIME: "one_time",
	RECURRING: "recurring",
	RECURRING_QUESTION_BANK_ONLY: "recurringQuestionBankOnly"
};
