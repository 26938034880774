import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { MAX_VIT_ANSWER_OPTIONS, QuestionTypes } from "@remar/shared/dist/constants";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import get from "lodash/get";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";

import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { useStyles } from "../../../../QuestionBank/AddQuestion/styles";

import AnswerOption from "../../../../QuestionBank/components/AnswerOption";

import AnswerOptionDropZone from "../../../../QuestionBank/components/AnswerOptionDropZone";
import { useTestOnlyQuestionStyles } from "../../../TestOnlyQuestions/testOnlyQuestions.styles";

const maxActionableVITAnswerOptionCount = MAX_VIT_ANSWER_OPTIONS - 1;
const maxAnswerOptionsInAllGroups = 2 * (MAX_VIT_ANSWER_OPTIONS - 1) - 1;
const VITGroupingQuestion = ({
	createQuestionAnswerOptionItem,
	getFullState,
	initGroupingQuestion,
	moveGroupQuestionAnswerOption,
	setStateValue,
	validateForm,
	statePath = "",
	formName = "interactiveLessonFormGroup",
	templatePath = "questions.groups.answerOptions",
	groupTemplatePath = "questions.groups"
}) => {
	const dispatch = useDispatch();
	const classes = { ...useTestOnlyQuestionStyles(), ...useStyles() };
	const fullStatePath = statePath ? `inputs.${statePath}` : "inputs";
	const questionData = get(useSelector(getFullState), `${formName}.${fullStatePath}`);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const { groups } = questionData;

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(
				initGroupingQuestion({
					formPath: formName,
					questionPath: `${formName}.${fullStatePath}`,
					templatePath: groupTemplatePath
				})
			);
		}
	}, [groups.length]);

	return (
		<>
			<Typography className={classes.addOptionHeader}>ANSWER OPTIONS</Typography>
			<DndProvider backend={HTML5Backend}>
				<Box display="flex" flexDirection="row" width="calc(100% - 51px)" mt="1" style={{ gap: "24px" }}>
					{groups.map((answerGroupInputs, answerGroupIndex) => {
						const { text, answerOptions } = answerGroupInputs;
						let answerOptionCount = 0;
						let allAnswerOptions = 0;
						return (
							<div
								style={{ border: "solid 1px #4d5058", flex: "1", padding: "11px 10px 10px" }}
								key={`createVITLesson-answerGroup-${answerGroupIndex}`}
							>
								<CustomInput theme={theme} options={{ ...defaultCustomInputOptions, inputData: text }} />
								<AnswerOptionDropZone
									canAlwaysDrop={true}
									index={0}
									onDrop={data => {
										const { oldDropZoneIndex, order } = data;
										if (
											answerGroupIndex === oldDropZoneIndex ||
											answerOptions.filter(x => !x.deleted.value).length < maxActionableVITAnswerOptionCount
										) {
											dispatch(
												moveGroupQuestionAnswerOption({
													templatePath,
													formPath: formName,
													groupsPath: `${formName}.${fullStatePath}.groups`,
													newGroupIndex: answerGroupIndex!,
													oldGroupIndex: oldDropZoneIndex!,
													oldOptionIndex: order,
													newOptionIndex: 0
												})
											);
										}
									}}
								>
									<Box style={{ minHeight: 10 }} />
								</AnswerOptionDropZone>
								{answerOptions.map((answerOptionInputs, answerOptionIndex) => {
									const { deleted, text } = answerOptionInputs;
									if (deleted.value) {
										return null;
									}
									answerOptionCount++;
									allAnswerOptions++;
									return (
										<AnswerOptionDropZone
											canAlwaysDrop={true}
											dropZoneIndex={answerGroupIndex}
											key={`createVITLesson-qestionItem-answerGroup-${answerGroupIndex}-answerOption-${answerOptionIndex}`}
											index={answerGroupIndex}
											onDrop={data => {
												const { oldDropZoneIndex, newDropZoneIndex, order } = data;
												if (
													oldDropZoneIndex === newDropZoneIndex ||
													answerOptions.filter(x => !x.deleted.value).length < maxActionableVITAnswerOptionCount
												) {
													dispatch(
														moveGroupQuestionAnswerOption({
															templatePath,
															formPath: formName,
															groupsPath: `${formName}.${fullStatePath}.groups`,
															newGroupIndex: answerGroupIndex!,
															oldGroupIndex: oldDropZoneIndex!,
															oldOptionIndex: order,
															newOptionIndex: answerOptionIndex + 1
														})
													);
												}
											}}
										>
											<AnswerOption
												formName={formName}
												statePath={statePath}
												answerOptionIndex={answerOptionIndex}
												defaultCustomInputOptions={defaultCustomInputOptions}
												dropZoneIndex={answerGroupIndex}
												key={`createLesson-qestionItem-answerGroup-${answerGroupIndex}-answerOption-${answerOptionIndex}`}
												questionPath={`groups.${answerGroupIndex}`}
												typeId={QuestionTypes.Grouping}
												text={text}
											/>
										</AnswerOptionDropZone>
									);
								})}
								<AddButton
									isDisabled={
										answerOptionCount >= maxActionableVITAnswerOptionCount ||
										allAnswerOptions >= maxAnswerOptionsInAllGroups
									}
									onClick={() => {
										if (
											answerOptionCount < maxActionableVITAnswerOptionCount &&
											allAnswerOptions < maxAnswerOptionsInAllGroups
										) {
											dispatch(
												createQuestionAnswerOptionItem({
													formName,
													inputsPath: `${fullStatePath}.groups.${answerGroupIndex}.answerOptions`,
													parentPath: fullStatePath,
													templatePath
												})
											);
										}
									}}
									label={"Add option"}
								/>
							</div>
						);
					})}
				</Box>
			</DndProvider>
		</>
	);
};

export default VITGroupingQuestion;
