import React, { useEffect, useState } from "react";

import { HighlightTable, HightlightTableContainer, HotspotHighlightQuestionBox } from "./style";

import HotspotHighlightInput, { HighlightedNode } from "../../HotspotHighlightInput/HotspotHighlightInput";

const HighlightTableQuestionPreview = ({ question: { data, id } }) => {
	const [value, setValue] = useState<{
		[key: string]: HighlightedNode[];
	}>({});

	const disableInput = event => event.preventDefault();

	const handleUpdate = (groupId, highlighted) => {
		const newValue = { ...value, [groupId]: highlighted };
		setValue(newValue);
	};

	useEffect(() => {
		const newValue = {};
		data.groups.forEach(group => (newValue[group.id] = [{ text: group.text, highlighted: false }]));
		setValue(newValue);
	}, [id, data]);

	return (
		<HightlightTableContainer>
			<HighlightTable>
				<thead>
					<tr>
						<td>{data.tableLabel}</td>
						<td>{data.answerOptionLabel}</td>
					</tr>
				</thead>
				<tbody>
					{data.groups.map(group => (
						<tr key={`group-${group.id}`}>
							<td>{group.title}</td>
							<td>
								<HotspotHighlightQuestionBox onKeyPress={disableInput} onKeyDown={disableInput} onPaste={disableInput}>
									<HotspotHighlightInput
										value={value[group.id] ?? []}
										onChange={newValue => handleUpdate(group.id, newValue)}
										student
									/>
								</HotspotHighlightQuestionBox>
							</td>
						</tr>
					))}
				</tbody>
			</HighlightTable>
		</HightlightTableContainer>
	);
};
export default HighlightTableQuestionPreview;
