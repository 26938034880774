import { Box } from "@material-ui/core";
import styled from "styled-components";

export const SelectWrapper = styled(Box)`
	margin-bottom: 10px;
	button {
		width: 100%;
		background-color: ${({ theme }) => theme.palette.colors.basic[800]};
	}
`;
