import React, { useState } from "react";

import { Box, Typography } from "@material-ui/core";

import DialogModal from "@remar/shared/dist/modals/DialogModal/DialogModal";

import { InputNumberWithControls } from "../../../../Components/FormikFields";

const MAX_PAUSE_DAYS = 90;
const MIN_PAUSE_DAYS = 1;
interface IPauseSubModal {
	setShowPauseSubModal: React.Dispatch<React.SetStateAction<boolean>>;
	handlePause: (days: number) => void;
	pauseSubLoading: boolean;
}
const PauseSubModal = ({ setShowPauseSubModal, handlePause, pauseSubLoading }: IPauseSubModal) => {
	const [Days, setDays] = useState(MIN_PAUSE_DAYS);

	const handlePauseDaysChange = val => {
		setDays(val);
	};

	return (
		<DialogModal
			title={"Pause Subscription"}
			onClose={() => setShowPauseSubModal(false)}
			open={true}
			maxWidth={500}
			cancelBtnText={"Cancel"}
			successBtnText={"Pause"}
			onSuccess={() => handlePause(Days)}
			loading={pauseSubLoading}
			successBtnDisabled={pauseSubLoading || !Days || Days > MAX_PAUSE_DAYS || Days < MIN_PAUSE_DAYS}
		>
			<Box p={2}>
				<Typography variant={"body1"} color={"textSecondary"}>
					Are you sure you want to pause subscription? Select the number of days for pausing from 1 day to 90 days
					maximum.
				</Typography>{" "}
				<Box mt={2}>
					<InputNumberWithControls
						value={Days}
						InputProps={{
							disableUnderline: true
						}}
						inputProps={{
							min: MIN_PAUSE_DAYS,
							max: MAX_PAUSE_DAYS,
							style: {
								textAlign: "center"
							}
						}}
						onChange={handlePauseDaysChange}
						disabled={false}
						style={{
							width: "60px"
						}}
					/>
				</Box>
			</Box>
		</DialogModal>
	);
};

export default PauseSubModal;
