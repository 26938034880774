import React from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { Book } from "@remar/shared/dist/models";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";

import CheckPermissions from "core/CheckPermissions";

import { AddPackageIcon, BookWrapper, DropPackageIcon, EditPackageContainer, EditPackageIcon } from "./styles";

export enum BookItemActionTypes {
	EDIT = "Edit",
	DELETE = "Delete",
	ADD = "Add"
}

const getActionIcon = (actionType: BookItemActionTypes) => {
	switch (actionType) {
		case BookItemActionTypes.DELETE:
			return <DropPackageIcon width="15px" height="15px" />;
		case BookItemActionTypes.ADD:
			return <AddPackageIcon width="15px" height="15px" />;
		case BookItemActionTypes.EDIT:
			return <EditPackageIcon width="15px" height="15px" />;
		default:
			return null;
	}
};

type BookItemProps = Pick<Book, "name" | "description" | "data" | "imageUrl"> & {
	onAction: () => void;
	actionType?: BookItemActionTypes;
};
const BookItem = ({
	name,
	description,
	data,
	imageUrl,
	onAction,
	actionType = BookItemActionTypes.EDIT
}: BookItemProps) => (
	<BookWrapper>
		<Card style={{ padding: "20px" }}>
			<Box>
				<img width={"100%"} height={"280px"} src={imageUrl} alt="" />
			</Box>
			<Box>
				<Typography className={"title"}>{name}</Typography>
				<Box height={80}>
					<Typography className={"description"}>{description}</Typography>
				</Box>
			</Box>
			<Box mt={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
				<Typography className={"price"}>{formatUSD(data.price || 0)} </Typography>
				<CheckPermissions>
					<EditPackageContainer onClick={onAction}>{getActionIcon(actionType)}</EditPackageContainer>
				</CheckPermissions>
			</Box>
		</Card>
	</BookWrapper>
);

export default BookItem;
