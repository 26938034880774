import { BaseApiService, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "@remar/shared/dist/api/baseApiService";
import { File } from "@remar/shared/dist/models";

import { FileCreateDto, FileDeleteDto } from "./dto";

import { URLS } from "../services.constants";

export class FilesService extends BaseApiService<
	File,
	FileCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	FileDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const filesService = new FilesService(URLS.FILES);
